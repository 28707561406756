.sec-resistance-page{
	padding-bottom: 90px;
	@include mq() {
		padding-bottom: 60px;
	}
	.box-equipment-inspection{
		margin-bottom: 50px;
		@include mq() {
			margin-bottom: 20px;
		}
		.box-left{
			.txt-basic{
				@include mq() {
					margin-bottom: 0;
				}
			}
		}
	}
	.block-intro{
		max-width: 1096px;
		margin: 0 auto;
		&__txt{
			@include font-size(16);
			margin-bottom: 30px;
			line-height: 1.75;
			@include mq() {
				@include font-size(14);
				margin-bottom: 15px;
			}
		}
		&__img{
			margin-bottom: 45px;
			@include mq() {
				margin-bottom: 25px;
			}
			img{
				@include mq() {
					width: 100%;
				}
			}
		}
		.ttl-comp01{
			&>span{
				@include mq() {
					display: inline-block;
					position: relative;
					background-image: none;
					line-height: 1.7;
					padding: 0 20px;
					&::after{
						content: "";
						display: block;
						position: absolute;
						left: 0;
						bottom: 10px;
						height: 10px;
						width: 100%;
						background: #FF8D08;
						opacity: 0.5;
						z-index: -1;
					}
				}
			}
		}
		.item{
			display:-webkit-box; // old
			display:-moz-box; // old
			display:-ms-flexbox; // ie
			display:-webkit-flex; // new
			display:flex; // new
			flex-wrap: wrap;
			align-items: center;
			@include mq() {
				margin-bottom: 40px;
			}
			&__img{
				@include mq() {
					margin-bottom: 25px;
				}
				img{
					width: 100%;
				}
				.caption{
					display: block;
					@include font-size(14);
					padding-top: 10px;
				}
			}
			&__txt{
				p{
					@include font-size(16);
					line-height: 1.7;
					@include mq() {
						@include font-size(14);
					}
				}
			}
		}
		.more-btn{
			margin-top: 80px;
			@include mq() {
				margin-top: 0;
			}
			.btn-comp01{
				background-color: #FF8D08;
			}
		}
	}
}
.page-service_resistance_about_index{
	.sec-resistance-page{
		.block-intro{
			max-width: 1202px;
			.item{
				&--a01{
					.item__img{
						width: 42.56%;
						@include mq(md-min) {
							order: 2;
						}
						@include mq() {
							width: 100%;
						}
					}
					.item__txt{
						width: 57.44%;
						padding-right: 20px;
						@include mq(md-min) {
							order: 1;
						}
						@include mq() {
							width: 100%;
							padding: 0;
						}
					}
				}
				&--a02{
					.item__img{
						width: 54%;
						@include mq() {
							width: 100%;
						}
					}
					.item__txt{
						width: 46%;
						padding-left: 20px;
						@include mq() {
							width: 100%;
							padding: 0;
						}
					}
				}
			}
		}
	}
}