.sec-entry{
	margin-bottom: 150px;
	@include mq{
		margin-bottom: 50px;
	}
}
#form-resume{
	@include mq{
		margin-bottom: 25px;
	}
	@include mq(sm){
		overflow: hidden;
	}
	
	.form-right{
		width: calc(100% - 216px);
		@include mq(sm){
			width: 100%;
		}
	}
	.th-label{
		@include font-size(20);
		line-height: 2;
		color: #656565;
		width: 216px;
		@include mq{
			@include font-size(18);
			line-height: 1.6;
		}
		@include mq(sm){
			width: 100%;
			font-weight: 500;
		}
	}
	.full-width{
		width: calc(100% - 216px);
		@include mq(sm){
			width: 100%;
		}
	}
	
	input{
		padding: 4.5px 5px;
		width: 100%;
		@include font-size(16);
		color: #656565;
		@include mq{
			@include font-size(14);
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}
	.txt-label{
		@include font-size(20);
		line-height: 2;
		color: #656565;
		@include mq{
			@include font-size(16);
			line-height: 1.6;
		}
	}
	.form-group{
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		margin-bottom: 16px;
		flex-wrap: wrap;
		@include font-size(16);
		@include mq{
			@include font-size(14);
		}
	}
	.th-label{
		&--pdr{
			padding-right: 11px;
			padding-left: 5px;
			@include mq{
				padding: 0;
			}
			@include mq(sm){
				margin-bottom: 10px;
				
			}
		}
	}
	.show-inline{
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-wrap: wrap;
	}
	.list-birth{
		.item-birth{
			margin-right: 27px;
			@include mq(xl){
				margin-right: 15px;
			}
			@include mq{
				width: 100%;
				margin-right: 0;
				margin-bottom: 15px;
			}
			input{
				width: 200px;
				@include mq(xl){
					width:15vw;
				}
				@include mq{
					width: 65%;
				}
			}
			
			&:last-child{
				margin-right: 0;
			}
		}
	}
	.item-university{
		margin-right: 55px;
		@include mq(xl){
			margin-right: 25px;
		}
		@include mq{
			width: 100%;
			margin-right: 0;
			margin-bottom: 15px;
		}
		.txt-label{
			padding-left: 5px;
		}
	input{
		width: 308px;
		@include mq(xl){
			width: 22vw;
		}
		@include mq{
			width: 70%;
		}
	}
	}
	textarea{
		width: 100%;
		height: 220px;
		overflow-y: scroll;
		@include font-size(16);
		padding: 4.5px 5px;
		@include mq{
			@include font-size(14);
		}
	}
//
	/* Hide the file input using
opacity */
	[type=file] {
		position: absolute;
		filter: alpha(opacity=0);
		opacity: 0;
	}
	[type=file] + label,
	.file-label{
		border: 1px solid #346EB1;
		border-radius: 3px;
		text-align: left;
		padding: 7px 10px;
		
		min-width: 200px;
		margin: 0;
		left: 0;
		position: relative;
		@include mq(xl){
			width:16vw;
		}
	}
	[type=file] + label,
	.file-label{
		text-align: center;
		left: 0em;
		top: 0em;
		/* Decorative */
		background: #346EB1;
		color: #fff;
		border: none;
		cursor: pointer;
		@include font-size(16);
		display: inline-block;
	}
	[type=file] + label:hover,
	.file-label:hover{
		opacity: 0.6;
	}
	.mwform-file-delete{
		@include font-size(16);
		padding-left: 20px;
	}
	.error{
		padding-top: 10px;
	}
}
.note-form{
	@include font-size(20);
	line-height: 2;
	color: #656565;
}
.link-submit{
	text-align: right;
	@include mq{
		margin-bottom: 50px;
	}
	.btn-submit{
		background: #346EB1;
		border: 1px solid #346EB1;
		color: #fff;
		@include font-size(20);
		font-family: $font-meiryo;
		padding: 12px 10px;
		min-width: 256px;
		outline: none;
		border-radius: 5px;
		cursor: pointer;
		//float: right;
		margin-left: 20px;
		@include mq{
			margin-left: 0;
		}
		&:hover{
			opacity: 0.6;
		}
		&--back{
			background: #EEEEEE;
			color: #303030;
			border-color: #EEEEEE;
			@include mq{
				margin-bottom: 15px;
			}
		}
	}
}

.mw_wp_form_confirm{
	#form-resume{
		.file-label{
			display: none;
		}
	}
}