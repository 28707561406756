.sec-news{
	.ttl-comp01{
		@include mq(md-min){
			margin-bottom: 57px;
		}
	}
	.block-worksdetail{
		.part-time{
			background: #F2F0ED;
		}
	}
}