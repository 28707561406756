.page-items_detail{
	padding-bottom: 105px;
	@include mq() {
		padding-bottom: 50px;
	}
	.main-bg--content{
		padding-top: 60px;
		@include mq() {
			padding-top: 0;
		}
	}
}
.item-detail{
	max-width: 1064px;
	margin: 0 auto;
	&__ttl{
		&--01{
			@include font-size(28);
			color: #346EB1;
			font-weight: bold;
			border-bottom: 1px solid #346EB1;
			margin-bottom: 10px;
			@include mq() {
				@include font-size(18);
			}
		} 
		&--02{
			@include font-size(28);
			font-weight: bold;
			margin-bottom: 10px;
			@include mq() {
				@include font-size(18);
			}
		}
	}
	&__img{
		display:-webkit-box; // old
		display:-moz-box; // old
		display:-ms-flexbox; // ie
		display:-webkit-flex; // new
		display:flex; // new
		flex-wrap: wrap;
		margin-bottom: 15px;
		.item{
			position: relative;
			overflow: hidden;
			width: 100%;
			max-width: 416px;
			border: 1px solid #707070;
			margin-right: 18px;
			margin-bottom: 18px;
			&::after{
				content: "";
				display: block;
				padding-top: 100%;
			}
			img{
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				height: auto;
				width: auto;
				max-width: 97%;
				max-height: 97%;
				margin: auto;
			}
			@include mq(lg) {
				width: 49%;
				margin-right: 2%;
				&:nth-of-type(2n){
					margin-right: 0;
				}
			}
			@include mq() {
				width: 100%;
				margin: 0 0 20px;
				max-width: inherit;
			}
		}
	}
	&__code{
		display:-webkit-box; // old
		display:-moz-box; // old
		display:-ms-flexbox; // ie
		display:-webkit-flex; // new
		display:flex; // new
		flex-wrap: wrap;
		align-items: center;
		margin-bottom: 30px;
		.code{
			display:-webkit-box; // old
			display:-moz-box; // old
			display:-ms-flexbox; // ie
			display:-webkit-flex; // new
			display:flex; // new
			flex-wrap: wrap;
			align-items: center;
			@include font-size(14);
			dt{
				font-weight: bold;
			}
			dd{
				margin-right: 20px;
			}
		}
	}
	.dl-btn{
		max-width: 255px;
		color: #FFF;
		text-align: center;
		@include font-size(20);
		margin-right: 20px;
		@include mq() {
			width: 100%;
			margin: 0 auto 30px;
			@include font-size(18);
		}
		a{
			position: relative;
			display: block;
			padding: 10px 60px 10px 15px;
			background: #FF8D08;
			@include mq() {
				padding-top: 13px;
				padding-bottom: 13px;
			}
			&::after{
				content: "";
				display: block;
				position: absolute;
				top: 50%;
				margin-top: -17px;
				right: 15px;
				background: url("../img/items/pdf-ico.png") no-repeat 0 0;
				background-size: 100% auto;
				width: 26px;
				height: 34px;
			}
		}
	}
	&__spec{
		margin-bottom: 30px;
		&__inner{
			@include mq() {
				width: 100%;
				display: block;
				overflow-x: scroll;
				white-space: nowrap;
				-webkit-overflow-scrolling: touch;
				padding-bottom: 10px;
			}
		}
		table{
			width: 100%;
			@include font-size(14);
			@include mq() {
				@include font-size(12);
			}
			th,td{
				border-right: 3px solid #FFF;
				text-align: center;
				padding: 5px;
				@include mq() {
					border-width: 2px;
					padding: 5px 10px;
				}
			}
			th{
				background: #346EB1;
				border-bottom: 3px solid #FFF;
				color: #FFF;
				font-weight: bold;
				@include mq() {
					border-width: 2px;
				}
			}
			td{
				background: #F2F0ED;
			}
		}
	}
	&__other{
		border-bottom: 1px solid #707070;
		padding-bottom: 30px;
		margin-bottom: 50px;
		&__ttl{
			@include font-size(20);
			border-bottom: 1px solid #707070;
			padding-bottom: 5px;
			margin-bottom: 30px;
			@include mq() {
				@include font-size(16);
			}
		}
		&__txt{
			@include font-size(16);
			padding: 0 15px;
			@include mq() {
				@include font-size(14);
				padding: 0;
			}
			a{
				text-decoration: underline;
			}
			strong{
				font-weight: bold;
			}
			em{
				font-style: italic;
			}
			ul,ol{
				margin-bottom: 35px;
				li{
					position: relative;
					padding-left: 10px;
					@include font-size(16);
					margin-bottom: 5px;
					&::before{
						content: "";
						position: absolute;
						left: 0;
						top: 50%;
						margin-top: -2px;
						display: block;
						width: 4px;
						height: 4px;
						background: #434444;
						border-radius: 5px;
					}
				}
			}
		}
	}
}