.box-st {
	margin-bottom: 28px;
	@include mq {
		margin-bottom: 20px;
	}
}

.ttl-st {
	color: #E3FEFF;
	@include font-size(20);
	padding: 3px 12px 1px;
	background-color: #346EB1;
	margin-bottom: 6px;
	@include mq {
		@include font-size(18);
		padding: 4px 12px 3px;
		margin-bottom: 6px;
	}
	@include mq(xs) {
		@include font-size(16);
	}
	&02 {
		@include font-size(20);
		line-height: 1.2;
		font-weight: bold;
		padding: 9px 17px 8px;
		border-top: 1px solid #346EB1;
		border-bottom: 1px solid #346EB1;
		margin-bottom: 19px;
		color: #346EB1;
		@include mq {
			@include font-size(16);
			line-height: 1.5;
			padding: 5px 0;
			margin-bottom: 15px;
		}
	}
	&03 {
		@include font-size(20);
		line-height: 1.2;
		text-align: center;
		background-color: #96B7DD;
		padding: 3px 10px 1px;
		margin-bottom: 8px;
		@include mq {
			@include font-size(18);
			line-height: 1.56;
			padding: 2px 10px 0px;
			margin-bottom: 6px;
		}
	}
}

.txt-st {
	@include font-size(14);
	line-height: 1.72;
	&02 {
		@include font-size(16);
		line-height: 1.75;
		@include mq {
			@include font-size(14);
			line-height: 1.72;
		}
	}
	&03 {
		@include font-size(16);
		line-height: 1.75;
	}
}

.layout-st {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -9px 18px;
	@include mq {
		margin: 0 0 21px;
	}
	.col-left {
		padding: 0 9px;
		width: 100%;
		max-width: 30.1%;
		flex: 0 0 30.1%;
		@include mq {
			max-width: 100% !important;
			flex: 0 0 100% !important;
			padding: 0!important;
		}
	}
	.col-right {
		padding: 0 9px;
		width: 100%;
		max-width: 69.9%;
		flex: 0 0 69.9%;
		@include mq {
			max-width: 100% !important;
			flex: 0 0 100% !important;
			padding: 0!important;
		}
	}
	&--style02 {
		margin: 0 -8px 10px;
		@include mq {
			margin: 0 0 7px;
		}
		.col-left {
			max-width: 60%;
			flex: 0 0 60%;
			padding: 0 8px;
		}
		.col-right {
			max-width: 40%;
			flex: 0 0 40%;
			padding: 0 8px;
		}
	}
	&--style03 {
		margin: 0 -13.5px 32px;
		@include mq {
			margin: 0 0 31px;
		}
		.col-left {
			padding: 0 13.5px;
			max-width: 48%;
			flex: 0 0 48%;
			@include mq {
				order: 1;
			}
		}
		.col-right {
			padding: 0 13.5px;
			max-width: 52%;
			flex: 0 0 52%;
			@include mq {
				padding: 0 2px !important;
				margin-bottom: 15px;
			}
		}
	}
	&--style04 {
		margin: 0 -8px 18px;
		@include mq {
			margin: 0 0 0;
		}
		.col-left {
			padding: 0 8px;
			max-width: 40%;
			flex: 0 0 40%;
		}
		.col-right {
			padding: 0 8px;
			max-width: 60%;
			flex: 0 0 60%;
		}
	}
	&--style05 {
		margin: 0 -7px 72px;
		@include mq {
			margin: 0 0 0;
		}
		.col-left {
			padding: 0 7px;
			max-width: 50.1%;
			flex: 0 0 50.1%;
		}
		.col-right {
			padding: 0 7px;
			max-width: 49.9%;
			flex: 0 0 49.9%;
		}
	}
	&--style06 {
		margin: 0 -7px 37px;
		@include mq {
			margin: 0 0 82px;
		}
		.col-left {
			padding: 0 7px;
			max-width: 49.95%;
			flex: 0 0 49.95%;
			@include mq {
				order: 1;
			}
		}
		.col-right {
			padding: 0 7px;
			max-width: 50.05%;
			flex: 0 0 50.05%;
		}
	}
	&--style07 {
		margin: 0 -42px 18px;
		@include mq(xl) {
			margin: 0px -10px 18px;
		}
		@include mq {
			margin: 0 0 0;
		}
		.col-left {
			padding: 0 42px;
			max-width: 44.3%;
			flex: 0 0 44.3%;
			@include mq(xl) {
				padding: 0 10px;
			}
		}
		.col-right {
			padding: 0 42px;
			max-width: 55.7%;
			flex: 0 0 55.7%;
			@include mq(xl) {
				padding: 0 10px;
			}
		}
	}
	&--style08 {
		margin-bottom: 41px;
		@include mq {
			margin-bottom: 77px;
		}
		.col-left {
			padding-right: 38px;
			max-width: 42.8%;
			flex: 0 0 42.8%;
			@media screen and (max-width: 1500px) {
				padding-right: 2.533vw;
			}
			@include mq {
				order: 1;
			}
		}
		.col-right {
			padding-top: 1px;
			max-width: 57.2%;
			flex: 0 0 57.2%;
			@include mq {
				margin-bottom: 12px;
			}
		}
	}
	&--style09 {
		margin: 0 -8px 41px;
		@include mq {
			margin: 0 0 37px;
		}
		.col-left {
			padding: 0 8px;
			max-width: 59.96%;
			flex: 0 0 59.96%;
			@include mq {
				order: 1;
			}
		}
		.col-right {
			padding: 0 8px;
			max-width: 40.04%;
			flex: 0 0 40.04%;
			@include mq {
				margin-bottom: 14px;
			}
		}
	}
	&--style10 {
		margin: 0 -11px 92px;
		@include mq {
			margin: 0;
		}
		.col {
			padding: 0 11px;
			width: 100%;
			max-width: 33.3333%;
			flex: 0 0 33.3333%;
			@include mq {
				max-width: 100%;
				flex: 0 0 100%;
				padding: 0 0 0 2px;
				margin-bottom: 79px;
			}
		}
	}
	&--style11 {
		margin: 0 -8px 112px;
		@include mq {
			margin: 0 0 62px;
		}
		.col {
			padding: 0 8px;
			width: 100%;
			max-width: 50%;
			flex: 0 0 50%;
			@include mq {
				max-width: 100%;
				flex: 0 0 100%;
				padding: 0;
				margin-bottom: 71px;
			}
		}
		&.reverse {
			.col {
				@include mq {
					margin-bottom: 0;
				}
				&:first-child {
					padding: 0 8px 0 11px;
					@include mq {
						order: 1;
						padding: 0;
					}
				}
				&:last-child {
					padding-top: 5px;
				}
			}
		}
	}
	&--style12 {
		margin: 0 0 102px;
		@include mq {
			margin: 0 0 131px;
		}
		.col-left {
			max-width: 50%;
			flex: 0 0 50%;
			padding: 0;
			@include mq {
				order: 1;
			}
		}
		.col-right {
			max-width: 50%;
			flex: 0 0 50%;
			padding: 29px 15px 0;
			@include mq {
				margin-bottom: 10px;
			}
		}
	}
}

.list-st {
	&-howto {
		@include mq {
			margin-bottom: 15px;
		}
		&>li {
			@include font-size(18);
			line-height: 1.33;
			color: #346EB1;
			border: 2px solid #346EB1;
			padding: 4px 8px 3px;
			margin-bottom: 6px;
			@include mq {
				@include font-size(16);
				line-height: 1.5;
				padding: 4px 8px 2px;
			}
		}
	}
	&-process {
		margin-bottom: 13px;
		@include mq {
			margin-bottom: 16px;
		}
		&>li {
			@extend .txt-st02;
			&:not(:last-child) {
				padding-bottom: 53px;
				position: relative;
				@include mq {
					padding-bottom: 74px;
				}
				&:after {
					content: '';
					position: absolute;
					z-index: 1;
					left: 49%;
					bottom: 1px;
					transform: translateX(-50%);
					width: 42px;
					height: 48px;
					background: url("../img/service/lightning/standard/2013/ico-down.png") no-repeat center;
					@include mq {
						bottom: 11px;
						left: 50%;
					}
				}
			}
			&:first-child {
				padding-bottom: 50px;
				@include mq {
					padding-bottom: 61px;
				}
			}
		}
	}
	&-type {
		margin-bottom: 16px;
		@include mq {
			margin: 0 -7px 11px 0;
		}
		li {
			display: flex;
			@extend .txt-st;
			span {
				margin-right: 14px;
			}
		}
	}
}

.warning-st {
	border: 2px solid #346EB1;
	background-color: #EEEEEE;
	padding: 9px 9px 9px 10px;
	@include mq {
		margin: 0 1px 0 2px;
		padding: 9px 11px 12px 10px;
	}
	.head {
		display: flex;
		align-items: center;
		margin-bottom: 1px;
		@include mq {
			margin-bottom: 7px;
		}
		img {
			flex-shrink: 0;
			margin-right: 12px;
			@include mq {
				margin: 0 11px 5px 1px;
			}
		}
		.txt-st {
			flex-grow: 1;
			@include mq {
				@include font-size(12);
				line-height: 1.67;
			}
		}
	}
	.body {
		display: flex;
		border: 1px solid #346EB1;
		@include font-size(12);
		line-height: 1.67;
		@include mq {
			flex-wrap: wrap;
		}
		.label {
			width: 112px;
			flex-shrink: 0;
			text-align: center;
			color: #ffffff;
			background-color: #346EB1;
			padding: 12px 5px;
			display: flex;
			justify-content: center;
			align-items: center;
			@include mq {
				width: 100%;
				text-align: center;
				padding: 2px 5px;
			}
		}
		.content {
			flex-grow: 1;
			background-color: #fff;
			padding: 2px 7px;
			@include mq {
				width: 100%;
				padding: 7px;
			}
		}
	}
}

.table-st {
	width: 100%;
	table-layout: fixed;
	border-collapse: collapse;
	@extend .txt-st;
	line-height: 1.7;
	@include mq {
		@include font-size(12);
		line-height: 1.67;
		margin-bottom: 17px;
	}
	td, th {
		border: 1px solid #000000;
		text-align: center;
		font-weight: normal;
	}
	thead {
		th {
			background-color: #96B7DD;
			&:first-child {
				@include mq {
					width: 14.7%;
				}
			}
			&:nth-child(2) {
				@include mq {
					width: 15%;
				}
			}
			&:last-child {
				@include mq {
					width: 18%;
				}
			}
		}
		th, td {
			@include mq {
				padding: 2px 0 1px;
			}
		}
	}
	tbody {
		background-color: #fff;
		th, td {
			@include mq {
				padding: 2px 0 1px;
			}
		}
	}
	&--style02 {
		@include mq {
			margin-bottom: 14px;
		}
		thead {
			th {
				&:first-child {
					width: 23.7%;
					@include mq {
						width: 28.7%;
					}
				}
				&:nth-child(2) {
					@include mq {
						width: auto;
					}
				}
				&:last-child {
					@include mq {
						width: auto;
					}
				}
			}
		}
		tbody {
			th {
				padding: 10px 8px 8px;
				text-align: left;
				line-height: 1.29;
				@include mq {
					@include font-size(14);
					text-align: center;
				}
				@include mq(xs) {
					@include font-size(12);
				}
			}
		}
		&.fix {
			tbody {
				th {
					padding: 10px 6px 8px;
					text-align: left;
				}
			}
		}
	}
	&--style03 {
		@include mq {
			@include font-size(14);
			table-layout: auto;
			margin-bottom: 10px;
		}
		@include mq(xs) {
			@include font-size(12);
		}
		thead {
			th {
				line-height: 1.15;
				padding: 7px 0 4px;
				&:first-child {
					@include mq {
						width: 29.7%;
						line-height: 1.34;
						padding: 6px 0 3px;
						@include mq {
							@include font-size(12);
						}
					}
				}
				&:nth-child(2) {
					@include mq {
						width: auto;
					}
				}
				&:last-child {
					@include mq {
						width: auto;
					}
				}
			}
		}
		tbody {
			tr:first-child th {
				&:first-child {
					background-color: #96B7DD;
					@include mq {
						width: 20%;
					}
					@include mq(xs) {
						width: 23%;
					}
				}
				&:nth-child(2) {
					@include mq {
						width: 9.7%;
					}
				}
			}
			th, td {
				@include mq {
					padding: 0;
				}
			}
		}
	}
	&--style04 {
		margin-bottom: 20px;
		@include mq {
			margin-bottom: 12px;
		}
		thead {
			th {
				padding: 3px 0;
				&:first-child {
					@include mq {
						width: 34%;
					}
				}
				&:nth-child(2) {
					@include mq {
						width: 14.5%;
					}
				}
				&:last-child {
					@include mq {
						width: auto;
					}
				}
			}
		}
		tbody {
			th {
				line-height: 1.43;
				padding: 10px 10px 8px 17px;
				text-align: left;
				@include mq {
					text-align: center;
					@include font-size(14);
					line-height: 1.5;
					padding: 19px 5px;
				}
				@include mq(xs) {
					padding: 15px 0;
					@include font-size(12);
				}
			}
		}
	}
	&--style05 {
		border-collapse: inherit;
		border: 1px solid #000000;
		@include mq {
			margin-bottom: 19px;
		}
		td, th {
			border: none;
			&:not(:last-child) {
				border-right: 1px solid #000000;
			}
		}
		thead {
			th {
				padding: 3px 0;
				border-bottom: 1px solid #000000;
				@include mq {
					padding: 3px 0 2px;
					@include font-size(14);
					line-height: 1.72;
				}
				@include mq(xs) {
					@include font-size(12);
				}
				&:first-child, &:last-child {
					width: 31.1%;
					@include mq {
						width: 12%;
					}
				}
			}
		}
		tbody {
			tr {
				&:first-child {
					td {
						padding-top: 6px;
					}
				}
				&:last-child {
					td {
						padding-bottom: 8px;
					}
				}
			}
			th, td {
				@include mq {
					padding: 0;
					@include font-size(14);
				}
				@include mq(xs) {
					@include font-size(12);
				}
			}
			td {
				line-height: 1.72;
			}
		}
		&.fix {
			thead {
				th {
					&:first-child {
						@include mq {
							width: 31%;
						}
					}
					&:nth-child(2) {
						@include mq {
							width: 34%;
						}
					}
					&:last-child {
						@include mq {
							width: auto;
						}
					}
				}
			}
		}
	}
	&--style06 {
		@extend .table-st--style05;
		@include mq {
			margin-bottom: 14px;
		}
		thead {
			th {
				@include mq {
					padding: 4px 0;
				}
				&:first-child {
					width: 23.7%;
					@include mq {
						width: 25.7%;
					}
				}
				&:nth-child(2) {
					width: 29%;
					@include mq {
						width: 31%;
					}
				}
				&:nth-child(3) {
					width: 25%;
					@include mq {
						width: 23%;
						@include font-size(12);
						line-height: 1.34;
					}
				}
				&:last-child {
					width: auto;
					@include mq {
						@include font-size(12);
						line-height: 1.34;
					}
				}
			}
		}
		tbody {
			tr {
				&:first-child {
					th, td {
						@include mq {
							padding-top: 2px;
						}
					}
				}
				&:last-child {
					th, td {
						@include mq {
							padding-bottom: 0;
						}
					}
				}
			}
		}
	}
}

.note-st {
	@extend .txt-st;
	border: 2px solid #346EB1;
	padding: 23px 16px 22px;
	@include mq {
		padding: 10px 7px 7px 10px;
	}
	&--style02 {
		@extend .txt-st02;
		padding: 18px;
		margin-bottom: 67px;
		@include mq {
			padding: 13px 15px;
			margin-bottom: 77px;
		}
	}
	&--style03 {
		@extend .txt-st02;
		border: 1px solid #303030;
		padding: 15px;
		@include mq {
			padding:11px 15px 9px;
			margin-bottom: 52px;
		}
	}
	&--style04 {
		padding: 13px 10px 10px 14px;
	}
}

.box-materials {
	$t: &;
	border-width: 0 2px 2px 2px;
	border-color: #346EB1;
	border-style: solid;
	&--style02 {
		#{$t}__body {
			padding: 21px 336px 29px;
			@media screen and (max-width: 1500px) {
				padding: 21px 22.4vw 29px;
			}
			@include mq {
				padding: 6px 12px 16px;
			}
		}
		&.fix {
			#{$t}__body {
				padding-bottom: 18px;
				@include mq {
					padding-bottom: 5px;
				}
			}
			.table-st {
				margin-bottom: 4px!important;
			}
		}
	}
	.ttl-st {
		padding: 3px 26px 1px;
		@include mq {
			padding: 4px 10px 3px;
		}
	}
	&__body {
		padding: 20px 51px 16px 25px;
		@include mq(xl) {
			padding: 20px 25px 16px;
		}
		@include mq {
			padding: 6px 11px 9px 12px;
		}
		.ttl {
			@include font-size(20);
			line-height: 1;
			margin-bottom: 13px;
			color: #346EB1;
			@include mq(xl) {
				@include font-size(14);
			}
			@include mq {
				@include font-size(18);
				margin-bottom: 11px;
				line-height: 1.34;
			}
			@include mq(xs) {
				@include font-size(16);
				line-height: 1.5;
			}
		}
		.txt {
			@extend .txt-st;
			line-height: 2;
		}
	}
}

.fzsp-10 {
	@include mq {
		@include font-size(10);
	}
}

.mlrsp--16 {
	@include mq {
		margin-left: -16px;
		margin-right: -16px;
	}
}

.pc-1100 {
	@media screen and (max-width: 1100px) {
		display: none;
	}
}