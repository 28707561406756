.sec-introbrand{
	margin-bottom: 100px;
	@include mq{
		margin-bottom: 50px;
	}
}
.block-introbrand{
	.inner{
		@include mq{
			padding: 0;
		}
	}
}
.part-introbrand{
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	flex-wrap: wrap;
	@include mq(ipd){
		align-items: flex-start;
	}
	&__txt{
		width: 61%;
		padding-right: 15px;
		
		@include mq(sm){
			width: 100%;
			margin-bottom: 20px;
			padding-right: 0;
			
		}
		.txt-lg{
			margin-bottom: 20px;
			&:last-child{
				margin-bottom: 0;
			}
		}
		.link-target{
			a{
				font-weight: bold;
				color: #14137E;
				word-break: break-all;
				@supports (-ms-ime-align:auto) {
					word-wrap: break-word;
				}
				@include mq(xs){
					@include font-size(14);
				}
			}
		}
		
	}
	
	&__logo{
		margin: 0 auto 25px;
		text-align: center;
		@include mq(sm){
			width: 50%;
			margin: 0 auto 25px;
		}
		
	}
	&__img{
		width: 39%;
		img{
			margin-bottom: 20px;
		}
		@include mq(sm){
			width: 100%;
			text-align: center;
		}
	}
}