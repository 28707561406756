
.inner {
	display: block;
	max-width: 1312px;
	padding: 0 16px;
	margin: 0 auto;
	
	&-1064 {
		max-width: 1096px;
	}
	
	&-1052 {
		max-width: 1084px;
	}
	
	&-848 {
		max-width: 880px;
	}
}

.clearfix {
	*zoom: 1;
	
	&:before {
		content: "";
		display: table;
	}
	
	&:after {
		clear: both;
		content: "";
		display: table;
	}
}

a {
	text-decoration: none;
	color: inherit;
	@include smooth-transition;
	
	&:hover {
		opacity: 0.7;
	}
	
	&[href^="tel"] {
		@include mq(md-min) {
			pointer-events: none;
		}
		@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
			display: inline-block;
		}
	}
}


.pc {
	@include mq() {
		display: none !important;
	}
	
	&-ipd {
		@include mq(ipd) {
			display: none !important;
		}
	}
	
	&-lg {
		@include mq(lg) {
			display: none !important;
		}
	}
	
	&-sm {
		@include mq(sm) {
			display: none !important;
		}
	}
	
	&-xs {
		@include mq(xs) {
			display: none !important;
		}
	}
}

.sp {
	@include mq(md-min) {
		display: none !important;
	}
	
	&-ipd {
		@include mq(ipd-min) {
			display: none !important;
		}
	}
	
	&-lg {
		@include mq(lg-min) {
			display: none !important;
		}
	}
	
	&-sm {
		@include mq(sm-min) {
			display: none !important;
		}
	}
	
	&-xs {
		@include mq(xs-min) {
			display: none !important;
		}
	}
}

// fix ttl on mac
body.mac {
	.ttl-comp01 > span {
		@include mq {
			background-position: 0% 0.6em;
		}
	}
}

//ttl
.ttl-comp {
	&00 {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		font-family: $font-meiryo;
		@include font-size(40);
		line-height: 1.2;
		color: #0060CB;
		font-weight: 400;
		text-align: center;
		margin-bottom: 50px;
		@include mq {
			@include font-size(32);
		}
		&__english {
			font-family: $font-raleway;
			@include font-size(32);
			display: inline-block;
			font-weight: 400;
			text-transform: uppercase;
			margin-left: 10px;
			position: relative;
			top: 2px;
			@include mq {
				@include font-size(20);
				display: inline-block;
				width: 100%;
			}
		}
	}
	&01 {
		@include font-size(32);
		line-height: 1.1;
		text-align: center;
		margin-bottom: 46px;
		@include mq(xl) {
			@include font-size(28);
		}
		@include mq {
			@include font-size(24);
			margin-bottom: 22px;
		}
		@include mq(xs) {
			@include font-size(23);
		}
		
		small {
			@include font-size(20);
			font-family: $font-raleway;
			text-transform: uppercase;
			@include mq(xl) {
				@include font-size(18);
			}
			@include mq {
				@include font-size(16);
			}
		}
		
		> span {
			background-image: linear-gradient(to right, rgba(#FF8D08, 0.5) 50%, transparent 50%);
			background-size: 200% 12px;
			background-repeat: no-repeat;
			background-position: 0% .85em;
			padding: 0 32px;
			@include mq {
				background-position: 0% 0.75em;
				padding: 0;
			}
		}
		
		&--style02 {
			> span {
				@include mq {
					background: none;
					display: block;
					position: relative;
					z-index: 1;
					&:after {
						position: absolute;
						content: '';
						bottom: 0;
						left: 0;
						right: 0;
						background: rgba(#FF8D08, 0.5);
						height: 12px;
						z-index: -1;
					}
				}
			}
		}
		
		&--white {
			color: white;
		}
	}
	
	&02 {
		
		font-family: $font-meiryo;
		color: #303030;
		@include font-size(24);
		font-weight: bold;
		line-height: 1.667;
		margin: 0 auto 28px;
		text-align: center;
		@include mq {
			@include font-size(16);
			line-height: 1.75;
			margin: 0 auto 28px;
		}
		
		span {
			display: inline-block;
			padding-bottom: 6px;
			@include mq {
				padding: 0 8px 1px 8px;
			}
		}
		
		&.bd-bt {
			span {
				border-bottom: 3px solid #0060CB;
			}
			
		}
	}
	
	&03 {
		font-family: $font-raleway;
		@include font-size(32);
		line-height: 1.25;
		font-weight: 400;
		color: #303030;
		margin-bottom: 11px;
		@include mq {
			@include font-size(28);
		}
		@include mq(xs) {
			@include font-size(22);
		}
		&--line {
			padding-bottom: 6px;
			border-bottom: 10px solid #96B7DD;
			margin-bottom: 39px;
			@include mq {
				border-width: 5px;
				margin-bottom: 30px;
			}
		}
	}
	
	&04 {
		font-weight: bold;
		@include font-size(20);
		border-bottom: solid 2px;
		color: #0060CB;
		margin-bottom: 11px;
		@include mq{
			@include font-size(18);
		}
		&--nobdbt{
			border-bottom: none;
		}
	}
	
	&05 {
		@include font-size(32);
		line-height: 1.25;
		color: #346EB1;
		text-align: center;
		margin-bottom: 40px;
		@include mq(xl) {
			@include font-size(27);
		}
		@include mq {
			@include font-size(22);
			margin-bottom: 30px;
		}
		
		> span {
			display: inline-block;
			position: relative;
			padding-bottom: 3px;
			
			&:after {
				position: absolute;
				content: '';
				left: 0;
				right: 0;
				bottom: 0;
				height: 4px;
				background: #96B7DD;
			}
			
			.en {
				@include font-size(20);
				line-height: 2;
				font-family: $font-raleway;
				text-transform: uppercase;
				@include mq(xl) {
					@include font-size(17);
				}
				@include mq {
					@include font-size(14);
				}
			}
		}
	}
	
	&06 {
		font-weight: bold;
		@include font-size(20);
		line-height: 1.4;
		color: #346EB1;
		margin-bottom: 10px;
		display: flex;
		align-items: center;
		
		&:before {
			content: "";
			display: inline-block;
			width: 10px;
			height: 10px;
			background: #346eb1;
			border-radius: 50%;
			overflow: hidden;
			margin-right: 3px;
			@include mq {
				margin-top: 5px;
			}
		}
		
		@include mq {
			@include font-size(16);
			align-items: flex-start;
		}
	}
	
	&07 {
		@include font-size(20);
		font-weight: bold;
		text-align: center;
		margin-bottom: 55px;
		line-height: 1.4;
		color: #346EB1;
		span {
			@include font-size(18);
			line-height: 2;
			display: block;
			font-weight: 400;
			color: #303030;
			margin-top: 12px;
		}
		
		@include mq {
			@include font-size(16);
			margin-bottom: 30px;
			span {
				@include font-size(14);
				margin-top: 5px;
			}
		}
	}
	&08{
		@include font-size(18);
		line-height: 1.56;
		color: #346EB1;
		font-weight: bold;
		margin-bottom: 14px;
		@include mq{
			margin-bottom: 10px;
		}
	}
	&09{
		@include font-size(24);
		font-weight: bold;
		color: #4EB46E;
		background: #C8E5CB;
		border: 2px solid #4EB46E;
		text-align: center;
		padding-top: 3px;
		margin-bottom: 28px;
		@include mq(lg) {
			@include font-size(20);
		}
		@include mq {
			border-width: 1px;
			@include font-size(16);
		}
		&--blue {
			border-color: #346EB1;
			background: #E3FEFF;
			color: #346EB1;
		}
		&--sm {
			@include font-size(20);
			padding: 4px 0 2px;
			@include mq(lg) {
				@include font-size(18);
			}
			@include mq {
				border-width: 1px;
				@include font-size(15);
			}
		}
	}
}
.mac{
	.ttl-comp01 > span{
		background-position: 0% .7em;
	}
}

//text
.txt {
	&-basic {
		line-height: 2;
		@include font-size(16);
		@include mq(lg) {
			@include font-size(15);
		}
		@include mq() {
			@include font-size(14);
		}
		&--bold{
			font-weight: bold;
			
		}
		&--bdbt{
			border-bottom: 1px solid #707070;
		}
	}
	
	&-lg {
		@include font-size(18);
		line-height: 1.78;
		@include mq(xl) {
			@include font-size(16);
		}
		@include mq {
			line-height: 1.5;
			@include font-size(15);
		}
	}
	&-smaller{
		@include font-size(14);
		line-height: 1.72;
		display: inline-block;
		width: 100%;
	}
	
	&-center {
		text-align: center;
	}
	
	&-right {
		text-align: right;
	}
	
	&-left {
		text-align: left;
	}
}

//btn
.btn {
	&-comp01 {
		display: flex;
		align-items: center;
		width: 100%;
		max-width: 154px;
		background: #346EB1;
		height: 30px;
		margin: 0 auto;
		font-weight: 500;
		@include font-size(14);
		color: #fff;
		padding: 3px 7px 1px;
		position: relative;
		&:after {
			position: absolute;
			content: '';
			width: 32px;
			height: 6px;
			background: url("../img/index/arrow-right-white.png") no-repeat;
			background-size: 100% 100%;
			right: 10px;
			top: 50%;
			transform: translateY(-50%);
			transition: 0.3s;
		}
		
		&:hover {
			opacity: 0.7;
			
			&:after {
				right: 5px;
				transition: 0.3s;
			}
		}
		
		&--short {
			max-width: 106px;
		}
		
		&--left {
			text-align: right;
			padding-right: 15px;
			justify-content: flex-end;
			height: 40px;
			max-width: 175px;
			
			&:after {
				right: auto;
				left: 10px;
				background: url("../img/recruit/message/arrow-left.png") no-repeat;
			}
			
			&:hover {
				&:after {
					right: auto;
					left: 16px;
				}
			}
		}
	}
	
	&-comp02 {
		background: linear-gradient(to bottom, #61bef7 0%, #0f6296 100%);
		font-weight: bold;
		@include font-size(24);
		line-height: 1.17;
		color: #ffffff;
		padding: 24px 50px 23px 21px;
		position: relative;
		width: 100%;
		max-width: 416px;
		display: inline-block;
		text-align: left;
		@include mq {
			max-width: 300px;
			padding: 12px 50px 12px 15px;
			@include font-size(16);
			line-height: 1.5;
		}
		
		&:after {
			content: '';
			position: absolute;
			@include v-align;
			right: 16px;
			width: 18px;
			height: 30px;
			background: url("../img/businesspartner/ico-next.png") no-repeat;
			transition: 0.3s;
			@include mq {
				right: 10px;
				width: 10px;
				height: 16px;
				-webkit-background-size: contain;
				background-size: contain;
			}
		}
		
		&:hover:after {
			right: 12px;
		}
	}
	
	&-com03 {
		@include font-size(26);
		line-height: 1.17;
		color: #346EB1;
		padding: 18px 20px 13px;
		width: 100%;
		max-width: 416px;
		display: block;
		text-align: center;
		border: 1px solid #346EB1;
		border-radius: 17px;
		margin: 0 auto;
		transition: all 0.25s;
		&:hover{
			transition: all 0.25s;
			opacity: 0.7 !important;
		}
		@include mq {
			max-width: 300px;
			padding: 12px 15px;
			@include font-size(16);
			line-height: 1.5;
		}
	}
}

// css module for mainvisual
.sec-mvpage {
	height: 43.34vw;
	@include mq(lg) {
		height: calc(100vh - 140px);
	}
	@include mq(sm-420) {
		height: 93.33vw;
	}
	
	.block-mvpage {
		position: fixed;
		top: 70px;
		width: 100%;
		height: inherit;
		@include mq(lg) {
			height: calc(100vh - 140px);
		}
		@include mq(sm-420) {
			height: inherit;
		}
	}
	
	.img-mv {
		height: 100%;
		
		img {
			height: 100%;
			object-fit: cover;
			font-family: 'object-fit: cover';
			width: 100%;
		}
	}
	
	.block-mv {
		position: absolute;
		left: 0;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		
		.ttl-mv {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			flex-wrap: wrap;
			font-family: $font-meiryo;
			@include font-size(40);
			line-height: 2;
			color: #fff;
			font-weight: 400;
			@include mq(lg) {
				margin-top: 0;
			}
			@include mq {
				@include font-size(32);
			}
			@include mq(sm-420) {
				margin-top: -58px;
			}
			&--modify{
				@include font-size(50);
				line-height: 1.6;
				text-shadow: 0 0 10px #000000;
				@include mq{
					@include font-size(40);
				}
				@include mq(sm){
					@include font-size(35);
				}
			}
			
			&__english {
				font-family: $font-raleway;
				@include font-size(32);
				line-height: 1.32;
				font-weight: 400;
				padding: 11px 0 10px 10px;
				text-transform: uppercase;
				@include mq {
					@include font-size(20);
					display: inline-block;
					width: 100%;
					line-height: 1.2;
					padding: 0;
					margin-top: -8px;
				}
			}
			
			&--black {
				color: #4B4B4B;
				
				span {
					color: #4B4B4B;
					//display: block;
					//width: 100%;
				}
			}
			
			&--bold {
				color: #303030;
				font-weight: bold;
			}
			
			&--downline {
				display: block;
				padding-top: 4px;
				line-height: 1.5;
				
				.ttl-mv__english {
					padding: 0;
					position: relative;
					top: -2px;
					display: block;
					@include mq {
						top: 0;
						margin-top: 0;
					}
				}
			}
		}
	}
	
	
	&.no-img {
		height: auto;
		
		.block-mvpage {
			position: inherit;
			top: 0;
			display: inline-block;
			width: 100%;
			text-align: center;
			margin: 0 0 9px 0;
			@include mq {
				margin: 15px 0;
			}
			
			.block-mv {
				position: relative;
				top: auto;
				transform: translateY(0);
			}
			
			.ttl-mv {
				color: #0060CB;
				text-align: center;
				display: block;
				
				@include mq {
					display: inline-block;
					margin-top: 0;
					line-height: 1.2;
				}
				
				&__english {
					color: #0060CB;
					padding: 0 0 10px 0;
					@include mq {
						margin: 0;
						padding: 0;
					}
				}
			}
		}
	}
}

.breadcrumb {
	margin: 0 0 29px;
	@include mq {
		margin: 0 0 19px;
	}
	
	a {
		font-family: $font-meiryo;
		color: #0060CB;
		font-weight: 400;
		line-height: 1.6;
		@include font-size(14);
		text-transform: uppercase;
		@include mq {
			@include font-size(12);
		}
		span{
			color: #0060CB;
		}
	}
	
	i {
		@include font-size(12);
		font-weight: 400;
		color: #303030;
		@include mq {
			@include font-size(10);
		}
	}
	
	span {
		font-family: $font-meiryo;
		@include font-size(14);
		font-weight: 400;
		color: #303030;
		@include mq {
			@include font-size(12);
		}
	}
}

// color
.color {
	&-white {
		color: white;
	}
}

// background
.bg-gray {
	background: #F2F0ED;
}


//fade up
.fade {
	&-up {
		opacity: 0;
		-webkit-transform: translate(0px, 70px);
		-ms-transform: translate(0px, 70px);
		transform: translate(0px, 70px);
		-webkit-transition: opacity 1s, -webkit-transform 1s;
		transition: opacity 1s, -webkit-transform 1s;
		-o-transition: transform 1s, opacity 1s;
		transition: transform 1s, opacity 1s;
		transition: transform 1s, opacity 1s, -webkit-transform 1s;
		
		&.active {
			-webkit-transform: translate(0px, 0px);
			-ms-transform: translate(0px, 0px);
			transform: translate(0px, 0px);
			opacity: 1;
		}
	}
}

