.page-recruit_message_index{
	.sec-mvpage{
		height: auto;
		.box-img02{
			padding-top: 50px;
			margin-bottom: 0px;
			@include mq{
				padding-top: 0;
			}
		}
}


}
.sec-recruit-message{
	padding-bottom: 110px;
	@include mq{
		padding-bottom: 50px;
	}
	.ttl-comp02{
		margin-bottom: 78px;
		@include mq{
			margin-bottom: 50px;
		}
	}
	.list-img{
		margin-bottom: 56px;
		@include mq{
			margin-bottom: 30px;
		}
	}
}
.list-message{
	margin-bottom: 90px;
	@include mq{
		margin-bottom: 50px;
	}
	&__item{
		padding: 42px 0 35px;
		@include mq{
			padding: 30px 0;
		}
		.inner{
			max-width: 1095px;
		}
		&:nth-child(2n+2){
			background: #fdfaf2;
			padding: 53px 0 50px 0;
			@include mq{
				padding: 20px 0;
			}
		}
	}
	.txt-basic{
		margin-bottom: 31px;
		line-height: 1.75;
		@include mq{
			line-height: 1.5;
		}
		&:last-child{
			margin-bottom: 0;
		}
	}
	&__case{
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 19px;
		align-items: center;
		@include mq{
			justify-content: center;
		}
		.box-case{
			width: 34%;
			margin-bottom: -30px;
			@include mq{
				width: 260px;
				margin-bottom: 10px;
			}
		}
		&__img{
			width: 66%;
			padding-left: 50px;
			@include mq(lg){
				padding-left: 30px;
			}
			@include mq{
				width: 100%;
				padding-left: 0;
			}
			img{
				display: block;
				width: 100%;
			}
		}
	}
}
.box-case{
	background: rgba(#346EB1,.68);
	padding: 8px 32px 12px;
	color:#fff;
	@include font-size(20);
	@include mq(lg){
		padding-left: 15px;
		padding-right: 15px;
	}
	@include mq{
		@include font-size(14);
	}
	&__number{
		top: -30px;
		position: relative;
		color:#61BEF7;
		font-weight: 500;
		@include font-size(56);
		line-height: .5;
		font-family: $font-Roboto;
		display: block;
		@include mq{
			@include font-size(30);
			top: -17px;
		}
	}
	&__time{
		display: block;
		margin-bottom: 13px;
		@include mq{
			margin-bottom: 5px;
		}
	}
	&__ttl{
		@include font-size(38);
		padding-bottom: 5px;
		border-bottom: solid 1px #fff;
		margin-bottom: 10px;
		@include mq(lg){
			@include font-size(25);
		}
		@include mq{
			@include font-size(20);
		}
		small{
			@include font-size(20);
			@include mq{
				@include font-size(16);
			}
		}
	}
	&__education{
		text-align: right;
	}
}