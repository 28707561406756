.content-table{
	margin-bottom: 108px;
	@include mq{
		margin-bottom: 50px;
	}
}
.table-task{
	@include font-size(14);
	line-height: 1.43;
	width: 100%;
	margin-bottom: 20px;
	table-layout: fixed;
	position: relative;
	@include mq{
		@include font-size(11);
	}
	&:after{
		content:"";
		position: absolute;
		height: 2px;
		width: 100%;
		bottom:-2px;
		left: 0;
		background: #f2f0ed;
	}
	
	tr{
		text-align: center;
		background: #f2f0ed;
		border-bottom: 4px solid #fff;
		td{
			padding: 6px 5px;
			border-right: 2px solid #fff;
			word-break: break-all;
			&:nth-child(1){
				width: calc(415/1065*100%)
			}
			&:nth-child(2){
				width: calc(217/1065*100%);
			}
			&:nth-child(3){
				width: calc(218/1065*100%);
			}
			&:nth-child(4){
				width: calc(218/1065*100%);
			}
		}
	}
	thead{
		tr{
			border-bottom: 2px solid #fff;
		}
		td{
			background: #96B7DD;
			font-weight: bold;
		}
	}
	tbody{
		tr{
			td{
				padding: 5px 5px;
				color:#020202;
				&:nth-child(1){
					color:#0060CB;
					text-align: left;
					padding-left: 13px;
					@include mq{
						padding-left: 5px;
					}
				}
				&:nth-child(4){
					text-align: left;
				}
			}

		}
	}
}
.sec-recruit-middle{
	padding-bottom: 100px;
	@include mq{
		padding-bottom: 50px;
	}
	.inner{
		max-width: 1096px;
	}
	.btn-comp02{
		margin-bottom: 100px;
		@include mq{
			margin-bottom: 30px;
		}
	}
}

.ip5{
	display: none;
	@include mq(sm-ip){
		display: initial;
	}
}