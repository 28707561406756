.page-service_resistance_stray_index{
	.box-equipment-inspection{
		@include mq() {
			margin-bottom: 40px;
		}
	}
	.sec-resistance-page{
		.block-intro{
			.item{
				align-items: flex-start;
				&--s01{
					margin-bottom: 100px;
					@include mq() {
						margin-bottom: 40px;
					}
					.item__img{
						width: 49.4%;
						@include mq(md-min) {
							order: 2;
						}
						@include mq() {
							width: 100%;
							margin-bottom: 10px;
						}
					}
					.item__txt{
						width: 50.6%;
						padding-right: 20px;
						@include mq(md-min) {
							order: 1;
						}
						@include mq() {
							width: 100%;
							padding: 0;
						}
					}
				}
			}
		}
	}
}