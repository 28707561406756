.sec-works{
	padding-bottom: 100px;
	@include mq{
		padding-bottom: 50px;
	}
}


.block-task{
	.txt {
		@include font-size(20);
		line-height: 2;
		margin-bottom: 18px;
		@include mq {
			@include font-size(16);
			line-height: 1.75;
		}
	}

	.list-task{
		display: flex;
		align-items: center;
		justify-content:center;
		flex-wrap: wrap;
		margin-bottom: 14px;
		@include mq{
			margin-bottom: 0;
		}
	}
	
	.item-task{
		width: calc(20% - 17.6px);
		margin-right: 22px;
		margin-bottom: 20px;
		&:last-child{
			margin-right: 0;
		}
		@include mq(lg){
			width: calc(33.33% - 13.33px);
			margin-right: 20px;
			margin-bottom: 20px;
			&:last-child,&:nth-child(3n){
				margin-right: 0;
			}
		}
		@include mq(sm){
			width: calc(50% - 20px);
			margin-right: 20px;
			&:last-child,&:nth-child(2n){
				margin-right: 0;
			}
			&:nth-child(3n){
				margin-right: 20px;
			}
		}
		@include mq(xs){
			width: 100%;
			margin-right: 0;
			&:last-child,&:nth-child(2n){
				margin-right: 0;
			}
			&:nth-child(3n){
				margin-right: 0;
			}
		}
		
		span{
			line-height: 1.3;
			color: #fff;
			font-weight: 400;
			padding: 8px 10px 4px;
			background: #0060CB;
			width: 100%;
			display: inline-block;
			text-align: center;
			@include font-size(20);
			@include mq{
				@include font-size(18);
			}
			
			&:hover{
				cursor: pointer;
			}
		}
		&:nth-child(5){
			order: 4;
			margin-right: 22px;
			@include mq(){
				margin-right: 0;
			}
		}
		&:nth-child(4){
			order: 5;
			margin-right: 0;
		}
	}
	.list-task__faq{
		.item-task {
			&:nth-child(5) {
				order: 5;
				margin-right: 0px;
			}
			
			&:nth-child(4) {
				order: 4;
				margin-right: 22px;
				@include mq(){
					margin-right: 0;
				}
			}
		}
	}
}
.block-introtask{
	.topic-list{
		display: none;
		&.current{
			display:flex;
		}
		&__content{
			background:#96B7DD ;
		}
	}
}
.link-turn{
	padding-top: 7px;
	border-top: 1px solid #707070;
	font-size: 16px;
	line-height: 2;
	overflow: hidden;
	a{
		cursor: pointer;
	}
	.link-prev{
		float: left;
		i{
			padding-right: 3px;
		}
	}
	.link-next{
		float: right;
		i{
			padding-left: 3px;
		}
	}
}