.sec-configuration{
	padding-top: 34px;
	padding-bottom: 28px;
	@include mq(){
		padding-top: 19px;
		padding-bottom: 50px;
	}
	.box-img{
		margin-bottom: 28px;
		@include mq(){
			margin-bottom: -8px;
		}
	}
}
.txt-blue{
	@include font-size(24);
	line-height: 1.6;
	color: #0060CB;
	font-weight: bold;
	text-align: center;
	margin-bottom: 36px;
	@include mq(){
		@include font-size(20);
		text-align: left;
		margin-bottom: 13px;
	}
	&__style02{
		@include font-size(32);
		font-weight: bold;
		color: #14137E;
		line-height: 1.6;
		text-align: center;
		@include mq(){
			@include font-size(18);
			text-align: left;
		}
	}
}
.list-lightning-system{
	max-width: 900px;
	margin: 0 auto 77px;
	padding-left: 38px;
	@include mq(){
		padding-left: 0;
		margin-bottom: 24px;
	}
	.item{
		@include font-size(20);
		color: #303030;
		margin-bottom: 12px;
		@include mq(){
			margin-bottom: 0;
			@include font-size(16);
			line-height: 1.65;
		}
		span{
			color: #0060CB;
		}
	}
	&__style02{
		@include mq(){
			margin-bottom: 70px;
		}
		.item{
			margin-bottom: 20px;
			@include mq(){
				margin-bottom: 23px;
			}
			&__ttl{
				@include font-size(16);
				color: #0060CB;
				font-weight: bold;
			}
			&__des{
				@include font-size(14);
				color: #303030;
				line-height: 1.7;
			}
		}
	}
}