.sec-boubaku{
	padding: 0px 0 122px;
	@include mq(){
		padding: 0px 0 50px;
	}
	.ttl-comp01{
		@include mq(md-min){
			margin-bottom: 90px;
		}
	}
	.img-boubaku-main{
		text-align: center;
		margin-bottom: 47px;
	}
	.box-special{
		display: flex;
		position: relative;
		margin-bottom: 71px;
		@include mq(lg){
			margin-bottom: 45px;
		}
		@include mq(){
			margin-bottom: 20px;
		}
		.img-special{
			width: 112px;
			padding-top: 3px;
			@include mq(){
				width: 100%;
				margin: 0 auto 30px;
				max-width: 200px;
				text-align: center;
			}
		}
	}
	.list-special{
		display: flex;
		padding-left: 45px;
		width: calc(100% - 112px);
		@include mq(lg){
			padding-left: 20px;
		}
		@include mq(){
			flex-wrap: wrap;
			width: 100%;
			padding-left: 0;
		}
	}
	.list-boubaku{
		&:nth-child(1){
			width: 59%;
			padding-right: 20px;
			@include mq(){
				width: 100%;
				padding-right: 0;
			}
		}
		&:nth-child(2){
			width: 41%;
			padding-left: 11px;
			@include mq(lg){
				padding-left: 0;
			}
			@include mq(){
				width: 100%;
				padding-left: 0;
			}
		}
		@include mq(){
			padding-left: 0;
			margin-bottom: 25px;
		}
		li{
			color: #303030;
			font-weight: bold;
			position: relative;
			padding-left: 15px;
			line-height: 1.8;
			@include font-size(20);
			@include mq(lg){
				@include font-size(16);
			}
			&:before{
				content: "";
				height: 12px;
				width: 12px;
				background: #4B4B4B;
				top: 11px;
				left: 0;
				position: absolute;
				border-radius: 50%;
				@include mq(){
					width: 8px;
					height: 8px;
					top: 7px;
				}
			}
		}
	}
	.box_product_lineup{
		margin-bottom: 30px;
		@include mq(){
			margin-bottom: 20px;
		}
		span{
			display: inline-block;
			color: #fff;
			font-weight: bold;
			background: #4EB46E;
			padding: 5px 21px;
			@include font-size(20);
			@include mq(){
				@include font-size(16);
			}
		}
	}
	.list-item__lineup{
		display: flex;
		flex-wrap: wrap;
		margin: 0 -35px 22px;
		@include mq(lg){
			margin: 0 -15px;
		}
		@include mq(){
			margin: 0;
		}
		.item__lineup{
			width: 33.333%;
			padding:  0 35px;
			margin-bottom: 53px;
			@include mq(lg){
				padding: 0 15px;
			}
			@include mq(){
				padding: 0;
				width: 100%;
				margin-bottom: 35px;
			}
			img{
				margin-bottom: 18px;
				width: 100%;
				@include mq(){
					margin-bottom: 15px;
				}
			}
			.item-list{
				li{
					color: #303030;
					margin-bottom: 0px;
					position: relative;
					padding-left: 17px;
					line-height: 1.8;
					@include font-size(16);
					@include mq(){
						line-height: 1.5;
						@include font-size(14);
					}
					&:before{
						content: "";
						background: #303030;
						width: 9px;
						position: absolute;
						height: 9px;
						top: 9px;
						left: 4px;
						@include mq(){
							top: 4px;
						}
					}
				}
			}
			p{
				margin-top: 6px;
				@include font-size(14);
			}
		}
		.item__lineup02{
			width: 43%;
			@include mq(){
				width: 100%;
			}
		}
	}
}

.box-boubaku{
	display: flex;
	margin-bottom: 46px;
	align-items: center;
	padding-bottom: 76px;
	border-bottom: 10px solid #4EB46E;
	@include mq(){
		margin-bottom: 35px;
		flex-wrap: wrap-reverse;
		padding-bottom: 20px;
		border-width: 3px;
	}

	&__ttl{
		color: #4EB46E;
		font-weight: bold;
		padding-right: 40px;
		width: 50.75%;
		line-height: 1.875;
		@include font-size(32);
		@include mq(lg){
			@include font-size(24);
		}
		@include mq(){
			width: 100%;
			padding-right: 0;
			line-height: 1.5;
			@include font-size(18);
		}
	}
	&__img{
		width: 49.25%;
		@include mq(){
			width: 100%;
			margin-bottom: 10px;
		}
	}
}
