.box-img{
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 80px;
	@include mq{
		margin-bottom: 50px;
	}
	.txt-basic{
		width: 71%;
		margin-top: -8px;
		padding-right: 20px;
		line-height: 1.75;
		@include mq{
			margin-top: 0;
			line-height: 1.5;
			width: 100%;
			margin-bottom: 30px;
			padding-right: 0;
		}
	}
	&__img{
		width: 29%;
		img{
			display: block;
			width: 100%;
		}
		@include mq{
			width: 100%;
			text-align: center;
			img{
				width: auto;
				display: inline-block;
			}
		}
	}
}
.box-img02{
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 113px;
	@include mq{
		margin-bottom: 50px;
	}
	
	&__txt{
		display: inline-flex;
		align-items: center;
		justify-content: center;
		@include font-size(40);
		line-height: 1.5;
		color:#fff;
		text-align: center;
		background: #346eb1;
		width: 37%;
		@include mq{
			width: 100%;
			padding: 20px 20px;
			@include font-size(25);
		}
	}
	&__img{
		width: 63%;
		img{
			display: block;
			width: 100%;
		}
		@include mq{
			width: 100%;
		}
	}
	&--style02{
		@include mq(md-min){
			.box-img02__txt{
				width: 36%;
			}
			.box-img02__img{
				width: 64%;
			}
		}
	}
}
.list-img{
	display: flex;
	flex-wrap: wrap;
	margin: 0 -8px;
	@include mq{
		padding: 0 -4px;
	}
	li{
		width: 50%;
		padding: 0 8px;
		@include mq{
			padding: 0 4px;
		}
		a{
			display: block;
			position: relative;
			&:after{
				content:"";
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 2;
				pointer-events: none;
				transition: all 0.3s;
				background: rgba(#61BEF7, .5);
			}
			&:hover{
				opacity: 1;
				&:after{
					height: 0;
					transition: all 0.3s;
				}
			}
			img{
				display: block;
				width: 100%;
			}
		}
	}
	&__ttl{
		position: absolute;
		top: 50%;
		width: 100%;
		left: 0;
		transform: translateY(-50%);
		color:#fff;
		z-index: 3;
		@include font-size(36);
		text-align: center;
		font-weight: bold;
		line-height: 2.9;
		@include mq{
			@include font-size(20);
		}
		@include mq(sm-ip){
			@include font-size(16);
		}
	}
}
.sec-recruit{
	padding-bottom: 108px;
	@include mq{
		padding-bottom: 50px;
	}
}
.recruit-01{
	max-width: 1068px;
	margin: 0 auto;
	.ttl-comp01{
		margin-bottom: 34px;
		@include mq{
			margin-bottom: 32px;
		}
	}
}
.recruit-02{
	.ttl-comp01{
		margin-bottom: 78px;
		@include mq{
			margin-bottom: 32px;
		}
	}
}

.lg{
	display: none;
	@include mq(lg){
		display: initial;
	}
	@include mq{
		display: none;
	}
}