.box-up {
	margin-top: -20px !important;
	padding-top: 20px !important;
}

.sec-link {
	margin-bottom: 43px;
}
.list-link {
	display: flex;
	margin: 0 -7px;
	@include font-size(20);
	text-align: center;
	color: white;
	@include mq(xl) {
		margin: 0 -5px;
		@include font-size(18);
	}
	@include mq {
		@include font-size(16);
	}
	&__item {
		width: 50%;
		padding: 0 7px;
		margin-bottom: 14px;
		@include mq(xl) {
			padding: 0 5px;
		}
		a {
			display: block;
			background: #D0D0D0;
			padding: 11px 10px 10px;
		}
		&.is-active {
			a {
				background: #346EB1;
			}
		}
	}
}


.sec-greeting {
	margin-bottom: 72px;
	@include mq {
		margin-bottom: 50px;
	}
}
.greeting {
	&-intro {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 33px;
		@include mq {
			margin-bottom: 30px;
		}
		&__img {
			width: 32.5%;
			padding-top: 4px;
			@include mq {
				width: 100%;
				max-width: 300px;
				margin: 0px auto 15px;
			}
		}
		&__content {
			width: 67.5%;
			padding-left: 17px;
			@include mq {
				width: 100%;
				padding-left: 0;
			}
			p {
				margin-bottom: 22px;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
	&-philosophy {
		padding: 29px 0 27px;
		@include mq {
			padding: 30px 0;
		}
		&__des {
			@include font-size(24);
			font-family: $font-mincho;
			font-weight: 500;
			line-height: 1.92;
			@include mq(xl) {
				@include font-size(20);
			}
			@include mq {
				@include font-size(16);
			}
		}
	}
	&-guidance {
		padding: 34px 0 43px;
	}
	&-des {
		@include font-size(20);
		line-height: 1.6;
		margin-bottom: 23px;
		@include mq(xl) {
			@include font-size(17);
		}
		@include mq {
			@include font-size(14);
		}
	}
	&-quality {
		padding: 33px 0 42px;
		@include mq {
			padding: 30px 0;
		}
		&__inner {
			max-width: 484px;
		}
		&__txt {
			margin-bottom: 26px;
		}
		&__date {
			padding-right: 3px;
		}
	}
	&-declaration {
		padding: 26px 0 0;
		@include mq {
			padding: 30px 0 0;
		}
		&__inner {
			max-width: 774px;
		}
		&__txt {
			width: 100%;
			max-width: 634px;
			margin: 0 auto 25px;
		}
		&__box {
			display: flex;
			justify-content: space-between;
			padding-right: 2px;
			@include mq {
				display: block;
			}
			.logo {
				margin-bottom: 15px;
				text-align: center;
			}
		}
	}
}

.sec-profile {
	margin-bottom: 107px;
	@include mq {
		margin-bottom: 50px;
	}
}

.sec-client {
	margin-bottom: 86px;
	@include mq {
		margin-bottom: 50px;
	}
}

.sec-chart {
	margin-bottom: 100px;
	@include mq {
		margin-bottom: 50px;
	}
}
.chart-img {
	overflow: auto;
	&__main {
		min-width: 700px;
	}
}


.sec-history {
	margin-bottom: 97px;
	@include mq {
		margin-bottom: 50px;
	}
}
.box-table {
	&__row {
		display: flex;
		flex-wrap: wrap;
		border-bottom: 1px solid #707070;
		padding-bottom: 7px;
		margin-bottom: 14px;
		@include mq {
			padding-bottom: 15px;
			margin-bottom: 30px;
		}
		&:last-child {
			margin-bottom: 0;
		}
		dt {
			width: 200px;
			@include mq(xl) {
				width: 150px;
			}
			@include mq {
				width: 100%;
				margin-bottom: 15px;
			}
			small {
				display: block;
				@include font-size(14);
				margin-top: 10px;
				@include mq {
					@include font-size(12);
					margin-top: 0;
				}
			}
		}
		dd {
			width: calc(100% - 200px);
			padding-left: 16px;
			@include mq(xl) {
				width: calc(100% - 150px);
			}
			@include mq {
				width: 100%;
				padding-left: 0;
			}
			p {
				line-height: 1.56;
			}
			.txt-basic {
				line-height: 1.75;
			}
		}
	}
	&__flex {
		width: 100%;
		max-width: 821px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-bottom: 5px;
		@include mq {
			margin-bottom: 0;
		}
	}
	&__list {
		li {
			margin-bottom: 10px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

.sec-origin {
	padding-bottom: 96px;
	@include mq {
		padding-bottom: 50px;
	}
}