.sec-businesspartner {
	padding-bottom: 99px;
	@include mq {
		padding-bottom: 50px;
	}
}

.list-businesspartner {
	padding: 0 333px;
	margin-bottom: 82px;
	@media screen and (max-width: 1500px) {
		padding: 0 22.2vw;
	}
	@include mq {
		padding: 0;
		margin-bottom: 30px;
	}
	.item {
		display: flex;
		margin-bottom: 40px;
		@include mq {
			margin-bottom: 20px;
		}
		&__img {
			flex-shrink: 0;
			margin-right: 13px;
			@include mq {
				max-width: 20px;
				margin-right: 10px;
			}
		}
		&__ttl {
			@include font-size(20);
			@include mq {
				@include font-size(15);
			}
		}
	}
}

.txt-businesspartner {
	@extend .txt-lg;
	@extend .txt-center;
	padding: 0 243px;
	margin-bottom: 91px;
	@media screen and (max-width: 1500px) {
		padding: 0 16.2vw;
	}
	@include mq {
		padding: 0;
		margin-bottom: 30px;
	}
	a {
		color: #0060CB;
	}
}