.part-introbrand{
	&--modify{
		.link-target{
			margin: 0 auto 75px;
			text-align: center;
			@include mq{
				margin: 0 auto 25px;
			}
		}
	}
	
	&__logo{
		&--modify{
			margin: 0 auto 75px;
			text-align: center;
			@include mq{
				margin: 0 auto 25px;
			}
		}
	}
}