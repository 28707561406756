.sec-resistance{
	padding-bottom: 145px;
	@include mq() {
		padding-bottom: 65px;
	}
	.block-list{		
		margin: 45px auto 100px;
		padding: 25px 0;
		background: #E3FEFF;
		@include mq(md-min) {
			width: 100%;
			max-width: 632px;
		}
		@include mq() {
			margin: 15px -16px 25px;
			padding: 15px 16px;
		}
		.list{
			color: #346EB1;
			@include font-size(20);
			max-width: 420px;
			margin: 0 auto;
			font-weight: bold;
			line-height: 1.8;
			@include mq() {
				@include font-size(16);
				max-width: 100%;
			}
		}
	}
	.block-flow{
		margin-bottom: 80px;
		@include mq() {
			margin-bottom: 65px;
		}
		&__ttl{
			text-align: center;
			font-weight: bold;
			@include font-size(18);
			margin-bottom: 30px;
			line-height: 1.7;
			@include mq() {
				@include font-size(16);
				margin-bottom: 25px;
				text-align: left;				
			}
		}
		.item{
			@include mq(md-min) {
				display: none !important;
			}
			position: relative;
			display: block;
			background: #FCF3E0;
			margin: 0 -16px;
			padding: 25px 16px 15px 75px;
			&:nth-of-type(2n){
				background: #E0D7CC;
			}
			&::after,
			&::before{
				content: "";
				display: block;
				position: absolute;
			}
			&::before{
				width: 22px;
				height: calc(100% - 58px);
				top: 25px;
				left: 24px;
				background-color: #438BDE;
			}
			&::after{
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 28px 19.5px 0 19.5px;
				border-color: #438BDE transparent transparent transparent;
				left: 15px;
				bottom: 15px;
			}
			&__ttl{
				font-weight: bold;
				@include font-size(20);
				margin-bottom: 5px;
			}
			&__txt{
				@include font-size(16);
				margin-bottom: 10px;
				&--sm{
					text-align: center;
					@include font-size(14);
					margin: 10px 0 5px;
				}
			}
			img{
				width: 100%;
				margin-bottom: 15px;
			}
			&__list{
				display:-webkit-box; // old
				display:-moz-box; // old
				display:-ms-flexbox; // ie
				display:-webkit-flex; // new
				display:flex; // new
				flex-wrap: wrap;
				justify-content: space-between;
				li{
					width: 100%;
					height: 67px;
					color: #FFF;
					display:-webkit-box; // old
					display:-moz-box; // old
					display:-ms-flexbox; // ie
					display:-webkit-flex; // new
					display:flex; // new
					flex-wrap: wrap;
					justify-content: center;
					align-items: center;
					@include font-size(16);
					text-align: center;
					line-height: 1.2;
				}
				&--02{
					li{
						width: 48%;
					}
				}
				&--03{
					li{
						width: 32%;
					}
				}
				&--blue{
					li{
						background: #438BDE;
					}
				}
				&--brown{
					li{
						background: #4E3012;
					}
				}
				&--green{
					li{
						background: #227114;
					}
				}
			}
			&--02{
				&::before{
					background-color: #56D888;
				}
				&::after{
					border-color: #56D888 transparent transparent transparent;
				}
			}
			&--03{
				&::before{
					background-color: #FFD750;
				}
				&::after{
					border-color: #FFD750 transparent transparent transparent;
				}
			}
			&--04{
				&::before{
					background-color: #FF7F50;
				}
				&::after{
					border-color: #FF7F50 transparent transparent transparent;
				}
			}
			&--05{
				&::before,
				&::after{
					display: none;
				}
				img{
					width: 90%;
					margin-bottom: 0;
				}
			}
		}
	}
	.block-intro{
		.item{
			max-width: 1064px;
			margin: 0 auto 60px;
			display:-webkit-box; // old
			display:-moz-box; // old
			display:-ms-flexbox; // ie
			display:-webkit-flex; // new
			display:flex; // new
			flex-wrap: wrap;
			align-items: center;
			@include mq() {
				margin-bottom: 35px;
			}
			&:last-of-type{
				margin-bottom: 0;
			}
			&--05{
				align-items: flex-start;
				@include mq(md-min) {
					.item__txt{
						padding-top: 10px;
					}
				}
			}
			&__ttl{
				width: 100%;
				border-bottom: 3px solid #346EB1;
				color: #346EB1;
				font-weight: bold;
				@include font-size(24);
				margin-bottom: 15px;
				@include mq() {
					@include font-size(16);
					padding-bottom: 3px;
					margin-bottom: 10px;
				}
			}
			&__img{
				width: 49.25%;
				@include mq() {
					width: 100%;
					margin-bottom: 15px;
					img{
						width: 100%;
					}
				}
			}
			&__txt{
				width: 50.75%;
				padding-left: 20px;
				@include mq() {
					width: 100%;
					padding-left: 0;
				}
				p{
					@include font-size(16);
					line-height: 1.7;
					@include mq() {
						@include font-size(14);
					}
				}
				table{
					margin-top: 30px;
					width: 100%;
					@include font-size(16);
					border: #707070 1px solid;
					@include mq() {
						margin-top: 20px;
						@include font-size(14);						
					}
					th,td{
						padding: 15px;
						line-height: 1.7;
						@include mq() {
							display: block;
							width: 100%;
							padding: 5px 10px;
						}
					}
					th{
						width: 100px;
						border-right: #707070 1px solid;
						text-align: center;
						font-weight: normal;
						@include mq() {
							width: 100%;
							border-right: none;
							border-bottom: #707070 1px solid;
						}
					}
					td{
						@include mq() {
							padding: 10px;
						}
					}
				}
			}
			.btn-comp01{
				margin: 25px 0 0;
				float: right;
				background: #FF8D08;
				@include mq() {
					margin-top: 15px;
				}
			}
		}
	}
}