.txt-large {
	@include font-size(20);
	font-weight: bold;
	line-height: 1.6;
	color: #4B4B4B;
	margin-bottom: 48px;
	@include mq{
		@include font-size(18);
		margin-bottom: 25px;
	}
}

.color-green {
	color: #4EB46E;
	font-weight: bold;
}

.bg-yellow {
	background: #FCF3E0;
}

.box-ttl__circle {
	@include font-size(24);
	color: #fff;
	border-radius: 50%;
	background: #4EB46E;
	width: 112px;
	height: 112px;
	display: flex;
	padding: 10px;
	align-items: center;
	justify-content: center;
	font-weight: bold;
	@include mq(lp){
		width: 90px;
		height: 90px;
		padding: 10px;
		@include font-size(20);
	}
	
	@include mq{
		width: 80px;
		height: 80px;
		padding: 8px;
		@include font-size(20);
	}
}

.block-special {
	padding: 25px 25px;
	display: flex;
	align-items: flex-start;
	
	@include mq{
		padding: 15px;
		flex-wrap: wrap;
	}
	@include mq(sm){
		padding: 50px 15px 15px;
		position: relative;
	}
	.part-special {
		&01 {
			width: calc(58% - 7px);
			display: flex;
			align-items: flex-start;
			padding-right: 100px;
			@include mq(lp){
				padding-right: 7vw;
			}
			@include mq{
				padding-right: 0;
				width: 100%;
			}
			.box-ttl__circle{
				@include mq(sm){
					position: absolute;
					top: -38px;
					left: 0;
				}
			}
			.part-special__content {
				width: calc(100% - 112px);
				padding-left: 18px;
				@include mq(lp){
					width: calc( 100% - 90px);
					padding-left: 15px;
				}
				@include mq{
					width: calc( 100% - 80px);
					margin-bottom: 25px;
				}
				@include mq(sm){
					width: 100%;
					padding-left: 0;
				}
			}
			
		}
		
		&02 {
			width: calc(42% + 7px);
			@include mq{
				width: 100%;
				padding-left: 95px;
			}
			@include mq(sm){
				padding-left: 0;
			}
		}
		
		&__list {
			margin-bottom: 40px;
			padding: 10px 0 0 12px;
			@include mq{
				margin-bottom: 20px;
				padding: 0 0 0 15px;
			}
			@include mq(sm){
				padding: 0;
			}
			
			
			.item {
				@include font-size(20);
				line-height: 1.8;
				font-weight: bold;
				color: #4B4B4B;
				position: relative;
				padding-left: 15px;
				display: flex;
				@include mq{
					@include font-size(18);
					line-height: 1.6;
				}
				&:before {
					content: '';
					width: 12px;
					height: 12px;
					background: #4B4B4B;
					border-radius: 10px;
					display: inline-block;
					position: absolute;
					top: 11px;
					left: 0;
					@include mq{
						width: 8px;
						height: 8px;
						top: 9px;
					}
				}
			}
		}
		&__content{
			.ttl-comp08 {
				padding-left: 2px;
				margin-bottom: 2px;
				@include font-size(20);
				@include mq{
					@include font-size(18);
				}
			}
		}
		&__process {
			.img{
				width: 42%;
			}
			.img-icon{
				width: 16%;
				position: relative;
				top: -16px;
			}
		}
	}
}

.part-flex {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;
	&--start{
		align-items: flex-start;
	}
	.img-icon {
		padding: 0 10px 0 15px;
	}
	
}

.block-ttl-common {
	@include font-size(28);
	color: #4EB46E;
	font-weight: bold;
	line-height: 1.43;
	text-align: center;
	margin-bottom: 17px;
	@include mq{
		@include font-size(24);
	}
	@include mq(sm){
		@include font-size(22);
	}
}

.sec-special {
	margin-bottom: 60px;
	@include mq{
		margin-bottom: 50px;
	}
}

.part-introduce {
	margin-bottom: 50px;
	@include mq{
		margin-bottom: 25px;
	}
	&__ttl {
		@include font-size(18);
		line-height: 2;
		font-weight: bold;
		border-bottom: 2px solid #4EB46E;
		margin-bottom: 14px;
		color:#4B4B4B;
		@include mq{
			@include font-size(16);
		}
	}
	
	&__list {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-wrap: wrap;
		&-img {
			width: calc( 40% - 20px);
			margin-right: 15px;
			@include mq(sm){
				width: 100%;
				margin-right: 0;
				margin-bottom: 20px;
				img{
					width: 100%;
				}
			}
		}
		
		&-txt {
			width:calc( 60% + 5px);
			display: flex;
			align-items: flex-start;
			flex-wrap: wrap;
			@include mq(sm){
				width: 100%;
			}
			.item-sub {
				width: calc(50% - 7.5px);
				margin-right: 15px;
				margin-bottom: 23px;
				&:nth-child(2n) {
					margin-right: 0;
				}
				img {
					margin-bottom: 12px;
				}
				.txt-basic{
					line-height: 1.75;
				}
				&:nth-last-child(2),&:last-child{
					margin-bottom: 0;
				}
			}
		}
	}
	
	.txt-lg {
		margin-bottom: 29px;
	}
	
	.txt-basic--bold {
		display: inline-block;
		width: 100%;
	}
	
	.ttl-comp08 {
		color: #4B4B4B;
		margin-bottom: 18px;
		text-align: center;
		line-height: 2;
	}
}

.box-ttlcomp {
	@include font-size(20);
	font-weight: bold;
	color: #fff;
	line-height: 1.8;
	padding: 2px 20px 0px;
	margin-bottom: 19px;
	display: inline-block;
	min-width: 200px;
	text-align: center;
	background: #4EB46E;
	@include mq{
		@include font-size(18);
		padding: 2px 15px;
	}
	@include mq(xs){
		display: block;
	}
	&--blue {
		background: #346EB1;
	}
}

.block-performance {
	margin-bottom: 71px;
	@include mq{
		margin-bottom: 35px;
		overflow: hidden;
	}
	&:last-child{
		margin-bottom: 0;
	}
}

.table-perfor {
	width: 100%;
	
	th {
		font-weight: bold;
		border-top: 2px solid #707070;
		border-bottom: 2px solid #707070;
		border-right: 1px solid #707070;
		border-left: 1px solid #707070;
		color: #4B4B4B;
	}
	
	td {
		border: 1px solid #707070;
	}
	
	th, td {
		padding: 4px 6px 5px;
		text-align: center;
		line-height: 1.5;
		&:first-child {
			border-left: none;
		}
		
		&:last-child {
			border-right: none;
		}
	}
	
	&--03 {
		th, td {
			width: 30%;
			&:last-child{
				width: 40%;
			}
		}
		
	}
	
	&--05 {
		
		
		th, td {
			width: calc(100% / 5);
		}
		td{
			&>span{
				min-height: 56px;
				vertical-align: middle;
				display: flex;
				align-items: center;
				justify-content: center;
				@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
					height: 56px;
				}
				
			}
			
		}
	}
	
	&--noborder {
		th, td {
			border-left: none;
			border-right: none;
			text-align: left;
			padding: 1px 6px 0 0;
			&:first-child {
				width: 31%;
			}
			
			&:nth-child(2n) {
				width: 56%;
				@include mq{
					width: 50%;
				}
			}
			&:last-child {
				width: 13%;
				@include mq{
					width: 14%;
				}
			}
		}
		td{
			padding: 14px 10px 5px 0;
			@include mq{
				padding: 14px 15px 5px 0;
			}
		}
		th{
			border-top: none;
		}
		
		
	}
}
.table-scroll{
	@include mq(xs-large){
		width: 100%;
		overflow-x: scroll;
	}
	
	.table-perfor--05{
		@include mq(xs-large){
			width: 560px;
			overflow-x: scroll;
		}
	}
}
.block-result {
	.box-result {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-wrap: wrap;
		margin-bottom: 15px;
		
		.txt-large {
			margin-bottom: 0;
			font-weight: normal;
			color: #303030;
		}
		
		.box-ttlcomp {
			margin-bottom: 0;
			margin-right: 25px;
			//padding: 0 21px;
		}
		
		
	}
	.txt-basic{
		line-height: 1.75;
	}
	.part-result {
		margin-bottom: 30px;
		&__img {
			width: 68%;
			text-align: center;
			margin-bottom: 12px;
			img{
				margin-bottom: 10px;
			}
			@include mq{
				width: 100%;
			}
		}
		
		&__des {
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			flex-wrap: wrap;
			.box-ttlcomp {
				width: 100%;
				text-align: center;
				font-weight: normal;
				margin-bottom: 31px;
			}
			
			&-img {
				width: 67%;
				.list{
					display: flex;
					align-items: flex-start;
					justify-content: flex-start;
					.item{
						width: calc( 50% - 7.5px);
						margin-right: 15px;
						img {
							width: 100%;
							margin-bottom: 18px;
						}
						.txt-basic{
							text-align: center;
						}
						&:nth-child(2n){
							margin-right: 0;
						}
					}
				}
				@include mq{
					width: 100%;
				}
			}
			
			&-txt {
				width: 33%;
				padding-left: 27px;
				@include mq{
					width: 100%;
					padding-left: 0;
				}
			}
		}
	}
}
.part-method {
	padding: 30px 27px;
	margin-bottom: 47px;
	@include mq{
		padding: 15px;
		margin-bottom: 25px;
	}
	//.box-ttlcomp{
	//	padding: 2px 15px;
	//}
	.list {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-wrap: wrap;
	}
	
	.item {
		width: calc(33.33% - 16.67px);
		margin-right: 25px;
		
		&:nth-child(3n) {
			margin-right: 0;
		}
		@include mq(sm){
			width: 100%;
			margin-right: 0;
			margin-bottom: 20px;
			text-align: center;
		}
		img{
			margin-bottom: 15px;
			@include mq(sm){
				width: 100%;
			}
		}
		.txt-basic {
			margin-bottom: 5px;
			@include mq(sm){
				text-align: left;
			}
		}
		.txt-smaller{
			@include mq(sm){
				text-align: left;
			}
		}
	}
}

.part-process {
	&__txt {
		width: 56%;
		padding-right: 20px;
		@include mq(sm){
			width: 100%;
			padding-right: 0;
			margin-bottom: 20px;
		}
	}
	
	&__img {
		width: 44%;
		flex-wrap: nowrap;
		.img-icon {
			padding: 0 18px;
		}
		@include mq(sm){
			width: 100%;
			padding: 0;
			justify-content: center;
			
		}
	}
}
.sec-future{
	@include mq(sm){
		margin-bottom: 50px;
	}
	.txt-lg{
		@include mq{
			margin-bottom: 20px;
		}
		
	}
	.img{
		@include mq{
			margin-bottom: 20px ;
		}
	}
	.ttl-comp02{
		@include mq{
			margin: 0 auto 15px;
		}
	}
}
.sec-result{
	margin-bottom: 91px;
	@include mq{
		margin-bottom: 50px;
	}
	.txt-basic{
		@include mq{
			margin-bottom: 20px;
		}
	}
}
.sec-itemfaq {
	margin-bottom: 100px;
	@include mq{
		margin-bottom: 50px;
	}
	.block-ttl-common{
		margin-bottom: 27px;
	}
	
	.list {
		margin-bottom: 80px;
		@include mq{
			margin-bottom: 40px;
		}
		.item {
			margin-bottom: 40px;
			
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	
	.itemfaq {
		padding: 8px 10px 8px 20px;
		border: 2px solid #4EB46E;
		margin-bottom: 8px;
		
		&:last-child {
			margin-bottom: 0;
		}
		@include mq{
			padding: 10px;
		}
		&__ttl {
			@include font-size(28);
			font-weight: bold;
			line-height: 1.43;
			height: 60px;
			width: 60px;
			color: #fff;
			border-radius: 30px;
			display: flex;
			align-items: center;
			justify-content: center;
			@include mq{
				height: 45px;
				width: 45px;
				@include font-size(26);
			}
		}
		.txt-faqlg{
			width: calc( 100% - 60px);
			padding-left: 27px;
			@include font-size(20);
			line-height: 1.6;
			color: #303030;
			@include mq{
				width: calc(100% - 45px);
				padding-left: 15px;
				@include font-size(18);
			}
		}
		&__q {
			background: #D8FAD2;
			
			.itemfaq {
				&__ttl {
					background: #4EB46E;
				}
			}
		}
		
		&__a {
			background: #FCF3E0;
			
			.itemfaq {
				&__ttl {
					background: #118B37;
				}
			}
		}
	}
}
.sec-introduce{
	margin-bottom: 119px;
	@include mq{
		margin-bottom: 50px;
	}
}