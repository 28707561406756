.sec-faq{
	margin-bottom: 100px;
	@include mq{
		margin-bottom: 50px;
	}
	.ttl-comp02 {
		margin-bottom: 54px;
		@include mq{
			margin-bottom: 35px;
		}
	}
	.block-task{
		margin-bottom: 37px;
		@include mq{
			margin-bottom: 15px;
		}
	}
	
}
.block-faq{
	margin-bottom: 61px;
	.box-faq_ttl{
		@include font-size(20);
		font-weight: bold;
		line-height: 1.4;
		color: #0060CB;
		border-bottom: 5px solid #96B7DD;
		margin-bottom: 26px;
		padding-bottom: 6px;
		@include mq{
			@include font-size(18);
			margin-bottom: 20px;
		}
	}
	.ttl-faq{
		width: 60px;
		height: 60px;
		margin-right: 17px;
		@include font-size(32);
		font-weight: bold;
		line-height: 2;
		padding: 5px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		&--a{
			background: #61BEF7;
		}
		&--q{
			background: #0060CB;
		}
		@include mq{
			width: 40px;
			height: 40px;
			@include font-size(26);
			margin-right: 10px;
		}
		@include mq(sm) {
			width: 30px;
			height: 30px;
			@include font-size(16);
		}
	}
	
	.txt{
		&-faqq{
			@include font-size(20);
			font-weight: bold;
			line-height: 1.4;
			color: #303030;
			width: calc(100% - 77px);
			word-break: break-all;
			@supports (-ms-ime-align:auto) {
				word-wrap: break-word;
			}
			@include mq{
				@include font-size(16);
				line-height: 1.75;
				width: calc(100% - 50px);
			}
		}
		&-faqa{
			@include font-size(18);
			font-weight: bold;
			line-height: 1.56;
			color: #0060CB;
			width: calc(100% - 77px);
			word-break: break-all;
			@supports (-ms-ime-align:auto) {
				word-wrap: break-word;
			}
			@include mq{
				@include font-size(14);
				line-height: 1.72;
				width: calc(100% - 50px);
			}
		}
	}
	.item{
		&-question{
			margin-bottom: 28px;
			display: flex;
			align-items: center;
			
			@include mq{
				margin-bottom: 25px;
			}
		}
		
		&-answer{
			background: #FCF3E0;
			border: 1px solid #0060CB;
			padding:23px 92px 22px 15px;
			display: flex;
			align-items: flex-start;
			margin: 0 0 40px 108px;
			@media screen and (max-width: 1350px){
				padding: 23px 15px 22px;
			}
			@include mq{
				padding: 15px 12px 15px;
				margin: 0 0 25px 50px;
			}
		}
	}

	.table-faq {
		width: 100%;
		width: calc(100% - 14px);
		table-layout: fixed;
		@include font-size(16);
		line-height: 1.75;
		margin: 0 0 8px -1px;
		@include mq(xl) {
			@include font-size(14);
			line-height: 1.72;
		}
		@media screen and (max-width: 1150px) {
			width: 850px;
		}
		thead {
			background-color: #4B4B4B;
			color: #fff;
			th {
				padding: 1px 0 1px 8px;
				font-weight: normal;
				&:first-child {
					width: 11.95%;
					padding: 1px 0 1px 16px;
				}
				&:nth-child(2) {
					width: 22.6%;
				}
				&:nth-child(3) {
					width: 10.9%;
				}
				&:nth-child(4) {
					width: 9.5%;
				}
				&:nth-child(5) {
					width: 11.4%;
				}
			}
		}
		tbody {
			background-color: #fff;
			color: #303030;
			tr {
				border-bottom: 1px solid #303030;
			}
			td {
				padding: 5px 0 1px 8px;
				font-weight: normal;
				&:first-child {
					padding: 5px 0 1px 16px;
				}
			}
		}
	}

	.row-faq {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -62px 7px;
		color: #303030;
		font-weight: normal;
		@include mq(xl) {
			margin: 0 -15px 0;
		}
		.col {
			width: 100%;
			max-width: 50%;
			flex: 0 0 50%;
			padding: 0 62px;
			@include mq(xl) {
				padding: 0 15px;
			}
			@include mq {
				max-width: 100%;
				flex: 0 0 100%;
				margin-bottom: 20px;
			}
			&:last-child {
				@include mq {
					margin-bottom: 0;
				}
			}
		}
		.ttl {
			@include font-size(20);
			line-height: 1.4;
			color: #303030;
			margin-bottom: 10px;
			font-weight: bold;
			@include mq {
				@include font-size(14);
				line-height: 1.72;
			}
		}
		.list {
			border: 1px solid #303030;
			li {
				@extend .txt-st02;
				background-color: #fff;
				padding: 8px 14px 4px;
				display: flex;
				&:not(:last-child) {
					border-bottom: 1px solid #303030;
				}
				span {
					width: 37.5%;
					min-width: 110px;
					@include mq {
						min-width: auto;
						padding-right: 10px;
					}
				}
			}
		}
	}
}

.overflow-sp {
	@media screen and (max-width: 1150px) {
		overflow-x: scroll;
	}
}