.list-resolution{
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: 0 -7px -123px;
	@include mq{
		margin-bottom: -30px;
	}
	&__item{
		width: calc(33.33% - 14px);
		margin: 0 7px;
		border:solid 3px #346EB1;
		background: #f2f0ed;
		padding:67px 20px 32px;
		margin-bottom: 123px;
		position: relative;
		&--main{
			border:0;
			padding: 0;
			background: 0;
			margin-top: -78px;
			margin-bottom: -40px;
			@media(max-width:1100px){
				margin-top: -50px;
				margin-bottom: 0;
			}
			@include mq(lg){
				margin-top: 0;
				display: flex;
				align-items: center;
				margin-bottom: 173px;
			}
			@include mq{
				justify-content: center;
			}
		}	
		&--vertical{
			display: flex;
			flex-wrap: wrap;
			padding-top: 72px;
			.list-resolution__img{
				width: 48%;
				img{
					display: block;
					width: 100%;
				}
				ul{
					li{
						width: 100%;
					}
				}
				@include mq{
					width: 100%;
					max-width: 225px;
					margin: 0 auto 10px;
				}
			}
			.txt-basic{
				width: 52%;
				padding-left: 17px;
				line-height: 1.75;
				@include mq{
					padding-left: 5px;
					width: 100%;
					padding-left: 0;
				}
			}
		}
		&:nth-child(1), &:nth-child(2){
			width: 50%;
			max-width: 522px;
			@include mq(xl){
				max-width: 45%;
			}
		}
		&:nth-child(n+6){
			width: calc(29.5% - 14px);
			padding-bottom: 23px;
			img{
				margin-bottom: 8px;
			}
			.list-resolution__img{
				margin-bottom: 21px;
			}
		}
		&:nth-child(8){
			width: calc(41% - 14px);
			@supports (-ms-ime-align:auto) {
				width: calc(40% - 14px);
			}
		}
		&:nth-child(3),&:nth-child(5){
			padding-top: 34px;
			margin-bottom: 173px;
			img{
				margin-bottom: 7px;
			}
			.txt-basic{
				line-height: 1.75;
			}
			.list-resolution__img{
				margin-bottom: 24px;
			}
		}
		@include mq{
			width: 100% !important;
			margin-bottom: 36px !important;
			max-width: 100%!important;
			padding: 64px 10px 24px!important;
			.list-resolution__img{
				margin-bottom: 7px !important;
			}
		}
	}
	
	&__img{
		margin-bottom: 13px;
		ul{
			display: flex;
			justify-content: center;
			p{
				@include font-size(16);
				line-height: 1.75;
				@include mq{
					@include font-size(14);
					line-height: 1.5;
					max-width: 225px;
					margin: 0 auto;
				}
			}
			.txt-basic{
				line-height: 1.875;
			}
			img{
				display: block;
				margin-bottom: 6px;
				@include mq{
					width: 100%;
					max-width: 225px;
					margin: 0 auto 8px;
				}
			}
			&.list{
				display: flex;
				margin: 0 -8px;
				@include mq{
					flex-wrap: wrap;
					margin: 0 -2.5px;
				}
				li{
					width: 50%;
					padding: 0 8px;
					img{
						display: block;
					}
					@include mq{
						width: 100%;
						margin-bottom: 15px;
						padding: 0 2.5px;
						&:last-child{
							margin-bottom: 0;
						}
					}
				}
			}
		}
		.txt-basic{
			@include mq{
				line-height: 1.78;
			}
		}
	}


	&__number{
		width: 44px;
		height: 44px;
		border-radius: 50%;
		position: absolute;
		top: 8px;
		left: 8px;
		background: #346eb1;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		color:#fff;
		font-weight: bold;
		@include font-size(24);
		margin-bottom:2px;
	}
}
.sec-service-regulation-excution{
	padding-bottom: 130px;
	@include mq{
		padding-bottom: 50px;
	}
	.box-equipment-inspection{
		margin-bottom: 50px;
		@include mq{
			margin-bottom: 35px;
		}
	}
}
.img-main{
	margin-bottom: 34px;
}