.table-service{
	max-width: 770px;
	margin: 0 auto;
	padding: 0 15px;
	@include mq{
		padding: 0;
		margin-bottom: 20px;
	}
	table{
		width: 100%;
		th, td{
			border: 1px solid #fff;
			@include font-size(16);
			line-height: 1.2;
			text-align: center;
			padding: 4px 19px;
			box-sizing: border-box;
			@include mq{
				@include font-size(13);
				line-height: 1.53;
				padding: 3px 4px;
			}
		}
		th{
			color: #fff;
			background:#346EB1;
			padding: 4px 18.5px 2px;
			@include mq{
				padding: 15px 5px 15px;
				width: 20%;
			}
		}
		td{
			color: #303030;
			background: #F2F0ED;
			&.td-name{
				background: #96B7DD;
			}
			@include mq{
				@include font-size(14);
			}
		}
	}
	
}

.sec-serstandard{
	padding: 0 6px 0px;
	@include mq{
		padding:  0 0px 50px;
	}
	#link01{
		margin-top: -60px;
		padding-top: 60px;
	}
	#link02{
		margin-top: -55px;
		padding-top: 55px;
	}
	.part-bd{
		padding: 19px 26px 25px 26px;
		border: 4px solid #346EB1;
		@include mq{
			padding: 18px 15px 20px 22px;
			.txt-lg{
				margin-bottom: 0;
			}
		}
	}
	.mb-common{
		margin-bottom: 89px;
		@include mq{
			margin-bottom: 54px;
		}
		
		&:last-child{
			margin-bottom: 0;
		}
	}
	.txt-lg{
		@include mq{
			margin-bottom: 13px;
			line-height: 1.8;
			@include font-size(16);
		}
	}
	.ttl-comp02{
		span{
			@include mq{
				padding: 0 0 6px 0;
			}
		}
	}
	.ttl-comp01{
		margin-bottom: 15px;
		
		.sp-iphone5{
			display: none;
			@include mq(xs){
				display: block;
			}
		
		}
		
	}
}
.block-protection{
	.txt-lg{
		@include mq{
			text-align: left;
			margin-bottom: 19px;
		}
		
	}
}
table{
	&.table-common{
		text-align: left;
		width: 100%;
		margin-bottom: 40px;
		@include mq{
			margin-bottom: 19px;
		}
		th,td{
			border: 1px solid #707070;
			padding: 16px 15px 9px ;
			vertical-align: baseline;
			@include mq{
				padding: 8px 5px 5px 5px ;
				line-height: 1.71;
			}
			&:first-child{
				width: 20%;
				vertical-align: middle;
				@include mq{
					width: 23%;
					vertical-align: baseline;
				}
			}
			&:nth-child(2n){
				width: 56%;
				padding-left: 32px;
				vertical-align: middle;
				@include mq{
					padding: 6px 10px 6px 12px;
					width: 51%;
					vertical-align: baseline;
				}
			}
			&:nth-child(3n){
				width: 24% ;
				@include mq{
					width: 26%;
				}
			}
		}
		th{
			background: #F2F0ED;
			&:nth-child(2n){
				@include mq{
					text-align: center;
				}
				
			}
		}
		td {
			background: #fff;
			line-height: 1.75;
			padding: 10px 15px 4px;
			@include mq{
				padding: 8px 5px 5px 8px;
			}
		}
		
		&--other{
			tbody{
				width: 100%;
			}
			td,th{
				&:first-child{
					width: 76% ;
					@include mq{
						width: 74%;
					}
				}
				&:nth-child(2n){
					width: 24%;
					padding-left: 15px;
					@include mq{
						width: 26%;
						padding-left: 8px;
						text-align: left;
					}
				}
			}
			
		}
	}
	
}