.txt-bold{
	font-weight: bold;
}
.ttl-item{
	@include font-size(18);
	color: #4976A9;
	line-height: 1.55;
	font-weight: bold;
	@include mq{
		@include font-size(16);
	}
	&__number{
		@include font-size(14);
		color: #fff;
		line-height: 1.643;
		min-width: 30px;
		min-height: 30px;
		border-radius: 50%;
		background: #4976A9;
		padding: 2px;
		font-weight: normal;
		display: inline-flex;
		text-align: center;
		align-items: center;
		justify-content: center;
		margin-right: 8px;
		@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
			padding: 4.5px 2px 1px;
		}
		
		@include mq{
			@include font-size(12);
			min-width: 25px;
			min-height: 25px;
		}
	}
}
.ttl-comp04--bd{
	border: 1px solid #346EB1;
	border-right: none;
	border-left: none;
	padding: 8px 0 5px 17px;
	margin-bottom: 36px;
	color: #346EB1;
	@include mq{
		margin-bottom: 20px;
	}
}
.sec-inspection{
	padding-bottom: 125px;
	@include mq{
		padding-bottom: 50px;
	}
}
.block-maintenance{
	max-width: 840px;
	margin: 0 auto;
	margin-bottom: 82px;
	@include mq{
		margin-bottom: 50px;
	}
	&>div{
		width: 50%;
		@include mq(sm){
			width: 100%;
			img{
				width: 100%;
			}
		}
	}
	&__list{
		padding-right: 15px;
		margin-top: 14px;
		@include mq(sm){
			padding-right: 0;
		}
	}
	.list{
		.item {
			@include font-size(20);
			line-height: 1.8;
			color: #303030;
			font-weight: bold;
			padding-left: 15px;
			display: flex;
			position: relative;
			margin-bottom: 14px;
			@include mq {
				@include font-size(18);
				line-height: 1.6;
			}
			@include mq(xs){
				@include font-size(16);
			}
			
			&:before {
				content: '';
				width: 12px;
				height: 12px;
				background: #4B4B4B;
				border-radius: 10px;
				display: inline-block;
				position: absolute;
				top: 11px;
				left: 0;
				@include mq {
					width: 8px;
					height: 8px;
					top: 9px;
				}
			}
		}
	}
}
.block-processmain{
	margin-bottom: 102px;
	@include mq{
		margin-bottom: 50px;
	}
}
.box-processmain{
	margin-bottom: 52px;
	&:last-child{
		margin-bottom: 0;
	}
	@include mq{
		margin-bottom: 25px;
	}
	&__ttl{
		@include font-size(24);
		line-height: 1.4;
		font-weight: bold;
		color: #346EB1;
		border-bottom: 3px solid #346EB1;
		margin-bottom: 18px;
		@include mq{
			@include font-size(22);
			margin-bottom: 15px;
		}
	}
	&__content {
		align-items: center;
		&--modify{
			align-items: flex-start;
		}
		&>div{
			width: 50%;
			@include mq(sm){
				width: 100%;
			}
		}
		.img{
			@include mq{
				margin-bottom: 10px;
				img{
					width: 100%;
				}
			}
		}
		.txt-right{
			@include mq{
				text-align: center;
			}
		}
		.txt-large{
			padding-left: 8px ;
			font-weight: normal;
			margin-bottom: 0;
			line-height: 2;
			color: #303030;
			&.pl-0{
				padding-left: 0;
			}
			@include mq(sm){
				padding-left: 0;
			}
			@include mq(xs){
				@include font-size(16);
			}
		}
		.btn-comp02{
			margin-top: 9px ;
			@include mq{
				margin-top: 20px;
			}
		}
	}
}
.block-test{
	margin-bottom: 77px;
	@include mq{
		margin-bottom: 40px;
	}
	.ttl-st__other{
		@include font-size(18);
		color: #fff;
		font-weight: bold;
		line-height: 1.78;
		background: #707070;
		padding: 6px 15px 3px 20px;
		margin-bottom: 14px;
		@include mq{
			@include font-size(16);
			line-height: 1.6;
		}
	}
	&__list{
		margin-bottom: 35px;
		@include mq{
			margin-bottom: 20px;
		}
		&:last-child{
			margin-bottom: 0;
		}
		.img{
			width: calc(50% - 7.5px);
			margin-right: 15px;
			@include mq(sm){
				width: 100%;
				margin-right: 0;
				margin-bottom: 20px;
				&:last-child{
					margin-bottom: 0;
				}
			}
			img{
				margin-bottom: 8px;
				@include mq{
					width: 100%;
				}
			}
			&:nth-child(2n){
				margin-right: 0;
			}
		}
	}
}
.list-box{
	margin: 0 -15px;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-wrap: wrap;
	@include mq{
		margin: 0 -10px;
	}
	@include mq(xs){
		margin: 0;
	}
	&.part-flex{
		align-items: center;
	}
	.ttl-item{
		margin-bottom: 10px;
	}
	.txt-basic{
		margin-bottom: 11px;
	}
	.txt-smaller{
		margin-top: 5px;
		display: block;
		
	}
	.item {
		width: calc(27% - 1px);
		padding: 0 15px;
		margin-bottom: 35px;
		@include mq{
			width: calc(50%);
			padding: 0 10px;
		}
		@include mq(xs){
			width: 100%;
			padding: 0;
		}
		.item-content {
			padding: 8px 4px 4px 14px;
			background: #F2F0ED;
			border: 3px solid #346EB1;
			display: inline-block;
			width: 100%;
			@include mq{
				border: 1px solid #346EB1;
			}
		}
		
		&span{
			&-02{
				width: calc(46% + 2px);
				@include mq{
					width: 100%;
				}
			}
			&-03{
				width: calc(73% - 1px);
				@include mq{
					width: 50%;
				}
				@include mq(xs){
					width: 100%;
				}
			}
		}
		&__img{
			padding-right: 11px;
			align-items: flex-start;
			img{
				width: 100%;
				@include mq{
					height: 100%;
				}
			}
			.img{
				width: calc( 50% - 6px);
				margin-right: 12px;
				//@include mq{
				//				//	width: 100%;
				//				//}
				
				&:nth-child(2n){
					margin-right: 0;
				}
			}
		}
		&-nobd{
			.item-content{
				border: none;
				padding: 0;
				background: transparent;
			}
			
		}
	}
	&--02{
		.txt-smaller{
			min-height: 24px;
		}
		.item{
			@include mq{
				width: calc(27% - 1px);
			}
			@include mq(sm){
				width: 100%;
			}
			&.itemspan-03{
				@include mq{
					width: calc(73% - 1px);
				}
				@include mq(sm){
					width: 100%;
				}
			}
		}
	}
}


.block-equipment{
	margin-bottom: 53px;
	@include mq{
		margin-bottom: 25px;
	}
}
.box-equipment{
	margin-bottom: 40px;
	display: inline-block;
	width: 100%;
	@include mq{
		margin-bottom: 20px;
	}
	.txt-red{
		color: #FC0505;
		font-weight: bold;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		flex-wrap: wrap;
		margin-top: -6px;
	}
	.bd-note{
		width: 95px;
		height: 25px;
		border: 3px dashed #FC0505;
		display: inline-block;
		margin: 0 5px;
		@include mq{
			width: 50px;
			height: 20px;
			border: 2px dashed #FC0505;
		}
	}
	&__des{
		align-items: flex-start;
		
		.txt-large{
			margin-bottom: 0;
			font-weight: normal;
			line-height: 2;
			margin-top: -4px;
			color: #303030;
			@include mq(xs){
				@include font-size(16);
			}
		}
	}
	&__txt{
		width: calc(44.3% - 15px);
		margin-right: 30px;
		@include mq(sm){
			width: 100%;
			margin-right: 0;
			margin-bottom: 15px;
		}
	}
	&__img{
		width: calc(55.7% - 15px);
		align-items: flex-start;
		@include mq(sm){
			width: 100%;
		}
		.img{
			&:first-child{
				width: 39.2%;
				padding-right: 15px;
				@include mq(sm){
					width: 40.8%;
				}
			}
			&:last-child{
				width: 60.8%;
				@include mq(sm){
					width: 59.2%;
				}
			}
			img{
				margin-bottom: 11px;
			}
		}
	}
	.list{
		display: flex;
		justify-content: flex-start;
		align-self: flex-start;
		flex-wrap: wrap;
		.img,.ttl-item{
			margin-bottom: 20px;
		}
		&-col03{
			.item{
				width: calc( 33.33% - 13.33px);
				margin: 0 20px 20px 0;
				padding: 18px 20px 21px;
				&:nth-child(3n){
					margin-right: 0;
					@include mq{
						margin-right: 20px;
					}
					@include mq(sm){
						margin-right: 10px;
					}
					@include mq(xs){
						margin-right: 0px;
					}
				}
				@include mq{
					width: calc(50% - 10px);
					&:nth-child(2n){
						margin-right: 0;
					}
				}
				@include mq(sm){
					padding: 15px;
					margin: 0 15px 15px 0;
				}
				@include mq(xs){
					width: 100%;
					margin: 0 0 10px 0;
				}
				.img{
					margin-bottom: 14px;
					img{
						width: 100%;
					}
				}
				.txt-smaller{
					min-height: 72px;
				}
			}
		}
	}
}