.intro-head {
	padding-bottom: 28px;
	border-bottom: 10px solid rgba(#4EB46E, 0.41);
	margin-bottom: 24px;
	color: #4EB46E;
	@include mq {
		border-width: 5px;
	}
	&__wrap {
		display: flex;
		@include mq {
			flex-wrap: wrap;
		}
	}
	&__ttl {
		@include font-size(32);
		font-weight: bold;
		line-height: 1.56625;
		padding-right: 20px;
		@include mq(lg) {
			@include font-size(28);
		}
		@include mq {
			@include font-size(24);
			text-align: center;
			margin-bottom: 10px;
			width: 100%;
		}
	}
	&__img {
		width: 53.5%;
		margin-left: auto;
		padding-top: 5px;
		@include mq {
			width: 100%;
			text-align: center;
		}
	}
	&--blue {
		color: #346EB1;
		border-color: rgba(#346EB1, 0.41);
	}
	&__group {
		display: flex;
		justify-content: space-between;
		width: 658px;
		padding-top: 30px;
		@include mq(xl) {
			width: 560px;
		}
		@include mq(lg) {
			width: 100%;
			max-width: 560px;
			padding-top: 0;
			margin: 0 auto 15px;
		}
		@include mq {
			max-width: 450px;
		}
		@include mq(sm) {
			flex-wrap: wrap;
			max-width: 350px;
		}
	}
	&__list {
		@include font-size(28);
		line-height: 1.29;
		font-weight: bold;
		@include mq(xl) {
			@include font-size(24);
		}
		@include mq {
			@include font-size(20);
		}
		@include mq(xs) {
			@include font-size(18);
			width: 100%;
		}
		li {
			margin-bottom: 13px;
			&:last-child {
				margin-bottom: 0;
			}
		}
		&:first-child {
			@include mq {
				li {
					&:last-child {
						margin-bottom: 13px;
					}
				}
			}
		}
	}
}


.sec-silicon {
	margin-bottom: 100px;
	@include mq {
		margin-bottom: 50px;
	}
}
.silicon {
	&__des {
		@include font-size(20);
		line-height: 2;
		text-align: center;
		margin-bottom: 55px;
		@include mq(lg) {
			@include font-size(18);
		}
		@include mq {
			@include font-size(16);
		}
		@include mq(xs) {
			@include font-size(14);
		}
	}
}

.box-lineup {
	margin-bottom: 53px;
	@include mq {
		margin-bottom: 20px;
	}
}
.lineup {
	&-list {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -10px;
		@include mq(lg) {
			margin: 0 -5px;
		}
		@include mq(xs) {
			margin: 0;
		}
		&__item {
			width: 25%;
			padding: 0 10px;
			margin-bottom: 30px;
			position: relative;
			@include mq(lg) {
				padding: 0 5px;
			}
			@include mq {
				width: 50%;
			}
			@include mq(xs) {
				width: 100%;
				padding: 0;
			}
			.link-fixed{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				opacity: 0;
			}
		}
		&__img {
			margin-bottom: 9px;
			@include mq {
				img {
					width: 100%;
				}
			}
			iframe{
				width: 100% !important;
			}
		}
		&__content {
			line-height: 1.75;
		}
		&__ttl {
			@include font-size(20);
			color: #4EB46E;
			font-weight: bold;
			margin-bottom: 7px;
			@include mq(lg) {
				@include font-size(18);
			}
			@include mq {
				@include font-size(16);
			}
		}
		&__des {
			@include font-size(14);
			line-height: 2;
			margin-bottom: 8px;
			@include mq {
				@include font-size(12);
			}
		}
		.br-link {
			@media only screen and (min-width: 1070px) {
				display: none;
			}
			@include mq {
				display: none;
			}
			@media only screen and (max-width: 550px) {
				display: inline-block;
			}
			@include mq(xs) {
				display: none;
			}
		}
	}
}

.box-gel {
	margin-bottom: 27px;
	@include mq {
		margin-bottom: 50px;
	}
}
.gel {
	&-flex {
		display: flex;
		flex-wrap: wrap;
		&__left {
			padding-right: 10px;
			width: 648px;
			margin-bottom: 0;
			@include mq(ipd) {
				width: 588px;
			}
			@media only screen and (max-width: 850px) {
				width: 100%;
				margin-bottom: 15px;
			}
		}
		&__img {
			width: calc(100% - 648px);
			@include mq(ipd) {
				width: calc(100% - 588px);
			}
			@media only screen and (max-width: 850px) {
				width: 100%;
				text-align: center;
			}
		}
	}
}

.box-instructions {
	margin-bottom: 46px;
}
.instructions {
	&-flex {
		display: flex;
		flex-wrap: wrap;
		&__left {
			width: 360px;
			padding-right: 10px;
			@include mq(lg) {
				width: 300px;
			}
			@include mq {
				width: 100%;
				margin-bottom: 15px;
				padding-right: 0;
			}
			.img {
				margin-bottom: 5px;
			}
		}
		&__info {
			margin-bottom: 10px;
		}
		&__img {
			width: calc(100% - 360px);
			@include mq(lg) {
				width: calc(100% - 300px);
			}
			@include mq {
				width: 100%;
			}
		}
	}
}

.box-precautions {
	margin-bottom: 70px;
	@include mq {
		margin-bottom: 50px;
	}
}
.precautions {
	&-flex {
		display: flex;
		flex-wrap: wrap;
		&__left {
			width: 716px;
			padding-right: 10px;
			@include mq(lg) {
				width: 500px;
			}
			@include mq {
				width: 100%;
				padding-right: 0;
				margin-bottom: 15px;
			}
		}
		&__img {
			width: calc(100% - 716px);
			@include mq(lg) {
				width: calc(100% - 500px);
			}
			@include mq {
				width: 100%;
				text-align: center;
			}
		}
	}
	&-list {
		padding-left: 18px;
		@include mq(lg) {
			padding-left: 15px;
		}
		li {
			text-indent: -18px;
			@include mq(lg) {
				text-indent: -15px;
			}
		}
	}
}

.box-point {
	margin-bottom: 71px;
	overflow: hidden;
	@include mq {
		margin-bottom: 50px;
	}
}

.point {
	&-flex {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		&__img {
			@include mq {
				margin-bottom: 15px;
			}
		}
		&__content {
			padding: 0 0 4px 16px;
			@include mq {
				padding: 0;
			}
			p {
				margin-bottom: 12px;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
	&-list {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -35px;
		@include mq(lg) {
			margin: 0 -15px;
		}
		@include mq {
			margin: 0 auto;
			width: 100%;
			max-width: 450px;
		}
		&__item {
			width: 33.33%;
			padding: 0 35px;
			@include mq(lg) {
				padding: 0 15px;
			}
			@include mq {
				width: 100%;
				padding: 0;
				margin-bottom: 30px;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
		&__img {
			display: flex;
			align-items: center;
			margin-bottom: 21px;
			@include mq {
				display: block;
				width: 100%;
				margin: 0 auto 15px;
			}
			img {
				width: 100%;
			}
		}
	}
}

.box-variation {
	margin-bottom: 67px;
	@include mq {
		margin-bottom: 50px;
	}
}
.table {
	&-comp01 {
		width: 100%;
		line-height: 1.7;
		@include mq(lg) {
			@include font-size(15);
		}
		@include mq {
			@include font-size(13);
		}
		@include mq(xs) {
			@include font-size(11);
		}
		tr {
			th, td {
				text-align: center;
				border: 5px solid #fff;
				padding: 0 5px;
				@include mq(ipd) {
					border-width: 3px;
				}
				&:first-child {
					width: 28.95%;
					border-left: none;
				}
				&:nth-of-type(2) {
					width: 21.93%;
				}
				&:last-child {
					width: 49.12%;
					border-right: none;
				}
			}
			th {
				background: #C8E5CB;
				font-weight: normal !important;
			}
			td {
				background: #FCF3E0;
			}
		}
	}
	&-comp02 {
		width: 100%;
		@include mq(lg) {
			@include font-size(15);
		}
		@include mq {
			@include font-size(13);
		}
		@include mq(xs) {
			@include font-size(11);
		}
		tr {
			th, td {
				border: 1px solid #707070;
				&:first-child {
					width: 20.3%;
				}
				&:nth-of-type(2) {
					width: 60.95%;
				}
				&:last-child {
					width: 18.75%;
				}
			}
			th {
				text-align: center;
				background: #F2F0ED;
				padding: 4px 10px 0px;
				font-weight: normal !important;
			}
			td {
				padding: 5px 10px 1px;
			}
		}
	}
}


.box-performance {
	margin-bottom: 100px;
	@include mq {
		margin-bottom: 50px;
	}
}

.box-bottom {
	@include font-size(20);
	display: block;
	width: 100%;
	margin: 0 auto;
	max-width: 416px;
	text-align: center;
	@include mq(lg) {
		@include font-size(18);
	}
	@include mq {
		@include font-size(16);
	}
	@include mq(xs) {
		@include font-size(14);
	}
	p {
		margin-bottom: 31px;
	}
	&__btn {
		margin-bottom: 81px;
	}
}