.pt0{
	padding-top: 0 !important;
}
.mt-0{
	margin-top: 0 !important;
}
.bg-none{
	background: transparent;
}
.box-regulations{
	margin: 0 auto 43px;
	@include mq(){
		margin-bottom: 0px;
	}
	.box-left{
		padding-left: 0;
		padding-right: 20px;
		@include mq(ipd){
			padding-top: 0;
		}
		@include mq(){
			padding-right: 0;
			padding-top: 20px;
			padding-bottom: 0;
		}
	}
}
.sec-nip-features{
	margin-bottom: 49px;
	@include mq(){
		margin-bottom: 75px;
	}
}
.box-tranquil{
	padding-top: 26px;
	padding-bottom: 18px;
	background: #E3FEFF;
	margin-bottom: 66px;
	@include mq(){
		padding: 30px 16px;
		margin-bottom: 45px;
		margin-left: -16px;
		margin-right: -16px;
	}
	&__ttl{
		@include font-size(24);
		font-weight: bold;
		line-height: 1.7;
		margin-bottom: 24px;
		@include mq(){
			@include font-size(16);
			margin-bottom: 15px;
		}
		span{
			//padding-bottom: 3px;
			//background-position: 0% 0.7em;
			//background-image: linear-gradient(to right, rgba(#61BEF7, 0.65) 50%, transparent 50%);
			//background-size: 200% 18px;
			//@include mq(){
				background: none;
			position: relative;
			display: inline-block;
			padding: 0 15px;
				&:after{
					content: '';
					bottom: 5px;
					left: 0;
					right: 0;
					position: absolute;
					background: rgba(#61BEF7, 0.65);
					height: 16px;
					z-index: -1;
					@include mq{
						height: 12px;
					}
				}
			//}
		}
	}
	&__des{
		text-align: center;
		@include font-size(16);
		line-height: 1.75;
		@include mq(){
			@include font-size(14);
			line-height: 1.7;
		}
		a{
			color: #0060CB;
		}
	}
	&__sub{
		padding: 20px 30px;
		@include font-size(16);
		line-height: 1.75;
		margin-bottom: 15px;
		@include mq(){
			padding: 16px 16px 5px;
			@include font-size(14);
			margin-bottom: 16px;
			margin-left: 0;
		}
	}
}
.box-nip-features{
	margin-bottom: 55px;
	@include mq(){
		margin-bottom: 50px;
	}
	.ttl-comp01{
		margin-bottom: 35px;
		@include mq(){
			margin-bottom: 28px;
		}
	}
	.wp{
		max-width: 1064px;
		margin: 0 auto;
	}
	.item{
		display: flex;
		align-items: flex-end;
		background: #EEEEEE;
		margin-bottom: 20px;
		@include mq(lg){
			align-items: center;
		}
		@include mq(){
			flex-wrap: wrap;
			margin-bottom: 30px;
		}
		&:last-of-type{
			margin-bottom: 0;
		}
		&__img{
			width: 49.24%;
			position: relative;
			@include mq(lg){
				img{
					height: 100%;
					object-fit: cover;
					font-family: "object-fit: cover;";
				}
			}
			@include mq(){
				width: 100%;
				img{
					height: auto;
					width: 100%;
				}
			}
			span{
				display: block;
				position: absolute;
				@include v-h-align();
				@include font-size(32);
				font-weight: bold;
				color: #14137E;
				width: 100%;
				text-align: center;
				@include mq(){
					@include font-size(28);
				}
			}
		}
		&__info{
			width: calc(100% - 49.24%);
			padding-bottom: 17px;
			padding-left: 21px;
			padding-right: 21px;
			@include mq(lg){
				padding-bottom: 0;
			}
			@include mq(){
				width: 100%;
				padding: 24px 14px 18px;
			}
			&--des{
				@include font-size(16);
				line-height: 1.75;
				margin-bottom: 30px;
				@include mq(lg){
					margin-bottom: 20px;
				}
				@include mq(){
					@include font-size(14);
					line-height: 1.7;
					margin-bottom: 22px;
				}
			}
			.btn-arrow{
				max-width: 154px;
				margin: 0 auto;
				width: 100%;
				height: 30px;
				background: #346EB1;
				font-family: $font-raleway;
				@include font-size(16);
				color: #fff;
				font-weight: 500;
				display: flex;
				align-items: center;
				padding-left: 14px;
				position: relative;
				&:after{
					content: "";
					display: block;
					width: 32px;
					height: 6px;
					background: url("../img/service/lightning/index/ico-right.png") no-repeat center;
					background-size: cover;
					position: absolute;
					top: 10px;
					right: 10px;
				}
			}
		}
	}
}

.box-setup-system{
	max-width: 1064px;
	margin: 0 auto;
	.ttl-comp01{
		@include mq(){
			line-height: 1.5;
			span{
				display: inline-block;
				padding: 0 30px;
			}
		}
	}
	&-wp{
		display: flex;
		align-items: flex-start;
		@include mq(){
			flex-wrap: wrap;
		}
		.box-content{
			width: calc(100% - 29.04%);
			padding-right: 15px;
			@include mq(lg){
				width: 65%;
			}
			@include mq(){
				width: 100%;
				margin-bottom: 28px;
			}
			.list-dots{
				margin-bottom: 20px;
				li{
					@include font-size(20);
					line-height: 1.6;
					padding-left: 30px;
					position: relative;
					margin-bottom: 20px;
					@include mq(lg){
						@include font-size(18);
					}
					@include mq(){
						@include font-size(16);
						margin-bottom: 20px;
						padding-left: 20px;
					}
					&:before{
						content: '';
						display: block;
						width: 22px;
						height: 22px;
						background: #346EB1;
						border-radius: 50%;
						position: absolute;
						top: 5px;
						left: 0;
						@include mq(){
							width: 15px;
							height: 15px;
						}
					}
				}
			}
			.txt-basic{
				line-height: 1.75;
			}
		}
		.box-img{
			width: 29.04%;
			@include mq(lg){
				width: 35%;
			}
			@include mq(){
				width: 308px;
				margin: 0 auto;
			}
			.box-ttlcomp{
				width: 100%;
				margin-bottom: 10px;
				@include font-size(24);
				@include mq(lg){
					@include font-size(22);
				}
				@include mq(){
					margin-bottom: 13px;
					@include font-size(20);
					padding: 4px 10px;
				}
			}
		}
	}
}

.ttl-koamaru{
	@include font-size(24);
	font-weight: bold;
	color: #14137E;
	margin-bottom: 9px;
	@include mq(){
		@include font-size(20);
		margin-bottom: 10px;
	}
}