.sec-series {
	padding-bottom: 87px;
	@include mq {
		padding-bottom: 50px;
	}
}
.series {
	&__link {
		font-weight: bold;
		a {
			position: relative;
			color: #0060CB;
			padding-bottom: 9px;
			@include mq {
				text-decoration: underline;
				text-underline-position: under;
			}
			&:before {
				position: absolute;
				content: '';
				bottom: 0;
				left: 0;
				right: 0;
				height: 1px;
				background: #0060CB;
				@include mq {
					display: none;
				}
			}
		}
	}
	&__txt {
		line-height: 1.75;
		margin-bottom: 60px;
		@include mq {
			margin-bottom: 50px;
		}
	}
}