
.list-01{
	margin-bottom: 80px;
	@include mq{
		margin-bottom: 30px;
	}
	li{
		display: flex;
		flex-wrap: wrap;
		@include font-size(16);
		line-height: 1.75;
		background: #eeeeee;
		margin-bottom: 10px;
		@include mq(){
			@include font-size(14);
			line-height: 1.5;
		}
		&:last-child{
			margin-bottom: 0;
		}
		>*{
			padding: 7px 10px 3px;
		}
		span{
			font-weight: bold;
			background: #96b7dd;
			width: 19%;
			display: inline-flex;
			align-items: center;
			@include mq{
				width: 120px;
			}
		}
		p{
			width: 81%;
			border-left: solid 1px #fff;
			@include mq{
				width: calc(100% - 120px);
			}
		}
	}
}
.sec-recruit-middle-outline{
	padding-bottom: 100px;
	.inner{
		max-width: 1096px;
	}
	.btn-comp02{
		margin-bottom: 50px;
		@include mq{
			margin-bottom: 20px;
		}
	}	
	@include mq{
		padding-bottom: 50px;
	}
	
}