.sec-police{
	margin-bottom: 100px;
	@include mq{
		margin-bottom: 50px;
	}
	.txt-basic{
		line-height: 1.75;
		@include mq{
			margin-bottom: 20px;
			&:last-child{
				margin-bottom: 0;
			}
		}
	}
	.txt-lg{
		@include mq{
			margin-bottom: 20px;
		}
	}
}

.block-policy{
	margin-bottom: 78px;
	@include mq{
		margin-bottom: 30px;
	}
	
	.ttl-comp04{
		margin-bottom: 8px;
		color: #346EB1;
	}
	
	.list{
		li{
			position: relative;
			padding-left: 7px;
			&:before{
				content: '';
				height: 3px;
				width: 3px;
				border-radius: 5px;
				background: #303030;
				display: inline-block;
				vertical-align: middle;
				margin-right: 5px;
			}
		}
	}
}
.box-police{
	padding: 13px 28px 21px;
	border: 1px solid #707070;
	@include mq{
		padding: 15px;
	}
	.txt-basic{
		line-height: 2.3;
	}
}