.main-page {
	padding-top: 120px;
}
.page-index{
	.ttl-comp01 small{
		@include mq(){
			display: inline-block;
		}
	}
}
.sec-mv {
	height: 46.26vw;
	@include mq {
		height: 111.6vw;
	}
}

.main-bg {
	position: relative;
	z-index: 1;
	background: #fff;
	padding-top: 50px;
	@include mq {
		padding-top: 35px;
	}
	&--content {
		padding-top: 15px;
		@include mq {
			padding-top: 10px;
		}
	}
}

.slider-home{
	position: fixed;
	top: 70px;
	left: 0;
	right: 0;
	.item {
		outline: none;
		position: relative;
		.img {
			img {
				width: 100%;
			}
		}
		.color-white{
			text-shadow: 0px 0px 10px rgba(0, 0, 0, 1) !important;
		}
		.mv {
			&-block {
				position: absolute;
				top: 47%;
				width: 100%;
				text-align: center;
				transform: translateY(-50%);
				padding: 0 10px;
				text-shadow: 0px 0px 10px rgba(#fff,1);
				@include mq(sm) {
					top: 31.2%;
				}
				@include mq(xs) {
					top: 50%
				}
			}
			&-ttl {
				font-family: $font-mincho;
				@include font-size(50);
				font-weight: 600;
				margin-bottom: 28px;
				@include mq(xl) {
					@include font-size(40);
				}
				@include mq(lg) {
					@include font-size(32)
				}
				@include mq(sm) {
					@include font-size(24);
					margin-bottom: 13px;
				}
				small {
					font-family: $font-meiryo;
					@include font-size(24);
					display: block;
					font-weight: normal;
					margin-bottom: 7px;
					@include mq(xl) {
						@include font-size(20);
					}
					@include mq(lg) {
						@include font-size(18);
					}
					@include mq(sm) {
						@include font-size(16);
						margin-bottom: 13px;
					}
					.en {
						font-family: $font-raleway;
						@include font-size(20);
						margin-left: 24px;
						@include mq(xl) {
							@include font-size(18);
						}
						@include mq(lg) {
							@include font-size(16)
						}
						@include mq(sm) {
							@include font-size(15);
							margin-left: 0;
							display: block;
						}
					}
				}
			}
			&-txt {
				@include font-size(16);
				font-weight: bold;
				line-height: 2.5;
				@include mq(sm) {
					@include font-size(14);
					line-height: 1.714;
				}
				@include mq(xs) {
					@include font-size(12);
				}
			}
		}
	}
}

.sec-service {
	margin-bottom: 80px;
	@include mq {
		margin-bottom: 50px;
	}
}
.service-list {
	display: flex;
	flex-wrap: wrap;
	text-align: center;
	&__item {
		width: 20%;
		@include mq(xl) {
			width: 33.33%;
		}
		@include mq {
			width: 100%;
			margin-bottom: 10px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	&__wrap {
		display: block;
		position: relative;
		z-index: 1;
		&:hover {
			opacity: 1;
			.service-list {
				&__content {
					&:before {
						transition: 0.3s;
						opacity: 0;
					}
				}
			}
		}
	}
	&__img {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: -1;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			font-family: 'object-fit: cover;';
		}
	}
	&__content {
		padding: 48px 10px 25px;
		position: relative;
		@include mq {
			padding: 10px 16px 10px 10px;
			display: flex;
			align-items: center;
			height: 145px;
		}
		&:before {
			position: absolute;
			content: '';
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			background: rgba(#303030, 0.49);
			z-index: -1;
			transition: 0.3s;
		}
	}
	&__img02 {
		margin-bottom: 20px;
		@include mq {
			margin-bottom: 0;
			width: 121px;
		}
	}
	&__ttl {
		@include font-size(28);
		line-height: 1.43;
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 6px;
		@include mq(xl) {
			@include font-size(24);
			min-height: 68px;
		}
		@include mq {
			@include font-size(20);
			line-height: 1.6;
			min-height: auto;
			width: calc(100% - 162px);
			text-align: left;
			display: block;
			margin: 0;
			padding: 0 7px;
		}
	}
	&__txt {
		@include mq {
			width: 41px;
		}
	}
	&__txt02 {
		display: flex;
		align-items: center;
		justify-items: center;
		@include mq {
			width: 200px;
		}
		p{
			background: #fff;
			color: #303030;
			max-width: 175px;
			width: 100%;
			margin: 0 auto;
			padding: 3px 5px;
			@include font-size(14);
		}
	}
}

.sec-block {
	margin-bottom: 111px;
	@include mq {
		margin-bottom: 55px;
	}
	.inner {
		@include mq {
			padding: 0;
		}
	}
}


.list-block {
	display: flex;
	align-items: stretch;
	flex-wrap: wrap;
	&__item {
		width: 50%;
		background: #346EB1;
		color: #fff;
		@include mq(lg) {
			width: 100%;
			margin-bottom: 10px;
			&:last-child {
				margin-bottom: 0;
			}
		}
		@include mq {
			margin-bottom: 0;
		}
	}
	&__wrap {
		display: flex;
		align-items: stretch;
		flex-wrap: wrap;
		width: 100%;
		height: 100%;
		@include mq(lg) {
			align-items: center;
		}
		&:hover {
			.list-block {
				&__inner {
					&:after {
						left: 55%;
					}
				}
			}
		}
	}
	&__img {
		width: 50.625%;
		@include mq(xl) {
			width: 45%;
		}
		@include mq {
			width: 100%;
		}
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			font-family: 'object-fit: cover;';
		}
	}
	&__content {
		width: 49.375%;
		position: relative;
		@include mq(xl) {
			width: 55%;
		}
		@include mq {
			width: 100%;
		}
		&:before {
			position: absolute;
			content: '';
			border-right: 30px solid #346EB1;
			border-top: 23px solid transparent;
			border-bottom: 23px solid transparent;
			left: 0;
			top: 47%;
			transform: translateX(-100%);
			@include mq {
				border-right: 15px solid transparent;
				border-left: 15px solid transparent;
				border-bottom: 20px solid #346EB1;
				left: 49.5%;
				top: 0;
				transform: translate(-50%, -100%);
			}
		}
	}
	&__inner {
		padding: 47px 0 42px;
		position: relative;
		@include mq {
			padding: 14px 0 39px;
		}
		&:after {
			position: absolute;
			content: '';
			width: 32px;
			height: 6px;
			background: url("../img/index/arrow-right-white.png") no-repeat;
			background-size: 100% 100%;
			left: 50%;
			bottom: 20px;
			transform: translateX(-50%);
			transition: 0.3s;
			@include mq {
				bottom: 18px;
			}
		}
		.txt-basic {
			@include mq(xl) {
				@include font-size(15);
			}
			@include mq {
				@include font-size(14);
			}
		}
	}
	&__ttl {
		margin-bottom: 27px;
		@include mq {
			margin-bottom: 14px;
		}
		small {
			display: block;
			margin-top: 7px;
			@include mq {
				display: inline-block;
				margin: 0 0 0 10px;
			}
		}
	}
}

.sec-block {

}

.sec-topic {
	margin-bottom: 65px;
	@include mq {
		margin-bottom: 71px;
	}
}
.topic-list {
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	margin: 0 -8px;
	@include mq {
		margin: 0 0 29px;
	}
	&__item {
		width: 33.33%;
		padding: 0 8px;
		margin-bottom: 30px;
		@include mq {
			width: 100%;
			padding: 0;
			margin-bottom: 10px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	&__wrap {
		display: block;
		width: 100%;
		height: 100%;
		background: #D0D0D0;
		position: relative;
		&:after {
			position: absolute;
			content: '';
			width: 32px;
			height: 6px;
			background: url("../img/index/arrow-right.png") no-repeat;
			background-size: 100% 100%;
			right: 19px;
			bottom: 19px;
			transition: 0.3s;
			@include mq {
				right: 16px;
				bottom: 20px;
			}
		}
		&:hover {
			&:after {
				transition: 0.3s;
				right: 5px;
			}
		}
	}
	&__img{
		position: relative;
		overflow: hidden;
		&:before{
			content: "";
			display: block;
			padding-bottom: calc(310/416*100%);
		}
		.thumbnail{
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
		}
		img{
			width: 100%;
			height: 100%;
			object-fit: cover;
			font-family: "object-fit: cover";
		}
	}
	&__img02{
		&:before{
			padding-bottom: calc(230/416*100%);
		}
	}
	&__content {
		padding: 14px 18px 12px;
		@include mq {
			padding: 11px 10px 13px;
			background: #D0D0D0;
			margin-top: -5px;
			position: relative;
		}
	}
	&__date {
		@include font-size(14);
		margin-bottom: 8px;
	}
	&__ttl {
		margin-bottom: 5px;
		@include font-size(16);
		@include mq {
			margin-bottom: 7px;
		}
	}
	&__cate {
		@include font-size(12);
		color :#346EB1;
		background: #fff;
		border: 1px solid #346EB1;
		display: inline-block;
		text-align: center;
		min-width: 90px;
		padding: 1.5px 1px 0px;
		
	}
}

.sec-other {
	background-color: #346EB1;
	position: relative;
	z-index: 1;
	padding: 64px 0 9px;
	background-image: url("../img/index/other-bg.png");
	background-repeat: no-repeat;
	background-size: 100% auto;
	@media only screen and (max-width: 1312px){
		padding: 50px 0 30px;
	}
	@include mq {
		padding: 49px 0 20px;
	}
	&__ttl {
		text-align: right;
		@include mq {
			text-align: center;
		}
	}
}
.other-list {
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	margin: 0 -9.4px;
	@media only screen and (max-width: 1312px){
		margin: 0 -5px;
	}
	@include mq {
		margin: 0;
	}
	&__item {
		width: 20%;
		padding: 0 9.4px;
		margin-bottom: 30px;
		@media only screen and (max-width: 1312px){
			padding: 0 5px;
			margin-bottom: 10px;
		}
		@include mq(xl) {
			width: 33.33%;
		}
		@include mq {
			width: 100%;
			padding: 0;
			margin-bottom: 20px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	&__wrap {
		background: #fff;
		display: block;
		width: 100%;
		height: 100%;
		padding: 14px 5px 15px;
		&:hover{
			@include mq{
				opacity: 1;
			}
		}
		@include mq {
			padding: 14px 5px 18px 15px;
			display: flex;
			align-items: center;
		}
	}
	&__img {
		padding: 0 9.5px;
		img{
			width: 100%;
		}
		@include mq {
			padding: 0;
			width: 49.55%;
		}
	}
	&__ttl {
		@include font-size(18);
		line-height: 1.55;
		color: #346EB1;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		position: relative;
		padding-bottom: 16px;
		@include mq {
			width: 50.45%;
			line-height: 1.35;
			padding: 12px 0 29px;
		}
		@include mq(sm){
			@include font-size(16);
		}
		&:after {
			position: absolute;
			content: '';
			width: 14px;
			height: 14px;
			border-right: 1px solid #346EB1;
			border-bottom: 1px solid #346EB1;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%) rotate(45deg);
			@include mq {
				left: 52%;
			}
		}
		span {
			span {
				display: block;
				@include mq {
					margin-bottom: 5px;
				}
			}
		}
	}
}
.ct-single__news{
	display: block;
	.part-introduce__img {
		width: 100% !important;
		margin-bottom: 20px;
		img{
			width: 100% !important;
		}
	}
	.part-introduce__des{
		width: 100% !important;
		padding-left: 0 !important;
	}
	.alignleft{
		float: left;
		margin: 0 20px 20px 0;
		@include mq(){
			display: block;
			float: none;
			margin: 0 auto 20px;
		}
	}
	.alignright{
		float: right;
		margin: 0 0px 20px 20px;
		@include mq(){
			display: block;
			float: none;
			margin: 20px auto 0;
		}
	}
}