.works-table {
	width: 100%;
	max-width: 740px;
	margin: 0 auto 71px;
	@include font-size(16);
	line-height: 1.5;
	min-width: 500px;
	@include mq {
		@include font-size(14);
		margin-bottom: 50px;
	}
	@include mq(xs) {
		@include font-size(12);
	}
	tr {
		th {
			text-align: center;
			font-weight: normal;
			background: #96B7DD;
			border: 1px solid white;
			border-top: none;
			border-bottom: none;
			padding: 3px 10px  1px;
		}
		td {
			border: 1px solid #707070;
			border-top: none;
			padding: 4px 14px 0px 22px;
			@include mq {
				padding: 5px 10px;
			}
			&:first-child {
				border-left: none;
				width: 12%;
				padding: 0 9px;
				@include mq {
					padding: 0 10px;
					width: 18%;
				}
			}
			&:nth-child(2) {
				width: 45.8%;
				@include mq {
					width: 39.8%;
				}
			}
			&:last-child {
				border-right: none;
				width: 21%;
				text-align: right;
			}
			&:nth-last-child(2) {
				width: 21.2%;
				text-align: center;
			}
		}
		&.works-table__row02 {
			td {
				padding: 0 14px 0 22px;
				@include mq {
					padding: 5px 10px;
				}
			}
		}
	}
}

.table-scroll {
	width: 100%;
	overflow-x: auto;
	overflow-y: hidden;
}