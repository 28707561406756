.sec-regulations{
	.ttl-comp02{
		@include mq(){
			margin-bottom: 22px;
		}
	}
}
.box-equipment-inspection{
	display: flex;
	background: #F2F0ED;
	margin-bottom: 18px;
	@include mq(){
		flex-wrap: wrap;
	}
	.box-left{
		width: calc(100% - 49.47%);
		padding-left: 110px;
		padding-top: 45px;
		padding-right: 90px;
		padding-bottom: 30px;
		@include mq(xl){
			padding: 30px 50px 30px;
		}
		@include mq(){
			padding: 27px 16px 33px;
			width: 100%;
			order: 2;
		}
		.txt-basic{
			line-height: 1.75;
			margin-bottom: 17px;
			@include mq(){
				margin-bottom: 13px;
			}
		}
		.txt-note{
			@include font-size(16);
			font-weight: bold;
			line-height: 1.75;
			@include mq(){
				@include font-size(14);
			}
			&__style02{
				@include font-size(18);
				margin-bottom: 10px;
				@include mq(){
					@include font-size(16);
					margin-bottom: 5px;
				}
			}
		}
		.list-standard{
			display: flex;
			margin: 0 -28px;
			padding-right: 12px;
			@include mq(lg){
				padding-right: 0;
				margin: 0 -10px;
			}
			@include mq(){
				padding-right: 0;
				margin: 0 -5.5px;
			}
			.item{
				width: 50%;
				padding: 0 28px;
				position: relative;
				@include mq(lg){
					padding: 0 10px;
				}
				@include mq(){
					padding:  0 5.5px;
				}
				&:after{
					content: "";
					display: block;
					width: 20px;
					height: 10px;
					background: url("../img/service/lightning/design/ico-down.png") no-repeat center;
					background-size: cover;
					position: absolute;
					bottom: 21px;
					left: 48%;
					@include mq(){
						bottom: 13px;
					}
				}
				&__wp{
					background: rgb(97,190,247);
					background: linear-gradient(180deg, rgba(97,190,247,1) 0%, rgba(15,98,150,1) 100%);
					text-align: center;
					@include font-size(16);
					line-height: 1.5;
					color: #fff;
					min-height: 135px;
					padding-top: 12px;
					@include mq(lg){
						@include font-size(14);
					}
					@include mq(){
						min-height: 110px;
					}
					@include mq(sm-ip){
						@include font-size(11);
					}
				}
			}
		}
	}
	.box-right{
		width: 49.47%;
		@include mq(){
			width: 100%;
			order: 1;
		}
		img{
			width: 100%;
		}
	}
	.ttl-en-jp{
		@include font-size(32);
		font-family: $font-meiryo;
		color: #346EB1;
		border-bottom: 4px solid #96B7DD;
		margin-bottom: 15px;
		@include mq(xl){
			@include font-size(28);
		}
		@include mq(){
			@include font-size(24);
			margin-bottom: 10px;
			padding-bottom: 5px;
			line-height: 1.2;
		}
		@include mq(xs){
			@include font-size(22);
		}
		small{
			font-family: $font-raleway;
			@include font-size(20);
			color: #346EB1;
			text-transform: uppercase;
			@include mq(ipd){
				display: block;
				width: 100%;
				text-align: right;
			}
			@include mq(){
				@include font-size(16);
				display: block;
				width: 100%;
				text-align: right;
			}
		}
		&--style02{
			small{
				display: inline-block;
				width: auto;
			}
		}
	}
	&.design{
		.box-left{
			padding-left: 80px;
			padding-top: 66px;
			padding-right: 50px;
			@include mq(xl){
				padding: 30px 50px 30px;
			}
			@include mq(lg){
				padding: 30px 20px;
			}
			@include mq(){
				padding: 24px 16px 15px;
				width: 100%;
				order: 2;
			}
		}
	}
}
.box-txt-intro{
	max-width: 1100px;
	margin: 0 auto 48px;
	@include font-size(18);
	line-height: 1.8;
	color: #303030;
	padding: 0 18px;
	@include mq(){
		@include font-size(14);
		margin-bottom: 50px;
		line-height: 1.75;
	}
}
.sec-protection-equipment{
	padding-bottom: 28px;
	.ttl-comp01{
		margin-bottom: 26px;
		@include mq(){
			margin-bottom: 15px;
		}
	}
	.list-item{
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		margin-bottom: 36px;
		margin-left: -8px;
		margin-right: -8px;
		@include mq(){
			margin-left: 0;
			margin-right: 0;
			margin-bottom: 10px;
		}
		.item{
			width: 25.025%;
			margin-bottom: 20px;
			padding: 0 8px;
			@include mq(){
				width: 100%;
				margin-bottom: 13px;
				padding: 0;
			}
			&-wp{
				border: 3px solid #346EB1;
				background: #F2F0ED;
				padding: 16px;
				@include mq(){
					padding: 16px 30px 45px;
				}
				&__img{
					margin-bottom: 19px;
					@include mq(){
						margin-bottom: 18px;
						text-align: center;
					}
					&--multiple{
						display: flex;
						margin: 0 -15px 19px;
						@include mq(){
							margin: 0 -10px 14px;
							flex-wrap: wrap;
						}
						.img{
							width: 50%;
							padding: 0 15px;
							@include mq(){
								padding: 0 10px;
								text-align: center;
								&:first-child{
									margin-bottom: 0;
								}
							}
							img{
								width: 100%;
							}
						}
					}
				}
				&__des{
					@include font-size(16);
					line-height: 1.75;
					color: #303030;
				}
			}
			&:nth-of-type(3n + 1){
				width: 49.95%;
				@include mq(){
					width: 100%;
				}
				.item-wp{
					padding: 16px 30px 16px;
					@include mq(){
						padding: 16px 30px 25px;
					}
				}
			}
			&:nth-of-type(3n){
				margin-right: 0;
			}
			
		}
	}
}
.box-border-blue{
	border: 3px solid #346EB1;
	padding: 10px 22px;
	max-width: 848px;
	margin: 0 auto 90px;
	@include font-size(18);
	line-height: 1.8;
	@include mq(){
		@include font-size(16);
		padding: 16px 16px 18px;
		margin-bottom: 53px;
	}
	@include mq(sm-ip){
		padding-bottom: 16px;
		@include font-size(14);
	}
	&__style02{
		border-width: 1px;
		max-width: 100%;
		@include font-size(14);
		line-height: 1.7;
		padding: 6px 13px;
		margin-bottom: 34px;
	}
}
.box-example{
	.ttl-comp01{
		margin-bottom: 43px;
		@include mq(){
			margin-bottom: 20px;
		}
	}
	.list-blue{
		display: flex;
		margin-bottom: 40px;
		@include mq(){
			flex-wrap: wrap;
			margin: 0 0 20px;
		}
		.item{
			width: calc((100% - 16px*3)/4);
			margin-right: 16px;
			background: #346EB1;
			padding: 17px 14px 22px;
			@include mq(lg){
				padding: 15px 10px 15px;
			}
			@include mq(){
				width: calc(100%/2 - 5px);
				margin-right: 10px;
				margin-bottom: 15px;
				&:nth-child(2n){
					margin-right: 0;
				}
			}
			@include mq(sm){
				width: 100%;
				margin-right: 0;
				padding-bottom: 30px;
			}
			@include mq(sm-ip){
				padding: 15px;
			}
			&:last-of-type{
				margin-right: 0;
			}
			&__img{
				margin-bottom: 4px;
				@include mq(){
					margin-bottom: 30px;
				}
				img{
					width: 100%;
				}
			}
			&__ttl{
				@include font-size(18);
				font-weight: bold;
				line-height: 1.8;
				color: #fff;
				border-bottom: 1px solid #fff;
				padding-bottom: 8px;
				margin-bottom: 0;
				display: flex;
				align-items: center;
				@include mq(lg){
					@include font-size(16);
					line-height: 1.6;
					margin-bottom: 15px;
				}
				@include mq(){
					@include font-size(18);
					padding-bottom: 17px;
					margin-bottom: 25px;
				}
				@include mq(sm-ip){
					@include font-size(16);
				}
			}
			&__des{
				@include font-size(16);
				line-height: 1.9;
				color: #fff;
				display: flex;
				align-items: center;
				@include mq(lg){
					@include font-size(14);
					line-height: 1.6;
				}
				@include mq(){
					@include font-size(16);
					line-height: 1.9;
				}
				@include mq(sm-ip){
					@include font-size(14);
					line-height: 1.6;
				}
			}
		}
	}
}
.sec-protection-system{
	background: #FCF3E0;
	padding-bottom: 70px;
	@include mq(){
		padding-bottom: 46px;
	}
	.ttl-comp01{
		@include mq(){
			line-height: 1.2;
			margin-bottom: 27px;
		}
	}
	.list-protection-system{
		max-width: 1064px;
		margin: 0 auto;
		.item{
			margin-bottom: 50px;
			@include mq(){
				margin-bottom: 43px;
			}
			&:last-of-type{
				margin-bottom: 0;
			}
			&__des{
				@include font-size(14);
				color: #303030;
				line-height: 1.75;
				@include mq(){
					@include font-size(12);
					line-height: 1.7;
				}
			}
		}
	}
}
.ttl-orange{
	text-align: center;
	@include font-size(24);
	font-weight: bold;
	line-height: 1.7;
	background: #FDC17A;
	padding: 8px 0 5px;
	margin-bottom: 65px;
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		padding: 11px 0 2px;
	}
	@include mq(){
		@include font-size(20);
		line-height: 1.6;
		padding: 10px 0;
		margin-bottom: 45px;
	}
}

.ttl-border-blue{
	@include font-size(20);
	line-height: 1.5;
	color: #fff;
	background: #346EB1;
	border-left: 10px solid #14137E;
	margin-bottom: 17px;
	padding-left: 9px;
	padding-top: 2px;
	@include mq(){
		@include font-size(18);
		line-height: 1.7;
		margin-bottom: 8px;
	}
}

