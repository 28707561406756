@charset "UTF-8";
/* ====================================================
reset style
==================================================== */
@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Raleway:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap");
html {
  overflow-y: scroll;
  overflow-x: hidden;
  font-size: 10px;
  /* Base font size, change to 14px or 16px if needed */
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, textarea, p, blockquote, th, td {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset, img {
  border: 0;
}

img {
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}

address, caption, cite, code, dfn, var {
  font-style: normal;
  font-weight: normal;
}

li {
  list-style: none;
}

caption, th {
  text-align: left;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}

q:before, q:after {
  content: '';
}

abbr, acronym {
  border: 0;
  font-variant: normal;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input, textarea, select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

/*to enable resizing for IE*/
input, textarea, select {
  *font-size: 100%;
}

/*because legend doesn't inherit in IE */
legend {
  color: #000;
}

del, ins {
  text-decoration: none;
}

main {
  display: block;
}

section {
  position: relative;
}

/* ====================================================
Font
==================================================== */
/* ====================================================
Media Quary
==================================================== */
@media screen and (min-width: 769px) {
  .mbpc-0 {
    margin-bottom: 0px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-0 {
    margin-bottom: 0px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-1 {
    margin-bottom: 1px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-1 {
    margin-bottom: 1px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-2 {
    margin-bottom: 2px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-2 {
    margin-bottom: 2px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-3 {
    margin-bottom: 3px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-3 {
    margin-bottom: 3px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-4 {
    margin-bottom: 4px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-4 {
    margin-bottom: 4px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-5 {
    margin-bottom: 5px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-5 {
    margin-bottom: 5px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-6 {
    margin-bottom: 6px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-6 {
    margin-bottom: 6px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-7 {
    margin-bottom: 7px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-7 {
    margin-bottom: 7px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-8 {
    margin-bottom: 8px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-8 {
    margin-bottom: 8px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-9 {
    margin-bottom: 9px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-9 {
    margin-bottom: 9px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-10 {
    margin-bottom: 10px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-10 {
    margin-bottom: 10px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-11 {
    margin-bottom: 11px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-11 {
    margin-bottom: 11px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-12 {
    margin-bottom: 12px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-12 {
    margin-bottom: 12px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-13 {
    margin-bottom: 13px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-13 {
    margin-bottom: 13px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-14 {
    margin-bottom: 14px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-14 {
    margin-bottom: 14px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-15 {
    margin-bottom: 15px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-15 {
    margin-bottom: 15px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-16 {
    margin-bottom: 16px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-16 {
    margin-bottom: 16px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-17 {
    margin-bottom: 17px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-17 {
    margin-bottom: 17px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-18 {
    margin-bottom: 18px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-18 {
    margin-bottom: 18px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-19 {
    margin-bottom: 19px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-19 {
    margin-bottom: 19px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-20 {
    margin-bottom: 20px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-20 {
    margin-bottom: 20px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-21 {
    margin-bottom: 21px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-21 {
    margin-bottom: 21px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-22 {
    margin-bottom: 22px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-22 {
    margin-bottom: 22px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-23 {
    margin-bottom: 23px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-23 {
    margin-bottom: 23px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-24 {
    margin-bottom: 24px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-24 {
    margin-bottom: 24px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-25 {
    margin-bottom: 25px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-25 {
    margin-bottom: 25px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-26 {
    margin-bottom: 26px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-26 {
    margin-bottom: 26px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-27 {
    margin-bottom: 27px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-27 {
    margin-bottom: 27px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-28 {
    margin-bottom: 28px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-28 {
    margin-bottom: 28px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-29 {
    margin-bottom: 29px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-29 {
    margin-bottom: 29px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-30 {
    margin-bottom: 30px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-30 {
    margin-bottom: 30px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-31 {
    margin-bottom: 31px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-31 {
    margin-bottom: 31px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-32 {
    margin-bottom: 32px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-32 {
    margin-bottom: 32px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-33 {
    margin-bottom: 33px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-33 {
    margin-bottom: 33px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-34 {
    margin-bottom: 34px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-34 {
    margin-bottom: 34px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-35 {
    margin-bottom: 35px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-35 {
    margin-bottom: 35px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-36 {
    margin-bottom: 36px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-36 {
    margin-bottom: 36px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-37 {
    margin-bottom: 37px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-37 {
    margin-bottom: 37px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-38 {
    margin-bottom: 38px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-38 {
    margin-bottom: 38px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-39 {
    margin-bottom: 39px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-39 {
    margin-bottom: 39px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-40 {
    margin-bottom: 40px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-40 {
    margin-bottom: 40px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-41 {
    margin-bottom: 41px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-41 {
    margin-bottom: 41px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-42 {
    margin-bottom: 42px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-42 {
    margin-bottom: 42px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-43 {
    margin-bottom: 43px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-43 {
    margin-bottom: 43px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-44 {
    margin-bottom: 44px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-44 {
    margin-bottom: 44px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-45 {
    margin-bottom: 45px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-45 {
    margin-bottom: 45px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-46 {
    margin-bottom: 46px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-46 {
    margin-bottom: 46px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-47 {
    margin-bottom: 47px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-47 {
    margin-bottom: 47px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-48 {
    margin-bottom: 48px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-48 {
    margin-bottom: 48px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-49 {
    margin-bottom: 49px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-49 {
    margin-bottom: 49px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-50 {
    margin-bottom: 50px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-50 {
    margin-bottom: 50px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-51 {
    margin-bottom: 51px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-51 {
    margin-bottom: 51px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-52 {
    margin-bottom: 52px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-52 {
    margin-bottom: 52px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-53 {
    margin-bottom: 53px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-53 {
    margin-bottom: 53px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-54 {
    margin-bottom: 54px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-54 {
    margin-bottom: 54px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-55 {
    margin-bottom: 55px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-55 {
    margin-bottom: 55px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-56 {
    margin-bottom: 56px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-56 {
    margin-bottom: 56px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-57 {
    margin-bottom: 57px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-57 {
    margin-bottom: 57px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-58 {
    margin-bottom: 58px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-58 {
    margin-bottom: 58px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-59 {
    margin-bottom: 59px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-59 {
    margin-bottom: 59px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-60 {
    margin-bottom: 60px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-60 {
    margin-bottom: 60px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-61 {
    margin-bottom: 61px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-61 {
    margin-bottom: 61px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-62 {
    margin-bottom: 62px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-62 {
    margin-bottom: 62px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-63 {
    margin-bottom: 63px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-63 {
    margin-bottom: 63px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-64 {
    margin-bottom: 64px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-64 {
    margin-bottom: 64px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-65 {
    margin-bottom: 65px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-65 {
    margin-bottom: 65px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-66 {
    margin-bottom: 66px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-66 {
    margin-bottom: 66px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-67 {
    margin-bottom: 67px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-67 {
    margin-bottom: 67px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-68 {
    margin-bottom: 68px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-68 {
    margin-bottom: 68px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-69 {
    margin-bottom: 69px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-69 {
    margin-bottom: 69px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-70 {
    margin-bottom: 70px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-70 {
    margin-bottom: 70px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-71 {
    margin-bottom: 71px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-71 {
    margin-bottom: 71px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-72 {
    margin-bottom: 72px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-72 {
    margin-bottom: 72px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-73 {
    margin-bottom: 73px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-73 {
    margin-bottom: 73px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-74 {
    margin-bottom: 74px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-74 {
    margin-bottom: 74px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-75 {
    margin-bottom: 75px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-75 {
    margin-bottom: 75px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-76 {
    margin-bottom: 76px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-76 {
    margin-bottom: 76px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-77 {
    margin-bottom: 77px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-77 {
    margin-bottom: 77px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-78 {
    margin-bottom: 78px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-78 {
    margin-bottom: 78px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-79 {
    margin-bottom: 79px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-79 {
    margin-bottom: 79px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-80 {
    margin-bottom: 80px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-80 {
    margin-bottom: 80px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-81 {
    margin-bottom: 81px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-81 {
    margin-bottom: 81px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-82 {
    margin-bottom: 82px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-82 {
    margin-bottom: 82px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-83 {
    margin-bottom: 83px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-83 {
    margin-bottom: 83px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-84 {
    margin-bottom: 84px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-84 {
    margin-bottom: 84px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-85 {
    margin-bottom: 85px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-85 {
    margin-bottom: 85px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-86 {
    margin-bottom: 86px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-86 {
    margin-bottom: 86px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-87 {
    margin-bottom: 87px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-87 {
    margin-bottom: 87px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-88 {
    margin-bottom: 88px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-88 {
    margin-bottom: 88px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-89 {
    margin-bottom: 89px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-89 {
    margin-bottom: 89px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-90 {
    margin-bottom: 90px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-90 {
    margin-bottom: 90px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-91 {
    margin-bottom: 91px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-91 {
    margin-bottom: 91px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-92 {
    margin-bottom: 92px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-92 {
    margin-bottom: 92px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-93 {
    margin-bottom: 93px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-93 {
    margin-bottom: 93px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-94 {
    margin-bottom: 94px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-94 {
    margin-bottom: 94px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-95 {
    margin-bottom: 95px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-95 {
    margin-bottom: 95px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-96 {
    margin-bottom: 96px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-96 {
    margin-bottom: 96px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-97 {
    margin-bottom: 97px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-97 {
    margin-bottom: 97px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-98 {
    margin-bottom: 98px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-98 {
    margin-bottom: 98px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-99 {
    margin-bottom: 99px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-99 {
    margin-bottom: 99px !important;
  }
}

@media screen and (min-width: 769px) {
  .mbpc-100 {
    margin-bottom: 100px !important;
  }
}

@media screen and (max-width: 768px) {
  .mbsp-100 {
    margin-bottom: 100px !important;
  }
}

body {
  color: #303030;
  font-family: "Meiryo", "メイリオ", "ヒラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", Osaka, "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  line-height: 1.4;
}

.mac table {
  table-layout: inherit !important;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
}

@media screen and (max-width: 1024px) {
  .header {
    height: 70px;
  }
}

.header-top {
  padding: 16px 0;
  background: #fff;
}

.header-top .inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 1024px) {
  .header-top .inner {
    height: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .header-top {
    height: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .header-logo {
    width: 87.5%;
    max-width: 300px;
  }
}

.header-control {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1024px) {
  .header-control {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #D0D0D0;
    height: 70px;
    align-items: stretch;
  }
}

@media screen and (max-width: 1024px) and (max-width: 768px) {
  .header-control {
    transform: translateY(70px);
    transition: all 0.3s;
  }
  .header-control.on {
    transform: translateY(0px);
    transition: all 0.3s;
  }
}

.header-control__mail {
  font-size: 16px;
  font-size: 1.6rem;
  margin-right: 39px;
}

.header-control__mail img {
  margin-right: 7px;
}

@media screen and (max-width: 1024px) {
  .header-control__mail {
    width: 50%;
    margin: 0;
    background: #346EB1;
    font-size: 12px;
    font-size: 1.2rem;
    color: #fff;
  }
  .header-control__mail img {
    margin: 0 0 0 15px;
    width: 25px;
  }
}

@media screen and (max-width: 1024px) and (max-width: 768px) {
  .header-control__mail img {
    width: 25px;
  }
}

@media screen and (max-width: 1024px) {
  .header-control__mail > a {
    width: 100%;
    height: 100%;
    padding: 21px 17px 0 0;
    display: block;
  }
}

@media screen and (max-width: 1024px) and (max-width: 640px) {
  .header-control__mail > a {
    padding: 23px 17px 0 0;
  }
}

@media screen and (max-width: 1024px) and (max-width: 374px) {
  .header-control__mail > a {
    padding-top: 26px;
  }
}

@media screen and (max-width: 1024px) {
  .header-control__mail > a > span {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .header-control__mail > a img {
    transform: translateY(2px);
  }
}

.header-control__mail .txt {
  position: relative;
  top: 2px;
}

@media screen and (max-width: 1024px) {
  .header-control__mail .txt {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 640px) {
  .header-control__mail .txt {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.header-control__search {
  width: 180px;
}

@media screen and (max-width: 1024px) {
  .header-control__search {
    width: 50%;
    display: flex;
    align-items: center;
    padding: 8px 0 0 8px;
    justify-content: center;
  }
}

.header-control__search table.gsc-search-box {
  position: relative;
}

@media screen and (max-width: 768px) {
  .header-control__search table.gsc-search-box {
    width: 210px !important;
  }
}

@media screen and (max-width: 640px) {
  .header-control__search table.gsc-search-box {
    width: 165px !important;
  }
}

@media screen and (max-width: 374px) {
  .header-control__search table.gsc-search-box {
    width: 137px !important;
  }
}

.header-control__search table.gsc-search-box button.gsc-search-button.gsc-search-button-v2 svg {
  width: 32px !important;
  height: 22px !important;
}

.header-control__search table.gsc-search-box input#gsc-i-id1 {
  background: none !important;
  text-indent: 4px !important;
  padding-left: 35px !important;
  max-width: 100% !important;
}

.header-control__search table.gsc-search-box button.gsc-search-button.gsc-search-button-v2 {
  position: absolute;
  left: 0;
  top: 0;
}

.header-control__search form {
  display: flex;
}

.header-control__search form button {
  width: 30px;
  height: 30px;
  border: 1px solid #303030;
  background: #303030;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .header-control__search form button {
    position: relative;
  }
  .header-control__search form button img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.header-control__search form input {
  border: 1px solid #303030;
  border-radius: 0;
  border-left: none;
  width: 100%;
  max-width: 155px;
  font-size: 12px;
  font-size: 1.2rem;
  padding: 2px 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
}

@media screen and (max-width: 374px) {
  .header-control__search form input {
    width: 112px;
  }
}

.header-nav {
  background: rgba(238, 238, 238, 0.6);
}

@media screen and (max-width: 1024px) {
  .header-nav {
    background: #F1F0ED;
    position: fixed;
    left: 0;
    right: 0;
    overflow-y: auto;
    top: 70px;
    height: calc(100vh - 70px);
    opacity: 0;
    pointer-events: none;
    transition: 0.3s;
  }
  .header-nav .inner {
    padding: 0;
  }
  .header-nav.active {
    opacity: 1;
    pointer-events: auto;
    transition: 0.3s;
  }
}

.header-menu {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
}

@media screen and (max-width: 1024px) {
  .header-menu {
    display: block;
    padding: 0 0 50px;
  }
}

.header-menu__item {
  width: 16.66%;
  font-size: 12px;
  font-size: 1.2rem;
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .header-menu__item {
    width: 100%;
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.header-menu__item a {
  display: block;
  width: 100%;
  height: 100%;
  border-bottom: 3px solid transparent;
  padding: 3px 10px;
}

@media screen and (max-width: 1024px) {
  .header-menu__item a {
    border-bottom: 1px solid #707070;
    padding: 0 10px 0 16px;
    text-align: left;
  }
}

@media screen and (min-width: 1025px) {
  .header-menu__item a .en {
    font-family: "Raleway", sans-serif;
    font-size: 18px;
    font-size: 1.8rem;
    display: block;
  }
}

.header-menu__item a:hover {
  opacity: 1;
}

@media screen and (min-width: 1025px) {
  .header-menu__item a:hover {
    border-color: #346EB1;
  }
}

.header-menu__item.is-active a {
  border-color: #346EB1;
}

@media screen and (max-width: 1024px) {
  .header-menu__item.is-active a {
    border-color: #707070;
  }
}

.header-menu__sub {
  display: none;
  background: #E2E0DC;
}

@media screen and (max-width: 1024px) {
  .header-menu__sub a {
    padding-left: 48px;
  }
}

.header-menu__sub02 {
  display: none;
  background: #61BEF7;
}

@media screen and (max-width: 1024px) {
  .header-menu__sub02 a {
    padding-left: 80px;
    color: #fff;
    border-color: #FFF;
  }
}

@media screen and (max-width: 1024px) {
  .header-menu__wrap {
    position: relative;
    display: inline-block;
    padding: 11px 0 9px 0;
    letter-spacing: -0.1px;
  }
  .header-menu__wrap.wrap-sub {
    padding-right: 35px;
  }
}

.header-menu__icon {
  position: absolute;
  width: 35px;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 10px 0 0 6px;
}

.header-menu__icon:before {
  display: inline-block;
  content: '';
  border-top: 8px solid #000;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

@media screen and (max-width: 1024px) {
  .header .mobile-icon {
    width: 28px;
    height: 29px;
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
  }
  .header .mobile-icon span {
    position: absolute;
    width: 100%;
    height: 4px;
    background: #346EB1;
    transition: 0.3s;
  }
  .header .mobile-icon span:first-child {
    top: 0;
  }
  .header .mobile-icon span:nth-of-type(2) {
    top: 45%;
  }
  .header .mobile-icon span:last-child {
    bottom: 0;
  }
  .header .mobile-icon--close {
    width: 37px;
    height: 37px;
    position: absolute;
    top: 49%;
    right: 11px;
  }
  .header .mobile-icon--close span:first-child {
    transform: rotate(45deg);
    top: 45%;
  }
  .header .mobile-icon--close span:nth-of-type(2) {
    opacity: 0;
  }
  .header .mobile-icon--close span:last-child {
    transform: rotate(-45deg);
    bottom: 45%;
  }
}

.header.fixed {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.header.fixed .header-nav {
  background: #eeeeee;
}

.footer {
  background: #EEEEEE;
  position: relative;
}

@media screen and (max-width: 1024px) {
  .footer {
    transition: all 0.3s;
  }
  .footer.on {
    padding-bottom: 70px;
    transition: all 0.3s;
  }
}

.footer__ttl {
  font-size: 20px;
  font-size: 2rem;
  margin-bottom: 8px;
}

@media screen and (max-width: 768px) {
  .footer__ttl {
    margin-bottom: 11px;
  }
}

@media screen and (max-width: 374px) {
  .footer__ttl {
    font-size: 17px;
    font-size: 1.7rem;
  }
}

.footer__ttl span {
  font-size: 16px;
  font-size: 1.6rem;
  font-family: "Raleway", sans-serif;
}

@media screen and (max-width: 374px) {
  .footer__ttl span {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.footer__ttl--border {
  border-bottom: 1px solid #707070;
  padding-bottom: 6px;
  margin-bottom: 28px;
}

@media screen and (max-width: 768px) {
  .footer__ttl--border {
    padding-bottom: 6px;
    margin-bottom: 15px;
  }
}

.footer-sitemap {
  padding: 36px 0 3px;
}

@media screen and (max-width: 768px) {
  .footer-sitemap {
    padding: 28px 0 38px;
  }
}

.footer-sitemap .sitemap-list {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 14px;
  font-size: 1.4rem;
}

@media screen and (max-width: 990px) {
  .footer-sitemap .sitemap-list {
    justify-content: flex-start;
  }
}

@media screen and (max-width: 374px) {
  .footer-sitemap .sitemap-list {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.footer-sitemap .sitemap-list__item {
  line-height: 1.43;
  padding-right: 20px;
  margin-bottom: 30px;
}

@media screen and (max-width: 990px) {
  .footer-sitemap .sitemap-list__item {
    width: 33.33%;
  }
}

@media screen and (max-width: 768px) {
  .footer-sitemap .sitemap-list__item {
    margin-bottom: 25px;
    transform: translateY(3px);
  }
}

.footer-sitemap .sitemap-list__item:last-child {
  padding-right: 0;
}

@media screen and (max-width: 990px) {
  .footer-sitemap .sitemap-list__item:last-child {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .footer-sitemap .sitemap-list__item:last-child {
    margin-bottom: 0;
    padding-top: 8px;
  }
}

.footer-sitemap .sitemap-list__item:last-child .sitemap-list__ttl {
  margin-bottom: 38px;
}

@media screen and (max-width: 768px) {
  .footer-sitemap .sitemap-list__item:last-child .sitemap-list__ttl {
    margin-bottom: 16px;
  }
}

.footer-sitemap .sitemap-list__item:last-child .sitemap-list__ttl:last-child {
  margin-bottom: 0;
}

.footer-sitemap .sitemap-list__ttl {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.78;
  margin-bottom: 13px;
  display: block;
}

@media screen and (max-width: 768px) {
  .footer-sitemap .sitemap-list__ttl {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 2;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 374px) {
  .footer-sitemap .sitemap-list__ttl {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 768px) {
  .footer-sitemap .sitemap-list__sub {
    display: none;
  }
}

.footer-sitemap .sitemap-list__sub li {
  margin-bottom: 8px;
}

.footer-sitemap .sitemap-list__sub li:last-child {
  margin-bottom: 0;
}

.footer-sitemap .sitemap-list__sub li a {
  padding-left: 4px;
  position: relative;
  display: block;
}

.footer-sitemap .sitemap-list__sub li a:before {
  position: absolute;
  content: '';
  width: 3px;
  height: 1px;
  background: #303030;
  top: 10px;
  left: 0;
}

.footer-contact {
  background: #14137E;
  color: white;
  padding: 46px 0 54px;
  align-items: flex-start;
}

@media screen and (max-width: 768px) {
  .footer-contact {
    padding: 19px 0 32px;
  }
}

.footer-contact .inner {
  display: flex;
  flex-wrap: wrap;
}

.footer-contact__left {
  width: 380px;
  padding: 13px 20px 0 0;
}

@media screen and (max-width: 768px) {
  .footer-contact__left {
    width: 100%;
    max-width: 100%;
    padding-right: 0;
    margin-bottom: 22px;
  }
}

.footer-contact__logo {
  display: inline-block;
  margin-bottom: 25px;
}

@media screen and (max-width: 768px) {
  .footer-contact__logo {
    margin-bottom: 14px;
  }
}

.footer-contact__txt {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.75;
}

@media screen and (max-width: 768px) {
  .footer-contact__txt {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.72;
  }
}

@media screen and (max-width: 374px) {
  .footer-contact__txt {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.footer-contact__right {
  width: calc(100% - 380px);
  max-width: 756px;
  margin-left: auto;
}

@media screen and (max-width: 768px) {
  .footer-contact__right {
    width: 100%;
    max-width: 100%;
  }
}

.footer-btn {
  width: 100%;
  height: 159px;
  display: flex;
  align-items: center;
  background: url("../img/common/bg-footer-contact.png") no-repeat;
  background-size: cover;
}

@media screen and (max-width: 768px) {
  .footer-btn {
    height: 197px;
    background: url("../img/common/bg-footer-contact-sp.png") no-repeat;
    background-size: cover;
  }
}

.footer-btn__inner {
  background: rgba(48, 48, 48, 0.81);
  width: 100%;
  max-width: 355px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 990px) {
  .footer-btn__inner {
    max-width: 290px;
  }
}

@media screen and (max-width: 768px) {
  .footer-btn__inner {
    max-width: 176px;
  }
}

.footer-btn__wrap {
  font-family: "Raleway", sans-serif;
  font-size: 30px;
  font-size: 3rem;
  line-height: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 990px) {
  .footer-btn__wrap {
    font-size: 22px;
    font-size: 2.2rem;
  }
}

.footer-btn__wrap img {
  margin-right: 14px;
}

@media screen and (max-width: 768px) {
  .footer-btn__wrap img {
    margin-right: 5px;
  }
}

.footer-btn__wrap span {
  font-family: "Meiryo", "メイリオ", "ヒラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", Osaka, "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
  font-size: 16px;
  font-size: 1.6rem;
  margin-left: 14px;
}

@media screen and (max-width: 768px) {
  .footer-btn__wrap span {
    width: 100%;
    text-align: center;
    margin: 10px 0 0 0;
  }
}

.footer .copyright {
  color: #14137E;
  text-align: center;
  padding: 6px 16px 5px;
  font-size: 14px;
  font-size: 1.4rem;
}

@media screen and (max-width: 768px) {
  .footer .copyright {
    font-size: 12px;
    font-size: 1.2rem;
    padding: 8px 16px 6px;
  }
}

@media screen and (max-width: 374px) {
  .footer .copyright {
    font-size: 10px;
    font-size: 1rem;
  }
}

.footer .backtop {
  width: 40px;
  height: 40px;
  background: #346EB1;
  position: fixed;
  bottom: 30px;
  right: 42px;
  z-index: 10;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-family: "Raleway", sans-serif;
  font-size: 12px;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .footer .backtop {
    bottom: 80px;
    right: 10px;
  }
}

.footer .backtop span {
  padding-top: 21px;
  position: relative;
}

.footer .backtop span:before {
  position: absolute;
  content: '';
  width: 14px;
  height: 14px;
  border-left: 2px solid white;
  border-top: 2px solid white;
  top: 10px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}

.sec-safe {
  position: relative;
}

.sec-safe .ttl-safe {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@media screen and (max-width: 990px) {
  .sec-safe .ttl-safe {
    justify-content: center;
    flex-wrap: wrap;
  }
}

.sec-safe .ttl-safe span {
  padding-left: 18px;
}

@media screen and (max-width: 990px) {
  .sec-safe .ttl-safe span {
    padding-left: 10px;
  }
}

@media screen and (max-width: 1200px) {
  .sec-safe .ttl-safe img {
    width: 5vw;
  }
}

@media screen and (max-width: 990px) {
  .sec-safe .ttl-safe img {
    width: 64px;
  }
}

.sec-safe .block-safe {
  font-size: 32px;
  font-size: 3.2rem;
  line-height: 1.5;
  color: #303030;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

@media screen and (max-width: 1200px) {
  .sec-safe .block-safe {
    font-size: 2.5vw;
  }
}

@media screen and (max-width: 990px) {
  .sec-safe .block-safe {
    font-size: 28px;
    font-size: 2.8rem;
  }
}

.sec-safe .block-safe a {
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.2;
  color: #303030;
  background: #fff;
  border: 1px solid #707070;
  width: 100%;
  padding: 6px 10px 6px 16px;
  min-height: 81px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  position: relative;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .sec-safe .block-safe a {
    height: 81px;
  }
}

@media screen and (max-width: 1200px) {
  .sec-safe .block-safe a {
    font-size: 1.6vw;
    padding-right: 2.5vw;
  }
}

@media screen and (max-width: 990px) {
  .sec-safe .block-safe a {
    font-size: 14px;
    font-size: 1.4rem;
    min-height: 44px;
    max-width: 255px;
    margin: 0px auto 9px;
    line-height: 1.43;
    padding: 9px 10px 6px 16px;
  }
}

@media screen and (max-width: 990px) and (-ms-high-contrast: active), screen and (max-width: 990px) and (-ms-high-contrast: none) {
  .sec-safe .block-safe a {
    height: 44px;
  }
}

.sec-safe .block-safe a:after {
  content: '';
  height: 15px;
  width: 15px;
  border-top: 2px solid #303030;
  border-right: 2px solid #303030;
  position: absolute;
  top: 50%;
  transform: rotate(45deg) translateY(-50%);
  right: 30px;
  display: inline-block;
}

@media screen and (max-width: 1200px) {
  .sec-safe .block-safe a:after {
    right: 1.9vw;
    height: 1.2vw;
    width: 1.2vw;
  }
}

@media screen and (max-width: 990px) {
  .sec-safe .block-safe a:after {
    height: 10px;
    width: 10px;
    right: 11px;
    top: 48%;
  }
}

.sec-safe .block-safe a:last-child {
  margin-bottom: 0;
}

.sec-safe .block-safe .box-safe {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 30px;
  padding-bottom: 30px;
}

@media screen and (max-width: 990px) {
  .sec-safe .block-safe .box-safe {
    padding-bottom: 20px;
    padding-top: 15px;
  }
}

.sec-safe .block-safe .ttl-safe {
  width: calc(49% - 1px);
  padding-right: 5px;
}

@media screen and (max-width: 1200px) {
  .sec-safe .block-safe .ttl-safe {
    width: 50%;
  }
}

@media screen and (max-width: 990px) {
  .sec-safe .block-safe .ttl-safe {
    width: 100%;
    padding-right: 0;
    margin-bottom: 9px;
  }
}

.sec-safe .block-safe .link-safe {
  width: calc(51% + 1px);
}

@media screen and (max-width: 1200px) {
  .sec-safe .block-safe .link-safe {
    width: 50%;
  }
}

@media screen and (max-width: 990px) {
  .sec-safe .block-safe .link-safe {
    width: 100%;
  }
}

.sec-safe .block-safe .part-safe {
  width: 50%;
}

@media screen and (max-width: 990px) {
  .sec-safe .block-safe .part-safe {
    width: 100%;
  }
}

.sec-safe .block-safe .part-safe__right {
  padding-left: 39px;
}

@media screen and (max-width: 1200px) {
  .sec-safe .block-safe .part-safe__right {
    padding-left: 2.5vw;
  }
}

@media screen and (max-width: 990px) {
  .sec-safe .block-safe .part-safe__right {
    padding-left: 0;
    position: relative;
  }
}

@media screen and (max-width: 990px) {
  .sec-safe .block-safe .part-safe__right .box-safe {
    padding-top: 14px;
    padding-bottom: 18px;
  }
}

@media screen and (max-width: 990px) {
  .sec-safe .block-safe .part-safe__right .ttl-safe {
    margin-bottom: 17px;
    max-width: 255px;
    margin: 0 auto 17px;
    justify-content: flex-start;
    align-items: flex-end;
  }
}

@media screen and (max-width: 990px) {
  .sec-safe .block-safe .part-safe__right .ttl-safe span {
    padding-left: 20px;
  }
}

@media screen and (max-width: 990px) {
  .sec-safe .block-safe .part-safe__right .ttl-safe img {
    width: 66px;
  }
}

.sec-safe .block-safe .part-safe__right a {
  min-height: 105px;
  padding: 6px 10px 6px 29px;
}

@media screen and (max-width: 990px) {
  .sec-safe .block-safe .part-safe__right a {
    min-height: 44px;
    padding: 6px 10px 6px 11px;
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.sec-safe .block-safe .part-safe__right:after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background: #FCF3E0;
  left: 50%;
  z-index: 0;
}

@media screen and (max-width: 990px) {
  .sec-safe .block-safe .part-safe__right:after {
    left: 0;
    right: -16px;
    width: auto;
  }
}

@media screen and (max-width: 990px) {
  .sec-safe .block-safe .part-safe__right:before {
    content: '';
    position: absolute;
    left: -16px;
    top: 0;
    bottom: 0;
    width: auto;
    background: #FCF3E0;
    right: 0;
    z-index: 0;
  }
}

.sec-safe .block-safe .part-safe__left {
  padding-right: 39px;
}

@media screen and (max-width: 1200px) {
  .sec-safe .block-safe .part-safe__left {
    padding-right: 2.5vw;
  }
}

@media screen and (max-width: 990px) {
  .sec-safe .block-safe .part-safe__left {
    padding-right: 0;
    position: relative;
  }
}

.sec-safe .block-safe .part-safe__left:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background: #E3FEFF;
  right: 50%;
  z-index: 0;
}

@media screen and (max-width: 990px) {
  .sec-safe .block-safe .part-safe__left:after {
    left: 0;
    right: -16px;
    width: auto;
  }
}

@media screen and (max-width: 990px) {
  .sec-safe .block-safe .part-safe__left:before {
    content: '';
    position: absolute;
    left: -16px;
    top: 0;
    bottom: 0;
    width: auto;
    background: #E3FEFF;
    right: 0;
    z-index: 0;
  }
}

.inner {
  display: block;
  max-width: 1312px;
  padding: 0 16px;
  margin: 0 auto;
}

.inner-1064 {
  max-width: 1096px;
}

.inner-1052 {
  max-width: 1084px;
}

.inner-848 {
  max-width: 880px;
}

.clearfix {
  *zoom: 1;
}

.clearfix:before {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
  content: "";
  display: table;
}

a {
  text-decoration: none;
  color: inherit;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

a:hover {
  opacity: 0.7;
}

@media screen and (min-width: 769px) {
  a[href^="tel"] {
    pointer-events: none;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  a[href^="tel"] {
    display: inline-block;
  }
}

@media screen and (max-width: 768px) {
  .pc {
    display: none !important;
  }
}

@media screen and (max-width: 1024px) {
  .pc-ipd {
    display: none !important;
  }
}

@media screen and (max-width: 990px) {
  .pc-lg {
    display: none !important;
  }
}

@media screen and (max-width: 640px) {
  .pc-sm {
    display: none !important;
  }
}

@media screen and (max-width: 374px) {
  .pc-xs {
    display: none !important;
  }
}

@media screen and (min-width: 769px) {
  .sp {
    display: none !important;
  }
}

@media screen and (min-width: 1025px) {
  .sp-ipd {
    display: none !important;
  }
}

@media screen and (min-width: 991px) {
  .sp-lg {
    display: none !important;
  }
}

@media screen and (min-width: 641px) {
  .sp-sm {
    display: none !important;
  }
}

@media screen and (min-width: 375px) {
  .sp-xs {
    display: none !important;
  }
}

@media screen and (max-width: 768px) {
  body.mac .ttl-comp01 > span {
    background-position: 0% 0.6em;
  }
}

.ttl-comp00 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  font-family: "Meiryo", "メイリオ", "ヒラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", Osaka, "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
  font-size: 40px;
  font-size: 4rem;
  line-height: 1.2;
  color: #0060CB;
  font-weight: 400;
  text-align: center;
  margin-bottom: 50px;
}

@media screen and (max-width: 768px) {
  .ttl-comp00 {
    font-size: 32px;
    font-size: 3.2rem;
  }
}

.ttl-comp00__english {
  font-family: "Raleway", sans-serif;
  font-size: 32px;
  font-size: 3.2rem;
  display: inline-block;
  font-weight: 400;
  text-transform: uppercase;
  margin-left: 10px;
  position: relative;
  top: 2px;
}

@media screen and (max-width: 768px) {
  .ttl-comp00__english {
    font-size: 20px;
    font-size: 2rem;
    display: inline-block;
    width: 100%;
  }
}

.ttl-comp01 {
  font-size: 32px;
  font-size: 3.2rem;
  line-height: 1.1;
  text-align: center;
  margin-bottom: 46px;
}

@media screen and (max-width: 1200px) {
  .ttl-comp01 {
    font-size: 28px;
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 768px) {
  .ttl-comp01 {
    font-size: 24px;
    font-size: 2.4rem;
    margin-bottom: 22px;
  }
}

@media screen and (max-width: 374px) {
  .ttl-comp01 {
    font-size: 23px;
    font-size: 2.3rem;
  }
}

.ttl-comp01 small {
  font-size: 20px;
  font-size: 2rem;
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
}

@media screen and (max-width: 1200px) {
  .ttl-comp01 small {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 768px) {
  .ttl-comp01 small {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.ttl-comp01 > span {
  background-image: linear-gradient(to right, rgba(255, 141, 8, 0.5) 50%, transparent 50%);
  background-size: 200% 12px;
  background-repeat: no-repeat;
  background-position: 0% .85em;
  padding: 0 32px;
}

@media screen and (max-width: 768px) {
  .ttl-comp01 > span {
    background-position: 0% 0.75em;
    padding: 0;
  }
}

@media screen and (max-width: 768px) {
  .ttl-comp01--style02 > span {
    background: none;
    display: block;
    position: relative;
    z-index: 1;
  }
  .ttl-comp01--style02 > span:after {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 141, 8, 0.5);
    height: 12px;
    z-index: -1;
  }
}

.ttl-comp01--white {
  color: white;
}

.ttl-comp02 {
  font-family: "Meiryo", "メイリオ", "ヒラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", Osaka, "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
  color: #303030;
  font-size: 24px;
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 1.667;
  margin: 0 auto 28px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .ttl-comp02 {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.75;
    margin: 0 auto 28px;
  }
}

.ttl-comp02 span {
  display: inline-block;
  padding-bottom: 6px;
}

@media screen and (max-width: 768px) {
  .ttl-comp02 span {
    padding: 0 8px 1px 8px;
  }
}

.ttl-comp02.bd-bt span {
  border-bottom: 3px solid #0060CB;
}

.ttl-comp03 {
  font-family: "Raleway", sans-serif;
  font-size: 32px;
  font-size: 3.2rem;
  line-height: 1.25;
  font-weight: 400;
  color: #303030;
  margin-bottom: 11px;
}

@media screen and (max-width: 768px) {
  .ttl-comp03 {
    font-size: 28px;
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 374px) {
  .ttl-comp03 {
    font-size: 22px;
    font-size: 2.2rem;
  }
}

.ttl-comp03--line {
  padding-bottom: 6px;
  border-bottom: 10px solid #96B7DD;
  margin-bottom: 39px;
}

@media screen and (max-width: 768px) {
  .ttl-comp03--line {
    border-width: 5px;
    margin-bottom: 30px;
  }
}

.ttl-comp04 {
  font-weight: bold;
  font-size: 20px;
  font-size: 2rem;
  border-bottom: solid 2px;
  color: #0060CB;
  margin-bottom: 11px;
}

@media screen and (max-width: 768px) {
  .ttl-comp04 {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.ttl-comp04--nobdbt {
  border-bottom: none;
}

.ttl-comp05 {
  font-size: 32px;
  font-size: 3.2rem;
  line-height: 1.25;
  color: #346EB1;
  text-align: center;
  margin-bottom: 40px;
}

@media screen and (max-width: 1200px) {
  .ttl-comp05 {
    font-size: 27px;
    font-size: 2.7rem;
  }
}

@media screen and (max-width: 768px) {
  .ttl-comp05 {
    font-size: 22px;
    font-size: 2.2rem;
    margin-bottom: 30px;
  }
}

.ttl-comp05 > span {
  display: inline-block;
  position: relative;
  padding-bottom: 3px;
}

.ttl-comp05 > span:after {
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  bottom: 0;
  height: 4px;
  background: #96B7DD;
}

.ttl-comp05 > span .en {
  font-size: 20px;
  font-size: 2rem;
  line-height: 2;
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
}

@media screen and (max-width: 1200px) {
  .ttl-comp05 > span .en {
    font-size: 17px;
    font-size: 1.7rem;
  }
}

@media screen and (max-width: 768px) {
  .ttl-comp05 > span .en {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.ttl-comp06 {
  font-weight: bold;
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.4;
  color: #346EB1;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.ttl-comp06:before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  background: #346eb1;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 3px;
}

@media screen and (max-width: 768px) {
  .ttl-comp06:before {
    margin-top: 5px;
  }
}

@media screen and (max-width: 768px) {
  .ttl-comp06 {
    font-size: 16px;
    font-size: 1.6rem;
    align-items: flex-start;
  }
}

.ttl-comp07 {
  font-size: 20px;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 55px;
  line-height: 1.4;
  color: #346EB1;
}

.ttl-comp07 span {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 2;
  display: block;
  font-weight: 400;
  color: #303030;
  margin-top: 12px;
}

@media screen and (max-width: 768px) {
  .ttl-comp07 {
    font-size: 16px;
    font-size: 1.6rem;
    margin-bottom: 30px;
  }
  .ttl-comp07 span {
    font-size: 14px;
    font-size: 1.4rem;
    margin-top: 5px;
  }
}

.ttl-comp08 {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.56;
  color: #346EB1;
  font-weight: bold;
  margin-bottom: 14px;
}

@media screen and (max-width: 768px) {
  .ttl-comp08 {
    margin-bottom: 10px;
  }
}

.ttl-comp09 {
  font-size: 24px;
  font-size: 2.4rem;
  font-weight: bold;
  color: #4EB46E;
  background: #C8E5CB;
  border: 2px solid #4EB46E;
  text-align: center;
  padding-top: 3px;
  margin-bottom: 28px;
}

@media screen and (max-width: 990px) {
  .ttl-comp09 {
    font-size: 20px;
    font-size: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .ttl-comp09 {
    border-width: 1px;
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.ttl-comp09--blue {
  border-color: #346EB1;
  background: #E3FEFF;
  color: #346EB1;
}

.ttl-comp09--sm {
  font-size: 20px;
  font-size: 2rem;
  padding: 4px 0 2px;
}

@media screen and (max-width: 990px) {
  .ttl-comp09--sm {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 768px) {
  .ttl-comp09--sm {
    border-width: 1px;
    font-size: 15px;
    font-size: 1.5rem;
  }
}

.mac .ttl-comp01 > span {
  background-position: 0% .7em;
}

.txt-basic {
  line-height: 2;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 990px) {
  .txt-basic {
    font-size: 15px;
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .txt-basic {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.txt-basic--bold {
  font-weight: bold;
}

.txt-basic--bdbt {
  border-bottom: 1px solid #707070;
}

.txt-lg, .txt-businesspartner {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.78;
}

@media screen and (max-width: 1200px) {
  .txt-lg, .txt-businesspartner {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 768px) {
  .txt-lg, .txt-businesspartner {
    line-height: 1.5;
    font-size: 15px;
    font-size: 1.5rem;
  }
}

.txt-smaller {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.72;
  display: inline-block;
  width: 100%;
}

.txt-center, .txt-businesspartner {
  text-align: center;
}

.txt-right {
  text-align: right;
}

.txt-left {
  text-align: left;
}

.btn-comp01 {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 154px;
  background: #346EB1;
  height: 30px;
  margin: 0 auto;
  font-weight: 500;
  font-size: 14px;
  font-size: 1.4rem;
  color: #fff;
  padding: 3px 7px 1px;
  position: relative;
}

.btn-comp01:after {
  position: absolute;
  content: '';
  width: 32px;
  height: 6px;
  background: url("../img/index/arrow-right-white.png") no-repeat;
  background-size: 100% 100%;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.3s;
}

.btn-comp01:hover {
  opacity: 0.7;
}

.btn-comp01:hover:after {
  right: 5px;
  transition: 0.3s;
}

.btn-comp01--short {
  max-width: 106px;
}

.btn-comp01--left {
  text-align: right;
  padding-right: 15px;
  justify-content: flex-end;
  height: 40px;
  max-width: 175px;
}

.btn-comp01--left:after {
  right: auto;
  left: 10px;
  background: url("../img/recruit/message/arrow-left.png") no-repeat;
}

.btn-comp01--left:hover:after {
  right: auto;
  left: 16px;
}

.btn-comp02 {
  background: linear-gradient(to bottom, #61bef7 0%, #0f6296 100%);
  font-weight: bold;
  font-size: 24px;
  font-size: 2.4rem;
  line-height: 1.17;
  color: #ffffff;
  padding: 24px 50px 23px 21px;
  position: relative;
  width: 100%;
  max-width: 416px;
  display: inline-block;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .btn-comp02 {
    max-width: 300px;
    padding: 12px 50px 12px 15px;
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.5;
  }
}

.btn-comp02:after {
  content: '';
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 16px;
  width: 18px;
  height: 30px;
  background: url("../img/businesspartner/ico-next.png") no-repeat;
  transition: 0.3s;
}

@media screen and (max-width: 768px) {
  .btn-comp02:after {
    right: 10px;
    width: 10px;
    height: 16px;
    -webkit-background-size: contain;
    background-size: contain;
  }
}

.btn-comp02:hover:after {
  right: 12px;
}

.btn-com03 {
  font-size: 26px;
  font-size: 2.6rem;
  line-height: 1.17;
  color: #346EB1;
  padding: 18px 20px 13px;
  width: 100%;
  max-width: 416px;
  display: block;
  text-align: center;
  border: 1px solid #346EB1;
  border-radius: 17px;
  margin: 0 auto;
  transition: all 0.25s;
}

.btn-com03:hover {
  transition: all 0.25s;
  opacity: 0.7 !important;
}

@media screen and (max-width: 768px) {
  .btn-com03 {
    max-width: 300px;
    padding: 12px 15px;
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.5;
  }
}

.sec-mvpage {
  height: 43.34vw;
}

@media screen and (max-width: 990px) {
  .sec-mvpage {
    height: calc(100vh - 140px);
  }
}

@media screen and (max-width: 420px) {
  .sec-mvpage {
    height: 93.33vw;
  }
}

.sec-mvpage .block-mvpage {
  position: fixed;
  top: 70px;
  width: 100%;
  height: inherit;
}

@media screen and (max-width: 990px) {
  .sec-mvpage .block-mvpage {
    height: calc(100vh - 140px);
  }
}

@media screen and (max-width: 420px) {
  .sec-mvpage .block-mvpage {
    height: inherit;
  }
}

.sec-mvpage .img-mv {
  height: 100%;
}

.sec-mvpage .img-mv img {
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover';
  width: 100%;
}

.sec-mvpage .block-mv {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.sec-mvpage .block-mv .ttl-mv {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  font-family: "Meiryo", "メイリオ", "ヒラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", Osaka, "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
  font-size: 40px;
  font-size: 4rem;
  line-height: 2;
  color: #fff;
  font-weight: 400;
}

@media screen and (max-width: 990px) {
  .sec-mvpage .block-mv .ttl-mv {
    margin-top: 0;
  }
}

@media screen and (max-width: 768px) {
  .sec-mvpage .block-mv .ttl-mv {
    font-size: 32px;
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 420px) {
  .sec-mvpage .block-mv .ttl-mv {
    margin-top: -58px;
  }
}

.sec-mvpage .block-mv .ttl-mv--modify {
  font-size: 50px;
  font-size: 5rem;
  line-height: 1.6;
  text-shadow: 0 0 10px #000000;
}

@media screen and (max-width: 768px) {
  .sec-mvpage .block-mv .ttl-mv--modify {
    font-size: 40px;
    font-size: 4rem;
  }
}

@media screen and (max-width: 640px) {
  .sec-mvpage .block-mv .ttl-mv--modify {
    font-size: 35px;
    font-size: 3.5rem;
  }
}

.sec-mvpage .block-mv .ttl-mv__english {
  font-family: "Raleway", sans-serif;
  font-size: 32px;
  font-size: 3.2rem;
  line-height: 1.32;
  font-weight: 400;
  padding: 11px 0 10px 10px;
  text-transform: uppercase;
}

@media screen and (max-width: 768px) {
  .sec-mvpage .block-mv .ttl-mv__english {
    font-size: 20px;
    font-size: 2rem;
    display: inline-block;
    width: 100%;
    line-height: 1.2;
    padding: 0;
    margin-top: -8px;
  }
}

.sec-mvpage .block-mv .ttl-mv--black {
  color: #4B4B4B;
}

.sec-mvpage .block-mv .ttl-mv--black span {
  color: #4B4B4B;
}

.sec-mvpage .block-mv .ttl-mv--bold {
  color: #303030;
  font-weight: bold;
}

.sec-mvpage .block-mv .ttl-mv--downline {
  display: block;
  padding-top: 4px;
  line-height: 1.5;
}

.sec-mvpage .block-mv .ttl-mv--downline .ttl-mv__english {
  padding: 0;
  position: relative;
  top: -2px;
  display: block;
}

@media screen and (max-width: 768px) {
  .sec-mvpage .block-mv .ttl-mv--downline .ttl-mv__english {
    top: 0;
    margin-top: 0;
  }
}

.sec-mvpage.no-img {
  height: auto;
}

.sec-mvpage.no-img .block-mvpage {
  position: inherit;
  top: 0;
  display: inline-block;
  width: 100%;
  text-align: center;
  margin: 0 0 9px 0;
}

@media screen and (max-width: 768px) {
  .sec-mvpage.no-img .block-mvpage {
    margin: 15px 0;
  }
}

.sec-mvpage.no-img .block-mvpage .block-mv {
  position: relative;
  top: auto;
  transform: translateY(0);
}

.sec-mvpage.no-img .block-mvpage .ttl-mv {
  color: #0060CB;
  text-align: center;
  display: block;
}

@media screen and (max-width: 768px) {
  .sec-mvpage.no-img .block-mvpage .ttl-mv {
    display: inline-block;
    margin-top: 0;
    line-height: 1.2;
  }
}

.sec-mvpage.no-img .block-mvpage .ttl-mv__english {
  color: #0060CB;
  padding: 0 0 10px 0;
}

@media screen and (max-width: 768px) {
  .sec-mvpage.no-img .block-mvpage .ttl-mv__english {
    margin: 0;
    padding: 0;
  }
}

.breadcrumb {
  margin: 0 0 29px;
}

@media screen and (max-width: 768px) {
  .breadcrumb {
    margin: 0 0 19px;
  }
}

.breadcrumb a {
  font-family: "Meiryo", "メイリオ", "ヒラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", Osaka, "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
  color: #0060CB;
  font-weight: 400;
  line-height: 1.6;
  font-size: 14px;
  font-size: 1.4rem;
  text-transform: uppercase;
}

@media screen and (max-width: 768px) {
  .breadcrumb a {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.breadcrumb a span {
  color: #0060CB;
}

.breadcrumb i {
  font-size: 12px;
  font-size: 1.2rem;
  font-weight: 400;
  color: #303030;
}

@media screen and (max-width: 768px) {
  .breadcrumb i {
    font-size: 10px;
    font-size: 1rem;
  }
}

.breadcrumb span {
  font-family: "Meiryo", "メイリオ", "ヒラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", Osaka, "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
  font-size: 14px;
  font-size: 1.4rem;
  font-weight: 400;
  color: #303030;
}

@media screen and (max-width: 768px) {
  .breadcrumb span {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.color-white {
  color: white;
}

.bg-gray {
  background: #F2F0ED;
}

.fade-up {
  opacity: 0;
  -webkit-transform: translate(0px, 70px);
  -ms-transform: translate(0px, 70px);
  transform: translate(0px, 70px);
  -webkit-transition: opacity 1s, -webkit-transform 1s;
  transition: opacity 1s, -webkit-transform 1s;
  -o-transition: transform 1s, opacity 1s;
  transition: transform 1s, opacity 1s;
  transition: transform 1s, opacity 1s, -webkit-transform 1s;
}

.fade-up.active {
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
  opacity: 1;
}

.main-page {
  padding-top: 120px;
}

@media screen and (max-width: 768px) {
  .page-index .ttl-comp01 small {
    display: inline-block;
  }
}

.sec-mv {
  height: 46.26vw;
}

@media screen and (max-width: 768px) {
  .sec-mv {
    height: 111.6vw;
  }
}

.main-bg {
  position: relative;
  z-index: 1;
  background: #fff;
  padding-top: 50px;
}

@media screen and (max-width: 768px) {
  .main-bg {
    padding-top: 35px;
  }
}

.main-bg--content {
  padding-top: 15px;
}

@media screen and (max-width: 768px) {
  .main-bg--content {
    padding-top: 10px;
  }
}

.slider-home {
  position: fixed;
  top: 70px;
  left: 0;
  right: 0;
}

.slider-home .item {
  outline: none;
  position: relative;
}

.slider-home .item .img img {
  width: 100%;
}

.slider-home .item .color-white {
  text-shadow: 0px 0px 10px black !important;
}

.slider-home .item .mv-block {
  position: absolute;
  top: 47%;
  width: 100%;
  text-align: center;
  transform: translateY(-50%);
  padding: 0 10px;
  text-shadow: 0px 0px 10px white;
}

@media screen and (max-width: 640px) {
  .slider-home .item .mv-block {
    top: 31.2%;
  }
}

@media screen and (max-width: 374px) {
  .slider-home .item .mv-block {
    top: 50%;
  }
}

.slider-home .item .mv-ttl {
  font-family: YuMincho, "游明朝", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "Sawarabi Mincho", "Yu Mincho", "ＭＳ 明朝", "MS Mincho", "HiraMinProN-W3", "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
  font-size: 50px;
  font-size: 5rem;
  font-weight: 600;
  margin-bottom: 28px;
}

@media screen and (max-width: 1200px) {
  .slider-home .item .mv-ttl {
    font-size: 40px;
    font-size: 4rem;
  }
}

@media screen and (max-width: 990px) {
  .slider-home .item .mv-ttl {
    font-size: 32px;
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 640px) {
  .slider-home .item .mv-ttl {
    font-size: 24px;
    font-size: 2.4rem;
    margin-bottom: 13px;
  }
}

.slider-home .item .mv-ttl small {
  font-family: "Meiryo", "メイリオ", "ヒラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", Osaka, "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
  font-size: 24px;
  font-size: 2.4rem;
  display: block;
  font-weight: normal;
  margin-bottom: 7px;
}

@media screen and (max-width: 1200px) {
  .slider-home .item .mv-ttl small {
    font-size: 20px;
    font-size: 2rem;
  }
}

@media screen and (max-width: 990px) {
  .slider-home .item .mv-ttl small {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 640px) {
  .slider-home .item .mv-ttl small {
    font-size: 16px;
    font-size: 1.6rem;
    margin-bottom: 13px;
  }
}

.slider-home .item .mv-ttl small .en {
  font-family: "Raleway", sans-serif;
  font-size: 20px;
  font-size: 2rem;
  margin-left: 24px;
}

@media screen and (max-width: 1200px) {
  .slider-home .item .mv-ttl small .en {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 990px) {
  .slider-home .item .mv-ttl small .en {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 640px) {
  .slider-home .item .mv-ttl small .en {
    font-size: 15px;
    font-size: 1.5rem;
    margin-left: 0;
    display: block;
  }
}

.slider-home .item .mv-txt {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 2.5;
}

@media screen and (max-width: 640px) {
  .slider-home .item .mv-txt {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.714;
  }
}

@media screen and (max-width: 374px) {
  .slider-home .item .mv-txt {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.sec-service {
  margin-bottom: 80px;
}

@media screen and (max-width: 768px) {
  .sec-service {
    margin-bottom: 50px;
  }
}

.service-list {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}

.service-list__item {
  width: 20%;
}

@media screen and (max-width: 1200px) {
  .service-list__item {
    width: 33.33%;
  }
}

@media screen and (max-width: 768px) {
  .service-list__item {
    width: 100%;
    margin-bottom: 10px;
  }
  .service-list__item:last-child {
    margin-bottom: 0;
  }
}

.service-list__wrap {
  display: block;
  position: relative;
  z-index: 1;
}

.service-list__wrap:hover {
  opacity: 1;
}

.service-list__wrap:hover .service-list__content:before {
  transition: 0.3s;
  opacity: 0;
}

.service-list__img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.service-list__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.service-list__content {
  padding: 48px 10px 25px;
  position: relative;
}

@media screen and (max-width: 768px) {
  .service-list__content {
    padding: 10px 16px 10px 10px;
    display: flex;
    align-items: center;
    height: 145px;
  }
}

.service-list__content:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(48, 48, 48, 0.49);
  z-index: -1;
  transition: 0.3s;
}

.service-list__img02 {
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .service-list__img02 {
    margin-bottom: 0;
    width: 121px;
  }
}

.service-list__ttl {
  font-size: 28px;
  font-size: 2.8rem;
  line-height: 1.43;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 6px;
}

@media screen and (max-width: 1200px) {
  .service-list__ttl {
    font-size: 24px;
    font-size: 2.4rem;
    min-height: 68px;
  }
}

@media screen and (max-width: 768px) {
  .service-list__ttl {
    font-size: 20px;
    font-size: 2rem;
    line-height: 1.6;
    min-height: auto;
    width: calc(100% - 162px);
    text-align: left;
    display: block;
    margin: 0;
    padding: 0 7px;
  }
}

@media screen and (max-width: 768px) {
  .service-list__txt {
    width: 41px;
  }
}

.service-list__txt02 {
  display: flex;
  align-items: center;
  justify-items: center;
}

@media screen and (max-width: 768px) {
  .service-list__txt02 {
    width: 200px;
  }
}

.service-list__txt02 p {
  background: #fff;
  color: #303030;
  max-width: 175px;
  width: 100%;
  margin: 0 auto;
  padding: 3px 5px;
  font-size: 14px;
  font-size: 1.4rem;
}

.sec-block {
  margin-bottom: 111px;
}

@media screen and (max-width: 768px) {
  .sec-block {
    margin-bottom: 55px;
  }
}

@media screen and (max-width: 768px) {
  .sec-block .inner {
    padding: 0;
  }
}

.list-block {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
}

.list-block__item {
  width: 50%;
  background: #346EB1;
  color: #fff;
}

@media screen and (max-width: 990px) {
  .list-block__item {
    width: 100%;
    margin-bottom: 10px;
  }
  .list-block__item:last-child {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 768px) {
  .list-block__item {
    margin-bottom: 0;
  }
}

.list-block__wrap {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 990px) {
  .list-block__wrap {
    align-items: center;
  }
}

.list-block__wrap:hover .list-block__inner:after {
  left: 55%;
}

.list-block__img {
  width: 50.625%;
}

@media screen and (max-width: 1200px) {
  .list-block__img {
    width: 45%;
  }
}

@media screen and (max-width: 768px) {
  .list-block__img {
    width: 100%;
  }
}

.list-block__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.list-block__content {
  width: 49.375%;
  position: relative;
}

@media screen and (max-width: 1200px) {
  .list-block__content {
    width: 55%;
  }
}

@media screen and (max-width: 768px) {
  .list-block__content {
    width: 100%;
  }
}

.list-block__content:before {
  position: absolute;
  content: '';
  border-right: 30px solid #346EB1;
  border-top: 23px solid transparent;
  border-bottom: 23px solid transparent;
  left: 0;
  top: 47%;
  transform: translateX(-100%);
}

@media screen and (max-width: 768px) {
  .list-block__content:before {
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: 20px solid #346EB1;
    left: 49.5%;
    top: 0;
    transform: translate(-50%, -100%);
  }
}

.list-block__inner {
  padding: 47px 0 42px;
  position: relative;
}

@media screen and (max-width: 768px) {
  .list-block__inner {
    padding: 14px 0 39px;
  }
}

.list-block__inner:after {
  position: absolute;
  content: '';
  width: 32px;
  height: 6px;
  background: url("../img/index/arrow-right-white.png") no-repeat;
  background-size: 100% 100%;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  transition: 0.3s;
}

@media screen and (max-width: 768px) {
  .list-block__inner:after {
    bottom: 18px;
  }
}

@media screen and (max-width: 1200px) {
  .list-block__inner .txt-basic {
    font-size: 15px;
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .list-block__inner .txt-basic {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.list-block__ttl {
  margin-bottom: 27px;
}

@media screen and (max-width: 768px) {
  .list-block__ttl {
    margin-bottom: 14px;
  }
}

.list-block__ttl small {
  display: block;
  margin-top: 7px;
}

@media screen and (max-width: 768px) {
  .list-block__ttl small {
    display: inline-block;
    margin: 0 0 0 10px;
  }
}

.sec-topic {
  margin-bottom: 65px;
}

@media screen and (max-width: 768px) {
  .sec-topic {
    margin-bottom: 71px;
  }
}

.topic-list {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  margin: 0 -8px;
}

@media screen and (max-width: 768px) {
  .topic-list {
    margin: 0 0 29px;
  }
}

.topic-list__item {
  width: 33.33%;
  padding: 0 8px;
  margin-bottom: 30px;
}

@media screen and (max-width: 768px) {
  .topic-list__item {
    width: 100%;
    padding: 0;
    margin-bottom: 10px;
  }
  .topic-list__item:last-child {
    margin-bottom: 0;
  }
}

.topic-list__wrap {
  display: block;
  width: 100%;
  height: 100%;
  background: #D0D0D0;
  position: relative;
}

.topic-list__wrap:after {
  position: absolute;
  content: '';
  width: 32px;
  height: 6px;
  background: url("../img/index/arrow-right.png") no-repeat;
  background-size: 100% 100%;
  right: 19px;
  bottom: 19px;
  transition: 0.3s;
}

@media screen and (max-width: 768px) {
  .topic-list__wrap:after {
    right: 16px;
    bottom: 20px;
  }
}

.topic-list__wrap:hover:after {
  transition: 0.3s;
  right: 5px;
}

.topic-list__img {
  position: relative;
  overflow: hidden;
}

.topic-list__img:before {
  content: "";
  display: block;
  padding-bottom: calc(310/416*100%);
}

.topic-list__img .thumbnail {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.topic-list__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover";
}

.topic-list__img02:before {
  padding-bottom: calc(230/416*100%);
}

.topic-list__content {
  padding: 14px 18px 12px;
}

@media screen and (max-width: 768px) {
  .topic-list__content {
    padding: 11px 10px 13px;
    background: #D0D0D0;
    margin-top: -5px;
    position: relative;
  }
}

.topic-list__date {
  font-size: 14px;
  font-size: 1.4rem;
  margin-bottom: 8px;
}

.topic-list__ttl {
  margin-bottom: 5px;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 768px) {
  .topic-list__ttl {
    margin-bottom: 7px;
  }
}

.topic-list__cate {
  font-size: 12px;
  font-size: 1.2rem;
  color: #346EB1;
  background: #fff;
  border: 1px solid #346EB1;
  display: inline-block;
  text-align: center;
  min-width: 90px;
  padding: 1.5px 1px 0px;
}

.sec-other {
  background-color: #346EB1;
  position: relative;
  z-index: 1;
  padding: 64px 0 9px;
  background-image: url("../img/index/other-bg.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
}

@media only screen and (max-width: 1312px) {
  .sec-other {
    padding: 50px 0 30px;
  }
}

@media screen and (max-width: 768px) {
  .sec-other {
    padding: 49px 0 20px;
  }
}

.sec-other__ttl {
  text-align: right;
}

@media screen and (max-width: 768px) {
  .sec-other__ttl {
    text-align: center;
  }
}

.other-list {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  margin: 0 -9.4px;
}

@media only screen and (max-width: 1312px) {
  .other-list {
    margin: 0 -5px;
  }
}

@media screen and (max-width: 768px) {
  .other-list {
    margin: 0;
  }
}

.other-list__item {
  width: 20%;
  padding: 0 9.4px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1312px) {
  .other-list__item {
    padding: 0 5px;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 1200px) {
  .other-list__item {
    width: 33.33%;
  }
}

@media screen and (max-width: 768px) {
  .other-list__item {
    width: 100%;
    padding: 0;
    margin-bottom: 20px;
  }
  .other-list__item:last-child {
    margin-bottom: 0;
  }
}

.other-list__wrap {
  background: #fff;
  display: block;
  width: 100%;
  height: 100%;
  padding: 14px 5px 15px;
}

@media screen and (max-width: 768px) {
  .other-list__wrap:hover {
    opacity: 1;
  }
}

@media screen and (max-width: 768px) {
  .other-list__wrap {
    padding: 14px 5px 18px 15px;
    display: flex;
    align-items: center;
  }
}

.other-list__img {
  padding: 0 9.5px;
}

.other-list__img img {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .other-list__img {
    padding: 0;
    width: 49.55%;
  }
}

.other-list__ttl {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.55;
  color: #346EB1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  padding-bottom: 16px;
}

@media screen and (max-width: 768px) {
  .other-list__ttl {
    width: 50.45%;
    line-height: 1.35;
    padding: 12px 0 29px;
  }
}

@media screen and (max-width: 640px) {
  .other-list__ttl {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.other-list__ttl:after {
  position: absolute;
  content: '';
  width: 14px;
  height: 14px;
  border-right: 1px solid #346EB1;
  border-bottom: 1px solid #346EB1;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}

@media screen and (max-width: 768px) {
  .other-list__ttl:after {
    left: 52%;
  }
}

.other-list__ttl span span {
  display: block;
}

@media screen and (max-width: 768px) {
  .other-list__ttl span span {
    margin-bottom: 5px;
  }
}

.ct-single__news {
  display: block;
}

.ct-single__news .part-introduce__img {
  width: 100% !important;
  margin-bottom: 20px;
}

.ct-single__news .part-introduce__img img {
  width: 100% !important;
}

.ct-single__news .part-introduce__des {
  width: 100% !important;
  padding-left: 0 !important;
}

.ct-single__news .alignleft {
  float: left;
  margin: 0 20px 20px 0;
}

@media screen and (max-width: 768px) {
  .ct-single__news .alignleft {
    display: block;
    float: none;
    margin: 0 auto 20px;
  }
}

.ct-single__news .alignright {
  float: right;
  margin: 0 0px 20px 20px;
}

@media screen and (max-width: 768px) {
  .ct-single__news .alignright {
    display: block;
    float: none;
    margin: 20px auto 0;
  }
}

.txt-des {
  font-family: "Meiryo", "メイリオ", "ヒラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", Osaka, "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
  font-size: 20px;
  font-size: 2rem;
  line-height: 2;
  color: #303030;
  font-weight: 400;
  max-width: 1065px;
  margin: 0 auto 61px;
}

@media screen and (max-width: 768px) {
  .txt-des {
    margin: 0 auto 45px;
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.75;
  }
}

.sec-resolve {
  padding-bottom: 100px;
}

@media screen and (max-width: 768px) {
  .sec-resolve {
    padding-bottom: 60px;
  }
}

.block-resolve .ttl-list {
  font-family: "Meiryo", "メイリオ", "ヒラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", Osaka, "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
  font-size: 38px;
  font-size: 3.8rem;
  line-height: 1.2;
  color: #346EB1;
  position: relative;
  padding: 14px 0 0px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  min-height: 148px;
  margin-bottom: 9px;
}

@media screen and (max-width: 1350px) {
  .block-resolve .ttl-list {
    padding-top: 0;
    min-height: 135px;
  }
}

@media screen and (max-width: 1200px) {
  .block-resolve .ttl-list {
    min-height: auto;
    padding: 0;
    max-height: 65px;
    font-size: 30px;
    font-size: 3rem;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1024px) {
  .block-resolve .ttl-list {
    font-size: 28px;
    font-size: 2.8rem;
    max-height: 50px;
  }
}

@media screen and (max-width: 990px) {
  .block-resolve .ttl-list {
    max-height: 100%;
  }
}

@media screen and (max-width: 768px) {
  .block-resolve .ttl-list {
    font-size: 24px;
    font-size: 2.4rem;
    margin-bottom: 25px;
  }
}

.block-resolve .ttl-list__icon {
  padding-left: 0;
  width: 200px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

@media screen and (max-width: 1200px) {
  .block-resolve .ttl-list__icon {
    width: 44%;
    padding-left: 0px;
    height: 60px;
  }
}

@media screen and (max-width: 1024px) {
  .block-resolve .ttl-list__icon {
    height: 50px;
  }
}

@media screen and (max-width: 990px) {
  .block-resolve .ttl-list__icon {
    height: auto;
  }
}

@media screen and (max-width: 768px) {
  .block-resolve .ttl-list__icon {
    height: 70px;
    width: 40%;
  }
}

@media screen and (max-width: 1200px) {
  .block-resolve .ttl-list__icon img {
    height: 100%;
    width: auto;
  }
}

.block-resolve .ttl-list__txt {
  width: calc( 100% - 200px);
  padding-left: 56px;
}

@media screen and (max-width: 1200px) {
  .block-resolve .ttl-list__txt {
    width: 56%;
    padding-left: 30px;
  }
}

@media screen and (max-width: 768px) {
  .block-resolve .ttl-list__txt {
    width: 60%;
  }
}

.block-resolve .item {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  background: #F2F0ED;
}

.block-resolve .item .img-resolve {
  width: 50%;
}

@media screen and (max-width: 990px) {
  .block-resolve .item .img-resolve {
    width: 100%;
  }
}

@media screen and (max-width: 990px) {
  .block-resolve .item .img-resolve img {
    width: 100%;
  }
}

.block-resolve .item .txt-resolve {
  width: 50%;
  padding: 35px 35px 29px;
}

@media screen and (max-width: 1350px) {
  .block-resolve .item .txt-resolve {
    padding: 2.5vw 2.5vw 2.5vw;
  }
}

@media screen and (max-width: 1200px) {
  .block-resolve .item .txt-resolve {
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 990px) {
  .block-resolve .item .txt-resolve {
    width: 100%;
    padding: 35px;
  }
}

@media screen and (max-width: 768px) {
  .block-resolve .item .txt-resolve {
    padding: 24px 19px 33px;
    overflow: hidden;
  }
}

.block-resolve .item .txt-resolve .txt-basic {
  margin-bottom: 20px;
  line-height: 1.75;
}

@media screen and (max-width: 768px) {
  .block-resolve .item .txt-resolve .txt-basic {
    margin-bottom: 22px;
    min-height: 117px;
  }
}

.block-resolve .item:nth-child(2n) {
  flex-direction: row-reverse;
}

.block-resolve .item.fade-reverse .txt-resolve__block {
  opacity: 0;
  -webkit-transform: translate(-70px, 0px);
  -ms-transform: translate(-70px, 0px);
  transform: translate(-70px, 0px);
  -webkit-transition: opacity 1s, -webkit-transform 1s;
  transition: opacity 1s, -webkit-transform 1s;
  -o-transition: transform 1s, opacity 1s;
  transition: transform 1s, opacity 1s;
  transition: transform 1s, opacity 1s, -webkit-transform 1s;
}

.block-resolve .item.fade-reverse:nth-child(even) .txt-resolve__block {
  -webkit-transform: translate(70px, 0px);
  -ms-transform: translate(70px, 0px);
  transform: translate(70px, 0px);
}

.block-resolve .item.fade-reverse.active .txt-resolve__block {
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
  opacity: 1;
}

.block-resolve .btn-comp01 {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 500;
}

.block-resolve .btn-comp01--modify {
  font-size: 14px;
  font-size: 1.4rem;
  font-weight: 400;
}

@media screen and (max-width: 768px) {
  .block-resolve .btn-comp01--modify {
    margin-bottom: 12px;
  }
}

.block-resolve .btn-comp01--modify.sp {
  margin-bottom: 0;
}

.btn-comp01--modify {
  justify-content: center;
}

.btn-comp01--modify:after {
  content: none;
}

.sec-works {
  padding-bottom: 100px;
}

@media screen and (max-width: 768px) {
  .sec-works {
    padding-bottom: 50px;
  }
}

.block-task .txt {
  font-size: 20px;
  font-size: 2rem;
  line-height: 2;
  margin-bottom: 18px;
}

@media screen and (max-width: 768px) {
  .block-task .txt {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.75;
  }
}

.block-task .list-task {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 14px;
}

@media screen and (max-width: 768px) {
  .block-task .list-task {
    margin-bottom: 0;
  }
}

.block-task .item-task {
  width: calc(20% - 17.6px);
  margin-right: 22px;
  margin-bottom: 20px;
}

.block-task .item-task:last-child {
  margin-right: 0;
}

@media screen and (max-width: 990px) {
  .block-task .item-task {
    width: calc(33.33% - 13.33px);
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .block-task .item-task:last-child, .block-task .item-task:nth-child(3n) {
    margin-right: 0;
  }
}

@media screen and (max-width: 640px) {
  .block-task .item-task {
    width: calc(50% - 20px);
    margin-right: 20px;
  }
  .block-task .item-task:last-child, .block-task .item-task:nth-child(2n) {
    margin-right: 0;
  }
  .block-task .item-task:nth-child(3n) {
    margin-right: 20px;
  }
}

@media screen and (max-width: 374px) {
  .block-task .item-task {
    width: 100%;
    margin-right: 0;
  }
  .block-task .item-task:last-child, .block-task .item-task:nth-child(2n) {
    margin-right: 0;
  }
  .block-task .item-task:nth-child(3n) {
    margin-right: 0;
  }
}

.block-task .item-task span {
  line-height: 1.3;
  color: #fff;
  font-weight: 400;
  padding: 8px 10px 4px;
  background: #0060CB;
  width: 100%;
  display: inline-block;
  text-align: center;
  font-size: 20px;
  font-size: 2rem;
}

@media screen and (max-width: 768px) {
  .block-task .item-task span {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.block-task .item-task span:hover {
  cursor: pointer;
}

.block-task .item-task:nth-child(5) {
  order: 4;
  margin-right: 22px;
}

@media screen and (max-width: 768px) {
  .block-task .item-task:nth-child(5) {
    margin-right: 0;
  }
}

.block-task .item-task:nth-child(4) {
  order: 5;
  margin-right: 0;
}

.block-task .list-task__faq .item-task:nth-child(5) {
  order: 5;
  margin-right: 0px;
}

.block-task .list-task__faq .item-task:nth-child(4) {
  order: 4;
  margin-right: 22px;
}

@media screen and (max-width: 768px) {
  .block-task .list-task__faq .item-task:nth-child(4) {
    margin-right: 0;
  }
}

.block-introtask .topic-list {
  display: none;
}

.block-introtask .topic-list.current {
  display: flex;
}

.block-introtask .topic-list__content {
  background: #96B7DD;
}

.link-turn {
  padding-top: 7px;
  border-top: 1px solid #707070;
  font-size: 16px;
  line-height: 2;
  overflow: hidden;
}

.link-turn a {
  cursor: pointer;
}

.link-turn .link-prev {
  float: left;
}

.link-turn .link-prev i {
  padding-right: 3px;
}

.link-turn .link-next {
  float: right;
}

.link-turn .link-next i {
  padding-left: 3px;
}

.block-worksdetail .part-time {
  background: #96B7DD;
  padding: 6px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.block-worksdetail .part-time__name {
  font-size: 12px;
  font-size: 1.2rem;
  color: #346EB1;
  background: #fff;
  border: 1px solid #346EB1;
  padding: 2px 14px;
  display: inline-block;
}

.block-worksdetail .part-time .topic-list__date {
  margin-bottom: 0;
}

.block-worksdetail .part-introduce {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 31px;
}

.block-worksdetail .part-introduce__img {
  width: 41%;
}

.block-worksdetail .part-introduce__img img {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .block-worksdetail .part-introduce__img {
    width: 100%;
    margin-bottom: 20px;
  }
}

.block-worksdetail .part-introduce__des {
  width: 59%;
  padding-left: 20px;
  margin-top: -3px;
}

@media screen and (max-width: 768px) {
  .block-worksdetail .part-introduce__des {
    width: 100%;
    padding-left: 0;
    margin-top: 0;
  }
}

.box-img {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 80px;
}

@media screen and (max-width: 768px) {
  .box-img {
    margin-bottom: 50px;
  }
}

.box-img .txt-basic {
  width: 71%;
  margin-top: -8px;
  padding-right: 20px;
  line-height: 1.75;
}

@media screen and (max-width: 768px) {
  .box-img .txt-basic {
    margin-top: 0;
    line-height: 1.5;
    width: 100%;
    margin-bottom: 30px;
    padding-right: 0;
  }
}

.box-img__img {
  width: 29%;
}

.box-img__img img {
  display: block;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .box-img__img {
    width: 100%;
    text-align: center;
  }
  .box-img__img img {
    width: auto;
    display: inline-block;
  }
}

.box-img02 {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 113px;
}

@media screen and (max-width: 768px) {
  .box-img02 {
    margin-bottom: 50px;
  }
}

.box-img02__txt {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  font-size: 4rem;
  line-height: 1.5;
  color: #fff;
  text-align: center;
  background: #346eb1;
  width: 37%;
}

@media screen and (max-width: 768px) {
  .box-img02__txt {
    width: 100%;
    padding: 20px 20px;
    font-size: 25px;
    font-size: 2.5rem;
  }
}

.box-img02__img {
  width: 63%;
}

.box-img02__img img {
  display: block;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .box-img02__img {
    width: 100%;
  }
}

@media screen and (min-width: 769px) {
  .box-img02--style02 .box-img02__txt {
    width: 36%;
  }
  .box-img02--style02 .box-img02__img {
    width: 64%;
  }
}

.list-img {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
}

@media screen and (max-width: 768px) {
  .list-img {
    padding: 0 -4px;
  }
}

.list-img li {
  width: 50%;
  padding: 0 8px;
}

@media screen and (max-width: 768px) {
  .list-img li {
    padding: 0 4px;
  }
}

.list-img li a {
  display: block;
  position: relative;
}

.list-img li a:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  pointer-events: none;
  transition: all 0.3s;
  background: rgba(97, 190, 247, 0.5);
}

.list-img li a:hover {
  opacity: 1;
}

.list-img li a:hover:after {
  height: 0;
  transition: all 0.3s;
}

.list-img li a img {
  display: block;
  width: 100%;
}

.list-img__ttl {
  position: absolute;
  top: 50%;
  width: 100%;
  left: 0;
  transform: translateY(-50%);
  color: #fff;
  z-index: 3;
  font-size: 36px;
  font-size: 3.6rem;
  text-align: center;
  font-weight: bold;
  line-height: 2.9;
}

@media screen and (max-width: 768px) {
  .list-img__ttl {
    font-size: 20px;
    font-size: 2rem;
  }
}

@media screen and (max-width: 330px) {
  .list-img__ttl {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.sec-recruit {
  padding-bottom: 108px;
}

@media screen and (max-width: 768px) {
  .sec-recruit {
    padding-bottom: 50px;
  }
}

.recruit-01 {
  max-width: 1068px;
  margin: 0 auto;
}

.recruit-01 .ttl-comp01 {
  margin-bottom: 34px;
}

@media screen and (max-width: 768px) {
  .recruit-01 .ttl-comp01 {
    margin-bottom: 32px;
  }
}

.recruit-02 .ttl-comp01 {
  margin-bottom: 78px;
}

@media screen and (max-width: 768px) {
  .recruit-02 .ttl-comp01 {
    margin-bottom: 32px;
  }
}

.lg {
  display: none;
}

@media screen and (max-width: 990px) {
  .lg {
    display: initial;
  }
}

@media screen and (max-width: 768px) {
  .lg {
    display: none;
  }
}

.page-recruit_message_index .sec-mvpage {
  height: auto;
}

.page-recruit_message_index .sec-mvpage .box-img02 {
  padding-top: 50px;
  margin-bottom: 0px;
}

@media screen and (max-width: 768px) {
  .page-recruit_message_index .sec-mvpage .box-img02 {
    padding-top: 0;
  }
}

.sec-recruit-message {
  padding-bottom: 110px;
}

@media screen and (max-width: 768px) {
  .sec-recruit-message {
    padding-bottom: 50px;
  }
}

.sec-recruit-message .ttl-comp02 {
  margin-bottom: 78px;
}

@media screen and (max-width: 768px) {
  .sec-recruit-message .ttl-comp02 {
    margin-bottom: 50px;
  }
}

.sec-recruit-message .list-img {
  margin-bottom: 56px;
}

@media screen and (max-width: 768px) {
  .sec-recruit-message .list-img {
    margin-bottom: 30px;
  }
}

.list-message {
  margin-bottom: 90px;
}

@media screen and (max-width: 768px) {
  .list-message {
    margin-bottom: 50px;
  }
}

.list-message__item {
  padding: 42px 0 35px;
}

@media screen and (max-width: 768px) {
  .list-message__item {
    padding: 30px 0;
  }
}

.list-message__item .inner {
  max-width: 1095px;
}

.list-message__item:nth-child(2n+2) {
  background: #fdfaf2;
  padding: 53px 0 50px 0;
}

@media screen and (max-width: 768px) {
  .list-message__item:nth-child(2n+2) {
    padding: 20px 0;
  }
}

.list-message .txt-basic {
  margin-bottom: 31px;
  line-height: 1.75;
}

@media screen and (max-width: 768px) {
  .list-message .txt-basic {
    line-height: 1.5;
  }
}

.list-message .txt-basic:last-child {
  margin-bottom: 0;
}

.list-message__case {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 19px;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .list-message__case {
    justify-content: center;
  }
}

.list-message__case .box-case {
  width: 34%;
  margin-bottom: -30px;
}

@media screen and (max-width: 768px) {
  .list-message__case .box-case {
    width: 260px;
    margin-bottom: 10px;
  }
}

.list-message__case__img {
  width: 66%;
  padding-left: 50px;
}

@media screen and (max-width: 990px) {
  .list-message__case__img {
    padding-left: 30px;
  }
}

@media screen and (max-width: 768px) {
  .list-message__case__img {
    width: 100%;
    padding-left: 0;
  }
}

.list-message__case__img img {
  display: block;
  width: 100%;
}

.box-case {
  background: rgba(52, 110, 177, 0.68);
  padding: 8px 32px 12px;
  color: #fff;
  font-size: 20px;
  font-size: 2rem;
}

@media screen and (max-width: 990px) {
  .box-case {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (max-width: 768px) {
  .box-case {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.box-case__number {
  top: -30px;
  position: relative;
  color: #61BEF7;
  font-weight: 500;
  font-size: 56px;
  font-size: 5.6rem;
  line-height: .5;
  font-family: "Roboto";
  display: block;
}

@media screen and (max-width: 768px) {
  .box-case__number {
    font-size: 30px;
    font-size: 3rem;
    top: -17px;
  }
}

.box-case__time {
  display: block;
  margin-bottom: 13px;
}

@media screen and (max-width: 768px) {
  .box-case__time {
    margin-bottom: 5px;
  }
}

.box-case__ttl {
  font-size: 38px;
  font-size: 3.8rem;
  padding-bottom: 5px;
  border-bottom: solid 1px #fff;
  margin-bottom: 10px;
}

@media screen and (max-width: 990px) {
  .box-case__ttl {
    font-size: 25px;
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 768px) {
  .box-case__ttl {
    font-size: 20px;
    font-size: 2rem;
  }
}

.box-case__ttl small {
  font-size: 20px;
  font-size: 2rem;
}

@media screen and (max-width: 768px) {
  .box-case__ttl small {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.box-case__education {
  text-align: right;
}

.box-up {
  margin-top: -20px !important;
  padding-top: 20px !important;
}

.sec-link {
  margin-bottom: 43px;
}

.list-link {
  display: flex;
  margin: 0 -7px;
  font-size: 20px;
  font-size: 2rem;
  text-align: center;
  color: white;
}

@media screen and (max-width: 1200px) {
  .list-link {
    margin: 0 -5px;
    font-size: 18px;
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 768px) {
  .list-link {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.list-link__item {
  width: 50%;
  padding: 0 7px;
  margin-bottom: 14px;
}

@media screen and (max-width: 1200px) {
  .list-link__item {
    padding: 0 5px;
  }
}

.list-link__item a {
  display: block;
  background: #D0D0D0;
  padding: 11px 10px 10px;
}

.list-link__item.is-active a {
  background: #346EB1;
}

.sec-greeting {
  margin-bottom: 72px;
}

@media screen and (max-width: 768px) {
  .sec-greeting {
    margin-bottom: 50px;
  }
}

.greeting-intro {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 33px;
}

@media screen and (max-width: 768px) {
  .greeting-intro {
    margin-bottom: 30px;
  }
}

.greeting-intro__img {
  width: 32.5%;
  padding-top: 4px;
}

@media screen and (max-width: 768px) {
  .greeting-intro__img {
    width: 100%;
    max-width: 300px;
    margin: 0px auto 15px;
  }
}

.greeting-intro__content {
  width: 67.5%;
  padding-left: 17px;
}

@media screen and (max-width: 768px) {
  .greeting-intro__content {
    width: 100%;
    padding-left: 0;
  }
}

.greeting-intro__content p {
  margin-bottom: 22px;
}

.greeting-intro__content p:last-child {
  margin-bottom: 0;
}

.greeting-philosophy {
  padding: 29px 0 27px;
}

@media screen and (max-width: 768px) {
  .greeting-philosophy {
    padding: 30px 0;
  }
}

.greeting-philosophy__des {
  font-size: 24px;
  font-size: 2.4rem;
  font-family: YuMincho, "游明朝", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "Sawarabi Mincho", "Yu Mincho", "ＭＳ 明朝", "MS Mincho", "HiraMinProN-W3", "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
  font-weight: 500;
  line-height: 1.92;
}

@media screen and (max-width: 1200px) {
  .greeting-philosophy__des {
    font-size: 20px;
    font-size: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .greeting-philosophy__des {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.greeting-guidance {
  padding: 34px 0 43px;
}

.greeting-des {
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.6;
  margin-bottom: 23px;
}

@media screen and (max-width: 1200px) {
  .greeting-des {
    font-size: 17px;
    font-size: 1.7rem;
  }
}

@media screen and (max-width: 768px) {
  .greeting-des {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.greeting-quality {
  padding: 33px 0 42px;
}

@media screen and (max-width: 768px) {
  .greeting-quality {
    padding: 30px 0;
  }
}

.greeting-quality__inner {
  max-width: 484px;
}

.greeting-quality__txt {
  margin-bottom: 26px;
}

.greeting-quality__date {
  padding-right: 3px;
}

.greeting-declaration {
  padding: 26px 0 0;
}

@media screen and (max-width: 768px) {
  .greeting-declaration {
    padding: 30px 0 0;
  }
}

.greeting-declaration__inner {
  max-width: 774px;
}

.greeting-declaration__txt {
  width: 100%;
  max-width: 634px;
  margin: 0 auto 25px;
}

.greeting-declaration__box {
  display: flex;
  justify-content: space-between;
  padding-right: 2px;
}

@media screen and (max-width: 768px) {
  .greeting-declaration__box {
    display: block;
  }
}

.greeting-declaration__box .logo {
  margin-bottom: 15px;
  text-align: center;
}

.sec-profile {
  margin-bottom: 107px;
}

@media screen and (max-width: 768px) {
  .sec-profile {
    margin-bottom: 50px;
  }
}

.sec-client {
  margin-bottom: 86px;
}

@media screen and (max-width: 768px) {
  .sec-client {
    margin-bottom: 50px;
  }
}

.sec-chart {
  margin-bottom: 100px;
}

@media screen and (max-width: 768px) {
  .sec-chart {
    margin-bottom: 50px;
  }
}

.chart-img {
  overflow: auto;
}

.chart-img__main {
  min-width: 700px;
}

.sec-history {
  margin-bottom: 97px;
}

@media screen and (max-width: 768px) {
  .sec-history {
    margin-bottom: 50px;
  }
}

.box-table__row {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #707070;
  padding-bottom: 7px;
  margin-bottom: 14px;
}

@media screen and (max-width: 768px) {
  .box-table__row {
    padding-bottom: 15px;
    margin-bottom: 30px;
  }
}

.box-table__row:last-child {
  margin-bottom: 0;
}

.box-table__row dt {
  width: 200px;
}

@media screen and (max-width: 1200px) {
  .box-table__row dt {
    width: 150px;
  }
}

@media screen and (max-width: 768px) {
  .box-table__row dt {
    width: 100%;
    margin-bottom: 15px;
  }
}

.box-table__row dt small {
  display: block;
  font-size: 14px;
  font-size: 1.4rem;
  margin-top: 10px;
}

@media screen and (max-width: 768px) {
  .box-table__row dt small {
    font-size: 12px;
    font-size: 1.2rem;
    margin-top: 0;
  }
}

.box-table__row dd {
  width: calc(100% - 200px);
  padding-left: 16px;
}

@media screen and (max-width: 1200px) {
  .box-table__row dd {
    width: calc(100% - 150px);
  }
}

@media screen and (max-width: 768px) {
  .box-table__row dd {
    width: 100%;
    padding-left: 0;
  }
}

.box-table__row dd p {
  line-height: 1.56;
}

.box-table__row dd .txt-basic {
  line-height: 1.75;
}

.box-table__flex {
  width: 100%;
  max-width: 821px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 5px;
}

@media screen and (max-width: 768px) {
  .box-table__flex {
    margin-bottom: 0;
  }
}

.box-table__list li {
  margin-bottom: 10px;
}

.box-table__list li:last-child {
  margin-bottom: 0;
}

.sec-origin {
  padding-bottom: 96px;
}

@media screen and (max-width: 768px) {
  .sec-origin {
    padding-bottom: 50px;
  }
}

.sec-items-search {
  padding-bottom: 100px;
}

@media screen and (max-width: 768px) {
  .sec-items-search {
    padding-bottom: 60px;
  }
}

.subcategory {
  font-size: 18px;
  font-size: 1.8rem;
  color: #346EB1;
  font-weight: bold;
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .subcategory {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.item-search-block {
  border: 1px solid #707070;
  background: #F2F0ED;
  padding: 20px 20px 0;
  margin-bottom: 55px;
}

@media screen and (max-width: 768px) {
  .item-search-block {
    margin-bottom: 20px;
  }
}

.item-search-block .search-ttl {
  font-size: 28px;
  font-size: 2.8rem;
  font-weight: bold;
  color: #0060CB;
  border-bottom: 10px solid #96B7DD;
  padding-bottom: 3px;
  margin-bottom: 30px;
}

@media screen and (max-width: 768px) {
  .item-search-block .search-ttl {
    font-size: 18px;
    font-size: 1.8rem;
    margin-bottom: 15px;
    padding-bottom: 7px;
  }
}

.item-search-block .search-ttl--02 {
  font-size: 24px;
  font-size: 2.4rem;
  font-weight: bold;
  margin-bottom: 25px;
}

@media screen and (max-width: 768px) {
  .item-search-block .search-ttl--02 {
    font-size: 16px;
    font-size: 1.6rem;
    margin-bottom: 15px;
  }
}

.item-search-block .search-area {
  margin-bottom: 50px;
}

@media screen and (max-width: 768px) {
  .item-search-block .search-area {
    margin-bottom: 20px;
  }
}

.item-search-block .search-area .item {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.item-search-block .search-area .item__ttl {
  width: 280px;
  height: 40px;
  line-height: 40px;
  background: #D0D0D0;
  font-size: 20px;
  font-size: 2rem;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .item-search-block .search-area .item__ttl {
    width: 100%;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    font-size: 1.4rem;
    margin-bottom: 15px;
  }
}

.item-search-block .search-area .item__field {
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  padding-left: 15px;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (min-width: 769px) {
  .item-search-block .search-area .item__field {
    flex: 1;
  }
}

@media screen and (max-width: 768px) {
  .item-search-block .search-area .item__field {
    width: 100%;
    padding: 0;
    margin-bottom: 15px;
  }
}

.item-search-block .search-area .item input[type="text"],
.item-search-block .search-area .item select {
  height: 40px;
  border: 1px solid #707070;
  border-radius: 0;
  padding: 0 15px;
}

.item-search-block .search-area .item input[type="text"] {
  width: calc(100% - 190px);
}

@media screen and (max-width: 768px) {
  .item-search-block .search-area .item input[type="text"] {
    width: 100%;
    appearance: none;
  }
}

.item-search-block .search-area .item select {
  max-width: 250px;
  width: 100%;
  border-radius: 10px;
  margin-right: 15px;
  background: #FFF url("../img/items/select-arrow.png") no-repeat right 20px center;
  background-size: 13px auto;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.item-search-block .search-area .item select::-ms-expand {
  display: none;
}

@media screen and (max-width: 990px) {
  .item-search-block .search-area .item select {
    max-width: 200px;
    margin-right: 10px;
  }
}

@media screen and (max-width: 768px) {
  .item-search-block .search-area .item select {
    max-width: 100%;
    margin: 0 0 10px;
  }
  .item-search-block .search-area .item select:last-of-type {
    margin-bottom: 0;
  }
}

.item-search-block .search-area .item .s-btn {
  cursor: pointer;
  width: 172px;
  background: #0060CB url("../img/items/search-ico.png") no-repeat right 15px center;
  background-size: 22px auto;
  color: #FFF;
  letter-spacing: 10px;
  border: none;
  border-radius: 10px;
  font-size: 21px;
  font-size: 2.1rem;
  text-align: left;
  padding: 0 0 0 20px;
  margin-left: 18px;
  height: 40px;
}

@media screen and (max-width: 768px) {
  .item-search-block .search-area .item .s-btn {
    margin: 25px auto 0;
    appearance: none;
  }
}

.item-search-block .search-category {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

@media screen and (max-width: 768px) {
  .item-search-block .search-category {
    margin-top: 20px;
  }
}

.item-search-block .search-category li {
  width: 24.47%;
  margin: 0 0.7% 20px 0;
  position: relative;
}

.item-search-block .search-category li:nth-child(4n) {
  margin-right: 0;
}

.item-search-block .search-category li:hover .list-search {
  display: block;
}

@media screen and (max-width: 990px) {
  .item-search-block .search-category li {
    width: 32.5%;
    margin-right: 1.25%;
  }
  .item-search-block .search-category li:nth-child(4n) {
    margin-right: 1.25%;
  }
  .item-search-block .search-category li:nth-child(3n) {
    margin-right: 0;
  }
}

@media screen and (max-width: 768px) {
  .item-search-block .search-category li {
    width: 100%;
    margin: 0 0 20px;
  }
}

.item-search-block .search-category li a {
  position: relative;
  display: block;
  border: 1px solid #346EB1;
  height: 100%;
  color: #346EB1;
  background: #FFF;
  text-align: center;
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: bold;
  padding: 19px 30px 19px 30px;
}

@media screen and (max-width: 768px) {
  .item-search-block .search-category li a {
    padding: 19px 20px;
  }
}

.item-search-block .search-category li a::after {
  content: "";
  display: block;
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -9px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 9px 0 9px 12px;
  border-color: transparent transparent transparent #346EB1;
}

.item-search-block .search-category li .list-search {
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  z-index: 23;
  padding: 15px;
  display: none;
  background: #346EB1 !important;
}

.item-search-block .search-category li .list-search li {
  width: 100%;
  margin-bottom: 5px;
  border: 0px;
}

.item-search-block .search-category li .list-search li a {
  padding: 4px 30px !important;
  border: 0px !important;
  color: #fff !important;
  background: none !important;
  text-align: left;
  font-size: 16px;
  font-size: 1.6rem;
}

.item-search-block .search-category li .list-search li a:after {
  display: none;
}

.catalog-dl-btn {
  max-width: 630px;
  margin: 0 auto;
  padding: 45px 0 0;
}

@media screen and (max-width: 768px) {
  .catalog-dl-btn {
    padding: 30px 0 0;
  }
}

.catalog-dl-btn a {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  padding: 0 0 0 165px;
  height: 160px;
  line-height: 1.8;
  border-radius: 20px;
  color: #FFF;
  font-size: 36px;
  font-size: 3.6rem;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#61bef7+0,0f6296+100 */
  background: #61bef7;
  /* Old browsers */
  background: -moz-linear-gradient(top, #61bef7 0%, #0f6296 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #61bef7 0%, #0f6296 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #61bef7 0%, #0f6296 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#61bef7', endColorstr='#0f6296',GradientType=0 );
  /* IE6-9 */
}

@media screen and (max-width: 768px) {
  .catalog-dl-btn a {
    font-size: 20px;
    font-size: 2rem;
    height: 173px;
    padding-left: 175px;
  }
}

@media screen and (max-width: 320px) {
  .catalog-dl-btn a {
    padding-left: 160px;
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.catalog-dl-btn a::before {
  position: absolute;
  left: 20px;
  top: 50%;
  margin-top: -60px;
  content: "";
  display: block;
  width: 120px;
  height: 120px;
  background: url("../img/items/catalog.png") no-repeat 0 0;
  background-size: 100% auto;
}

.empty-txt {
  font-size: 18px;
  font-size: 1.8rem;
  margin-bottom: 80px;
}

.sec-ofice {
  padding-bottom: 69px;
}

@media screen and (max-width: 768px) {
  .sec-ofice {
    padding-bottom: 50px;
  }
}

.ofice-link {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px 53px;
  text-align: center;
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: bold;
  color: #fff;
}

@media screen and (max-width: 1200px) {
  .ofice-link {
    font-size: 16px;
    font-size: 1.6rem;
    margin: 0 -5px 50px;
  }
}

@media screen and (max-width: 768px) {
  .ofice-link {
    font-size: 15px;
    font-size: 1.5rem;
  }
}

.ofice-link__item {
  width: 25%;
  padding: 0 8px;
  margin-bottom: 10px;
}

@media screen and (max-width: 1200px) {
  .ofice-link__item {
    padding: 0 5px;
  }
}

@media screen and (max-width: 990px) {
  .ofice-link__item {
    width: 33.33%;
  }
}

@media screen and (max-width: 768px) {
  .ofice-link__item {
    width: 50%;
  }
}

.ofice-link__item a {
  display: block;
  width: 100%;
  background: #61BEF7;
  padding: 8px 10px 7px;
}

.ofice-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
}

@media screen and (max-width: 990px) {
  .ofice-list {
    margin: 0;
  }
}

.ofice-list__item {
  width: 50%;
  padding: 0 8px;
  margin-bottom: 49px;
}

@media screen and (max-width: 990px) {
  .ofice-list__item {
    padding: 0;
    width: 100%;
    margin-bottom: 30px;
  }
  .ofice-list__item:last-child {
    margin-bottom: 0;
  }
}

.ofice-list__ttl {
  font-size: 20px;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 11px;
  color: #0060CB;
}

@media screen and (max-width: 1200px) {
  .ofice-list__ttl {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 768px) {
  .ofice-list__ttl {
    font-size: 15px;
    font-size: 1.5rem;
  }
}

.ofice-list__map {
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .ofice-list__map {
    margin-bottom: 10px;
  }
}

.ofice-list__map iframe {
  display: block;
  width: 100%;
  max-height: 328px;
}

.ofice-list__info {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 2;
}

@media screen and (max-width: 1200px) {
  .ofice-list__info {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.5;
  }
}

.ofice-list__info dl {
  display: flex;
  align-items: stretch;
  margin-bottom: 2px;
}

.ofice-list__info dl dt {
  width: 31.8%;
  background: #346EB1;
  color: #fff;
  font-size: 20px;
  font-size: 2rem;
  text-align: center;
}

@media screen and (max-width: 1200px) {
  .ofice-list__info dl dt {
    font-size: 17px;
    font-size: 1.7rem;
    padding: 5px 5px 4px;
    width: 110px;
  }
}

@media screen and (max-width: 768px) {
  .ofice-list__info dl dt {
    font-size: 15px;
    font-size: 1.5rem;
    width: 85px;
  }
}

.ofice-list__info dl dd {
  width: calc(68.2% - 2px);
  margin-left: auto;
  background: #EEEEEE;
  padding: 7px 5px 0px 13px;
}

@media screen and (max-width: 1200px) {
  .ofice-list__info dl dd {
    width: calc(100% - 110px);
  }
}

@media screen and (max-width: 768px) {
  .ofice-list__info dl dd {
    padding: 6px 5px 0px 10px;
    width: calc(100% - 85px);
  }
}

@media screen and (max-width: 768px) {
  .sec-items-search.result {
    padding-bottom: 50px;
  }
}

.item-back-btn {
  max-width: 416px;
  margin: 0 auto;
}

.item-back-btn a {
  display: block;
  height: 64px;
  line-height: 60px;
  border: 2px solid #346EB1;
  font-size: 26px;
  font-size: 2.6rem;
  text-align: center;
  color: #346EB1;
  border-radius: 15px;
}

@media screen and (max-width: 768px) {
  .item-back-btn a {
    font-size: 20px;
    font-size: 2rem;
    height: 54px;
    line-height: 50px;
  }
}

.item-back-btn a:hover {
  background: #346EB1;
  opacity: 1;
  color: #FFF;
}

.item-search-result {
  margin: 60px 0;
  padding-bottom: 20px;
  border-bottom: 1px solid #707070;
}

@media screen and (max-width: 768px) {
  .item-search-result {
    margin-top: 20px;
    margin-bottom: 50px;
    border: none;
    padding-bottom: 0;
  }
}

.item-search-result .result-item {
  font-size: 18px;
  font-size: 1.8rem;
  margin-bottom: 25px;
}

@media screen and (max-width: 768px) {
  .item-search-result .result-item {
    align-items: flex-start;
    font-size: 14px;
    font-size: 1.4rem;
    margin-bottom: 20px;
  }
}

.item-search-result .result-item__wrap {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.item-search-result .result-item__wrap:hover {
  opacity: 1;
}

.item-search-result .result-item__wrap:hover .result-item__ttl {
  text-decoration: underline;
}

.item-search-result .result-item__img {
  width: 80px;
  min-height: 80px;
}

.item-search-result .result-item__img__inner {
  overflow: hidden;
  position: relative;
  border: 1px solid #707070;
}

.item-search-result .result-item__img__inner::after {
  content: "";
  display: block;
  padding-top: 100%;
}

.item-search-result .result-item__img__inner img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: auto;
  width: auto;
  max-width: 97%;
  max-height: 97%;
  margin: auto;
}

.item-search-result .result-item__inner {
  padding-left: 15px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

@media screen and (min-width: 769px) {
  .item-search-result .result-item__inner {
    flex: 1;
  }
}

@media screen and (max-width: 768px) {
  .item-search-result .result-item__inner {
    display: block;
    width: calc(100% - 80px);
  }
}

.item-search-result .result-item__code {
  width: 15%;
  padding-right: 20px;
  color: #0060CB;
  word-break: break-all;
}

@media screen and (max-width: 768px) {
  .item-search-result .result-item__code {
    width: 100%;
    padding: 0;
    margin-bottom: 5px;
  }
}

.item-search-result .result-item__ttl {
  width: 41%;
  font-weight: bold;
  padding-right: 20px;
}

@media screen and (max-width: 768px) {
  .item-search-result .result-item__ttl {
    width: 100%;
    padding: 0;
  }
}

.item-search-result .result-item__category {
  width: 22%;
  padding-right: 20px;
}

@media screen and (max-width: 768px) {
  .item-search-result .result-item__category {
    display: none;
  }
}

.item-search-result .result-item__type {
  width: 22%;
}

@media screen and (max-width: 768px) {
  .item-search-result .result-item__type {
    display: none;
  }
}

.list-qa {
  margin-bottom: 50px;
}

.list-qa__ttl {
  font-size: 24px;
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 1.667;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .list-qa__ttl {
    font-size: 18px;
    font-size: 1.8rem;
    margin-bottom: 10px;
  }
}

.list-qa__item {
  margin-bottom: 53px;
}

@media screen and (max-width: 768px) {
  .list-qa__item {
    margin-bottom: 30px;
  }
}

.list-qa__item:last-child {
  margin-bottom: 0;
}

.list-qa__question {
  padding: 17px 15px;
  border-radius: 7px;
  background: #346eb1;
  color: #fff;
  font-size: 24px;
  font-size: 2.4rem;
  line-height: 1.2;
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-bottom: 10px;
  position: relative;
  padding-left: 75px;
}

@media screen and (max-width: 768px) {
  .list-qa__question {
    font-size: 18px;
    font-size: 1.8rem;
    padding: 11px 10px 11px 60px;
  }
}

@media screen and (max-width: 330px) {
  .list-qa__question {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.list-qa__question span {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: #0060CB;
  background: #ffffff;
  font-size: 36px;
  font-size: 3.6rem;
  line-height: .6;
  margin-right: 10px;
  flex-shrink: 0;
  font-weight: bold;
  position: absolute;
  left: 15px;
  top: 6px;
}

@media screen and (max-width: 768px) {
  .list-qa__question span {
    width: 35px;
    height: 35px;
    flex-basis: 35px;
    font-size: 20px;
    font-size: 2rem;
    margin-right: 5px;
  }
}

.list-qa__answer {
  padding: 30px 15px 33px;
  border-radius: 3px;
  background: #e3feff;
  border: solid 3px #61BEF7;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 30px;
}

.list-qa__answer:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 768px) {
  .list-qa__answer {
    padding: 10px 10px;
  }
}

.list-qa__answer span {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #61BEF7;
  font-weight: bold;
  color: #fff;
  font-size: 36px;
  font-size: 3.6rem;
  line-height: .6;
  margin-right: 27px;
}

@media screen and (max-width: 768px) {
  .list-qa__answer span {
    font-size: 20px;
    font-size: 2rem;
    margin-right: 10px;
    width: 35px;
    height: 35px;
  }
}

.list-qa__answer .txt-basic {
  width: calc(100% - 77px);
  line-height: 1.75;
}

@media screen and (max-width: 768px) {
  .list-qa__answer .txt-basic {
    line-height: 1.5;
    width: calc(100% - 45px);
  }
}

@media screen and (max-width: 330px) {
  .list-qa__answer .txt-basic {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.box-apply {
  margin-bottom: 58px;
}

@media screen and (max-width: 768px) {
  .box-apply {
    margin-bottom: 30px;
  }
}

.box-apply__ttl {
  color: #0060cb;
  font-weight: bold;
  font-size: 24px;
  font-size: 2.4rem;
  text-align: center;
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .box-apply__ttl {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.box-apply img {
  display: block;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .box-apply img {
    max-width: 70%;
  }
}

.sec-recruit-fresh {
  padding-bottom: 100px;
}

@media screen and (max-width: 768px) {
  .sec-recruit-fresh {
    padding-bottom: 50px;
  }
}

.sec-recruit-fresh .inner {
  max-width: 1096px;
}

.sec-entry {
  margin-bottom: 150px;
}

@media screen and (max-width: 768px) {
  .sec-entry {
    margin-bottom: 50px;
  }
}

#form-resume {
  /* Hide the file input using
opacity */
}

@media screen and (max-width: 768px) {
  #form-resume {
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 640px) {
  #form-resume {
    overflow: hidden;
  }
}

#form-resume .form-right {
  width: calc(100% - 216px);
}

@media screen and (max-width: 640px) {
  #form-resume .form-right {
    width: 100%;
  }
}

#form-resume .th-label {
  font-size: 20px;
  font-size: 2rem;
  line-height: 2;
  color: #656565;
  width: 216px;
}

@media screen and (max-width: 768px) {
  #form-resume .th-label {
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 1.6;
  }
}

@media screen and (max-width: 640px) {
  #form-resume .th-label {
    width: 100%;
    font-weight: 500;
  }
}

#form-resume .full-width {
  width: calc(100% - 216px);
}

@media screen and (max-width: 640px) {
  #form-resume .full-width {
    width: 100%;
  }
}

#form-resume input {
  padding: 4.5px 5px;
  width: 100%;
  font-size: 16px;
  font-size: 1.6rem;
  color: #656565;
}

@media screen and (max-width: 768px) {
  #form-resume input {
    font-size: 14px;
    font-size: 1.4rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

#form-resume .txt-label {
  font-size: 20px;
  font-size: 2rem;
  line-height: 2;
  color: #656565;
}

@media screen and (max-width: 768px) {
  #form-resume .txt-label {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.6;
  }
}

#form-resume .form-group {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 16px;
  flex-wrap: wrap;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 768px) {
  #form-resume .form-group {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

#form-resume .th-label--pdr {
  padding-right: 11px;
  padding-left: 5px;
}

@media screen and (max-width: 768px) {
  #form-resume .th-label--pdr {
    padding: 0;
  }
}

@media screen and (max-width: 640px) {
  #form-resume .th-label--pdr {
    margin-bottom: 10px;
  }
}

#form-resume .show-inline {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

#form-resume .list-birth .item-birth {
  margin-right: 27px;
}

@media screen and (max-width: 1200px) {
  #form-resume .list-birth .item-birth {
    margin-right: 15px;
  }
}

@media screen and (max-width: 768px) {
  #form-resume .list-birth .item-birth {
    width: 100%;
    margin-right: 0;
    margin-bottom: 15px;
  }
}

#form-resume .list-birth .item-birth input {
  width: 200px;
}

@media screen and (max-width: 1200px) {
  #form-resume .list-birth .item-birth input {
    width: 15vw;
  }
}

@media screen and (max-width: 768px) {
  #form-resume .list-birth .item-birth input {
    width: 65%;
  }
}

#form-resume .list-birth .item-birth:last-child {
  margin-right: 0;
}

#form-resume .item-university {
  margin-right: 55px;
}

@media screen and (max-width: 1200px) {
  #form-resume .item-university {
    margin-right: 25px;
  }
}

@media screen and (max-width: 768px) {
  #form-resume .item-university {
    width: 100%;
    margin-right: 0;
    margin-bottom: 15px;
  }
}

#form-resume .item-university .txt-label {
  padding-left: 5px;
}

#form-resume .item-university input {
  width: 308px;
}

@media screen and (max-width: 1200px) {
  #form-resume .item-university input {
    width: 22vw;
  }
}

@media screen and (max-width: 768px) {
  #form-resume .item-university input {
    width: 70%;
  }
}

#form-resume textarea {
  width: 100%;
  height: 220px;
  overflow-y: scroll;
  font-size: 16px;
  font-size: 1.6rem;
  padding: 4.5px 5px;
}

@media screen and (max-width: 768px) {
  #form-resume textarea {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

#form-resume [type=file] {
  position: absolute;
  filter: alpha(opacity=0);
  opacity: 0;
}

#form-resume [type=file] + label,
#form-resume .file-label {
  border: 1px solid #346EB1;
  border-radius: 3px;
  text-align: left;
  padding: 7px 10px;
  min-width: 200px;
  margin: 0;
  left: 0;
  position: relative;
}

@media screen and (max-width: 1200px) {
  #form-resume [type=file] + label,
  #form-resume .file-label {
    width: 16vw;
  }
}

#form-resume [type=file] + label,
#form-resume .file-label {
  text-align: center;
  left: 0em;
  top: 0em;
  /* Decorative */
  background: #346EB1;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-size: 1.6rem;
  display: inline-block;
}

#form-resume [type=file] + label:hover,
#form-resume .file-label:hover {
  opacity: 0.6;
}

#form-resume .mwform-file-delete {
  font-size: 16px;
  font-size: 1.6rem;
  padding-left: 20px;
}

#form-resume .error {
  padding-top: 10px;
}

.note-form {
  font-size: 20px;
  font-size: 2rem;
  line-height: 2;
  color: #656565;
}

.link-submit {
  text-align: right;
}

@media screen and (max-width: 768px) {
  .link-submit {
    margin-bottom: 50px;
  }
}

.link-submit .btn-submit {
  background: #346EB1;
  border: 1px solid #346EB1;
  color: #fff;
  font-size: 20px;
  font-size: 2rem;
  font-family: "Meiryo", "メイリオ", "ヒラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", Osaka, "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
  padding: 12px 10px;
  min-width: 256px;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 20px;
}

@media screen and (max-width: 768px) {
  .link-submit .btn-submit {
    margin-left: 0;
  }
}

.link-submit .btn-submit:hover {
  opacity: 0.6;
}

.link-submit .btn-submit--back {
  background: #EEEEEE;
  color: #303030;
  border-color: #EEEEEE;
}

@media screen and (max-width: 768px) {
  .link-submit .btn-submit--back {
    margin-bottom: 15px;
  }
}

.mw_wp_form_confirm #form-resume .file-label {
  display: none;
}

.sec-catalog {
  margin-bottom: 66px;
}

@media screen and (max-width: 768px) {
  .sec-catalog {
    margin-bottom: 25px;
  }
}

.sec-catalog .inner > .ttl-comp02 {
  margin-bottom: 116px;
}

@media screen and (max-width: 768px) {
  .sec-catalog .inner > .ttl-comp02 {
    margin-bottom: 50px;
  }
}

.sec-catalog .block-catalog {
  margin-bottom: 50px;
}

@media screen and (max-width: 768px) {
  .sec-catalog .block-catalog {
    margin-bottom: 25px;
  }
}

.sec-catalog .mb-common .ttl-comp02 {
  margin-bottom: 13px;
}

@media screen and (max-width: 768px) {
  .sec-catalog .mb-common .ttl-comp02 {
    margin-bottom: 13px;
  }
}

.list-file {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.list-file .item-file {
  width: calc(25% - 15px);
  margin-right: 20px;
  margin-bottom: 35px;
}

.list-file .item-file:nth-child(4n) {
  margin-right: 0;
}

@media screen and (max-width: 1200px) {
  .list-file .item-file {
    width: calc(33.33% - 13.33px);
    margin-right: 20px;
  }
  .list-file .item-file:nth-child(3n) {
    margin-right: 0;
  }
  .list-file .item-file:nth-child(2n), .list-file .item-file:nth-child(4n) {
    margin-right: 20px;
  }
}

@media screen and (max-width: 768px) {
  .list-file .item-file {
    width: calc(50% - 10px);
    margin-right: 20px;
  }
  .list-file .item-file:nth-child(2n) {
    margin-right: 0;
  }
  .list-file .item-file:nth-child(3n) {
    margin-right: 20px;
  }
}

@media screen and (max-width: 640px) {
  .list-file .item-file {
    width: 100%;
    margin-right: 0;
    margin-bottom: 25px;
  }
}

.list-file .item-file .img {
  text-align: center;
  padding: 26px 5px;
  border: 1px solid #707070;
  margin-bottom: 15px;
  height: 285px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.list-file .item-file .img img {
  height: auto;
  width: auto;
  max-height: 100%;
}

.list-file .function-download {
  position: relative;
}

.list-file .function-download a {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.78;
  color: #0060CB;
  border: 1px solid #0060CB;
  background: #F2F0ED;
  padding: 21px 45px 21px 14px;
  display: inline-block;
  width: 100%;
  position: relative;
  z-index: 2;
}

@media screen and (max-width: 1200px) {
  .list-file .function-download a {
    line-height: 1.6;
    font-size: 16px;
    font-size: 1.6rem;
    padding: 21px 45px 21px 8px;
  }
}

.list-file .function-download a:after {
  content: '';
  width: 36px;
  height: 36px;
  background: url("../img/catalog/icon-donwload.png") no-repeat center center;
  -webkit-background-size: cover;
  background-size: cover;
  display: inline-block;
  right: 9px;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
}

.list-file .function-download .txt-nodownload {
  visibility: visible;
  width: 100%;
  background-color: #346EB1;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: calc( 100% + 5px);
  left: 0;
  margin-left: 0;
  opacity: 1;
  transition: opacity 0.3s;
  font-size: 14px;
  font-size: 1.4rem;
}

.list-file .function-download .txt-nodownload::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #346EB1 transparent transparent transparent;
}

.sec-businesspartner {
  padding-bottom: 99px;
}

@media screen and (max-width: 768px) {
  .sec-businesspartner {
    padding-bottom: 50px;
  }
}

.list-businesspartner {
  padding: 0 333px;
  margin-bottom: 82px;
}

@media screen and (max-width: 1500px) {
  .list-businesspartner {
    padding: 0 22.2vw;
  }
}

@media screen and (max-width: 768px) {
  .list-businesspartner {
    padding: 0;
    margin-bottom: 30px;
  }
}

.list-businesspartner .item {
  display: flex;
  margin-bottom: 40px;
}

@media screen and (max-width: 768px) {
  .list-businesspartner .item {
    margin-bottom: 20px;
  }
}

.list-businesspartner .item__img {
  flex-shrink: 0;
  margin-right: 13px;
}

@media screen and (max-width: 768px) {
  .list-businesspartner .item__img {
    max-width: 20px;
    margin-right: 10px;
  }
}

.list-businesspartner .item__ttl {
  font-size: 20px;
  font-size: 2rem;
}

@media screen and (max-width: 768px) {
  .list-businesspartner .item__ttl {
    font-size: 15px;
    font-size: 1.5rem;
  }
}

.txt-businesspartner {
  padding: 0 243px;
  margin-bottom: 91px;
}

@media screen and (max-width: 1500px) {
  .txt-businesspartner {
    padding: 0 16.2vw;
  }
}

@media screen and (max-width: 768px) {
  .txt-businesspartner {
    padding: 0;
    margin-bottom: 30px;
  }
}

.txt-businesspartner a {
  color: #0060CB;
}

.content-table {
  margin-bottom: 108px;
}

@media screen and (max-width: 768px) {
  .content-table {
    margin-bottom: 50px;
  }
}

.table-task {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.43;
  width: 100%;
  margin-bottom: 20px;
  table-layout: fixed;
  position: relative;
}

@media screen and (max-width: 768px) {
  .table-task {
    font-size: 11px;
    font-size: 1.1rem;
  }
}

.table-task:after {
  content: "";
  position: absolute;
  height: 2px;
  width: 100%;
  bottom: -2px;
  left: 0;
  background: #f2f0ed;
}

.table-task tr {
  text-align: center;
  background: #f2f0ed;
  border-bottom: 4px solid #fff;
}

.table-task tr td {
  padding: 6px 5px;
  border-right: 2px solid #fff;
  word-break: break-all;
}

.table-task tr td:nth-child(1) {
  width: calc(415/1065*100%);
}

.table-task tr td:nth-child(2) {
  width: calc(217/1065*100%);
}

.table-task tr td:nth-child(3) {
  width: calc(218/1065*100%);
}

.table-task tr td:nth-child(4) {
  width: calc(218/1065*100%);
}

.table-task thead tr {
  border-bottom: 2px solid #fff;
}

.table-task thead td {
  background: #96B7DD;
  font-weight: bold;
}

.table-task tbody tr td {
  padding: 5px 5px;
  color: #020202;
}

.table-task tbody tr td:nth-child(1) {
  color: #0060CB;
  text-align: left;
  padding-left: 13px;
}

@media screen and (max-width: 768px) {
  .table-task tbody tr td:nth-child(1) {
    padding-left: 5px;
  }
}

.table-task tbody tr td:nth-child(4) {
  text-align: left;
}

.sec-recruit-middle {
  padding-bottom: 100px;
}

@media screen and (max-width: 768px) {
  .sec-recruit-middle {
    padding-bottom: 50px;
  }
}

.sec-recruit-middle .inner {
  max-width: 1096px;
}

.sec-recruit-middle .btn-comp02 {
  margin-bottom: 100px;
}

@media screen and (max-width: 768px) {
  .sec-recruit-middle .btn-comp02 {
    margin-bottom: 30px;
  }
}

.ip5 {
  display: none;
}

@media screen and (max-width: 330px) {
  .ip5 {
    display: initial;
  }
}

.list-01 {
  margin-bottom: 80px;
}

@media screen and (max-width: 768px) {
  .list-01 {
    margin-bottom: 30px;
  }
}

.list-01 li {
  display: flex;
  flex-wrap: wrap;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.75;
  background: #eeeeee;
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .list-01 li {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.5;
  }
}

.list-01 li:last-child {
  margin-bottom: 0;
}

.list-01 li > * {
  padding: 7px 10px 3px;
}

.list-01 li span {
  font-weight: bold;
  background: #96b7dd;
  width: 19%;
  display: inline-flex;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .list-01 li span {
    width: 120px;
  }
}

.list-01 li p {
  width: 81%;
  border-left: solid 1px #fff;
}

@media screen and (max-width: 768px) {
  .list-01 li p {
    width: calc(100% - 120px);
  }
}

.sec-recruit-middle-outline {
  padding-bottom: 100px;
}

.sec-recruit-middle-outline .inner {
  max-width: 1096px;
}

.sec-recruit-middle-outline .btn-comp02 {
  margin-bottom: 50px;
}

@media screen and (max-width: 768px) {
  .sec-recruit-middle-outline .btn-comp02 {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 768px) {
  .sec-recruit-middle-outline {
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 768px) {
  .sec-regulations .ttl-comp02 {
    margin-bottom: 22px;
  }
}

.box-equipment-inspection {
  display: flex;
  background: #F2F0ED;
  margin-bottom: 18px;
}

@media screen and (max-width: 768px) {
  .box-equipment-inspection {
    flex-wrap: wrap;
  }
}

.box-equipment-inspection .box-left {
  width: calc(100% - 49.47%);
  padding-left: 110px;
  padding-top: 45px;
  padding-right: 90px;
  padding-bottom: 30px;
}

@media screen and (max-width: 1200px) {
  .box-equipment-inspection .box-left {
    padding: 30px 50px 30px;
  }
}

@media screen and (max-width: 768px) {
  .box-equipment-inspection .box-left {
    padding: 27px 16px 33px;
    width: 100%;
    order: 2;
  }
}

.box-equipment-inspection .box-left .txt-basic {
  line-height: 1.75;
  margin-bottom: 17px;
}

@media screen and (max-width: 768px) {
  .box-equipment-inspection .box-left .txt-basic {
    margin-bottom: 13px;
  }
}

.box-equipment-inspection .box-left .txt-note {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.75;
}

@media screen and (max-width: 768px) {
  .box-equipment-inspection .box-left .txt-note {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.box-equipment-inspection .box-left .txt-note__style02 {
  font-size: 18px;
  font-size: 1.8rem;
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .box-equipment-inspection .box-left .txt-note__style02 {
    font-size: 16px;
    font-size: 1.6rem;
    margin-bottom: 5px;
  }
}

.box-equipment-inspection .box-left .list-standard {
  display: flex;
  margin: 0 -28px;
  padding-right: 12px;
}

@media screen and (max-width: 990px) {
  .box-equipment-inspection .box-left .list-standard {
    padding-right: 0;
    margin: 0 -10px;
  }
}

@media screen and (max-width: 768px) {
  .box-equipment-inspection .box-left .list-standard {
    padding-right: 0;
    margin: 0 -5.5px;
  }
}

.box-equipment-inspection .box-left .list-standard .item {
  width: 50%;
  padding: 0 28px;
  position: relative;
}

@media screen and (max-width: 990px) {
  .box-equipment-inspection .box-left .list-standard .item {
    padding: 0 10px;
  }
}

@media screen and (max-width: 768px) {
  .box-equipment-inspection .box-left .list-standard .item {
    padding: 0 5.5px;
  }
}

.box-equipment-inspection .box-left .list-standard .item:after {
  content: "";
  display: block;
  width: 20px;
  height: 10px;
  background: url("../img/service/lightning/design/ico-down.png") no-repeat center;
  background-size: cover;
  position: absolute;
  bottom: 21px;
  left: 48%;
}

@media screen and (max-width: 768px) {
  .box-equipment-inspection .box-left .list-standard .item:after {
    bottom: 13px;
  }
}

.box-equipment-inspection .box-left .list-standard .item__wp {
  background: #61bef7;
  background: linear-gradient(180deg, #61bef7 0%, #0f6296 100%);
  text-align: center;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.5;
  color: #fff;
  min-height: 135px;
  padding-top: 12px;
}

@media screen and (max-width: 990px) {
  .box-equipment-inspection .box-left .list-standard .item__wp {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 768px) {
  .box-equipment-inspection .box-left .list-standard .item__wp {
    min-height: 110px;
  }
}

@media screen and (max-width: 330px) {
  .box-equipment-inspection .box-left .list-standard .item__wp {
    font-size: 11px;
    font-size: 1.1rem;
  }
}

.box-equipment-inspection .box-right {
  width: 49.47%;
}

@media screen and (max-width: 768px) {
  .box-equipment-inspection .box-right {
    width: 100%;
    order: 1;
  }
}

.box-equipment-inspection .box-right img {
  width: 100%;
}

.box-equipment-inspection .ttl-en-jp {
  font-size: 32px;
  font-size: 3.2rem;
  font-family: "Meiryo", "メイリオ", "ヒラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", Osaka, "MS Pゴシック", Arial, Helvetica, Verdana, sans-serif;
  color: #346EB1;
  border-bottom: 4px solid #96B7DD;
  margin-bottom: 15px;
}

@media screen and (max-width: 1200px) {
  .box-equipment-inspection .ttl-en-jp {
    font-size: 28px;
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 768px) {
  .box-equipment-inspection .ttl-en-jp {
    font-size: 24px;
    font-size: 2.4rem;
    margin-bottom: 10px;
    padding-bottom: 5px;
    line-height: 1.2;
  }
}

@media screen and (max-width: 374px) {
  .box-equipment-inspection .ttl-en-jp {
    font-size: 22px;
    font-size: 2.2rem;
  }
}

.box-equipment-inspection .ttl-en-jp small {
  font-family: "Raleway", sans-serif;
  font-size: 20px;
  font-size: 2rem;
  color: #346EB1;
  text-transform: uppercase;
}

@media screen and (max-width: 1024px) {
  .box-equipment-inspection .ttl-en-jp small {
    display: block;
    width: 100%;
    text-align: right;
  }
}

@media screen and (max-width: 768px) {
  .box-equipment-inspection .ttl-en-jp small {
    font-size: 16px;
    font-size: 1.6rem;
    display: block;
    width: 100%;
    text-align: right;
  }
}

.box-equipment-inspection .ttl-en-jp--style02 small {
  display: inline-block;
  width: auto;
}

.box-equipment-inspection.design .box-left {
  padding-left: 80px;
  padding-top: 66px;
  padding-right: 50px;
}

@media screen and (max-width: 1200px) {
  .box-equipment-inspection.design .box-left {
    padding: 30px 50px 30px;
  }
}

@media screen and (max-width: 990px) {
  .box-equipment-inspection.design .box-left {
    padding: 30px 20px;
  }
}

@media screen and (max-width: 768px) {
  .box-equipment-inspection.design .box-left {
    padding: 24px 16px 15px;
    width: 100%;
    order: 2;
  }
}

.box-txt-intro {
  max-width: 1100px;
  margin: 0 auto 48px;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.8;
  color: #303030;
  padding: 0 18px;
}

@media screen and (max-width: 768px) {
  .box-txt-intro {
    font-size: 14px;
    font-size: 1.4rem;
    margin-bottom: 50px;
    line-height: 1.75;
  }
}

.sec-protection-equipment {
  padding-bottom: 28px;
}

.sec-protection-equipment .ttl-comp01 {
  margin-bottom: 26px;
}

@media screen and (max-width: 768px) {
  .sec-protection-equipment .ttl-comp01 {
    margin-bottom: 15px;
  }
}

.sec-protection-equipment .list-item {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-bottom: 36px;
  margin-left: -8px;
  margin-right: -8px;
}

@media screen and (max-width: 768px) {
  .sec-protection-equipment .list-item {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 10px;
  }
}

.sec-protection-equipment .list-item .item {
  width: 25.025%;
  margin-bottom: 20px;
  padding: 0 8px;
}

@media screen and (max-width: 768px) {
  .sec-protection-equipment .list-item .item {
    width: 100%;
    margin-bottom: 13px;
    padding: 0;
  }
}

.sec-protection-equipment .list-item .item-wp {
  border: 3px solid #346EB1;
  background: #F2F0ED;
  padding: 16px;
}

@media screen and (max-width: 768px) {
  .sec-protection-equipment .list-item .item-wp {
    padding: 16px 30px 45px;
  }
}

.sec-protection-equipment .list-item .item-wp__img {
  margin-bottom: 19px;
}

@media screen and (max-width: 768px) {
  .sec-protection-equipment .list-item .item-wp__img {
    margin-bottom: 18px;
    text-align: center;
  }
}

.sec-protection-equipment .list-item .item-wp__img--multiple {
  display: flex;
  margin: 0 -15px 19px;
}

@media screen and (max-width: 768px) {
  .sec-protection-equipment .list-item .item-wp__img--multiple {
    margin: 0 -10px 14px;
    flex-wrap: wrap;
  }
}

.sec-protection-equipment .list-item .item-wp__img--multiple .img {
  width: 50%;
  padding: 0 15px;
}

@media screen and (max-width: 768px) {
  .sec-protection-equipment .list-item .item-wp__img--multiple .img {
    padding: 0 10px;
    text-align: center;
  }
  .sec-protection-equipment .list-item .item-wp__img--multiple .img:first-child {
    margin-bottom: 0;
  }
}

.sec-protection-equipment .list-item .item-wp__img--multiple .img img {
  width: 100%;
}

.sec-protection-equipment .list-item .item-wp__des {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.75;
  color: #303030;
}

.sec-protection-equipment .list-item .item:nth-of-type(3n + 1) {
  width: 49.95%;
}

@media screen and (max-width: 768px) {
  .sec-protection-equipment .list-item .item:nth-of-type(3n + 1) {
    width: 100%;
  }
}

.sec-protection-equipment .list-item .item:nth-of-type(3n + 1) .item-wp {
  padding: 16px 30px 16px;
}

@media screen and (max-width: 768px) {
  .sec-protection-equipment .list-item .item:nth-of-type(3n + 1) .item-wp {
    padding: 16px 30px 25px;
  }
}

.sec-protection-equipment .list-item .item:nth-of-type(3n) {
  margin-right: 0;
}

.box-border-blue {
  border: 3px solid #346EB1;
  padding: 10px 22px;
  max-width: 848px;
  margin: 0 auto 90px;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.8;
}

@media screen and (max-width: 768px) {
  .box-border-blue {
    font-size: 16px;
    font-size: 1.6rem;
    padding: 16px 16px 18px;
    margin-bottom: 53px;
  }
}

@media screen and (max-width: 330px) {
  .box-border-blue {
    padding-bottom: 16px;
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.box-border-blue__style02 {
  border-width: 1px;
  max-width: 100%;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.7;
  padding: 6px 13px;
  margin-bottom: 34px;
}

.box-example .ttl-comp01 {
  margin-bottom: 43px;
}

@media screen and (max-width: 768px) {
  .box-example .ttl-comp01 {
    margin-bottom: 20px;
  }
}

.box-example .list-blue {
  display: flex;
  margin-bottom: 40px;
}

@media screen and (max-width: 768px) {
  .box-example .list-blue {
    flex-wrap: wrap;
    margin: 0 0 20px;
  }
}

.box-example .list-blue .item {
  width: calc((100% - 16px*3)/4);
  margin-right: 16px;
  background: #346EB1;
  padding: 17px 14px 22px;
}

@media screen and (max-width: 990px) {
  .box-example .list-blue .item {
    padding: 15px 10px 15px;
  }
}

@media screen and (max-width: 768px) {
  .box-example .list-blue .item {
    width: calc(100%/2 - 5px);
    margin-right: 10px;
    margin-bottom: 15px;
  }
  .box-example .list-blue .item:nth-child(2n) {
    margin-right: 0;
  }
}

@media screen and (max-width: 640px) {
  .box-example .list-blue .item {
    width: 100%;
    margin-right: 0;
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 330px) {
  .box-example .list-blue .item {
    padding: 15px;
  }
}

.box-example .list-blue .item:last-of-type {
  margin-right: 0;
}

.box-example .list-blue .item__img {
  margin-bottom: 4px;
}

@media screen and (max-width: 768px) {
  .box-example .list-blue .item__img {
    margin-bottom: 30px;
  }
}

.box-example .list-blue .item__img img {
  width: 100%;
}

.box-example .list-blue .item__ttl {
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1.8;
  color: #fff;
  border-bottom: 1px solid #fff;
  padding-bottom: 8px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 990px) {
  .box-example .list-blue .item__ttl {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.6;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 768px) {
  .box-example .list-blue .item__ttl {
    font-size: 18px;
    font-size: 1.8rem;
    padding-bottom: 17px;
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 330px) {
  .box-example .list-blue .item__ttl {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.box-example .list-blue .item__des {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.9;
  color: #fff;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 990px) {
  .box-example .list-blue .item__des {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.6;
  }
}

@media screen and (max-width: 768px) {
  .box-example .list-blue .item__des {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.9;
  }
}

@media screen and (max-width: 330px) {
  .box-example .list-blue .item__des {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.6;
  }
}

.sec-protection-system {
  background: #FCF3E0;
  padding-bottom: 70px;
}

@media screen and (max-width: 768px) {
  .sec-protection-system {
    padding-bottom: 46px;
  }
}

@media screen and (max-width: 768px) {
  .sec-protection-system .ttl-comp01 {
    line-height: 1.2;
    margin-bottom: 27px;
  }
}

.sec-protection-system .list-protection-system {
  max-width: 1064px;
  margin: 0 auto;
}

.sec-protection-system .list-protection-system .item {
  margin-bottom: 50px;
}

@media screen and (max-width: 768px) {
  .sec-protection-system .list-protection-system .item {
    margin-bottom: 43px;
  }
}

.sec-protection-system .list-protection-system .item:last-of-type {
  margin-bottom: 0;
}

.sec-protection-system .list-protection-system .item__des {
  font-size: 14px;
  font-size: 1.4rem;
  color: #303030;
  line-height: 1.75;
}

@media screen and (max-width: 768px) {
  .sec-protection-system .list-protection-system .item__des {
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 1.7;
  }
}

.ttl-orange {
  text-align: center;
  font-size: 24px;
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 1.7;
  background: #FDC17A;
  padding: 8px 0 5px;
  margin-bottom: 65px;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .ttl-orange {
    padding: 11px 0 2px;
  }
}

@media screen and (max-width: 768px) {
  .ttl-orange {
    font-size: 20px;
    font-size: 2rem;
    line-height: 1.6;
    padding: 10px 0;
    margin-bottom: 45px;
  }
}

.ttl-border-blue {
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.5;
  color: #fff;
  background: #346EB1;
  border-left: 10px solid #14137E;
  margin-bottom: 17px;
  padding-left: 9px;
  padding-top: 2px;
}

@media screen and (max-width: 768px) {
  .ttl-border-blue {
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 1.7;
    margin-bottom: 8px;
  }
}

.common-menu {
  background-color: #61BEF7;
  margin: 35px 0 15px;
}

.common-menu.on {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  margin: 0;
}

@media screen and (max-width: 768px) {
  .common-menu {
    margin: 0 0 10px;
  }
}

.common-menu .inner {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
  .common-menu .inner {
    padding: 0;
  }
}

.common-menu .inner > a {
  width: 100%;
  max-width: 20%;
  flex: 0 0 20%;
  text-align: center;
  color: #ffffff;
  border-width: 0 1px 0 0;
  border-style: solid;
  border-color: #ffffff;
  font-size: 20px;
  font-size: 2rem;
  line-height: 2;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .common-menu .inner > a {
    font-size: 13px;
    font-size: 1.3rem;
    line-height: 1.54;
  }
}

.common-menu .inner > a:first-child {
  border-width: 0 1px 0 1px;
}

@media screen and (max-width: 768px) {
  .common-menu .inner > a:first-child {
    border-width: 0 1px 0 0;
  }
}

@media screen and (max-width: 768px) {
  .common-menu .inner > a:last-child {
    border-width: 0;
  }
}

.common-menu .inner > a.active {
  background-color: #346EB1;
}

.common-direction {
  margin-bottom: 56px;
}

@media screen and (max-width: 768px) {
  .common-direction {
    margin-bottom: 34px;
  }
}

.common-direction .ttl {
  text-align: center;
  font-size: 26px;
  font-size: 2.6rem;
  line-height: 1.77;
  color: #0060CB;
  margin-bottom: 3px;
}

@media screen and (max-width: 768px) {
  .common-direction .ttl {
    font-size: 20px;
    font-size: 2rem;
    line-height: 1.5;
    margin-bottom: 1px;
  }
}

.common-direction .list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
}

.common-direction .list > li {
  width: 100%;
  max-width: 33.3333%;
  flex: 0 0 33.3333%;
  padding: 0 5px;
}

@media screen and (max-width: 768px) {
  .common-direction .list > li {
    max-width: 100%;
    flex: 0 0 100%;
    margin-bottom: 7px;
  }
}

.common-direction .list .item {
  border: 1px solid #0060CB;
  border-radius: 10px;
  display: block;
  padding: 14px 34px 10px 15px;
  position: relative;
  height: 100%;
  color: #0060CB;
  transform: .3s;
}

@media screen and (max-width: 768px) {
  .common-direction .list .item {
    padding: 5px 34px 5px 10px;
  }
}

.common-direction .list .item:after {
  content: '';
  position: absolute;
  z-index: 1;
  width: 12px;
  height: 22px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 10px;
  transform: .3s;
  background: url("../img/service/lightning/standard/ico-next-blue.png") no-repeat center;
}

@media screen and (max-width: 768px) {
  .common-direction .list .item:after {
    width: 18px;
    height: 28px;
    right: 13px;
  }
}

.common-direction .list .item__ttl {
  display: flex;
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.4;
  margin-bottom: 8px;
}

@media screen and (max-width: 990px) {
  .common-direction .list .item__ttl {
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 1.55;
  }
}

@media screen and (max-width: 768px) {
  .common-direction .list .item__ttl {
    margin-bottom: 2px;
    width: 100%;
  }
}

.common-direction .list .item__order {
  display: inline-block;
  margin-right: 7px;
}

@media screen and (max-width: 768px) {
  .common-direction .list .item__order {
    margin-right: 6px;
  }
}

.common-direction .list .item__grow {
  flex-grow: 1;
}

.common-direction .list .item__txt {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.42;
  padding: 0 0 0 19px;
}

@media screen and (max-width: 768px) {
  .common-direction .list .item__txt {
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 1.33;
    padding: 0 0 0 22px;
  }
}

.common-direction .list .item.active, .common-direction .list .item:hover {
  color: #ffffff;
  opacity: 1;
  background-color: #0060CB;
}

.common-direction .list .item.active:after, .common-direction .list .item:hover:after {
  background: url("../img/service/lightning/standard/ico-next-white.png") no-repeat center;
}

@media screen and (max-width: 990px) {
  .fix-ipd {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .fix-ipd {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .page-service_regulations_standard_index .main-bg--content {
    padding-top: 0;
  }
}

.sec-standard {
  padding: 0 0 42px;
}

@media screen and (max-width: 768px) {
  .sec-standard {
    padding: 0 0 20px;
  }
}

.ttl-standard {
  font-weight: bold;
  font-size: 24px;
  font-size: 2.4rem;
  line-height: 1;
  color: #0060CB;
  margin-bottom: 11px;
}

@media screen and (max-width: 768px) {
  .ttl-standard {
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 1.5;
  }
}

.txt-standard {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.75;
}

@media screen and (max-width: 768px) {
  .txt-standard {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.71;
  }
}

.box-standard {
  margin-bottom: 36px;
}

@media screen and (max-width: 768px) {
  .box-standard {
    margin-bottom: 30px;
  }
}

.box-standard .ttl {
  color: #E3FEFF;
  font-size: 20px;
  font-size: 2rem;
  padding: 3px 12px 1px;
  background-color: #346EB1;
  margin-bottom: 13px;
}

@media screen and (max-width: 768px) {
  .box-standard .ttl {
    font-size: 18px;
    font-size: 1.8rem;
    padding: 4px 12px 3px;
    margin-bottom: 6px;
  }
}

.box-standard .heading {
  display: flex;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.5;
  margin-bottom: 12px;
}

@media screen and (max-width: 768px) {
  .box-standard .heading {
    font-size: 14px;
    font-size: 1.4rem;
    flex-wrap: wrap;
  }
}

.box-standard .heading__lb {
  background-color: #0060CB;
  color: #ffffff;
  padding: 2px 11px;
  min-width: 28.9%;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .box-standard .heading__lb {
    width: 100%;
    padding: 2px 12px;
  }
}

.box-standard .heading__txt {
  flex-grow: 1;
  border: 1px solid #0060CB;
  color: #0060CB;
  padding: 2px 15px;
}

@media screen and (max-width: 768px) {
  .box-standard .heading__txt {
    padding: 2px 18px;
  }
}

.box-standard .wrap {
  display: flex;
  padding-top: 4px;
}

@media screen and (max-width: 768px) {
  .box-standard .wrap {
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
}

.box-standard .wrap__content {
  flex-grow: 1;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.72;
  position: relative;
}

@media screen and (max-width: 990px) {
  .box-standard .wrap__content {
    width: 50%;
    padding-right: 20px;
  }
}

@media screen and (max-width: 768px) {
  .box-standard .wrap__content {
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 1.5;
  }
}

@media screen and (max-width: 640px) {
  .box-standard .wrap__content {
    width: 100%;
  }
}

.box-standard .wrap__content p {
  margin-bottom: 24px;
}

.box-standard .wrap__content p:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 1201px) {
  .box-standard .wrap__content {
    margin-right: -72px;
  }
}

.box-standard .wrap__img {
  flex-shrink: 0;
}

@media screen and (max-width: 990px) {
  .box-standard .wrap__img {
    width: 50%;
  }
}

@media screen and (max-width: 640px) {
  .box-standard .wrap__img {
    width: 100%;
  }
}

.row, .sec-design-sample .list {
  display: flex;
  flex-wrap: wrap;
}

.sec-design-sample {
  background-color: #FCF3E0;
  padding: 62px 0 61px;
}

@media screen and (max-width: 768px) {
  .sec-design-sample {
    padding: 16px 0 10px;
  }
}

.sec-design-sample .col-4 {
  width: 100%;
  max-width: 40.42%;
  flex: 0 0 40.42%;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .sec-design-sample .col-4 {
    max-width: 100%;
    flex: 0 0 100%;
    justify-content: center;
    padding-bottom: 16px;
  }
}

.sec-design-sample .col-6 {
  width: 100%;
  max-width: 59.58%;
  flex: 0 0 59.58%;
}

@media screen and (max-width: 768px) {
  .sec-design-sample .col-6 {
    max-width: 100%;
    flex: 0 0 100%;
  }
}

.sec-design-sample .link-home {
  display: flex;
  align-items: center;
  font-size: 32px;
  font-size: 3.2rem;
  line-height: 1.71;
}

@media screen and (max-width: 768px) {
  .sec-design-sample .link-home {
    font-size: 28px;
    font-size: 2.8rem;
  }
}

.sec-design-sample .link-home img {
  flex-shrink: 0;
  margin-right: 20px;
}

.sec-design-sample .link-home .pt {
  padding-top: 18px;
}

.sec-design-sample .list {
  margin: 0 -9px;
}

@media screen and (max-width: 768px) {
  .sec-design-sample .list {
    margin: 0 auto;
    max-width: 255px;
  }
}

.sec-design-sample .list li {
  width: 100%;
  max-width: 50%;
  flex: 0 0 50%;
  padding: 0 9px;
}

@media screen and (max-width: 768px) {
  .sec-design-sample .list li {
    max-width: 100%;
    flex: 0 0 100%;
    padding: 0;
    margin-bottom: 8px;
  }
}

.sec-design-sample .list li a {
  border: 1px solid #707070;
  display: block;
  padding: 40px 50px 39px 27px;
  position: relative;
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.2;
  background-color: #fff;
}

@media screen and (max-width: 768px) {
  .sec-design-sample .list li a {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.43;
    padding: 12px 22px 10px 11px;
  }
}

.sec-design-sample .list li a:after {
  content: '';
  position: absolute;
  z-index: 1;
  width: 15px;
  height: 26px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 21px;
  background: url("../img/service/lightning/standard/ico-next.png") no-repeat center;
}

@media screen and (max-width: 768px) {
  .sec-design-sample .list li a:after {
    width: 12px;
    height: 18px;
    right: 4px;
    background-size: contain;
  }
}

.page-items_detail {
  padding-bottom: 105px;
}

@media screen and (max-width: 768px) {
  .page-items_detail {
    padding-bottom: 50px;
  }
}

.page-items_detail .main-bg--content {
  padding-top: 60px;
}

@media screen and (max-width: 768px) {
  .page-items_detail .main-bg--content {
    padding-top: 0;
  }
}

.item-detail {
  max-width: 1064px;
  margin: 0 auto;
}

.item-detail__ttl--01 {
  font-size: 28px;
  font-size: 2.8rem;
  color: #346EB1;
  font-weight: bold;
  border-bottom: 1px solid #346EB1;
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .item-detail__ttl--01 {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.item-detail__ttl--02 {
  font-size: 28px;
  font-size: 2.8rem;
  font-weight: bold;
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .item-detail__ttl--02 {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.item-detail__img {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.item-detail__img .item {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 416px;
  border: 1px solid #707070;
  margin-right: 18px;
  margin-bottom: 18px;
}

.item-detail__img .item::after {
  content: "";
  display: block;
  padding-top: 100%;
}

.item-detail__img .item img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: auto;
  width: auto;
  max-width: 97%;
  max-height: 97%;
  margin: auto;
}

@media screen and (max-width: 990px) {
  .item-detail__img .item {
    width: 49%;
    margin-right: 2%;
  }
  .item-detail__img .item:nth-of-type(2n) {
    margin-right: 0;
  }
}

@media screen and (max-width: 768px) {
  .item-detail__img .item {
    width: 100%;
    margin: 0 0 20px;
    max-width: inherit;
  }
}

.item-detail__code {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 30px;
}

.item-detail__code .code {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 14px;
  font-size: 1.4rem;
}

.item-detail__code .code dt {
  font-weight: bold;
}

.item-detail__code .code dd {
  margin-right: 20px;
}

.item-detail .dl-btn {
  max-width: 255px;
  color: #FFF;
  text-align: center;
  font-size: 20px;
  font-size: 2rem;
  margin-right: 20px;
}

@media screen and (max-width: 768px) {
  .item-detail .dl-btn {
    width: 100%;
    margin: 0 auto 30px;
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.item-detail .dl-btn a {
  position: relative;
  display: block;
  padding: 10px 60px 10px 15px;
  background: #FF8D08;
}

@media screen and (max-width: 768px) {
  .item-detail .dl-btn a {
    padding-top: 13px;
    padding-bottom: 13px;
  }
}

.item-detail .dl-btn a::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -17px;
  right: 15px;
  background: url("../img/items/pdf-ico.png") no-repeat 0 0;
  background-size: 100% auto;
  width: 26px;
  height: 34px;
}

.item-detail__spec {
  margin-bottom: 30px;
}

@media screen and (max-width: 768px) {
  .item-detail__spec__inner {
    width: 100%;
    display: block;
    overflow-x: scroll;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 10px;
  }
}

.item-detail__spec table {
  width: 100%;
  font-size: 14px;
  font-size: 1.4rem;
}

@media screen and (max-width: 768px) {
  .item-detail__spec table {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.item-detail__spec table th, .item-detail__spec table td {
  border-right: 3px solid #FFF;
  text-align: center;
  padding: 5px;
}

@media screen and (max-width: 768px) {
  .item-detail__spec table th, .item-detail__spec table td {
    border-width: 2px;
    padding: 5px 10px;
  }
}

.item-detail__spec table th {
  background: #346EB1;
  border-bottom: 3px solid #FFF;
  color: #FFF;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .item-detail__spec table th {
    border-width: 2px;
  }
}

.item-detail__spec table td {
  background: #F2F0ED;
}

.item-detail__other {
  border-bottom: 1px solid #707070;
  padding-bottom: 30px;
  margin-bottom: 50px;
}

.item-detail__other__ttl {
  font-size: 20px;
  font-size: 2rem;
  border-bottom: 1px solid #707070;
  padding-bottom: 5px;
  margin-bottom: 30px;
}

@media screen and (max-width: 768px) {
  .item-detail__other__ttl {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.item-detail__other__txt {
  font-size: 16px;
  font-size: 1.6rem;
  padding: 0 15px;
}

@media screen and (max-width: 768px) {
  .item-detail__other__txt {
    font-size: 14px;
    font-size: 1.4rem;
    padding: 0;
  }
}

.item-detail__other__txt a {
  text-decoration: underline;
}

.item-detail__other__txt strong {
  font-weight: bold;
}

.item-detail__other__txt em {
  font-style: italic;
}

.item-detail__other__txt ul, .item-detail__other__txt ol {
  margin-bottom: 35px;
}

.item-detail__other__txt ul li, .item-detail__other__txt ol li {
  position: relative;
  padding-left: 10px;
  font-size: 16px;
  font-size: 1.6rem;
  margin-bottom: 5px;
}

.item-detail__other__txt ul li::before, .item-detail__other__txt ol li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -2px;
  display: block;
  width: 4px;
  height: 4px;
  background: #434444;
  border-radius: 5px;
}

.sec-boubaku {
  padding: 0px 0 122px;
}

@media screen and (max-width: 768px) {
  .sec-boubaku {
    padding: 0px 0 50px;
  }
}

@media screen and (min-width: 769px) {
  .sec-boubaku .ttl-comp01 {
    margin-bottom: 90px;
  }
}

.sec-boubaku .img-boubaku-main {
  text-align: center;
  margin-bottom: 47px;
}

.sec-boubaku .box-special {
  display: flex;
  position: relative;
  margin-bottom: 71px;
}

@media screen and (max-width: 990px) {
  .sec-boubaku .box-special {
    margin-bottom: 45px;
  }
}

@media screen and (max-width: 768px) {
  .sec-boubaku .box-special {
    margin-bottom: 20px;
  }
}

.sec-boubaku .box-special .img-special {
  width: 112px;
  padding-top: 3px;
}

@media screen and (max-width: 768px) {
  .sec-boubaku .box-special .img-special {
    width: 100%;
    margin: 0 auto 30px;
    max-width: 200px;
    text-align: center;
  }
}

.sec-boubaku .list-special {
  display: flex;
  padding-left: 45px;
  width: calc(100% - 112px);
}

@media screen and (max-width: 990px) {
  .sec-boubaku .list-special {
    padding-left: 20px;
  }
}

@media screen and (max-width: 768px) {
  .sec-boubaku .list-special {
    flex-wrap: wrap;
    width: 100%;
    padding-left: 0;
  }
}

.sec-boubaku .list-boubaku:nth-child(1) {
  width: 59%;
  padding-right: 20px;
}

@media screen and (max-width: 768px) {
  .sec-boubaku .list-boubaku:nth-child(1) {
    width: 100%;
    padding-right: 0;
  }
}

.sec-boubaku .list-boubaku:nth-child(2) {
  width: 41%;
  padding-left: 11px;
}

@media screen and (max-width: 990px) {
  .sec-boubaku .list-boubaku:nth-child(2) {
    padding-left: 0;
  }
}

@media screen and (max-width: 768px) {
  .sec-boubaku .list-boubaku:nth-child(2) {
    width: 100%;
    padding-left: 0;
  }
}

@media screen and (max-width: 768px) {
  .sec-boubaku .list-boubaku {
    padding-left: 0;
    margin-bottom: 25px;
  }
}

.sec-boubaku .list-boubaku li {
  color: #303030;
  font-weight: bold;
  position: relative;
  padding-left: 15px;
  line-height: 1.8;
  font-size: 20px;
  font-size: 2rem;
}

@media screen and (max-width: 990px) {
  .sec-boubaku .list-boubaku li {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.sec-boubaku .list-boubaku li:before {
  content: "";
  height: 12px;
  width: 12px;
  background: #4B4B4B;
  top: 11px;
  left: 0;
  position: absolute;
  border-radius: 50%;
}

@media screen and (max-width: 768px) {
  .sec-boubaku .list-boubaku li:before {
    width: 8px;
    height: 8px;
    top: 7px;
  }
}

.sec-boubaku .box_product_lineup {
  margin-bottom: 30px;
}

@media screen and (max-width: 768px) {
  .sec-boubaku .box_product_lineup {
    margin-bottom: 20px;
  }
}

.sec-boubaku .box_product_lineup span {
  display: inline-block;
  color: #fff;
  font-weight: bold;
  background: #4EB46E;
  padding: 5px 21px;
  font-size: 20px;
  font-size: 2rem;
}

@media screen and (max-width: 768px) {
  .sec-boubaku .box_product_lineup span {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.sec-boubaku .list-item__lineup {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -35px 22px;
}

@media screen and (max-width: 990px) {
  .sec-boubaku .list-item__lineup {
    margin: 0 -15px;
  }
}

@media screen and (max-width: 768px) {
  .sec-boubaku .list-item__lineup {
    margin: 0;
  }
}

.sec-boubaku .list-item__lineup .item__lineup {
  width: 33.333%;
  padding: 0 35px;
  margin-bottom: 53px;
}

@media screen and (max-width: 990px) {
  .sec-boubaku .list-item__lineup .item__lineup {
    padding: 0 15px;
  }
}

@media screen and (max-width: 768px) {
  .sec-boubaku .list-item__lineup .item__lineup {
    padding: 0;
    width: 100%;
    margin-bottom: 35px;
  }
}

.sec-boubaku .list-item__lineup .item__lineup img {
  margin-bottom: 18px;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .sec-boubaku .list-item__lineup .item__lineup img {
    margin-bottom: 15px;
  }
}

.sec-boubaku .list-item__lineup .item__lineup .item-list li {
  color: #303030;
  margin-bottom: 0px;
  position: relative;
  padding-left: 17px;
  line-height: 1.8;
  font-size: 16px;
  font-size: 1.6rem;
}

@media screen and (max-width: 768px) {
  .sec-boubaku .list-item__lineup .item__lineup .item-list li {
    line-height: 1.5;
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.sec-boubaku .list-item__lineup .item__lineup .item-list li:before {
  content: "";
  background: #303030;
  width: 9px;
  position: absolute;
  height: 9px;
  top: 9px;
  left: 4px;
}

@media screen and (max-width: 768px) {
  .sec-boubaku .list-item__lineup .item__lineup .item-list li:before {
    top: 4px;
  }
}

.sec-boubaku .list-item__lineup .item__lineup p {
  margin-top: 6px;
  font-size: 14px;
  font-size: 1.4rem;
}

.sec-boubaku .list-item__lineup .item__lineup02 {
  width: 43%;
}

@media screen and (max-width: 768px) {
  .sec-boubaku .list-item__lineup .item__lineup02 {
    width: 100%;
  }
}

.box-boubaku {
  display: flex;
  margin-bottom: 46px;
  align-items: center;
  padding-bottom: 76px;
  border-bottom: 10px solid #4EB46E;
}

@media screen and (max-width: 768px) {
  .box-boubaku {
    margin-bottom: 35px;
    flex-wrap: wrap-reverse;
    padding-bottom: 20px;
    border-width: 3px;
  }
}

.box-boubaku__ttl {
  color: #4EB46E;
  font-weight: bold;
  padding-right: 40px;
  width: 50.75%;
  line-height: 1.875;
  font-size: 32px;
  font-size: 3.2rem;
}

@media screen and (max-width: 990px) {
  .box-boubaku__ttl {
    font-size: 24px;
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 768px) {
  .box-boubaku__ttl {
    width: 100%;
    padding-right: 0;
    line-height: 1.5;
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.box-boubaku__img {
  width: 49.25%;
}

@media screen and (max-width: 768px) {
  .box-boubaku__img {
    width: 100%;
    margin-bottom: 10px;
  }
}

.list-group li {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  padding: 20px 0 10px;
  min-height: 150px;
  border-bottom: 1px solid #707070;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .list-group li {
    min-height: 50px;
    padding: 40px 0;
  }
}

@media screen and (max-width: 768px) {
  .list-group li {
    min-height: 120px;
    padding: 20px 0;
  }
}

.list-group span {
  display: inline-block;
  width: 22.5%;
  font-weight: bold;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.33;
  color: #0060CB;
  padding-right: 10px;
}

.list-group span.txt-black {
  color: #303030;
}

@media screen and (max-width: 990px) {
  .list-group span {
    font-size: 16px;
    font-size: 1.6rem;
    width: 26%;
  }
}

@media screen and (max-width: 768px) {
  .list-group span {
    font-size: 14px;
    font-size: 1.4rem;
    width: 70%;
  }
}

.list-group .txt-basic {
  width: 57.5%;
  line-height: 1.875;
}

@media screen and (max-width: 990px) {
  .list-group .txt-basic {
    width: 54%;
  }
}

@media screen and (max-width: 768px) {
  .list-group .txt-basic {
    line-height: 1.5;
    width: 100%;
    margin-top: 20px;
  }
}

.list-group__img {
  width: 20%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .list-group__img {
    display: block;
  }
}

.list-group__img img {
  max-width: 90%;
  display: block;
}

@media screen and (max-width: 768px) {
  .list-group__img {
    width: 30%;
    justify-content: flex-start;
    padding-left: 0;
  }
  .list-group__img img {
    width: auto;
  }
}

.sec-content {
  padding-bottom: 100px;
}

@media screen and (max-width: 768px) {
  .sec-content {
    padding-bottom: 50px;
  }
}

.table-service {
  max-width: 770px;
  margin: 0 auto;
  padding: 0 15px;
}

@media screen and (max-width: 768px) {
  .table-service {
    padding: 0;
    margin-bottom: 20px;
  }
}

.table-service table {
  width: 100%;
}

.table-service table th, .table-service table td {
  border: 1px solid #fff;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.2;
  text-align: center;
  padding: 4px 19px;
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  .table-service table th, .table-service table td {
    font-size: 13px;
    font-size: 1.3rem;
    line-height: 1.53;
    padding: 3px 4px;
  }
}

.table-service table th {
  color: #fff;
  background: #346EB1;
  padding: 4px 18.5px 2px;
}

@media screen and (max-width: 768px) {
  .table-service table th {
    padding: 15px 5px 15px;
    width: 20%;
  }
}

.table-service table td {
  color: #303030;
  background: #F2F0ED;
}

.table-service table td.td-name {
  background: #96B7DD;
}

@media screen and (max-width: 768px) {
  .table-service table td {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.sec-serstandard {
  padding: 0 6px 0px;
}

@media screen and (max-width: 768px) {
  .sec-serstandard {
    padding: 0 0px 50px;
  }
}

.sec-serstandard #link01 {
  margin-top: -60px;
  padding-top: 60px;
}

.sec-serstandard #link02 {
  margin-top: -55px;
  padding-top: 55px;
}

.sec-serstandard .part-bd {
  padding: 19px 26px 25px 26px;
  border: 4px solid #346EB1;
}

@media screen and (max-width: 768px) {
  .sec-serstandard .part-bd {
    padding: 18px 15px 20px 22px;
  }
  .sec-serstandard .part-bd .txt-lg, .sec-serstandard .part-bd .txt-businesspartner {
    margin-bottom: 0;
  }
}

.sec-serstandard .mb-common {
  margin-bottom: 89px;
}

@media screen and (max-width: 768px) {
  .sec-serstandard .mb-common {
    margin-bottom: 54px;
  }
}

.sec-serstandard .mb-common:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 768px) {
  .sec-serstandard .txt-lg, .sec-serstandard .txt-businesspartner {
    margin-bottom: 13px;
    line-height: 1.8;
    font-size: 16px;
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 768px) {
  .sec-serstandard .ttl-comp02 span {
    padding: 0 0 6px 0;
  }
}

.sec-serstandard .ttl-comp01 {
  margin-bottom: 15px;
}

.sec-serstandard .ttl-comp01 .sp-iphone5 {
  display: none;
}

@media screen and (max-width: 374px) {
  .sec-serstandard .ttl-comp01 .sp-iphone5 {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .block-protection .txt-lg, .block-protection .txt-businesspartner {
    text-align: left;
    margin-bottom: 19px;
  }
}

table.table-common {
  text-align: left;
  width: 100%;
  margin-bottom: 40px;
}

@media screen and (max-width: 768px) {
  table.table-common {
    margin-bottom: 19px;
  }
}

table.table-common th, table.table-common td {
  border: 1px solid #707070;
  padding: 16px 15px 9px;
  vertical-align: baseline;
}

@media screen and (max-width: 768px) {
  table.table-common th, table.table-common td {
    padding: 8px 5px 5px 5px;
    line-height: 1.71;
  }
}

table.table-common th:first-child, table.table-common td:first-child {
  width: 20%;
  vertical-align: middle;
}

@media screen and (max-width: 768px) {
  table.table-common th:first-child, table.table-common td:first-child {
    width: 23%;
    vertical-align: baseline;
  }
}

table.table-common th:nth-child(2n), table.table-common td:nth-child(2n) {
  width: 56%;
  padding-left: 32px;
  vertical-align: middle;
}

@media screen and (max-width: 768px) {
  table.table-common th:nth-child(2n), table.table-common td:nth-child(2n) {
    padding: 6px 10px 6px 12px;
    width: 51%;
    vertical-align: baseline;
  }
}

table.table-common th:nth-child(3n), table.table-common td:nth-child(3n) {
  width: 24%;
}

@media screen and (max-width: 768px) {
  table.table-common th:nth-child(3n), table.table-common td:nth-child(3n) {
    width: 26%;
  }
}

table.table-common th {
  background: #F2F0ED;
}

@media screen and (max-width: 768px) {
  table.table-common th:nth-child(2n) {
    text-align: center;
  }
}

table.table-common td {
  background: #fff;
  line-height: 1.75;
  padding: 10px 15px 4px;
}

@media screen and (max-width: 768px) {
  table.table-common td {
    padding: 8px 5px 5px 8px;
  }
}

table.table-common--other tbody {
  width: 100%;
}

table.table-common--other td:first-child, table.table-common--other th:first-child {
  width: 76%;
}

@media screen and (max-width: 768px) {
  table.table-common--other td:first-child, table.table-common--other th:first-child {
    width: 74%;
  }
}

table.table-common--other td:nth-child(2n), table.table-common--other th:nth-child(2n) {
  width: 24%;
  padding-left: 15px;
}

@media screen and (max-width: 768px) {
  table.table-common--other td:nth-child(2n), table.table-common--other th:nth-child(2n) {
    width: 26%;
    padding-left: 8px;
    text-align: left;
  }
}

.sec-faq {
  margin-bottom: 100px;
}

@media screen and (max-width: 768px) {
  .sec-faq {
    margin-bottom: 50px;
  }
}

.sec-faq .ttl-comp02 {
  margin-bottom: 54px;
}

@media screen and (max-width: 768px) {
  .sec-faq .ttl-comp02 {
    margin-bottom: 35px;
  }
}

.sec-faq .block-task {
  margin-bottom: 37px;
}

@media screen and (max-width: 768px) {
  .sec-faq .block-task {
    margin-bottom: 15px;
  }
}

.block-faq {
  margin-bottom: 61px;
}

.block-faq .box-faq_ttl {
  font-size: 20px;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.4;
  color: #0060CB;
  border-bottom: 5px solid #96B7DD;
  margin-bottom: 26px;
  padding-bottom: 6px;
}

@media screen and (max-width: 768px) {
  .block-faq .box-faq_ttl {
    font-size: 18px;
    font-size: 1.8rem;
    margin-bottom: 20px;
  }
}

.block-faq .ttl-faq {
  width: 60px;
  height: 60px;
  margin-right: 17px;
  font-size: 32px;
  font-size: 3.2rem;
  font-weight: bold;
  line-height: 2;
  padding: 5px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.block-faq .ttl-faq--a {
  background: #61BEF7;
}

.block-faq .ttl-faq--q {
  background: #0060CB;
}

@media screen and (max-width: 768px) {
  .block-faq .ttl-faq {
    width: 40px;
    height: 40px;
    font-size: 26px;
    font-size: 2.6rem;
    margin-right: 10px;
  }
}

@media screen and (max-width: 640px) {
  .block-faq .ttl-faq {
    width: 30px;
    height: 30px;
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.block-faq .txt-faqq {
  font-size: 20px;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.4;
  color: #303030;
  width: calc(100% - 77px);
  word-break: break-all;
}

@supports (-ms-ime-align: auto) {
  .block-faq .txt-faqq {
    word-wrap: break-word;
  }
}

@media screen and (max-width: 768px) {
  .block-faq .txt-faqq {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.75;
    width: calc(100% - 50px);
  }
}

.block-faq .txt-faqa {
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1.56;
  color: #0060CB;
  width: calc(100% - 77px);
  word-break: break-all;
}

@supports (-ms-ime-align: auto) {
  .block-faq .txt-faqa {
    word-wrap: break-word;
  }
}

@media screen and (max-width: 768px) {
  .block-faq .txt-faqa {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.72;
    width: calc(100% - 50px);
  }
}

.block-faq .item-question {
  margin-bottom: 28px;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .block-faq .item-question {
    margin-bottom: 25px;
  }
}

.block-faq .item-answer {
  background: #FCF3E0;
  border: 1px solid #0060CB;
  padding: 23px 92px 22px 15px;
  display: flex;
  align-items: flex-start;
  margin: 0 0 40px 108px;
}

@media screen and (max-width: 1350px) {
  .block-faq .item-answer {
    padding: 23px 15px 22px;
  }
}

@media screen and (max-width: 768px) {
  .block-faq .item-answer {
    padding: 15px 12px 15px;
    margin: 0 0 25px 50px;
  }
}

.block-faq .table-faq {
  width: 100%;
  width: calc(100% - 14px);
  table-layout: fixed;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.75;
  margin: 0 0 8px -1px;
}

@media screen and (max-width: 1200px) {
  .block-faq .table-faq {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.72;
  }
}

@media screen and (max-width: 1150px) {
  .block-faq .table-faq {
    width: 850px;
  }
}

.block-faq .table-faq thead {
  background-color: #4B4B4B;
  color: #fff;
}

.block-faq .table-faq thead th {
  padding: 1px 0 1px 8px;
  font-weight: normal;
}

.block-faq .table-faq thead th:first-child {
  width: 11.95%;
  padding: 1px 0 1px 16px;
}

.block-faq .table-faq thead th:nth-child(2) {
  width: 22.6%;
}

.block-faq .table-faq thead th:nth-child(3) {
  width: 10.9%;
}

.block-faq .table-faq thead th:nth-child(4) {
  width: 9.5%;
}

.block-faq .table-faq thead th:nth-child(5) {
  width: 11.4%;
}

.block-faq .table-faq tbody {
  background-color: #fff;
  color: #303030;
}

.block-faq .table-faq tbody tr {
  border-bottom: 1px solid #303030;
}

.block-faq .table-faq tbody td {
  padding: 5px 0 1px 8px;
  font-weight: normal;
}

.block-faq .table-faq tbody td:first-child {
  padding: 5px 0 1px 16px;
}

.block-faq .row-faq {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -62px 7px;
  color: #303030;
  font-weight: normal;
}

@media screen and (max-width: 1200px) {
  .block-faq .row-faq {
    margin: 0 -15px 0;
  }
}

.block-faq .row-faq .col {
  width: 100%;
  max-width: 50%;
  flex: 0 0 50%;
  padding: 0 62px;
}

@media screen and (max-width: 1200px) {
  .block-faq .row-faq .col {
    padding: 0 15px;
  }
}

@media screen and (max-width: 768px) {
  .block-faq .row-faq .col {
    max-width: 100%;
    flex: 0 0 100%;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 768px) {
  .block-faq .row-faq .col:last-child {
    margin-bottom: 0;
  }
}

.block-faq .row-faq .ttl {
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.4;
  color: #303030;
  margin-bottom: 10px;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .block-faq .row-faq .ttl {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.72;
  }
}

.block-faq .row-faq .list {
  border: 1px solid #303030;
}

.block-faq .row-faq .list li {
  background-color: #fff;
  padding: 8px 14px 4px;
  display: flex;
}

.block-faq .row-faq .list li:not(:last-child) {
  border-bottom: 1px solid #303030;
}

.block-faq .row-faq .list li span {
  width: 37.5%;
  min-width: 110px;
}

@media screen and (max-width: 768px) {
  .block-faq .row-faq .list li span {
    min-width: auto;
    padding-right: 10px;
  }
}

@media screen and (max-width: 1150px) {
  .overflow-sp {
    overflow-x: scroll;
  }
}

.list-resolution {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -7px -123px;
}

@media screen and (max-width: 768px) {
  .list-resolution {
    margin-bottom: -30px;
  }
}

.list-resolution__item {
  width: calc(33.33% - 14px);
  margin: 0 7px;
  border: solid 3px #346EB1;
  background: #f2f0ed;
  padding: 67px 20px 32px;
  margin-bottom: 123px;
  position: relative;
}

.list-resolution__item--main {
  border: 0;
  padding: 0;
  background: 0;
  margin-top: -78px;
  margin-bottom: -40px;
}

@media (max-width: 1100px) {
  .list-resolution__item--main {
    margin-top: -50px;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 990px) {
  .list-resolution__item--main {
    margin-top: 0;
    display: flex;
    align-items: center;
    margin-bottom: 173px;
  }
}

@media screen and (max-width: 768px) {
  .list-resolution__item--main {
    justify-content: center;
  }
}

.list-resolution__item--vertical {
  display: flex;
  flex-wrap: wrap;
  padding-top: 72px;
}

.list-resolution__item--vertical .list-resolution__img {
  width: 48%;
}

.list-resolution__item--vertical .list-resolution__img img {
  display: block;
  width: 100%;
}

.list-resolution__item--vertical .list-resolution__img ul li {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .list-resolution__item--vertical .list-resolution__img {
    width: 100%;
    max-width: 225px;
    margin: 0 auto 10px;
  }
}

.list-resolution__item--vertical .txt-basic {
  width: 52%;
  padding-left: 17px;
  line-height: 1.75;
}

@media screen and (max-width: 768px) {
  .list-resolution__item--vertical .txt-basic {
    padding-left: 5px;
    width: 100%;
    padding-left: 0;
  }
}

.list-resolution__item:nth-child(1), .list-resolution__item:nth-child(2) {
  width: 50%;
  max-width: 522px;
}

@media screen and (max-width: 1200px) {
  .list-resolution__item:nth-child(1), .list-resolution__item:nth-child(2) {
    max-width: 45%;
  }
}

.list-resolution__item:nth-child(n+6) {
  width: calc(29.5% - 14px);
  padding-bottom: 23px;
}

.list-resolution__item:nth-child(n+6) img {
  margin-bottom: 8px;
}

.list-resolution__item:nth-child(n+6) .list-resolution__img {
  margin-bottom: 21px;
}

.list-resolution__item:nth-child(8) {
  width: calc(41% - 14px);
}

@supports (-ms-ime-align: auto) {
  .list-resolution__item:nth-child(8) {
    width: calc(40% - 14px);
  }
}

.list-resolution__item:nth-child(3), .list-resolution__item:nth-child(5) {
  padding-top: 34px;
  margin-bottom: 173px;
}

.list-resolution__item:nth-child(3) img, .list-resolution__item:nth-child(5) img {
  margin-bottom: 7px;
}

.list-resolution__item:nth-child(3) .txt-basic, .list-resolution__item:nth-child(5) .txt-basic {
  line-height: 1.75;
}

.list-resolution__item:nth-child(3) .list-resolution__img, .list-resolution__item:nth-child(5) .list-resolution__img {
  margin-bottom: 24px;
}

@media screen and (max-width: 768px) {
  .list-resolution__item {
    width: 100% !important;
    margin-bottom: 36px !important;
    max-width: 100% !important;
    padding: 64px 10px 24px !important;
  }
  .list-resolution__item .list-resolution__img {
    margin-bottom: 7px !important;
  }
}

.list-resolution__img {
  margin-bottom: 13px;
}

.list-resolution__img ul {
  display: flex;
  justify-content: center;
}

.list-resolution__img ul p {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.75;
}

@media screen and (max-width: 768px) {
  .list-resolution__img ul p {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.5;
    max-width: 225px;
    margin: 0 auto;
  }
}

.list-resolution__img ul .txt-basic {
  line-height: 1.875;
}

.list-resolution__img ul img {
  display: block;
  margin-bottom: 6px;
}

@media screen and (max-width: 768px) {
  .list-resolution__img ul img {
    width: 100%;
    max-width: 225px;
    margin: 0 auto 8px;
  }
}

.list-resolution__img ul.list {
  display: flex;
  margin: 0 -8px;
}

@media screen and (max-width: 768px) {
  .list-resolution__img ul.list {
    flex-wrap: wrap;
    margin: 0 -2.5px;
  }
}

.list-resolution__img ul.list li {
  width: 50%;
  padding: 0 8px;
}

.list-resolution__img ul.list li img {
  display: block;
}

@media screen and (max-width: 768px) {
  .list-resolution__img ul.list li {
    width: 100%;
    margin-bottom: 15px;
    padding: 0 2.5px;
  }
  .list-resolution__img ul.list li:last-child {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 768px) {
  .list-resolution__img .txt-basic {
    line-height: 1.78;
  }
}

.list-resolution__number {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  position: absolute;
  top: 8px;
  left: 8px;
  background: #346eb1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: bold;
  font-size: 24px;
  font-size: 2.4rem;
  margin-bottom: 2px;
}

.sec-service-regulation-excution {
  padding-bottom: 130px;
}

@media screen and (max-width: 768px) {
  .sec-service-regulation-excution {
    padding-bottom: 50px;
  }
}

.sec-service-regulation-excution .box-equipment-inspection {
  margin-bottom: 50px;
}

@media screen and (max-width: 768px) {
  .sec-service-regulation-excution .box-equipment-inspection {
    margin-bottom: 35px;
  }
}

.img-main {
  margin-bottom: 34px;
}

@media screen and (min-width: 769px) {
  .sec-news .ttl-comp01 {
    margin-bottom: 57px;
  }
}

.sec-news .block-worksdetail .part-time {
  background: #F2F0ED;
}

.list-quality {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px 40px;
}

@media screen and (max-width: 768px) {
  .list-quality {
    margin-bottom: 13px;
  }
}

.list-quality li {
  width: 33.33%;
  padding: 0 8px;
  margin-bottom: 47px;
}

@media screen and (max-width: 768px) {
  .list-quality li {
    width: 50%;
    margin-bottom: 18px;
  }
  .list-quality li:last-child {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 990px) {
  .list-quality li {
    width: 100%;
  }
}

.list-quality img {
  display: block;
  margin: 0 auto 10px;
  width: 100%;
}

.list-quality__ttl {
  text-align: center;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.75;
  text-align: center;
}

@media screen and (max-width: 990px) {
  .list-quality__ttl {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.6875;
  }
}

.box-txt {
  padding: 18px 20px 11px 40px;
  border: solid 3px #346EB1;
  max-width: 848px;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .box-txt {
    padding: 12px 16px;
  }
}

.box-txt p {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 2;
}

@media screen and (max-width: 768px) {
  .box-txt p {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.5;
  }
}

.sec-service-regulation-quality {
  padding-bottom: 88px;
}

.sec-service-regulation-quality .box-equipment-inspection {
  margin-bottom: 53px;
}

@media screen and (max-width: 768px) {
  .sec-service-regulation-quality {
    padding-bottom: 50px;
  }
}

.sec-configuration {
  padding-top: 34px;
  padding-bottom: 28px;
}

@media screen and (max-width: 768px) {
  .sec-configuration {
    padding-top: 19px;
    padding-bottom: 50px;
  }
}

.sec-configuration .box-img {
  margin-bottom: 28px;
}

@media screen and (max-width: 768px) {
  .sec-configuration .box-img {
    margin-bottom: -8px;
  }
}

.txt-blue {
  font-size: 24px;
  font-size: 2.4rem;
  line-height: 1.6;
  color: #0060CB;
  font-weight: bold;
  text-align: center;
  margin-bottom: 36px;
}

@media screen and (max-width: 768px) {
  .txt-blue {
    font-size: 20px;
    font-size: 2rem;
    text-align: left;
    margin-bottom: 13px;
  }
}

.txt-blue__style02 {
  font-size: 32px;
  font-size: 3.2rem;
  font-weight: bold;
  color: #14137E;
  line-height: 1.6;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .txt-blue__style02 {
    font-size: 18px;
    font-size: 1.8rem;
    text-align: left;
  }
}

.list-lightning-system {
  max-width: 900px;
  margin: 0 auto 77px;
  padding-left: 38px;
}

@media screen and (max-width: 768px) {
  .list-lightning-system {
    padding-left: 0;
    margin-bottom: 24px;
  }
}

.list-lightning-system .item {
  font-size: 20px;
  font-size: 2rem;
  color: #303030;
  margin-bottom: 12px;
}

@media screen and (max-width: 768px) {
  .list-lightning-system .item {
    margin-bottom: 0;
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.65;
  }
}

.list-lightning-system .item span {
  color: #0060CB;
}

@media screen and (max-width: 768px) {
  .list-lightning-system__style02 {
    margin-bottom: 70px;
  }
}

.list-lightning-system__style02 .item {
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .list-lightning-system__style02 .item {
    margin-bottom: 23px;
  }
}

.list-lightning-system__style02 .item__ttl {
  font-size: 16px;
  font-size: 1.6rem;
  color: #0060CB;
  font-weight: bold;
}

.list-lightning-system__style02 .item__des {
  font-size: 14px;
  font-size: 1.4rem;
  color: #303030;
  line-height: 1.7;
}

.sec-drone-top {
  padding-bottom: 100px;
}

@media screen and (max-width: 768px) {
  .sec-drone-top {
    padding-bottom: 46px;
  }
}

.sec-drone-top .ttl-comp02 {
  margin-bottom: 38px;
}

@media screen and (max-width: 768px) {
  .sec-drone-top .ttl-comp02 {
    margin-bottom: 22px;
  }
}

.box-drone {
  display: flex;
  margin-bottom: 62px;
}

@media screen and (max-width: 768px) {
  .box-drone {
    flex-wrap: wrap;
    margin-bottom: 30px;
  }
}

.box-drone__left {
  width: 49.375%;
}

.box-drone__left img {
  width: 100%;
}

@media screen and (max-width: 1250px) {
  .box-drone__left {
    width: 50%;
  }
}

@media screen and (max-width: 768px) {
  .box-drone__left {
    width: 100%;
    margin-bottom: 25px;
  }
}

.box-drone__right {
  width: calc(100% - 49.375%);
  padding-left: 10px;
  position: relative;
  top: -6px;
}

@media screen and (max-width: 1250px) {
  .box-drone__right {
    width: 50%;
    top: 0;
  }
}

@media screen and (max-width: 768px) {
  .box-drone__right {
    width: 100%;
    padding-left: 0;
    top: 0;
  }
}

.box-drone__right .txt-basic {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.8;
  font-weight: bold;
  margin-bottom: 19px;
  padding-left: 7px;
}

@media screen and (max-width: 768px) {
  .box-drone__right .txt-basic {
    font-size: 16px;
    font-size: 1.6rem;
    padding-left: 0;
    margin-bottom: 15px;
  }
}

.box-drone__right .box-support {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 990px) {
  .box-drone__right .box-support {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 768px) {
  .box-drone__right .box-support {
    flex-wrap: nowrap;
  }
}

@media screen and (max-width: 640px) {
  .box-drone__right .box-support {
    flex-wrap: wrap;
  }
}

.box-drone__right .box-support .img {
  width: calc(100% - 216px);
}

@media screen and (max-width: 990px) {
  .box-drone__right .box-support .img {
    width: 100%;
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .box-drone__right .box-support .img {
    width: calc(100% - 216px);
    text-align: left;
  }
}

@media screen and (max-width: 640px) {
  .box-drone__right .box-support .img {
    width: 100%;
    margin-bottom: 15px;
  }
}

.box-drone__right .box-support .txt-circle {
  width: 216px;
  height: 216px;
  border-radius: 50%;
  background: #FF8D08;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 24px;
  font-size: 2.4rem;
  color: #fff;
  line-height: 1.35;
  margin-right: 25px;
}

@media screen and (max-width: 1250px) {
  .box-drone__right .box-support .txt-circle {
    margin-right: 0;
  }
}

@media screen and (max-width: 990px) {
  .box-drone__right .box-support .txt-circle {
    margin: 0 auto;
  }
}

@media screen and (max-width: 640px) {
  .box-drone__right .box-support .txt-circle {
    margin: 0 auto;
    font-size: 20px;
    font-size: 2rem;
    line-height: 1.5;
  }
}

.box-steps {
  margin-bottom: 100px;
}

@media screen and (max-width: 768px) {
  .box-steps {
    margin-bottom: 50px;
  }
}

.box-steps .ttl-comp01 {
  margin-bottom: 52px;
}

@media screen and (max-width: 768px) {
  .box-steps .ttl-comp01 {
    margin-bottom: 20px;
  }
}

.box-steps .list-steps {
  display: flex;
  margin: 0 -8px;
}

@media screen and (max-width: 768px) {
  .box-steps .list-steps {
    flex-wrap: wrap;
    margin: 0;
  }
}

.box-steps .list-steps .item {
  padding: 0 8px;
  width: 25%;
}

@media screen and (max-width: 768px) {
  .box-steps .list-steps .item {
    width: 100%;
    padding: 0;
    margin-bottom: 15px;
  }
}

.box-steps .list-steps .item-wp {
  background: #F2F0ED;
  text-align: center;
  padding: 18px 20px 42px;
}

@media screen and (max-width: 990px) {
  .box-steps .list-steps .item-wp {
    padding: 18px 10px;
  }
}

@media screen and (max-width: 768px) {
  .box-steps .list-steps .item-wp {
    padding: 15px;
  }
}

.box-steps .list-steps .item-wp__ttl {
  padding-bottom: 3px;
  margin-bottom: 20px;
  display: inline-flex;
  align-items: center;
  border-bottom: 1px dashed #346EB1;
}

.box-steps .list-steps .item-wp__ttl span {
  color: #346EB1;
}

.box-steps .list-steps .item-wp__ttl span:first-of-type {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: bold;
  margin-right: 12px;
}

@media screen and (max-width: 768px) {
  .box-steps .list-steps .item-wp__ttl span:first-of-type {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.box-steps .list-steps .item-wp__ttl span:last-of-type {
  font-size: 48px;
  font-size: 4.8rem;
  font-weight: bold;
  line-height: 1;
}

@media screen and (max-width: 768px) {
  .box-steps .list-steps .item-wp__ttl span:last-of-type {
    font-size: 36px;
    font-size: 3.6rem;
  }
}

.box-steps .list-steps .item-wp__des {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.box-steps .list-steps .item-wp__des .txt-basic {
  line-height: 1.75;
}

.box-steps .list-steps .item-wp__des a {
  color: #346EB1;
}

.box-steps .list-steps .item-wp__des small {
  width: 100%;
  display: block;
  font-size: 14px;
  font-size: 1.4rem;
  margin-top: 6px;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .box-steps .list-steps .item-wp__des small {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.box-flight-plan {
  padding-bottom: 30px;
}

.box-flight-plan .list-item .item {
  display: flex;
}

@media screen and (max-width: 768px) {
  .box-flight-plan .list-item .item {
    flex-wrap: wrap;
  }
}

.box-flight-plan .list-item .item__info {
  width: calc(100% - 40.9%);
  padding-right: 16px;
}

@media screen and (max-width: 768px) {
  .box-flight-plan .list-item .item__info {
    width: 100%;
    order: 2;
    padding-right: 0;
  }
}

.box-flight-plan .list-item .item__info--ttl {
  font-size: 24px;
  font-size: 2.4rem;
  color: #346EB1;
  font-weight: bold;
  border-bottom: 1px solid #346EB1;
  margin-bottom: 20px;
  padding-bottom: 9px;
}

@media screen and (max-width: 990px) {
  .box-flight-plan .list-item .item__info--ttl {
    font-size: 22px;
    font-size: 2.2rem;
  }
}

@media screen and (max-width: 768px) {
  .box-flight-plan .list-item .item__info--ttl {
    font-size: 18px;
    font-size: 1.8rem;
    padding-bottom: 5px;
  }
}

.box-flight-plan .list-item .item__info .txt-basic {
  font-size: 20px;
  font-size: 2rem;
  margin-bottom: 27px;
}

@media screen and (max-width: 768px) {
  .box-flight-plan .list-item .item__info .txt-basic {
    font-size: 16px;
    font-size: 1.6rem;
    margin-bottom: 15px;
  }
}

.box-flight-plan .list-item .item__info .list-dots li {
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.6;
  padding-left: 30px;
  position: relative;
  margin-bottom: 32px;
}

@media screen and (max-width: 990px) {
  .box-flight-plan .list-item .item__info .list-dots li {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 768px) {
  .box-flight-plan .list-item .item__info .list-dots li {
    font-size: 16px;
    font-size: 1.6rem;
    margin-bottom: 20px;
    padding-left: 20px;
  }
}

.box-flight-plan .list-item .item__info .list-dots li:before {
  content: '';
  display: block;
  width: 22px;
  height: 22px;
  background: #346EB1;
  border-radius: 50%;
  position: absolute;
  top: 5px;
  left: 0;
}

@media screen and (max-width: 768px) {
  .box-flight-plan .list-item .item__info .list-dots li:before {
    width: 15px;
    height: 15px;
  }
}

.box-flight-plan .list-item .item__img {
  width: 40.9%;
}

.box-flight-plan .list-item .item__img img {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .box-flight-plan .list-item .item__img {
    width: 100%;
    order: 1;
    margin-bottom: 15px;
  }
}

.box-orange {
  background: #FFEBCD;
  padding: 35px 46px 42px;
  margin-bottom: 80px;
}

@media screen and (max-width: 768px) {
  .box-orange {
    margin-bottom: 50px;
    padding: 15px 20px;
  }
}

.box-orange .ttl-border-orange {
  text-align: center;
  margin-bottom: 28px;
}

@media screen and (max-width: 768px) {
  .box-orange .ttl-border-orange {
    margin-bottom: 15px;
  }
}

.box-orange .ttl-border-orange span {
  display: inline-block;
  font-size: 20px;
  font-size: 2rem;
  font-weight: bold;
  border-bottom: 3px solid #FF8D08;
  padding: 0 6px 10px;
}

@media screen and (max-width: 768px) {
  .box-orange .ttl-border-orange span {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.box-orange .list-orange {
  display: flex;
  margin: 0 -10px;
}

@media screen and (max-width: 640px) {
  .box-orange .list-orange {
    flex-wrap: wrap;
    margin: 0;
  }
}

.box-orange .list-orange li {
  width: 25%;
  padding: 0 10px;
}

.box-orange .list-orange li img {
  width: 100%;
}

@media screen and (max-width: 640px) {
  .box-orange .list-orange li {
    width: 100%;
    padding: 0;
    margin-bottom: 15px;
  }
  .box-orange .list-orange li:last-of-type {
    margin-bottom: 0;
  }
}

.box-shooting-sample {
  margin-bottom: 50px;
}

@media screen and (max-width: 768px) {
  .box-shooting-sample {
    margin-bottom: 30px;
  }
}

.box-shooting-sample .box-video {
  display: flex;
}

@media screen and (max-width: 768px) {
  .box-shooting-sample .box-video {
    flex-wrap: wrap;
  }
}

.box-shooting-sample .box-video__left {
  width: calc(100% - 57.81%);
  padding-right: 50px;
}

@media screen and (max-width: 768px) {
  .box-shooting-sample .box-video__left {
    width: 100%;
    padding-right: 0;
    margin-bottom: 30px;
    text-align: center;
  }
}

.box-shooting-sample .box-video__right {
  width: 57.81%;
}

@media screen and (max-width: 768px) {
  .box-shooting-sample .box-video__right {
    width: 100%;
  }
}

.box-shooting-sample .box-video__right .box-iframe {
  margin-bottom: 17px;
}

@media screen and (max-width: 768px) {
  .box-shooting-sample .box-video__right .box-iframe {
    margin-bottom: 15px;
  }
}

.box-shooting-sample .box-video__right .box-iframe iframe {
  width: 100%;
  height: 416px;
}

.box-shooting-sample .box-video__right .txt-caption {
  font-size: 18px;
  font-size: 1.8rem;
  text-align: center;
  display: block;
}

@media screen and (max-width: 768px) {
  .box-shooting-sample .box-video__right .txt-caption {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.box-drone-lineup {
  margin-bottom: 82px;
}

@media screen and (max-width: 768px) {
  .box-drone-lineup {
    margin-bottom: 30px;
  }
}

.box-contact {
  margin-bottom: 85px;
}

@media screen and (max-width: 768px) {
  .box-contact {
    margin-bottom: 30px;
  }
}

.list-single-img {
  max-width: 1066px;
  margin: 0 auto;
}

.list-single-img .wp {
  margin: 0 -9px;
  display: flex;
}

@media screen and (max-width: 768px) {
  .list-single-img .wp {
    flex-wrap: wrap;
    margin: 0;
  }
}

.list-single-img .wp .item {
  width: 50%;
  padding: 0 9px;
}

@media screen and (max-width: 640px) {
  .list-single-img .wp .item {
    width: 100%;
    padding: 0;
    margin-bottom: 15px;
  }
  .list-single-img .wp .item:last-of-type {
    margin-bottom: 0;
  }
}

.list-single-img .wp .item__caption {
  text-align: center;
  font-size: 24px;
  font-size: 2.4rem;
  font-weight: bold;
  display: block;
  margin-top: 17px;
}

@media screen and (max-width: 990px) {
  .list-single-img .wp .item__caption {
    font-size: 22px;
    font-size: 2.2rem;
  }
}

@media screen and (max-width: 768px) {
  .list-single-img .wp .item__caption {
    font-size: 16px;
    font-size: 1.6rem;
    margin-top: 5px;
  }
}

.tab-blue-white {
  display: flex;
  align-items: center;
  background: #fff;
  border: 2px solid #346EB1;
  max-width: 1066px;
  margin: 0 auto 72px;
}

@media screen and (max-width: 768px) {
  .tab-blue-white {
    margin-bottom: 30px;
  }
}

.tab-blue-white span {
  width: 50%;
  font-size: 24px;
  font-size: 2.4rem;
  line-height: 1;
  font-weight: bold;
  text-align: center;
  padding: 11px 0;
}

@media screen and (max-width: 990px) {
  .tab-blue-white span {
    font-size: 20px;
    font-size: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .tab-blue-white span {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.5;
  }
}

.tab-blue-white span.blue {
  background: #346EB1;
  color: #fff;
  width: 49.3%;
}

.tab-blue-white span.white {
  width: calc(100% - 49.3%);
  color: #303030;
}

.tab-blue-white span.w100 {
  width: 100%;
}

.txt-guide {
  font-size: 24px;
  font-size: 2.4rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 37px;
}

@media screen and (max-width: 990px) {
  .txt-guide {
    font-size: 22px;
    font-size: 2.2rem;
  }
}

@media screen and (max-width: 768px) {
  .txt-guide {
    font-size: 16px;
    font-size: 1.6rem;
    margin-bottom: 15px;
  }
}

.btn-gradient {
  max-width: 416px;
  width: 100%;
  min-height: 75px;
  margin: 0 auto;
  background: #61bef7;
  background: linear-gradient(180deg, #61bef7 0%, #0f6296 100%);
  display: flex;
  align-items: center;
  font-size: 24px;
  font-size: 2.4rem;
  line-height: 1;
  font-weight: bold;
  padding-left: 20px;
  color: #fff;
  position: relative;
}

@media screen and (max-width: 768px) {
  .btn-gradient {
    font-size: 20px;
    font-size: 2rem;
    min-height: 50px;
  }
}

.btn-gradient:after {
  content: '';
  display: block;
  background: url("/img/service/drone/ico-right.png") no-repeat center;
  background-size: cover;
  width: 13px;
  height: 26px;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.tl {
  display: block;
}

@media screen and (max-width: 990px) {
  .tl {
    display: none;
  }
}

.txt-large {
  font-size: 20px;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.6;
  color: #4B4B4B;
  margin-bottom: 48px;
}

@media screen and (max-width: 768px) {
  .txt-large {
    font-size: 18px;
    font-size: 1.8rem;
    margin-bottom: 25px;
  }
}

.color-green {
  color: #4EB46E;
  font-weight: bold;
}

.bg-yellow {
  background: #FCF3E0;
}

.box-ttl__circle {
  font-size: 24px;
  font-size: 2.4rem;
  color: #fff;
  border-radius: 50%;
  background: #4EB46E;
  width: 112px;
  height: 112px;
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

@media screen and (max-width: 1440px) {
  .box-ttl__circle {
    width: 90px;
    height: 90px;
    padding: 10px;
    font-size: 20px;
    font-size: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .box-ttl__circle {
    width: 80px;
    height: 80px;
    padding: 8px;
    font-size: 20px;
    font-size: 2rem;
  }
}

.block-special {
  padding: 25px 25px;
  display: flex;
  align-items: flex-start;
}

@media screen and (max-width: 768px) {
  .block-special {
    padding: 15px;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 640px) {
  .block-special {
    padding: 50px 15px 15px;
    position: relative;
  }
}

.block-special .part-special01 {
  width: calc(58% - 7px);
  display: flex;
  align-items: flex-start;
  padding-right: 100px;
}

@media screen and (max-width: 1440px) {
  .block-special .part-special01 {
    padding-right: 7vw;
  }
}

@media screen and (max-width: 768px) {
  .block-special .part-special01 {
    padding-right: 0;
    width: 100%;
  }
}

@media screen and (max-width: 640px) {
  .block-special .part-special01 .box-ttl__circle {
    position: absolute;
    top: -38px;
    left: 0;
  }
}

.block-special .part-special01 .part-special__content {
  width: calc(100% - 112px);
  padding-left: 18px;
}

@media screen and (max-width: 1440px) {
  .block-special .part-special01 .part-special__content {
    width: calc( 100% - 90px);
    padding-left: 15px;
  }
}

@media screen and (max-width: 768px) {
  .block-special .part-special01 .part-special__content {
    width: calc( 100% - 80px);
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 640px) {
  .block-special .part-special01 .part-special__content {
    width: 100%;
    padding-left: 0;
  }
}

.block-special .part-special02 {
  width: calc(42% + 7px);
}

@media screen and (max-width: 768px) {
  .block-special .part-special02 {
    width: 100%;
    padding-left: 95px;
  }
}

@media screen and (max-width: 640px) {
  .block-special .part-special02 {
    padding-left: 0;
  }
}

.block-special .part-special__list {
  margin-bottom: 40px;
  padding: 10px 0 0 12px;
}

@media screen and (max-width: 768px) {
  .block-special .part-special__list {
    margin-bottom: 20px;
    padding: 0 0 0 15px;
  }
}

@media screen and (max-width: 640px) {
  .block-special .part-special__list {
    padding: 0;
  }
}

.block-special .part-special__list .item {
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.8;
  font-weight: bold;
  color: #4B4B4B;
  position: relative;
  padding-left: 15px;
  display: flex;
}

@media screen and (max-width: 768px) {
  .block-special .part-special__list .item {
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 1.6;
  }
}

.block-special .part-special__list .item:before {
  content: '';
  width: 12px;
  height: 12px;
  background: #4B4B4B;
  border-radius: 10px;
  display: inline-block;
  position: absolute;
  top: 11px;
  left: 0;
}

@media screen and (max-width: 768px) {
  .block-special .part-special__list .item:before {
    width: 8px;
    height: 8px;
    top: 9px;
  }
}

.block-special .part-special__content .ttl-comp08 {
  padding-left: 2px;
  margin-bottom: 2px;
  font-size: 20px;
  font-size: 2rem;
}

@media screen and (max-width: 768px) {
  .block-special .part-special__content .ttl-comp08 {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.block-special .part-special__process .img {
  width: 42%;
}

.block-special .part-special__process .img-icon {
  width: 16%;
  position: relative;
  top: -16px;
}

.part-flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.part-flex--start {
  align-items: flex-start;
}

.part-flex .img-icon {
  padding: 0 10px 0 15px;
}

.block-ttl-common {
  font-size: 28px;
  font-size: 2.8rem;
  color: #4EB46E;
  font-weight: bold;
  line-height: 1.43;
  text-align: center;
  margin-bottom: 17px;
}

@media screen and (max-width: 768px) {
  .block-ttl-common {
    font-size: 24px;
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 640px) {
  .block-ttl-common {
    font-size: 22px;
    font-size: 2.2rem;
  }
}

.sec-special {
  margin-bottom: 60px;
}

@media screen and (max-width: 768px) {
  .sec-special {
    margin-bottom: 50px;
  }
}

.part-introduce {
  margin-bottom: 50px;
}

@media screen and (max-width: 768px) {
  .part-introduce {
    margin-bottom: 25px;
  }
}

.part-introduce__ttl {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 2;
  font-weight: bold;
  border-bottom: 2px solid #4EB46E;
  margin-bottom: 14px;
  color: #4B4B4B;
}

@media screen and (max-width: 768px) {
  .part-introduce__ttl {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.part-introduce__list {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.part-introduce__list-img {
  width: calc( 40% - 20px);
  margin-right: 15px;
}

@media screen and (max-width: 640px) {
  .part-introduce__list-img {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .part-introduce__list-img img {
    width: 100%;
  }
}

.part-introduce__list-txt {
  width: calc( 60% + 5px);
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

@media screen and (max-width: 640px) {
  .part-introduce__list-txt {
    width: 100%;
  }
}

.part-introduce__list-txt .item-sub {
  width: calc(50% - 7.5px);
  margin-right: 15px;
  margin-bottom: 23px;
}

.part-introduce__list-txt .item-sub:nth-child(2n) {
  margin-right: 0;
}

.part-introduce__list-txt .item-sub img {
  margin-bottom: 12px;
}

.part-introduce__list-txt .item-sub .txt-basic {
  line-height: 1.75;
}

.part-introduce__list-txt .item-sub:nth-last-child(2), .part-introduce__list-txt .item-sub:last-child {
  margin-bottom: 0;
}

.part-introduce .txt-lg, .part-introduce .txt-businesspartner {
  margin-bottom: 29px;
}

.part-introduce .txt-basic--bold {
  display: inline-block;
  width: 100%;
}

.part-introduce .ttl-comp08 {
  color: #4B4B4B;
  margin-bottom: 18px;
  text-align: center;
  line-height: 2;
}

.box-ttlcomp {
  font-size: 20px;
  font-size: 2rem;
  font-weight: bold;
  color: #fff;
  line-height: 1.8;
  padding: 2px 20px 0px;
  margin-bottom: 19px;
  display: inline-block;
  min-width: 200px;
  text-align: center;
  background: #4EB46E;
}

@media screen and (max-width: 768px) {
  .box-ttlcomp {
    font-size: 18px;
    font-size: 1.8rem;
    padding: 2px 15px;
  }
}

@media screen and (max-width: 374px) {
  .box-ttlcomp {
    display: block;
  }
}

.box-ttlcomp--blue {
  background: #346EB1;
}

.block-performance {
  margin-bottom: 71px;
}

@media screen and (max-width: 768px) {
  .block-performance {
    margin-bottom: 35px;
    overflow: hidden;
  }
}

.block-performance:last-child {
  margin-bottom: 0;
}

.table-perfor {
  width: 100%;
}

.table-perfor th {
  font-weight: bold;
  border-top: 2px solid #707070;
  border-bottom: 2px solid #707070;
  border-right: 1px solid #707070;
  border-left: 1px solid #707070;
  color: #4B4B4B;
}

.table-perfor td {
  border: 1px solid #707070;
}

.table-perfor th, .table-perfor td {
  padding: 4px 6px 5px;
  text-align: center;
  line-height: 1.5;
}

.table-perfor th:first-child, .table-perfor td:first-child {
  border-left: none;
}

.table-perfor th:last-child, .table-perfor td:last-child {
  border-right: none;
}

.table-perfor--03 th, .table-perfor--03 td {
  width: 30%;
}

.table-perfor--03 th:last-child, .table-perfor--03 td:last-child {
  width: 40%;
}

.table-perfor--05 th, .table-perfor--05 td {
  width: calc(100% / 5);
}

.table-perfor--05 td > span {
  min-height: 56px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .table-perfor--05 td > span {
    height: 56px;
  }
}

.table-perfor--noborder th, .table-perfor--noborder td {
  border-left: none;
  border-right: none;
  text-align: left;
  padding: 1px 6px 0 0;
}

.table-perfor--noborder th:first-child, .table-perfor--noborder td:first-child {
  width: 31%;
}

.table-perfor--noborder th:nth-child(2n), .table-perfor--noborder td:nth-child(2n) {
  width: 56%;
}

@media screen and (max-width: 768px) {
  .table-perfor--noborder th:nth-child(2n), .table-perfor--noborder td:nth-child(2n) {
    width: 50%;
  }
}

.table-perfor--noborder th:last-child, .table-perfor--noborder td:last-child {
  width: 13%;
}

@media screen and (max-width: 768px) {
  .table-perfor--noborder th:last-child, .table-perfor--noborder td:last-child {
    width: 14%;
  }
}

.table-perfor--noborder td {
  padding: 14px 10px 5px 0;
}

@media screen and (max-width: 768px) {
  .table-perfor--noborder td {
    padding: 14px 15px 5px 0;
  }
}

.table-perfor--noborder th {
  border-top: none;
}

@media screen and (max-width: 560px) {
  .table-scroll {
    width: 100%;
    overflow-x: scroll;
  }
}

@media screen and (max-width: 560px) {
  .table-scroll .table-perfor--05 {
    width: 560px;
    overflow-x: scroll;
  }
}

.block-result .box-result {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.block-result .box-result .txt-large {
  margin-bottom: 0;
  font-weight: normal;
  color: #303030;
}

.block-result .box-result .box-ttlcomp {
  margin-bottom: 0;
  margin-right: 25px;
}

.block-result .txt-basic {
  line-height: 1.75;
}

.block-result .part-result {
  margin-bottom: 30px;
}

.block-result .part-result__img {
  width: 68%;
  text-align: center;
  margin-bottom: 12px;
}

.block-result .part-result__img img {
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .block-result .part-result__img {
    width: 100%;
  }
}

.block-result .part-result__des {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.block-result .part-result__des .box-ttlcomp {
  width: 100%;
  text-align: center;
  font-weight: normal;
  margin-bottom: 31px;
}

.block-result .part-result__des-img {
  width: 67%;
}

.block-result .part-result__des-img .list {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.block-result .part-result__des-img .list .item {
  width: calc( 50% - 7.5px);
  margin-right: 15px;
}

.block-result .part-result__des-img .list .item img {
  width: 100%;
  margin-bottom: 18px;
}

.block-result .part-result__des-img .list .item .txt-basic {
  text-align: center;
}

.block-result .part-result__des-img .list .item:nth-child(2n) {
  margin-right: 0;
}

@media screen and (max-width: 768px) {
  .block-result .part-result__des-img {
    width: 100%;
  }
}

.block-result .part-result__des-txt {
  width: 33%;
  padding-left: 27px;
}

@media screen and (max-width: 768px) {
  .block-result .part-result__des-txt {
    width: 100%;
    padding-left: 0;
  }
}

.part-method {
  padding: 30px 27px;
  margin-bottom: 47px;
}

@media screen and (max-width: 768px) {
  .part-method {
    padding: 15px;
    margin-bottom: 25px;
  }
}

.part-method .list {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.part-method .item {
  width: calc(33.33% - 16.67px);
  margin-right: 25px;
}

.part-method .item:nth-child(3n) {
  margin-right: 0;
}

@media screen and (max-width: 640px) {
  .part-method .item {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
    text-align: center;
  }
}

.part-method .item img {
  margin-bottom: 15px;
}

@media screen and (max-width: 640px) {
  .part-method .item img {
    width: 100%;
  }
}

.part-method .item .txt-basic {
  margin-bottom: 5px;
}

@media screen and (max-width: 640px) {
  .part-method .item .txt-basic {
    text-align: left;
  }
}

@media screen and (max-width: 640px) {
  .part-method .item .txt-smaller {
    text-align: left;
  }
}

.part-process__txt {
  width: 56%;
  padding-right: 20px;
}

@media screen and (max-width: 640px) {
  .part-process__txt {
    width: 100%;
    padding-right: 0;
    margin-bottom: 20px;
  }
}

.part-process__img {
  width: 44%;
  flex-wrap: nowrap;
}

.part-process__img .img-icon {
  padding: 0 18px;
}

@media screen and (max-width: 640px) {
  .part-process__img {
    width: 100%;
    padding: 0;
    justify-content: center;
  }
}

@media screen and (max-width: 640px) {
  .sec-future {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 768px) {
  .sec-future .txt-lg, .sec-future .txt-businesspartner {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 768px) {
  .sec-future .img {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 768px) {
  .sec-future .ttl-comp02 {
    margin: 0 auto 15px;
  }
}

.sec-result {
  margin-bottom: 91px;
}

@media screen and (max-width: 768px) {
  .sec-result {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 768px) {
  .sec-result .txt-basic {
    margin-bottom: 20px;
  }
}

.sec-itemfaq {
  margin-bottom: 100px;
}

@media screen and (max-width: 768px) {
  .sec-itemfaq {
    margin-bottom: 50px;
  }
}

.sec-itemfaq .block-ttl-common {
  margin-bottom: 27px;
}

.sec-itemfaq .list {
  margin-bottom: 80px;
}

@media screen and (max-width: 768px) {
  .sec-itemfaq .list {
    margin-bottom: 40px;
  }
}

.sec-itemfaq .list .item {
  margin-bottom: 40px;
}

.sec-itemfaq .list .item:last-child {
  margin-bottom: 0;
}

.sec-itemfaq .itemfaq {
  padding: 8px 10px 8px 20px;
  border: 2px solid #4EB46E;
  margin-bottom: 8px;
}

.sec-itemfaq .itemfaq:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 768px) {
  .sec-itemfaq .itemfaq {
    padding: 10px;
  }
}

.sec-itemfaq .itemfaq__ttl {
  font-size: 28px;
  font-size: 2.8rem;
  font-weight: bold;
  line-height: 1.43;
  height: 60px;
  width: 60px;
  color: #fff;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .sec-itemfaq .itemfaq__ttl {
    height: 45px;
    width: 45px;
    font-size: 26px;
    font-size: 2.6rem;
  }
}

.sec-itemfaq .itemfaq .txt-faqlg {
  width: calc( 100% - 60px);
  padding-left: 27px;
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.6;
  color: #303030;
}

@media screen and (max-width: 768px) {
  .sec-itemfaq .itemfaq .txt-faqlg {
    width: calc(100% - 45px);
    padding-left: 15px;
    font-size: 18px;
    font-size: 1.8rem;
  }
}

.sec-itemfaq .itemfaq__q {
  background: #D8FAD2;
}

.sec-itemfaq .itemfaq__q .itemfaq__ttl {
  background: #4EB46E;
}

.sec-itemfaq .itemfaq__a {
  background: #FCF3E0;
}

.sec-itemfaq .itemfaq__a .itemfaq__ttl {
  background: #118B37;
}

.sec-introduce {
  margin-bottom: 119px;
}

@media screen and (max-width: 768px) {
  .sec-introduce {
    margin-bottom: 50px;
  }
}

.box-intro-nipearth {
  display: flex;
  align-items: center;
  padding: 0 200px 27px 150px;
  border-bottom: 10px solid rgba(52, 110, 177, 0.4);
  margin-bottom: 54px;
}

@media screen and (max-width: 990px) {
  .box-intro-nipearth {
    padding: 0 60px 30px 60px;
  }
}

@media screen and (max-width: 768px) {
  .box-intro-nipearth {
    flex-wrap: wrap;
    padding: 0 0 20px 0;
    margin-bottom: 30px;
  }
}

.box-intro-nipearth .ttl {
  width: calc(100% - 213px);
  font-size: 32px;
  font-size: 3.2rem;
  font-weight: bold;
  line-height: 1;
  color: #346EB1;
}

@media screen and (max-width: 990px) {
  .box-intro-nipearth .ttl {
    font-size: 28px;
    font-size: 2.8rem;
    line-height: 1.5;
  }
}

@media screen and (max-width: 768px) {
  .box-intro-nipearth .ttl {
    width: 100%;
    font-size: 24px;
    font-size: 2.4rem;
    text-align: center;
  }
}

.box-intro-nipearth .img {
  width: 213px;
}

@media screen and (max-width: 768px) {
  .box-intro-nipearth .img {
    margin: 0 auto;
  }
}

.box-special {
  display: flex;
  margin-bottom: 54px;
}

@media screen and (max-width: 768px) {
  .box-special {
    flex-wrap: wrap;
    margin-bottom: 30px;
  }
}

.box-special .box-circle {
  width: 112px;
  height: 112px;
  border-radius: 50%;
  background: #346EB1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-size: 2.4rem;
  font-weight: bold;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .box-special .box-circle {
    font-size: 20px;
    font-size: 2rem;
    margin: 0 auto 20px;
  }
}

.box-special .box-circle--green {
  background: #4EB46E;
}

.box-special .txt-dots {
  width: calc(100% - 112px);
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.8;
  padding-left: 16px;
  position: relative;
  top: -6px;
}

@media screen and (max-width: 1024px) {
  .box-special .txt-dots {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 768px) {
  .box-special .txt-dots {
    width: 100%;
    font-size: 14px;
    font-size: 1.4rem;
    padding-left: 0;
    top: 0;
  }
}

.box-special .txt-dots .dots-txt {
  margin-top: 30px;
  line-height: 1.75;
}

.box-special .txt-dots .dots-txt .txt-lg, .box-special .txt-dots .dots-txt .txt-businesspartner {
  font-weight: bold;
  margin-bottom: 5px;
}

.box-main-uses {
  margin-bottom: 70px;
}

@media screen and (max-width: 768px) {
  .box-main-uses {
    margin-bottom: 30px;
  }
}

.box-main-uses .tag-blue {
  margin-bottom: 6px;
}

@media screen and (max-width: 768px) {
  .box-main-uses .tag-blue {
    margin-bottom: 15px;
  }
}

.box-specifications .tag-blue {
  margin-bottom: 42px;
}

@media screen and (max-width: 768px) {
  .box-specifications .tag-blue {
    margin-bottom: 15px;
  }
}

.box-specifications .list-item {
  display: flex;
  margin-bottom: 45px;
}

@media screen and (max-width: 990px) {
  .box-specifications .list-item {
    margin: 0 -10px 45px -10px;
  }
}

@media screen and (max-width: 768px) {
  .box-specifications .list-item {
    margin: 0 auto 30px;
    flex-wrap: wrap;
  }
}

.box-specifications .list-item .item {
  width: calc(100% - 417px);
  display: flex;
  align-items: center;
  padding-right: 130px;
}

@media screen and (max-width: 990px) {
  .box-specifications .list-item .item {
    width: 55%;
    padding: 0 10px;
  }
}

@media screen and (max-width: 768px) {
  .box-specifications .list-item .item {
    width: 100%;
    padding-right: 0;
    margin-bottom: 20px;
  }
  .box-specifications .list-item .item:last-of-type {
    margin-bottom: 0;
  }
}

.box-specifications .list-item .item:nth-of-type(2n) {
  width: 417px;
  padding-right: 0;
}

@media screen and (max-width: 990px) {
  .box-specifications .list-item .item:nth-of-type(2n) {
    width: 45%;
  }
}

@media screen and (max-width: 768px) {
  .box-specifications .list-item .item:nth-of-type(2n) {
    width: 100%;
    padding-right: 0;
  }
}

.box-specifications .list-item .item__name {
  max-width: 200px;
  width: 100%;
  height: 78px;
  border: 3px solid #346EB1;
  border-radius: 10px;
  font-size: 36px;
  font-size: 3.6rem;
  font-weight: bold;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #346EB1;
  margin-right: 15px;
}

@media screen and (max-width: 990px) {
  .box-specifications .list-item .item__name {
    font-size: 28px;
    font-size: 2.8rem;
    max-width: 150px;
    height: 50px;
  }
}

@media screen and (max-width: 768px) {
  .box-specifications .list-item .item__name {
    font-size: 24px;
    font-size: 2.4rem;
    margin-right: 10px;
    max-width: 150px;
    height: 50px;
  }
}

.box-specifications .list-item .item__arrow {
  width: 70px;
  margin-right: 13px;
}

@media screen and (max-width: 990px) {
  .box-specifications .list-item .item__arrow {
    margin-right: 10px;
    width: 50px;
  }
}

.box-specifications .list-item .item__des {
  width: calc(100% - 270px);
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: bold;
  color: #346EB1;
  line-height: 1.6;
}

@media screen and (max-width: 990px) {
  .box-specifications .list-item .item__des {
    width: calc(100% - 200px);
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.box-chart {
  margin-bottom: 73px;
}

@media screen and (max-width: 768px) {
  .box-chart {
    margin-bottom: 30px;
  }
}

.box-chart .tag-blue {
  margin-bottom: 18px;
}

@media screen and (max-width: 768px) {
  .box-chart .tag-blue {
    margin-bottom: 15px;
  }
}

.box-chart-wp {
  display: flex;
  margin-right: -110px;
}

@media screen and (max-width: 1250px) {
  .box-chart-wp {
    margin-right: 0;
  }
}

@media screen and (max-width: 768px) {
  .box-chart-wp {
    flex-wrap: wrap;
    margin-right: 0;
  }
}

.box-chart-wp .box-table02 {
  width: 632px;
}

@media screen and (max-width: 1200px) {
  .box-chart-wp .box-table02 {
    width: 50%;
  }
}

@media screen and (max-width: 768px) {
  .box-chart-wp .box-table02 {
    width: 100%;
  }
}

.box-chart-wp .img-chart {
  width: 524px;
  position: relative;
  right: -18px;
  top: 3px;
}

@media screen and (max-width: 1200px) {
  .box-chart-wp .img-chart {
    width: 50%;
    position: static;
  }
}

@media screen and (max-width: 768px) {
  .box-chart-wp .img-chart {
    text-align: center;
    width: 100%;
    position: static;
  }
}

.box-internal {
  margin-bottom: 70px;
}

@media screen and (max-width: 768px) {
  .box-internal {
    margin-bottom: 30px;
  }
}

.box-internal .tag-blue {
  margin-bottom: 18px;
}

@media screen and (max-width: 768px) {
  .box-internal .tag-blue {
    margin-bottom: 15px;
  }
}

.box-internal .item {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 640px) {
  .box-internal .item {
    flex-wrap: wrap;
  }
}

.box-internal .item__img {
  width: 350px;
}

@media screen and (max-width: 640px) {
  .box-internal .item__img {
    margin: 0 auto 15px;
  }
}

.box-internal .item__content {
  width: calc(100% - 350px);
  padding-left: 41px;
  font-size: 20px;
  font-size: 2rem;
  line-height: 2;
}

@media screen and (max-width: 768px) {
  .box-internal .item__content {
    padding-left: 20px;
    font-size: 16px;
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 640px) {
  .box-internal .item__content {
    width: 100%;
    padding-left: 0;
  }
}

.box-instructions .tag-blue {
  margin-bottom: 18px;
}

@media screen and (max-width: 768px) {
  .box-instructions .tag-blue {
    margin-bottom: 15px;
  }
}

.box-instructions .list-steps-instructions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 39px;
}

@media screen and (max-width: 990px) {
  .box-instructions .list-steps-instructions {
    margin-bottom: 30px;
    flex-wrap: wrap;
    margin: 0 -10px;
  }
}

@media screen and (max-width: 990px) {
  .box-instructions .list-steps-instructions .item {
    margin-bottom: 25px;
    width: 33.33% !important;
    padding: 0 10px;
  }
}

@media screen and (max-width: 990px) {
  .box-instructions .list-steps-instructions .item {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 640px) {
  .box-instructions .list-steps-instructions .item {
    width: 50% !important;
  }
}

.box-instructions .list-steps-instructions .item__ttl {
  display: flex;
  align-items: center;
  margin-bottom: 11px;
}

@media screen and (max-width: 990px) {
  .box-instructions .list-steps-instructions .item__ttl {
    justify-content: center;
  }
}

.box-instructions .list-steps-instructions .item__ttl .number {
  width: 30px;
  height: 30px;
  background: #346EB1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-size: 2rem;
  font-weight: bold;
  color: #fff;
  border-radius: 50%;
  margin-right: 10px;
}

@media screen and (max-width: 768px) {
  .box-instructions .list-steps-instructions .item__ttl .number {
    width: 25px;
    height: 25px;
    font-size: 16px;
    font-size: 1.6rem;
    margin-right: 5px;
  }
}

.box-instructions .list-steps-instructions .item__ttl .txt {
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: bold;
  color: #346EB1;
}

@media screen and (max-width: 768px) {
  .box-instructions .list-steps-instructions .item__ttl .txt {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.box-instructions .list-steps-instructions .item__img {
  text-align: center;
}

.box-instructions .list-steps-instructions .item:nth-of-type(1) {
  width: 11.11%;
}

.box-instructions .list-steps-instructions .item:nth-of-type(2) {
  width: 12.61%;
}

.box-instructions .list-steps-instructions .item:nth-of-type(3) {
  width: 17.6%;
}

.box-instructions .list-steps-instructions .item:nth-of-type(4) {
  width: 13.18%;
}

.box-instructions .list-steps-instructions .item:nth-of-type(5) {
  width: 12.71%;
}

.box-instructions .list-steps-instructions .item:nth-of-type(6) {
  width: 12.71%;
}

.box-instructions .txt-contact {
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1.6;
  margin-bottom: 48px;
}

@media screen and (max-width: 768px) {
  .box-instructions .txt-contact {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.5;
    margin-bottom: 30px;
  }
}

.txt-number {
  font-size: 20px;
  font-size: 2rem;
  line-height: 2;
  margin-bottom: 40px;
}

@media screen and (max-width: 768px) {
  .txt-number {
    font-size: 16px;
    font-size: 1.6rem;
    margin-bottom: 20px;
    line-height: 1.6;
  }
}

.txt-table {
  font-size: 16px;
  font-size: 1.6rem;
  margin-bottom: 10px;
  display: block;
}

@media screen and (max-width: 768px) {
  .txt-table {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.box-table {
  margin-bottom: 68px;
}

@media screen and (max-width: 768px) {
  .box-table {
    margin-bottom: 30px;
  }
}

.box-table table {
  width: 100%;
  max-width: 833px;
}

.box-table table tr th {
  font-weight: normal;
  width: 455px;
  background: #BBD6F5;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.6;
  padding-left: 13px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-bottom: 4px solid #fff;
  border-right: 4px solid #fff;
}

@media screen and (max-width: 768px) {
  .box-table table tr th {
    width: auto;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.5;
    padding-left: 5px;
    padding-right: 5px;
    border-width: 2px;
  }
}

.box-table table tr td {
  width: 115px;
  background: #F2F0ED;
  border-bottom: 4px solid #fff;
  border-right: 4px solid #fff;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.6;
  padding-left: 10px;
}

@media screen and (max-width: 768px) {
  .box-table table tr td {
    width: auto;
    font-size: 14px;
    font-size: 1.4rem;
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
    border-width: 2px;
  }
}

.box-table02 table {
  border: 1px solid #303030;
  width: 100%;
}

.box-table02 table tr td {
  border-bottom: 1px solid #303030;
  border-right: 1px solid #303030;
  background: #fff;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.6;
  padding: 10px 13px;
}

@media screen and (max-width: 768px) {
  .box-table02 table tr td {
    font-size: 14px;
    font-size: 1.4rem;
    padding: 5px;
  }
}

.box-table02 table tr:first-of-type td {
  text-align: center;
  padding: 13px 0;
}

@media screen and (max-width: 768px) {
  .box-table02 table tr:first-of-type td {
    padding: 8px 5px;
  }
}

.box-table02 table tr:first-of-type td:first-of-type {
  width: 48.25%;
}

.box-table02 table tr:first-of-type td:nth-of-type(2) {
  width: 18.67%;
}

.box-table02 table tr:first-of-type td:last-of-type {
  width: calc(100% - 48.25% - 18.67%);
}

.box-table02 table tr:nth-of-type(2) td:first-of-type {
  width: 15.82%;
}

.box-table02 table tr:nth-of-type(2) td:nth-of-type(2) {
  width: 32.59%;
}

.box-table02 table tr:nth-of-type(2) td:nth-of-type(3) {
  width: 18.67%;
}

.box-table02 table tr:nth-of-type(2) td:nth-of-type(4) {
  width: 17.24%;
}

.box-table02 table tr:nth-of-type(2) td:last-of-type {
  width: 15.82%;
}

.txt-dots {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.8;
}

@media screen and (max-width: 768px) {
  .txt-dots {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.6;
  }
}

.tag-blue {
  max-width: 200px;
  min-height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-size: 2rem;
  font-weight: bold;
  color: #fff;
  line-height: 1;
  background: #346EB1;
}

@media screen and (max-width: 768px) {
  .tag-blue {
    font-size: 18px;
    font-size: 1.8rem;
    min-height: 30px;
  }
}

.mb100 {
  margin-bottom: 100px !important;
}

@media screen and (max-width: 768px) {
  .mb100 {
    margin-bottom: 50px !important;
  }
}

.sec-introbrand {
  margin-bottom: 100px;
}

@media screen and (max-width: 768px) {
  .sec-introbrand {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 768px) {
  .block-introbrand .inner {
    padding: 0;
  }
}

.part-introbrand {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-wrap: wrap;
}

@media screen and (max-width: 1024px) {
  .part-introbrand {
    align-items: flex-start;
  }
}

.part-introbrand__txt {
  width: 61%;
  padding-right: 15px;
}

@media screen and (max-width: 640px) {
  .part-introbrand__txt {
    width: 100%;
    margin-bottom: 20px;
    padding-right: 0;
  }
}

.part-introbrand__txt .txt-lg, .part-introbrand__txt .txt-businesspartner {
  margin-bottom: 20px;
}

.part-introbrand__txt .txt-lg:last-child, .part-introbrand__txt .txt-businesspartner:last-child {
  margin-bottom: 0;
}

.part-introbrand__txt .link-target a {
  font-weight: bold;
  color: #14137E;
  word-break: break-all;
}

@supports (-ms-ime-align: auto) {
  .part-introbrand__txt .link-target a {
    word-wrap: break-word;
  }
}

@media screen and (max-width: 374px) {
  .part-introbrand__txt .link-target a {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.part-introbrand__logo {
  margin: 0 auto 25px;
  text-align: center;
}

@media screen and (max-width: 640px) {
  .part-introbrand__logo {
    width: 50%;
    margin: 0 auto 25px;
  }
}

.part-introbrand__img {
  width: 39%;
}

.part-introbrand__img img {
  margin-bottom: 20px;
}

@media screen and (max-width: 640px) {
  .part-introbrand__img {
    width: 100%;
    text-align: center;
  }
}

.part-introbrand--modify .link-target {
  margin: 0 auto 75px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .part-introbrand--modify .link-target {
    margin: 0 auto 25px;
  }
}

.part-introbrand__logo--modify {
  margin: 0 auto 75px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .part-introbrand__logo--modify {
    margin: 0 auto 25px;
  }
}

.sec-resistance {
  padding-bottom: 145px;
}

@media screen and (max-width: 768px) {
  .sec-resistance {
    padding-bottom: 65px;
  }
}

.sec-resistance .block-list {
  margin: 45px auto 100px;
  padding: 25px 0;
  background: #E3FEFF;
}

@media screen and (min-width: 769px) {
  .sec-resistance .block-list {
    width: 100%;
    max-width: 632px;
  }
}

@media screen and (max-width: 768px) {
  .sec-resistance .block-list {
    margin: 15px -16px 25px;
    padding: 15px 16px;
  }
}

.sec-resistance .block-list .list {
  color: #346EB1;
  font-size: 20px;
  font-size: 2rem;
  max-width: 420px;
  margin: 0 auto;
  font-weight: bold;
  line-height: 1.8;
}

@media screen and (max-width: 768px) {
  .sec-resistance .block-list .list {
    font-size: 16px;
    font-size: 1.6rem;
    max-width: 100%;
  }
}

.sec-resistance .block-flow {
  margin-bottom: 80px;
}

@media screen and (max-width: 768px) {
  .sec-resistance .block-flow {
    margin-bottom: 65px;
  }
}

.sec-resistance .block-flow__ttl {
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  font-size: 1.8rem;
  margin-bottom: 30px;
  line-height: 1.7;
}

@media screen and (max-width: 768px) {
  .sec-resistance .block-flow__ttl {
    font-size: 16px;
    font-size: 1.6rem;
    margin-bottom: 25px;
    text-align: left;
  }
}

.sec-resistance .block-flow .item {
  position: relative;
  display: block;
  background: #FCF3E0;
  margin: 0 -16px;
  padding: 25px 16px 15px 75px;
}

@media screen and (min-width: 769px) {
  .sec-resistance .block-flow .item {
    display: none !important;
  }
}

.sec-resistance .block-flow .item:nth-of-type(2n) {
  background: #E0D7CC;
}

.sec-resistance .block-flow .item::after, .sec-resistance .block-flow .item::before {
  content: "";
  display: block;
  position: absolute;
}

.sec-resistance .block-flow .item::before {
  width: 22px;
  height: calc(100% - 58px);
  top: 25px;
  left: 24px;
  background-color: #438BDE;
}

.sec-resistance .block-flow .item::after {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 28px 19.5px 0 19.5px;
  border-color: #438BDE transparent transparent transparent;
  left: 15px;
  bottom: 15px;
}

.sec-resistance .block-flow .item__ttl {
  font-weight: bold;
  font-size: 20px;
  font-size: 2rem;
  margin-bottom: 5px;
}

.sec-resistance .block-flow .item__txt {
  font-size: 16px;
  font-size: 1.6rem;
  margin-bottom: 10px;
}

.sec-resistance .block-flow .item__txt--sm {
  text-align: center;
  font-size: 14px;
  font-size: 1.4rem;
  margin: 10px 0 5px;
}

.sec-resistance .block-flow .item img {
  width: 100%;
  margin-bottom: 15px;
}

.sec-resistance .block-flow .item__list {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.sec-resistance .block-flow .item__list li {
  width: 100%;
  height: 67px;
  color: #FFF;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-size: 1.6rem;
  text-align: center;
  line-height: 1.2;
}

.sec-resistance .block-flow .item__list--02 li {
  width: 48%;
}

.sec-resistance .block-flow .item__list--03 li {
  width: 32%;
}

.sec-resistance .block-flow .item__list--blue li {
  background: #438BDE;
}

.sec-resistance .block-flow .item__list--brown li {
  background: #4E3012;
}

.sec-resistance .block-flow .item__list--green li {
  background: #227114;
}

.sec-resistance .block-flow .item--02::before {
  background-color: #56D888;
}

.sec-resistance .block-flow .item--02::after {
  border-color: #56D888 transparent transparent transparent;
}

.sec-resistance .block-flow .item--03::before {
  background-color: #FFD750;
}

.sec-resistance .block-flow .item--03::after {
  border-color: #FFD750 transparent transparent transparent;
}

.sec-resistance .block-flow .item--04::before {
  background-color: #FF7F50;
}

.sec-resistance .block-flow .item--04::after {
  border-color: #FF7F50 transparent transparent transparent;
}

.sec-resistance .block-flow .item--05::before, .sec-resistance .block-flow .item--05::after {
  display: none;
}

.sec-resistance .block-flow .item--05 img {
  width: 90%;
  margin-bottom: 0;
}

.sec-resistance .block-intro .item {
  max-width: 1064px;
  margin: 0 auto 60px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .sec-resistance .block-intro .item {
    margin-bottom: 35px;
  }
}

.sec-resistance .block-intro .item:last-of-type {
  margin-bottom: 0;
}

.sec-resistance .block-intro .item--05 {
  align-items: flex-start;
}

@media screen and (min-width: 769px) {
  .sec-resistance .block-intro .item--05 .item__txt {
    padding-top: 10px;
  }
}

.sec-resistance .block-intro .item__ttl {
  width: 100%;
  border-bottom: 3px solid #346EB1;
  color: #346EB1;
  font-weight: bold;
  font-size: 24px;
  font-size: 2.4rem;
  margin-bottom: 15px;
}

@media screen and (max-width: 768px) {
  .sec-resistance .block-intro .item__ttl {
    font-size: 16px;
    font-size: 1.6rem;
    padding-bottom: 3px;
    margin-bottom: 10px;
  }
}

.sec-resistance .block-intro .item__img {
  width: 49.25%;
}

@media screen and (max-width: 768px) {
  .sec-resistance .block-intro .item__img {
    width: 100%;
    margin-bottom: 15px;
  }
  .sec-resistance .block-intro .item__img img {
    width: 100%;
  }
}

.sec-resistance .block-intro .item__txt {
  width: 50.75%;
  padding-left: 20px;
}

@media screen and (max-width: 768px) {
  .sec-resistance .block-intro .item__txt {
    width: 100%;
    padding-left: 0;
  }
}

.sec-resistance .block-intro .item__txt p {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.7;
}

@media screen and (max-width: 768px) {
  .sec-resistance .block-intro .item__txt p {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.sec-resistance .block-intro .item__txt table {
  margin-top: 30px;
  width: 100%;
  font-size: 16px;
  font-size: 1.6rem;
  border: #707070 1px solid;
}

@media screen and (max-width: 768px) {
  .sec-resistance .block-intro .item__txt table {
    margin-top: 20px;
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.sec-resistance .block-intro .item__txt table th, .sec-resistance .block-intro .item__txt table td {
  padding: 15px;
  line-height: 1.7;
}

@media screen and (max-width: 768px) {
  .sec-resistance .block-intro .item__txt table th, .sec-resistance .block-intro .item__txt table td {
    display: block;
    width: 100%;
    padding: 5px 10px;
  }
}

.sec-resistance .block-intro .item__txt table th {
  width: 100px;
  border-right: #707070 1px solid;
  text-align: center;
  font-weight: normal;
}

@media screen and (max-width: 768px) {
  .sec-resistance .block-intro .item__txt table th {
    width: 100%;
    border-right: none;
    border-bottom: #707070 1px solid;
  }
}

@media screen and (max-width: 768px) {
  .sec-resistance .block-intro .item__txt table td {
    padding: 10px;
  }
}

.sec-resistance .block-intro .item .btn-comp01 {
  margin: 25px 0 0;
  float: right;
  background: #FF8D08;
}

@media screen and (max-width: 768px) {
  .sec-resistance .block-intro .item .btn-comp01 {
    margin-top: 15px;
  }
}

.sec-resistance-page {
  padding-bottom: 90px;
}

@media screen and (max-width: 768px) {
  .sec-resistance-page {
    padding-bottom: 60px;
  }
}

.sec-resistance-page .box-equipment-inspection {
  margin-bottom: 50px;
}

@media screen and (max-width: 768px) {
  .sec-resistance-page .box-equipment-inspection {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 768px) {
  .sec-resistance-page .box-equipment-inspection .box-left .txt-basic {
    margin-bottom: 0;
  }
}

.sec-resistance-page .block-intro {
  max-width: 1096px;
  margin: 0 auto;
}

.sec-resistance-page .block-intro__txt {
  font-size: 16px;
  font-size: 1.6rem;
  margin-bottom: 30px;
  line-height: 1.75;
}

@media screen and (max-width: 768px) {
  .sec-resistance-page .block-intro__txt {
    font-size: 14px;
    font-size: 1.4rem;
    margin-bottom: 15px;
  }
}

.sec-resistance-page .block-intro__img {
  margin-bottom: 45px;
}

@media screen and (max-width: 768px) {
  .sec-resistance-page .block-intro__img {
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 768px) {
  .sec-resistance-page .block-intro__img img {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .sec-resistance-page .block-intro .ttl-comp01 > span {
    display: inline-block;
    position: relative;
    background-image: none;
    line-height: 1.7;
    padding: 0 20px;
  }
  .sec-resistance-page .block-intro .ttl-comp01 > span::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: 10px;
    height: 10px;
    width: 100%;
    background: #FF8D08;
    opacity: 0.5;
    z-index: -1;
  }
}

.sec-resistance-page .block-intro .item {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .sec-resistance-page .block-intro .item {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 768px) {
  .sec-resistance-page .block-intro .item__img {
    margin-bottom: 25px;
  }
}

.sec-resistance-page .block-intro .item__img img {
  width: 100%;
}

.sec-resistance-page .block-intro .item__img .caption {
  display: block;
  font-size: 14px;
  font-size: 1.4rem;
  padding-top: 10px;
}

.sec-resistance-page .block-intro .item__txt p {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.7;
}

@media screen and (max-width: 768px) {
  .sec-resistance-page .block-intro .item__txt p {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.sec-resistance-page .block-intro .more-btn {
  margin-top: 80px;
}

@media screen and (max-width: 768px) {
  .sec-resistance-page .block-intro .more-btn {
    margin-top: 0;
  }
}

.sec-resistance-page .block-intro .more-btn .btn-comp01 {
  background-color: #FF8D08;
}

.page-service_resistance_about_index .sec-resistance-page .block-intro {
  max-width: 1202px;
}

.page-service_resistance_about_index .sec-resistance-page .block-intro .item--a01 .item__img {
  width: 42.56%;
}

@media screen and (min-width: 769px) {
  .page-service_resistance_about_index .sec-resistance-page .block-intro .item--a01 .item__img {
    order: 2;
  }
}

@media screen and (max-width: 768px) {
  .page-service_resistance_about_index .sec-resistance-page .block-intro .item--a01 .item__img {
    width: 100%;
  }
}

.page-service_resistance_about_index .sec-resistance-page .block-intro .item--a01 .item__txt {
  width: 57.44%;
  padding-right: 20px;
}

@media screen and (min-width: 769px) {
  .page-service_resistance_about_index .sec-resistance-page .block-intro .item--a01 .item__txt {
    order: 1;
  }
}

@media screen and (max-width: 768px) {
  .page-service_resistance_about_index .sec-resistance-page .block-intro .item--a01 .item__txt {
    width: 100%;
    padding: 0;
  }
}

.page-service_resistance_about_index .sec-resistance-page .block-intro .item--a02 .item__img {
  width: 54%;
}

@media screen and (max-width: 768px) {
  .page-service_resistance_about_index .sec-resistance-page .block-intro .item--a02 .item__img {
    width: 100%;
  }
}

.page-service_resistance_about_index .sec-resistance-page .block-intro .item--a02 .item__txt {
  width: 46%;
  padding-left: 20px;
}

@media screen and (max-width: 768px) {
  .page-service_resistance_about_index .sec-resistance-page .block-intro .item--a02 .item__txt {
    width: 100%;
    padding: 0;
  }
}

.intro-head {
  padding-bottom: 28px;
  border-bottom: 10px solid rgba(78, 180, 110, 0.41);
  margin-bottom: 24px;
  color: #4EB46E;
}

@media screen and (max-width: 768px) {
  .intro-head {
    border-width: 5px;
  }
}

.intro-head__wrap {
  display: flex;
}

@media screen and (max-width: 768px) {
  .intro-head__wrap {
    flex-wrap: wrap;
  }
}

.intro-head__ttl {
  font-size: 32px;
  font-size: 3.2rem;
  font-weight: bold;
  line-height: 1.56625;
  padding-right: 20px;
}

@media screen and (max-width: 990px) {
  .intro-head__ttl {
    font-size: 28px;
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 768px) {
  .intro-head__ttl {
    font-size: 24px;
    font-size: 2.4rem;
    text-align: center;
    margin-bottom: 10px;
    width: 100%;
  }
}

.intro-head__img {
  width: 53.5%;
  margin-left: auto;
  padding-top: 5px;
}

@media screen and (max-width: 768px) {
  .intro-head__img {
    width: 100%;
    text-align: center;
  }
}

.intro-head--blue {
  color: #346EB1;
  border-color: rgba(52, 110, 177, 0.41);
}

.intro-head__group {
  display: flex;
  justify-content: space-between;
  width: 658px;
  padding-top: 30px;
}

@media screen and (max-width: 1200px) {
  .intro-head__group {
    width: 560px;
  }
}

@media screen and (max-width: 990px) {
  .intro-head__group {
    width: 100%;
    max-width: 560px;
    padding-top: 0;
    margin: 0 auto 15px;
  }
}

@media screen and (max-width: 768px) {
  .intro-head__group {
    max-width: 450px;
  }
}

@media screen and (max-width: 640px) {
  .intro-head__group {
    flex-wrap: wrap;
    max-width: 350px;
  }
}

.intro-head__list {
  font-size: 28px;
  font-size: 2.8rem;
  line-height: 1.29;
  font-weight: bold;
}

@media screen and (max-width: 1200px) {
  .intro-head__list {
    font-size: 24px;
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 768px) {
  .intro-head__list {
    font-size: 20px;
    font-size: 2rem;
  }
}

@media screen and (max-width: 374px) {
  .intro-head__list {
    font-size: 18px;
    font-size: 1.8rem;
    width: 100%;
  }
}

.intro-head__list li {
  margin-bottom: 13px;
}

.intro-head__list li:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 768px) {
  .intro-head__list:first-child li:last-child {
    margin-bottom: 13px;
  }
}

.sec-silicon {
  margin-bottom: 100px;
}

@media screen and (max-width: 768px) {
  .sec-silicon {
    margin-bottom: 50px;
  }
}

.silicon__des {
  font-size: 20px;
  font-size: 2rem;
  line-height: 2;
  text-align: center;
  margin-bottom: 55px;
}

@media screen and (max-width: 990px) {
  .silicon__des {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 768px) {
  .silicon__des {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 374px) {
  .silicon__des {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.box-lineup {
  margin-bottom: 53px;
}

@media screen and (max-width: 768px) {
  .box-lineup {
    margin-bottom: 20px;
  }
}

.lineup-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}

@media screen and (max-width: 990px) {
  .lineup-list {
    margin: 0 -5px;
  }
}

@media screen and (max-width: 374px) {
  .lineup-list {
    margin: 0;
  }
}

.lineup-list__item {
  width: 25%;
  padding: 0 10px;
  margin-bottom: 30px;
  position: relative;
}

@media screen and (max-width: 990px) {
  .lineup-list__item {
    padding: 0 5px;
  }
}

@media screen and (max-width: 768px) {
  .lineup-list__item {
    width: 50%;
  }
}

@media screen and (max-width: 374px) {
  .lineup-list__item {
    width: 100%;
    padding: 0;
  }
}

.lineup-list__item .link-fixed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.lineup-list__img {
  margin-bottom: 9px;
}

@media screen and (max-width: 768px) {
  .lineup-list__img img {
    width: 100%;
  }
}

.lineup-list__img iframe {
  width: 100% !important;
}

.lineup-list__content {
  line-height: 1.75;
}

.lineup-list__ttl {
  font-size: 20px;
  font-size: 2rem;
  color: #4EB46E;
  font-weight: bold;
  margin-bottom: 7px;
}

@media screen and (max-width: 990px) {
  .lineup-list__ttl {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 768px) {
  .lineup-list__ttl {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.lineup-list__des {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 2;
  margin-bottom: 8px;
}

@media screen and (max-width: 768px) {
  .lineup-list__des {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

@media only screen and (min-width: 1070px) {
  .lineup-list .br-link {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .lineup-list .br-link {
    display: none;
  }
}

@media only screen and (max-width: 550px) {
  .lineup-list .br-link {
    display: inline-block;
  }
}

@media screen and (max-width: 374px) {
  .lineup-list .br-link {
    display: none;
  }
}

.box-gel {
  margin-bottom: 27px;
}

@media screen and (max-width: 768px) {
  .box-gel {
    margin-bottom: 50px;
  }
}

.gel-flex {
  display: flex;
  flex-wrap: wrap;
}

.gel-flex__left {
  padding-right: 10px;
  width: 648px;
  margin-bottom: 0;
}

@media screen and (max-width: 1024px) {
  .gel-flex__left {
    width: 588px;
  }
}

@media only screen and (max-width: 850px) {
  .gel-flex__left {
    width: 100%;
    margin-bottom: 15px;
  }
}

.gel-flex__img {
  width: calc(100% - 648px);
}

@media screen and (max-width: 1024px) {
  .gel-flex__img {
    width: calc(100% - 588px);
  }
}

@media only screen and (max-width: 850px) {
  .gel-flex__img {
    width: 100%;
    text-align: center;
  }
}

.box-instructions {
  margin-bottom: 46px;
}

.instructions-flex {
  display: flex;
  flex-wrap: wrap;
}

.instructions-flex__left {
  width: 360px;
  padding-right: 10px;
}

@media screen and (max-width: 990px) {
  .instructions-flex__left {
    width: 300px;
  }
}

@media screen and (max-width: 768px) {
  .instructions-flex__left {
    width: 100%;
    margin-bottom: 15px;
    padding-right: 0;
  }
}

.instructions-flex__left .img {
  margin-bottom: 5px;
}

.instructions-flex__info {
  margin-bottom: 10px;
}

.instructions-flex__img {
  width: calc(100% - 360px);
}

@media screen and (max-width: 990px) {
  .instructions-flex__img {
    width: calc(100% - 300px);
  }
}

@media screen and (max-width: 768px) {
  .instructions-flex__img {
    width: 100%;
  }
}

.box-precautions {
  margin-bottom: 70px;
}

@media screen and (max-width: 768px) {
  .box-precautions {
    margin-bottom: 50px;
  }
}

.precautions-flex {
  display: flex;
  flex-wrap: wrap;
}

.precautions-flex__left {
  width: 716px;
  padding-right: 10px;
}

@media screen and (max-width: 990px) {
  .precautions-flex__left {
    width: 500px;
  }
}

@media screen and (max-width: 768px) {
  .precautions-flex__left {
    width: 100%;
    padding-right: 0;
    margin-bottom: 15px;
  }
}

.precautions-flex__img {
  width: calc(100% - 716px);
}

@media screen and (max-width: 990px) {
  .precautions-flex__img {
    width: calc(100% - 500px);
  }
}

@media screen and (max-width: 768px) {
  .precautions-flex__img {
    width: 100%;
    text-align: center;
  }
}

.precautions-list {
  padding-left: 18px;
}

@media screen and (max-width: 990px) {
  .precautions-list {
    padding-left: 15px;
  }
}

.precautions-list li {
  text-indent: -18px;
}

@media screen and (max-width: 990px) {
  .precautions-list li {
    text-indent: -15px;
  }
}

.box-point {
  margin-bottom: 71px;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .box-point {
    margin-bottom: 50px;
  }
}

.point-flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .point-flex__img {
    margin-bottom: 15px;
  }
}

.point-flex__content {
  padding: 0 0 4px 16px;
}

@media screen and (max-width: 768px) {
  .point-flex__content {
    padding: 0;
  }
}

.point-flex__content p {
  margin-bottom: 12px;
}

.point-flex__content p:last-child {
  margin-bottom: 0;
}

.point-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -35px;
}

@media screen and (max-width: 990px) {
  .point-list {
    margin: 0 -15px;
  }
}

@media screen and (max-width: 768px) {
  .point-list {
    margin: 0 auto;
    width: 100%;
    max-width: 450px;
  }
}

.point-list__item {
  width: 33.33%;
  padding: 0 35px;
}

@media screen and (max-width: 990px) {
  .point-list__item {
    padding: 0 15px;
  }
}

@media screen and (max-width: 768px) {
  .point-list__item {
    width: 100%;
    padding: 0;
    margin-bottom: 30px;
  }
  .point-list__item:last-child {
    margin-bottom: 0;
  }
}

.point-list__img {
  display: flex;
  align-items: center;
  margin-bottom: 21px;
}

@media screen and (max-width: 768px) {
  .point-list__img {
    display: block;
    width: 100%;
    margin: 0 auto 15px;
  }
}

.point-list__img img {
  width: 100%;
}

.box-variation {
  margin-bottom: 67px;
}

@media screen and (max-width: 768px) {
  .box-variation {
    margin-bottom: 50px;
  }
}

.table-comp01 {
  width: 100%;
  line-height: 1.7;
}

@media screen and (max-width: 990px) {
  .table-comp01 {
    font-size: 15px;
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .table-comp01 {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 374px) {
  .table-comp01 {
    font-size: 11px;
    font-size: 1.1rem;
  }
}

.table-comp01 tr th, .table-comp01 tr td {
  text-align: center;
  border: 5px solid #fff;
  padding: 0 5px;
}

@media screen and (max-width: 1024px) {
  .table-comp01 tr th, .table-comp01 tr td {
    border-width: 3px;
  }
}

.table-comp01 tr th:first-child, .table-comp01 tr td:first-child {
  width: 28.95%;
  border-left: none;
}

.table-comp01 tr th:nth-of-type(2), .table-comp01 tr td:nth-of-type(2) {
  width: 21.93%;
}

.table-comp01 tr th:last-child, .table-comp01 tr td:last-child {
  width: 49.12%;
  border-right: none;
}

.table-comp01 tr th {
  background: #C8E5CB;
  font-weight: normal !important;
}

.table-comp01 tr td {
  background: #FCF3E0;
}

.table-comp02 {
  width: 100%;
}

@media screen and (max-width: 990px) {
  .table-comp02 {
    font-size: 15px;
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .table-comp02 {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 374px) {
  .table-comp02 {
    font-size: 11px;
    font-size: 1.1rem;
  }
}

.table-comp02 tr th, .table-comp02 tr td {
  border: 1px solid #707070;
}

.table-comp02 tr th:first-child, .table-comp02 tr td:first-child {
  width: 20.3%;
}

.table-comp02 tr th:nth-of-type(2), .table-comp02 tr td:nth-of-type(2) {
  width: 60.95%;
}

.table-comp02 tr th:last-child, .table-comp02 tr td:last-child {
  width: 18.75%;
}

.table-comp02 tr th {
  text-align: center;
  background: #F2F0ED;
  padding: 4px 10px 0px;
  font-weight: normal !important;
}

.table-comp02 tr td {
  padding: 5px 10px 1px;
}

.box-performance {
  margin-bottom: 100px;
}

@media screen and (max-width: 768px) {
  .box-performance {
    margin-bottom: 50px;
  }
}

.box-bottom {
  font-size: 20px;
  font-size: 2rem;
  display: block;
  width: 100%;
  margin: 0 auto;
  max-width: 416px;
  text-align: center;
}

@media screen and (max-width: 990px) {
  .box-bottom {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 768px) {
  .box-bottom {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 374px) {
  .box-bottom {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.box-bottom p {
  margin-bottom: 31px;
}

.box-bottom__btn {
  margin-bottom: 81px;
}

.box-st {
  margin-bottom: 28px;
}

@media screen and (max-width: 768px) {
  .box-st {
    margin-bottom: 20px;
  }
}

.ttl-st {
  color: #E3FEFF;
  font-size: 20px;
  font-size: 2rem;
  padding: 3px 12px 1px;
  background-color: #346EB1;
  margin-bottom: 6px;
}

@media screen and (max-width: 768px) {
  .ttl-st {
    font-size: 18px;
    font-size: 1.8rem;
    padding: 4px 12px 3px;
    margin-bottom: 6px;
  }
}

@media screen and (max-width: 374px) {
  .ttl-st {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.ttl-st02 {
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.2;
  font-weight: bold;
  padding: 9px 17px 8px;
  border-top: 1px solid #346EB1;
  border-bottom: 1px solid #346EB1;
  margin-bottom: 19px;
  color: #346EB1;
}

@media screen and (max-width: 768px) {
  .ttl-st02 {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.5;
    padding: 5px 0;
    margin-bottom: 15px;
  }
}

.ttl-st03 {
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.2;
  text-align: center;
  background-color: #96B7DD;
  padding: 3px 10px 1px;
  margin-bottom: 8px;
}

@media screen and (max-width: 768px) {
  .ttl-st03 {
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 1.56;
    padding: 2px 10px 0px;
    margin-bottom: 6px;
  }
}

.txt-st, .list-st-type li, .table-st, .note-st, .box-materials__body .txt {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.72;
}

.txt-st02, .block-faq .row-faq .list li, .list-st-process > li, .note-st--style02, .note-st--style03 {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.75;
}

@media screen and (max-width: 768px) {
  .txt-st02, .block-faq .row-faq .list li, .list-st-process > li, .note-st--style02, .note-st--style03 {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.72;
  }
}

.txt-st03 {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.75;
}

.layout-st {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -9px 18px;
}

@media screen and (max-width: 768px) {
  .layout-st {
    margin: 0 0 21px;
  }
}

.layout-st .col-left {
  padding: 0 9px;
  width: 100%;
  max-width: 30.1%;
  flex: 0 0 30.1%;
}

@media screen and (max-width: 768px) {
  .layout-st .col-left {
    max-width: 100% !important;
    flex: 0 0 100% !important;
    padding: 0 !important;
  }
}

.layout-st .col-right {
  padding: 0 9px;
  width: 100%;
  max-width: 69.9%;
  flex: 0 0 69.9%;
}

@media screen and (max-width: 768px) {
  .layout-st .col-right {
    max-width: 100% !important;
    flex: 0 0 100% !important;
    padding: 0 !important;
  }
}

.layout-st--style02 {
  margin: 0 -8px 10px;
}

@media screen and (max-width: 768px) {
  .layout-st--style02 {
    margin: 0 0 7px;
  }
}

.layout-st--style02 .col-left {
  max-width: 60%;
  flex: 0 0 60%;
  padding: 0 8px;
}

.layout-st--style02 .col-right {
  max-width: 40%;
  flex: 0 0 40%;
  padding: 0 8px;
}

.layout-st--style03 {
  margin: 0 -13.5px 32px;
}

@media screen and (max-width: 768px) {
  .layout-st--style03 {
    margin: 0 0 31px;
  }
}

.layout-st--style03 .col-left {
  padding: 0 13.5px;
  max-width: 48%;
  flex: 0 0 48%;
}

@media screen and (max-width: 768px) {
  .layout-st--style03 .col-left {
    order: 1;
  }
}

.layout-st--style03 .col-right {
  padding: 0 13.5px;
  max-width: 52%;
  flex: 0 0 52%;
}

@media screen and (max-width: 768px) {
  .layout-st--style03 .col-right {
    padding: 0 2px !important;
    margin-bottom: 15px;
  }
}

.layout-st--style04 {
  margin: 0 -8px 18px;
}

@media screen and (max-width: 768px) {
  .layout-st--style04 {
    margin: 0 0 0;
  }
}

.layout-st--style04 .col-left {
  padding: 0 8px;
  max-width: 40%;
  flex: 0 0 40%;
}

.layout-st--style04 .col-right {
  padding: 0 8px;
  max-width: 60%;
  flex: 0 0 60%;
}

.layout-st--style05 {
  margin: 0 -7px 72px;
}

@media screen and (max-width: 768px) {
  .layout-st--style05 {
    margin: 0 0 0;
  }
}

.layout-st--style05 .col-left {
  padding: 0 7px;
  max-width: 50.1%;
  flex: 0 0 50.1%;
}

.layout-st--style05 .col-right {
  padding: 0 7px;
  max-width: 49.9%;
  flex: 0 0 49.9%;
}

.layout-st--style06 {
  margin: 0 -7px 37px;
}

@media screen and (max-width: 768px) {
  .layout-st--style06 {
    margin: 0 0 82px;
  }
}

.layout-st--style06 .col-left {
  padding: 0 7px;
  max-width: 49.95%;
  flex: 0 0 49.95%;
}

@media screen and (max-width: 768px) {
  .layout-st--style06 .col-left {
    order: 1;
  }
}

.layout-st--style06 .col-right {
  padding: 0 7px;
  max-width: 50.05%;
  flex: 0 0 50.05%;
}

.layout-st--style07 {
  margin: 0 -42px 18px;
}

@media screen and (max-width: 1200px) {
  .layout-st--style07 {
    margin: 0px -10px 18px;
  }
}

@media screen and (max-width: 768px) {
  .layout-st--style07 {
    margin: 0 0 0;
  }
}

.layout-st--style07 .col-left {
  padding: 0 42px;
  max-width: 44.3%;
  flex: 0 0 44.3%;
}

@media screen and (max-width: 1200px) {
  .layout-st--style07 .col-left {
    padding: 0 10px;
  }
}

.layout-st--style07 .col-right {
  padding: 0 42px;
  max-width: 55.7%;
  flex: 0 0 55.7%;
}

@media screen and (max-width: 1200px) {
  .layout-st--style07 .col-right {
    padding: 0 10px;
  }
}

.layout-st--style08 {
  margin-bottom: 41px;
}

@media screen and (max-width: 768px) {
  .layout-st--style08 {
    margin-bottom: 77px;
  }
}

.layout-st--style08 .col-left {
  padding-right: 38px;
  max-width: 42.8%;
  flex: 0 0 42.8%;
}

@media screen and (max-width: 1500px) {
  .layout-st--style08 .col-left {
    padding-right: 2.533vw;
  }
}

@media screen and (max-width: 768px) {
  .layout-st--style08 .col-left {
    order: 1;
  }
}

.layout-st--style08 .col-right {
  padding-top: 1px;
  max-width: 57.2%;
  flex: 0 0 57.2%;
}

@media screen and (max-width: 768px) {
  .layout-st--style08 .col-right {
    margin-bottom: 12px;
  }
}

.layout-st--style09 {
  margin: 0 -8px 41px;
}

@media screen and (max-width: 768px) {
  .layout-st--style09 {
    margin: 0 0 37px;
  }
}

.layout-st--style09 .col-left {
  padding: 0 8px;
  max-width: 59.96%;
  flex: 0 0 59.96%;
}

@media screen and (max-width: 768px) {
  .layout-st--style09 .col-left {
    order: 1;
  }
}

.layout-st--style09 .col-right {
  padding: 0 8px;
  max-width: 40.04%;
  flex: 0 0 40.04%;
}

@media screen and (max-width: 768px) {
  .layout-st--style09 .col-right {
    margin-bottom: 14px;
  }
}

.layout-st--style10 {
  margin: 0 -11px 92px;
}

@media screen and (max-width: 768px) {
  .layout-st--style10 {
    margin: 0;
  }
}

.layout-st--style10 .col {
  padding: 0 11px;
  width: 100%;
  max-width: 33.3333%;
  flex: 0 0 33.3333%;
}

@media screen and (max-width: 768px) {
  .layout-st--style10 .col {
    max-width: 100%;
    flex: 0 0 100%;
    padding: 0 0 0 2px;
    margin-bottom: 79px;
  }
}

.layout-st--style11 {
  margin: 0 -8px 112px;
}

@media screen and (max-width: 768px) {
  .layout-st--style11 {
    margin: 0 0 62px;
  }
}

.layout-st--style11 .col {
  padding: 0 8px;
  width: 100%;
  max-width: 50%;
  flex: 0 0 50%;
}

@media screen and (max-width: 768px) {
  .layout-st--style11 .col {
    max-width: 100%;
    flex: 0 0 100%;
    padding: 0;
    margin-bottom: 71px;
  }
}

@media screen and (max-width: 768px) {
  .layout-st--style11.reverse .col {
    margin-bottom: 0;
  }
}

.layout-st--style11.reverse .col:first-child {
  padding: 0 8px 0 11px;
}

@media screen and (max-width: 768px) {
  .layout-st--style11.reverse .col:first-child {
    order: 1;
    padding: 0;
  }
}

.layout-st--style11.reverse .col:last-child {
  padding-top: 5px;
}

.layout-st--style12 {
  margin: 0 0 102px;
}

@media screen and (max-width: 768px) {
  .layout-st--style12 {
    margin: 0 0 131px;
  }
}

.layout-st--style12 .col-left {
  max-width: 50%;
  flex: 0 0 50%;
  padding: 0;
}

@media screen and (max-width: 768px) {
  .layout-st--style12 .col-left {
    order: 1;
  }
}

.layout-st--style12 .col-right {
  max-width: 50%;
  flex: 0 0 50%;
  padding: 29px 15px 0;
}

@media screen and (max-width: 768px) {
  .layout-st--style12 .col-right {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 768px) {
  .list-st-howto {
    margin-bottom: 15px;
  }
}

.list-st-howto > li {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.33;
  color: #346EB1;
  border: 2px solid #346EB1;
  padding: 4px 8px 3px;
  margin-bottom: 6px;
}

@media screen and (max-width: 768px) {
  .list-st-howto > li {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.5;
    padding: 4px 8px 2px;
  }
}

.list-st-process {
  margin-bottom: 13px;
}

@media screen and (max-width: 768px) {
  .list-st-process {
    margin-bottom: 16px;
  }
}

.list-st-process > li:not(:last-child) {
  padding-bottom: 53px;
  position: relative;
}

@media screen and (max-width: 768px) {
  .list-st-process > li:not(:last-child) {
    padding-bottom: 74px;
  }
}

.list-st-process > li:not(:last-child):after {
  content: '';
  position: absolute;
  z-index: 1;
  left: 49%;
  bottom: 1px;
  transform: translateX(-50%);
  width: 42px;
  height: 48px;
  background: url("../img/service/lightning/standard/2013/ico-down.png") no-repeat center;
}

@media screen and (max-width: 768px) {
  .list-st-process > li:not(:last-child):after {
    bottom: 11px;
    left: 50%;
  }
}

.list-st-process > li:first-child {
  padding-bottom: 50px;
}

@media screen and (max-width: 768px) {
  .list-st-process > li:first-child {
    padding-bottom: 61px;
  }
}

.list-st-type {
  margin-bottom: 16px;
}

@media screen and (max-width: 768px) {
  .list-st-type {
    margin: 0 -7px 11px 0;
  }
}

.list-st-type li {
  display: flex;
}

.list-st-type li span {
  margin-right: 14px;
}

.warning-st {
  border: 2px solid #346EB1;
  background-color: #EEEEEE;
  padding: 9px 9px 9px 10px;
}

@media screen and (max-width: 768px) {
  .warning-st {
    margin: 0 1px 0 2px;
    padding: 9px 11px 12px 10px;
  }
}

.warning-st .head {
  display: flex;
  align-items: center;
  margin-bottom: 1px;
}

@media screen and (max-width: 768px) {
  .warning-st .head {
    margin-bottom: 7px;
  }
}

.warning-st .head img {
  flex-shrink: 0;
  margin-right: 12px;
}

@media screen and (max-width: 768px) {
  .warning-st .head img {
    margin: 0 11px 5px 1px;
  }
}

.warning-st .head .txt-st, .warning-st .head .list-st-type li, .list-st-type .warning-st .head li, .warning-st .head .table-st, .warning-st .head .note-st, .warning-st .head .box-materials__body .txt, .box-materials__body .warning-st .head .txt {
  flex-grow: 1;
}

@media screen and (max-width: 768px) {
  .warning-st .head .txt-st, .warning-st .head .list-st-type li, .list-st-type .warning-st .head li, .warning-st .head .table-st, .warning-st .head .note-st, .warning-st .head .box-materials__body .txt, .box-materials__body .warning-st .head .txt {
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 1.67;
  }
}

.warning-st .body {
  display: flex;
  border: 1px solid #346EB1;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.67;
}

@media screen and (max-width: 768px) {
  .warning-st .body {
    flex-wrap: wrap;
  }
}

.warning-st .body .label {
  width: 112px;
  flex-shrink: 0;
  text-align: center;
  color: #ffffff;
  background-color: #346EB1;
  padding: 12px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .warning-st .body .label {
    width: 100%;
    text-align: center;
    padding: 2px 5px;
  }
}

.warning-st .body .content {
  flex-grow: 1;
  background-color: #fff;
  padding: 2px 7px;
}

@media screen and (max-width: 768px) {
  .warning-st .body .content {
    width: 100%;
    padding: 7px;
  }
}

.table-st {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  line-height: 1.7;
}

@media screen and (max-width: 768px) {
  .table-st {
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 1.67;
    margin-bottom: 17px;
  }
}

.table-st td, .table-st th {
  border: 1px solid #000000;
  text-align: center;
  font-weight: normal;
}

.table-st thead th {
  background-color: #96B7DD;
}

@media screen and (max-width: 768px) {
  .table-st thead th:first-child {
    width: 14.7%;
  }
}

@media screen and (max-width: 768px) {
  .table-st thead th:nth-child(2) {
    width: 15%;
  }
}

@media screen and (max-width: 768px) {
  .table-st thead th:last-child {
    width: 18%;
  }
}

@media screen and (max-width: 768px) {
  .table-st thead th, .table-st thead td {
    padding: 2px 0 1px;
  }
}

.table-st tbody {
  background-color: #fff;
}

@media screen and (max-width: 768px) {
  .table-st tbody th, .table-st tbody td {
    padding: 2px 0 1px;
  }
}

@media screen and (max-width: 768px) {
  .table-st--style02 {
    margin-bottom: 14px;
  }
}

.table-st--style02 thead th:first-child {
  width: 23.7%;
}

@media screen and (max-width: 768px) {
  .table-st--style02 thead th:first-child {
    width: 28.7%;
  }
}

@media screen and (max-width: 768px) {
  .table-st--style02 thead th:nth-child(2) {
    width: auto;
  }
}

@media screen and (max-width: 768px) {
  .table-st--style02 thead th:last-child {
    width: auto;
  }
}

.table-st--style02 tbody th {
  padding: 10px 8px 8px;
  text-align: left;
  line-height: 1.29;
}

@media screen and (max-width: 768px) {
  .table-st--style02 tbody th {
    font-size: 14px;
    font-size: 1.4rem;
    text-align: center;
  }
}

@media screen and (max-width: 374px) {
  .table-st--style02 tbody th {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.table-st--style02.fix tbody th {
  padding: 10px 6px 8px;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .table-st--style03 {
    font-size: 14px;
    font-size: 1.4rem;
    table-layout: auto;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 374px) {
  .table-st--style03 {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.table-st--style03 thead th {
  line-height: 1.15;
  padding: 7px 0 4px;
}

@media screen and (max-width: 768px) {
  .table-st--style03 thead th:first-child {
    width: 29.7%;
    line-height: 1.34;
    padding: 6px 0 3px;
  }
}

@media screen and (max-width: 768px) and (max-width: 768px) {
  .table-st--style03 thead th:first-child {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 768px) {
  .table-st--style03 thead th:nth-child(2) {
    width: auto;
  }
}

@media screen and (max-width: 768px) {
  .table-st--style03 thead th:last-child {
    width: auto;
  }
}

.table-st--style03 tbody tr:first-child th:first-child {
  background-color: #96B7DD;
}

@media screen and (max-width: 768px) {
  .table-st--style03 tbody tr:first-child th:first-child {
    width: 20%;
  }
}

@media screen and (max-width: 374px) {
  .table-st--style03 tbody tr:first-child th:first-child {
    width: 23%;
  }
}

@media screen and (max-width: 768px) {
  .table-st--style03 tbody tr:first-child th:nth-child(2) {
    width: 9.7%;
  }
}

@media screen and (max-width: 768px) {
  .table-st--style03 tbody th, .table-st--style03 tbody td {
    padding: 0;
  }
}

.table-st--style04 {
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .table-st--style04 {
    margin-bottom: 12px;
  }
}

.table-st--style04 thead th {
  padding: 3px 0;
}

@media screen and (max-width: 768px) {
  .table-st--style04 thead th:first-child {
    width: 34%;
  }
}

@media screen and (max-width: 768px) {
  .table-st--style04 thead th:nth-child(2) {
    width: 14.5%;
  }
}

@media screen and (max-width: 768px) {
  .table-st--style04 thead th:last-child {
    width: auto;
  }
}

.table-st--style04 tbody th {
  line-height: 1.43;
  padding: 10px 10px 8px 17px;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .table-st--style04 tbody th {
    text-align: center;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.5;
    padding: 19px 5px;
  }
}

@media screen and (max-width: 374px) {
  .table-st--style04 tbody th {
    padding: 15px 0;
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.table-st--style05, .table-st--style06 {
  border-collapse: inherit;
  border: 1px solid #000000;
}

@media screen and (max-width: 768px) {
  .table-st--style05, .table-st--style06 {
    margin-bottom: 19px;
  }
}

.table-st--style05 td, .table-st--style06 td, .table-st--style05 th, .table-st--style06 th {
  border: none;
}

.table-st--style05 td:not(:last-child), .table-st--style06 td:not(:last-child), .table-st--style05 th:not(:last-child), .table-st--style06 th:not(:last-child) {
  border-right: 1px solid #000000;
}

.table-st--style05 thead th, .table-st--style06 thead th {
  padding: 3px 0;
  border-bottom: 1px solid #000000;
}

@media screen and (max-width: 768px) {
  .table-st--style05 thead th, .table-st--style06 thead th {
    padding: 3px 0 2px;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.72;
  }
}

@media screen and (max-width: 374px) {
  .table-st--style05 thead th, .table-st--style06 thead th {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.table-st--style05 thead th:first-child, .table-st--style06 thead th:first-child, .table-st--style05 thead th:last-child, .table-st--style06 thead th:last-child {
  width: 31.1%;
}

@media screen and (max-width: 768px) {
  .table-st--style05 thead th:first-child, .table-st--style06 thead th:first-child, .table-st--style05 thead th:last-child, .table-st--style06 thead th:last-child {
    width: 12%;
  }
}

.table-st--style05 tbody tr:first-child td, .table-st--style06 tbody tr:first-child td {
  padding-top: 6px;
}

.table-st--style05 tbody tr:last-child td, .table-st--style06 tbody tr:last-child td {
  padding-bottom: 8px;
}

@media screen and (max-width: 768px) {
  .table-st--style05 tbody th, .table-st--style06 tbody th, .table-st--style05 tbody td, .table-st--style06 tbody td {
    padding: 0;
    font-size: 14px;
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 374px) {
  .table-st--style05 tbody th, .table-st--style06 tbody th, .table-st--style05 tbody td, .table-st--style06 tbody td {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.table-st--style05 tbody td, .table-st--style06 tbody td {
  line-height: 1.72;
}

@media screen and (max-width: 768px) {
  .table-st--style05.fix thead th:first-child, .fix.table-st--style06 thead th:first-child {
    width: 31%;
  }
}

@media screen and (max-width: 768px) {
  .table-st--style05.fix thead th:nth-child(2), .fix.table-st--style06 thead th:nth-child(2) {
    width: 34%;
  }
}

@media screen and (max-width: 768px) {
  .table-st--style05.fix thead th:last-child, .fix.table-st--style06 thead th:last-child {
    width: auto;
  }
}

@media screen and (max-width: 768px) {
  .table-st--style06 {
    margin-bottom: 14px;
  }
}

@media screen and (max-width: 768px) {
  .table-st--style06 thead th {
    padding: 4px 0;
  }
}

.table-st--style06 thead th:first-child {
  width: 23.7%;
}

@media screen and (max-width: 768px) {
  .table-st--style06 thead th:first-child {
    width: 25.7%;
  }
}

.table-st--style06 thead th:nth-child(2) {
  width: 29%;
}

@media screen and (max-width: 768px) {
  .table-st--style06 thead th:nth-child(2) {
    width: 31%;
  }
}

.table-st--style06 thead th:nth-child(3) {
  width: 25%;
}

@media screen and (max-width: 768px) {
  .table-st--style06 thead th:nth-child(3) {
    width: 23%;
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 1.34;
  }
}

.table-st--style06 thead th:last-child {
  width: auto;
}

@media screen and (max-width: 768px) {
  .table-st--style06 thead th:last-child {
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 1.34;
  }
}

@media screen and (max-width: 768px) {
  .table-st--style06 tbody tr:first-child th, .table-st--style06 tbody tr:first-child td {
    padding-top: 2px;
  }
}

@media screen and (max-width: 768px) {
  .table-st--style06 tbody tr:last-child th, .table-st--style06 tbody tr:last-child td {
    padding-bottom: 0;
  }
}

.note-st {
  border: 2px solid #346EB1;
  padding: 23px 16px 22px;
}

@media screen and (max-width: 768px) {
  .note-st {
    padding: 10px 7px 7px 10px;
  }
}

.note-st--style02 {
  padding: 18px;
  margin-bottom: 67px;
}

@media screen and (max-width: 768px) {
  .note-st--style02 {
    padding: 13px 15px;
    margin-bottom: 77px;
  }
}

.note-st--style03 {
  border: 1px solid #303030;
  padding: 15px;
}

@media screen and (max-width: 768px) {
  .note-st--style03 {
    padding: 11px 15px 9px;
    margin-bottom: 52px;
  }
}

.note-st--style04 {
  padding: 13px 10px 10px 14px;
}

.box-materials {
  border-width: 0 2px 2px 2px;
  border-color: #346EB1;
  border-style: solid;
}

.box-materials--style02 .box-materials__body {
  padding: 21px 336px 29px;
}

@media screen and (max-width: 1500px) {
  .box-materials--style02 .box-materials__body {
    padding: 21px 22.4vw 29px;
  }
}

@media screen and (max-width: 768px) {
  .box-materials--style02 .box-materials__body {
    padding: 6px 12px 16px;
  }
}

.box-materials--style02.fix .box-materials__body {
  padding-bottom: 18px;
}

@media screen and (max-width: 768px) {
  .box-materials--style02.fix .box-materials__body {
    padding-bottom: 5px;
  }
}

.box-materials--style02.fix .table-st {
  margin-bottom: 4px !important;
}

.box-materials .ttl-st {
  padding: 3px 26px 1px;
}

@media screen and (max-width: 768px) {
  .box-materials .ttl-st {
    padding: 4px 10px 3px;
  }
}

.box-materials__body {
  padding: 20px 51px 16px 25px;
}

@media screen and (max-width: 1200px) {
  .box-materials__body {
    padding: 20px 25px 16px;
  }
}

@media screen and (max-width: 768px) {
  .box-materials__body {
    padding: 6px 11px 9px 12px;
  }
}

.box-materials__body .ttl {
  font-size: 20px;
  font-size: 2rem;
  line-height: 1;
  margin-bottom: 13px;
  color: #346EB1;
}

@media screen and (max-width: 1200px) {
  .box-materials__body .ttl {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 768px) {
  .box-materials__body .ttl {
    font-size: 18px;
    font-size: 1.8rem;
    margin-bottom: 11px;
    line-height: 1.34;
  }
}

@media screen and (max-width: 374px) {
  .box-materials__body .ttl {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.5;
  }
}

.box-materials__body .txt {
  line-height: 2;
}

@media screen and (max-width: 768px) {
  .fzsp-10 {
    font-size: 10px;
    font-size: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .mlrsp--16 {
    margin-left: -16px;
    margin-right: -16px;
  }
}

@media screen and (max-width: 1100px) {
  .pc-1100 {
    display: none;
  }
}

.sec-police {
  margin-bottom: 100px;
}

@media screen and (max-width: 768px) {
  .sec-police {
    margin-bottom: 50px;
  }
}

.sec-police .txt-basic {
  line-height: 1.75;
}

@media screen and (max-width: 768px) {
  .sec-police .txt-basic {
    margin-bottom: 20px;
  }
  .sec-police .txt-basic:last-child {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 768px) {
  .sec-police .txt-lg, .sec-police .txt-businesspartner {
    margin-bottom: 20px;
  }
}

.block-policy {
  margin-bottom: 78px;
}

@media screen and (max-width: 768px) {
  .block-policy {
    margin-bottom: 30px;
  }
}

.block-policy .ttl-comp04 {
  margin-bottom: 8px;
  color: #346EB1;
}

.block-policy .list li {
  position: relative;
  padding-left: 7px;
}

.block-policy .list li:before {
  content: '';
  height: 3px;
  width: 3px;
  border-radius: 5px;
  background: #303030;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}

.box-police {
  padding: 13px 28px 21px;
  border: 1px solid #707070;
}

@media screen and (max-width: 768px) {
  .box-police {
    padding: 15px;
  }
}

.box-police .txt-basic {
  line-height: 2.3;
}

.sec-privacy .txt-basic {
  line-height: 1.75;
}

@media screen and (max-width: 768px) {
  .sec-privacy .txt-basic {
    margin-bottom: 20px;
  }
  .sec-privacy .txt-basic:last-child {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 768px) {
  .sec-privacy .txt-lg, .sec-privacy .txt-businesspartner {
    margin-bottom: 20px;
  }
}

.sec-privacy .block-policy__list {
  padding-left: 50px;
  padding-right: 50px;
}

@media screen and (max-width: 768px) {
  .sec-privacy .block-policy__list {
    padding-left: 5.5vw;
    padding-right: 5.5vw;
  }
}

.sec-privacy .block-policy .txt-basic {
  margin-bottom: 26px;
}

@media screen and (max-width: 768px) {
  .sec-privacy .block-policy .txt-basic {
    margin-bottom: 20px;
  }
}

.sec-privacy .block-policy .txt-basic:last-child {
  margin-bottom: 0;
}

.sec-privacy .block-policy .txt-basic--bdbt {
  margin-bottom: 8px;
  line-height: 1.5;
}

.sec-privacy .block-policy__list .list {
  padding-left: 17px;
}

@media screen and (max-width: 768px) {
  .sec-privacy .block-policy__list .list {
    padding-left: 2.5vw;
  }
}

.sec-privacy .block-policy__list .list--pdl {
  padding-left: 0;
}

.sec-privacy .block-policy__item {
  margin-bottom: 28px;
}

@media screen and (max-width: 768px) {
  .sec-privacy .block-policy__item {
    margin-bottom: 20px;
  }
}

.sec-torinip {
  margin-bottom: 101px;
}

@media screen and (max-width: 768px) {
  .sec-torinip {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 769px) {
  .torinip-head {
    padding-bottom: 21px;
  }
  .torinip-head__wrap {
    align-items: center;
  }
  .torinip-head__wrap .intro-head__ttl {
    line-height: 1.875;
    padding-top: 6px;
  }
  .torinip-head__wrap .intro-head__ttl, .torinip-head__wrap .intro-head__img {
    width: auto;
  }
}

.torinip-intro {
  margin-bottom: 76px;
}

@media screen and (max-width: 768px) {
  .torinip-intro {
    margin-bottom: 50px;
  }
}

.torinip-intro__flex {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-size: 2rem;
  line-height: 2;
  margin-bottom: 25px;
}

@media screen and (max-width: 990px) {
  .torinip-intro__flex {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 768px) {
  .torinip-intro__flex {
    font-size: 16px;
    font-size: 1.6rem;
    margin-bottom: 15px;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 374px) {
  .torinip-intro__flex {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.torinip-intro__img {
  width: 267px;
}

@media screen and (max-width: 768px) {
  .torinip-intro__img {
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
  }
}

.torinip-intro__txt {
  width: calc(100% - 267px);
  padding: 0 10px 9px;
}

@media screen and (max-width: 768px) {
  .torinip-intro__txt {
    width: 100%;
    padding: 0;
  }
}

.box-pigeon {
  margin-bottom: 70px;
}

@media screen and (max-width: 768px) {
  .box-pigeon {
    margin-bottom: 50px;
  }
}

.box-pigeon__block {
  margin-bottom: 62px;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .box-pigeon__block {
    margin-bottom: 50px;
  }
}

.box-pigeon__block:last-child {
  margin-bottom: 0;
}

.box-pigeon__img {
  margin-bottom: 25px;
}

.box-pigeon__ttl {
  font-weight: bold;
  margin-bottom: 3px;
}

.box-pigeon__txt {
  line-height: 1.75;
}

.pigeon-list {
  display: flex;
  text-align: center;
  justify-content: center;
  margin: 0 0 24px;
}

@media screen and (max-width: 990px) {
  .pigeon-list {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 768px) {
  .pigeon-list {
    font-size: 12px;
    font-size: 1.2rem;
    width: 100%;
    max-width: 600px;
    margin: 0 auto 30px;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 640px) {
  .pigeon-list {
    display: block;
  }
}

.pigeon-list__item {
  margin-right: 60px;
}

@media screen and (max-width: 990px) {
  .pigeon-list__item {
    margin-right: 20px;
  }
}

@media screen and (max-width: 768px) {
  .pigeon-list__item {
    width: 50%;
    margin: 0 0 20px;
  }
  .pigeon-list__item:last-child {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 640px) {
  .pigeon-list__item {
    width: 100%;
  }
}

.pigeon-list__item:last-child {
  margin-right: 0;
}

.pigeon-list__img {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 10px;
}

@media screen and (max-width: 990px) and (-ms-high-contrast: active), screen and (max-width: 990px) and (-ms-high-contrast: none) {
  .pigeon-list__img {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .page-service_resistance_stray_index .box-equipment-inspection {
    margin-bottom: 40px;
  }
}

.page-service_resistance_stray_index .sec-resistance-page .block-intro .item {
  align-items: flex-start;
}

.page-service_resistance_stray_index .sec-resistance-page .block-intro .item--s01 {
  margin-bottom: 100px;
}

@media screen and (max-width: 768px) {
  .page-service_resistance_stray_index .sec-resistance-page .block-intro .item--s01 {
    margin-bottom: 40px;
  }
}

.page-service_resistance_stray_index .sec-resistance-page .block-intro .item--s01 .item__img {
  width: 49.4%;
}

@media screen and (min-width: 769px) {
  .page-service_resistance_stray_index .sec-resistance-page .block-intro .item--s01 .item__img {
    order: 2;
  }
}

@media screen and (max-width: 768px) {
  .page-service_resistance_stray_index .sec-resistance-page .block-intro .item--s01 .item__img {
    width: 100%;
    margin-bottom: 10px;
  }
}

.page-service_resistance_stray_index .sec-resistance-page .block-intro .item--s01 .item__txt {
  width: 50.6%;
  padding-right: 20px;
}

@media screen and (min-width: 769px) {
  .page-service_resistance_stray_index .sec-resistance-page .block-intro .item--s01 .item__txt {
    order: 1;
  }
}

@media screen and (max-width: 768px) {
  .page-service_resistance_stray_index .sec-resistance-page .block-intro .item--s01 .item__txt {
    width: 100%;
    padding: 0;
  }
}

@media screen and (max-width: 768px) {
  .page-service_resistance_analysis_index .box-equipment-inspection {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 768px) {
  .page-service_resistance_analysis_index .ttl-comp01 {
    margin-bottom: 10px;
  }
}

.txt-bold {
  font-weight: bold;
}

.ttl-item {
  font-size: 18px;
  font-size: 1.8rem;
  color: #4976A9;
  line-height: 1.55;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .ttl-item {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.ttl-item__number {
  font-size: 14px;
  font-size: 1.4rem;
  color: #fff;
  line-height: 1.643;
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
  background: #4976A9;
  padding: 2px;
  font-weight: normal;
  display: inline-flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ttl-item__number {
    padding: 4.5px 2px 1px;
  }
}

@media screen and (max-width: 768px) {
  .ttl-item__number {
    font-size: 12px;
    font-size: 1.2rem;
    min-width: 25px;
    min-height: 25px;
  }
}

.ttl-comp04--bd {
  border: 1px solid #346EB1;
  border-right: none;
  border-left: none;
  padding: 8px 0 5px 17px;
  margin-bottom: 36px;
  color: #346EB1;
}

@media screen and (max-width: 768px) {
  .ttl-comp04--bd {
    margin-bottom: 20px;
  }
}

.sec-inspection {
  padding-bottom: 125px;
}

@media screen and (max-width: 768px) {
  .sec-inspection {
    padding-bottom: 50px;
  }
}

.block-maintenance {
  max-width: 840px;
  margin: 0 auto;
  margin-bottom: 82px;
}

@media screen and (max-width: 768px) {
  .block-maintenance {
    margin-bottom: 50px;
  }
}

.block-maintenance > div {
  width: 50%;
}

@media screen and (max-width: 640px) {
  .block-maintenance > div {
    width: 100%;
  }
  .block-maintenance > div img {
    width: 100%;
  }
}

.block-maintenance__list {
  padding-right: 15px;
  margin-top: 14px;
}

@media screen and (max-width: 640px) {
  .block-maintenance__list {
    padding-right: 0;
  }
}

.block-maintenance .list .item {
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.8;
  color: #303030;
  font-weight: bold;
  padding-left: 15px;
  display: flex;
  position: relative;
  margin-bottom: 14px;
}

@media screen and (max-width: 768px) {
  .block-maintenance .list .item {
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 1.6;
  }
}

@media screen and (max-width: 374px) {
  .block-maintenance .list .item {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.block-maintenance .list .item:before {
  content: '';
  width: 12px;
  height: 12px;
  background: #4B4B4B;
  border-radius: 10px;
  display: inline-block;
  position: absolute;
  top: 11px;
  left: 0;
}

@media screen and (max-width: 768px) {
  .block-maintenance .list .item:before {
    width: 8px;
    height: 8px;
    top: 9px;
  }
}

.block-processmain {
  margin-bottom: 102px;
}

@media screen and (max-width: 768px) {
  .block-processmain {
    margin-bottom: 50px;
  }
}

.box-processmain {
  margin-bottom: 52px;
}

.box-processmain:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 768px) {
  .box-processmain {
    margin-bottom: 25px;
  }
}

.box-processmain__ttl {
  font-size: 24px;
  font-size: 2.4rem;
  line-height: 1.4;
  font-weight: bold;
  color: #346EB1;
  border-bottom: 3px solid #346EB1;
  margin-bottom: 18px;
}

@media screen and (max-width: 768px) {
  .box-processmain__ttl {
    font-size: 22px;
    font-size: 2.2rem;
    margin-bottom: 15px;
  }
}

.box-processmain__content {
  align-items: center;
}

.box-processmain__content--modify {
  align-items: flex-start;
}

.box-processmain__content > div {
  width: 50%;
}

@media screen and (max-width: 640px) {
  .box-processmain__content > div {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .box-processmain__content .img {
    margin-bottom: 10px;
  }
  .box-processmain__content .img img {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .box-processmain__content .txt-right {
    text-align: center;
  }
}

.box-processmain__content .txt-large {
  padding-left: 8px;
  font-weight: normal;
  margin-bottom: 0;
  line-height: 2;
  color: #303030;
}

.box-processmain__content .txt-large.pl-0 {
  padding-left: 0;
}

@media screen and (max-width: 640px) {
  .box-processmain__content .txt-large {
    padding-left: 0;
  }
}

@media screen and (max-width: 374px) {
  .box-processmain__content .txt-large {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.box-processmain__content .btn-comp02 {
  margin-top: 9px;
}

@media screen and (max-width: 768px) {
  .box-processmain__content .btn-comp02 {
    margin-top: 20px;
  }
}

.block-test {
  margin-bottom: 77px;
}

@media screen and (max-width: 768px) {
  .block-test {
    margin-bottom: 40px;
  }
}

.block-test .ttl-st__other {
  font-size: 18px;
  font-size: 1.8rem;
  color: #fff;
  font-weight: bold;
  line-height: 1.78;
  background: #707070;
  padding: 6px 15px 3px 20px;
  margin-bottom: 14px;
}

@media screen and (max-width: 768px) {
  .block-test .ttl-st__other {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.6;
  }
}

.block-test__list {
  margin-bottom: 35px;
}

@media screen and (max-width: 768px) {
  .block-test__list {
    margin-bottom: 20px;
  }
}

.block-test__list:last-child {
  margin-bottom: 0;
}

.block-test__list .img {
  width: calc(50% - 7.5px);
  margin-right: 15px;
}

@media screen and (max-width: 640px) {
  .block-test__list .img {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .block-test__list .img:last-child {
    margin-bottom: 0;
  }
}

.block-test__list .img img {
  margin-bottom: 8px;
}

@media screen and (max-width: 768px) {
  .block-test__list .img img {
    width: 100%;
  }
}

.block-test__list .img:nth-child(2n) {
  margin-right: 0;
}

.list-box {
  margin: 0 -15px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
  .list-box {
    margin: 0 -10px;
  }
}

@media screen and (max-width: 374px) {
  .list-box {
    margin: 0;
  }
}

.list-box.part-flex {
  align-items: center;
}

.list-box .ttl-item {
  margin-bottom: 10px;
}

.list-box .txt-basic {
  margin-bottom: 11px;
}

.list-box .txt-smaller {
  margin-top: 5px;
  display: block;
}

.list-box .item {
  width: calc(27% - 1px);
  padding: 0 15px;
  margin-bottom: 35px;
}

@media screen and (max-width: 768px) {
  .list-box .item {
    width: calc(50%);
    padding: 0 10px;
  }
}

@media screen and (max-width: 374px) {
  .list-box .item {
    width: 100%;
    padding: 0;
  }
}

.list-box .item .item-content {
  padding: 8px 4px 4px 14px;
  background: #F2F0ED;
  border: 3px solid #346EB1;
  display: inline-block;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .list-box .item .item-content {
    border: 1px solid #346EB1;
  }
}

.list-box .itemspan-02 {
  width: calc(46% + 2px);
}

@media screen and (max-width: 768px) {
  .list-box .itemspan-02 {
    width: 100%;
  }
}

.list-box .itemspan-03 {
  width: calc(73% - 1px);
}

@media screen and (max-width: 768px) {
  .list-box .itemspan-03 {
    width: 50%;
  }
}

@media screen and (max-width: 374px) {
  .list-box .itemspan-03 {
    width: 100%;
  }
}

.list-box .item__img {
  padding-right: 11px;
  align-items: flex-start;
}

.list-box .item__img img {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .list-box .item__img img {
    height: 100%;
  }
}

.list-box .item__img .img {
  width: calc( 50% - 6px);
  margin-right: 12px;
}

.list-box .item__img .img:nth-child(2n) {
  margin-right: 0;
}

.list-box .item-nobd .item-content {
  border: none;
  padding: 0;
  background: transparent;
}

.list-box--02 .txt-smaller {
  min-height: 24px;
}

@media screen and (max-width: 768px) {
  .list-box--02 .item {
    width: calc(27% - 1px);
  }
}

@media screen and (max-width: 640px) {
  .list-box--02 .item {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .list-box--02 .item.itemspan-03 {
    width: calc(73% - 1px);
  }
}

@media screen and (max-width: 640px) {
  .list-box--02 .item.itemspan-03 {
    width: 100%;
  }
}

.block-equipment {
  margin-bottom: 53px;
}

@media screen and (max-width: 768px) {
  .block-equipment {
    margin-bottom: 25px;
  }
}

.box-equipment {
  margin-bottom: 40px;
  display: inline-block;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .box-equipment {
    margin-bottom: 20px;
  }
}

.box-equipment .txt-red {
  color: #FC0505;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-wrap: wrap;
  margin-top: -6px;
}

.box-equipment .bd-note {
  width: 95px;
  height: 25px;
  border: 3px dashed #FC0505;
  display: inline-block;
  margin: 0 5px;
}

@media screen and (max-width: 768px) {
  .box-equipment .bd-note {
    width: 50px;
    height: 20px;
    border: 2px dashed #FC0505;
  }
}

.box-equipment__des {
  align-items: flex-start;
}

.box-equipment__des .txt-large {
  margin-bottom: 0;
  font-weight: normal;
  line-height: 2;
  margin-top: -4px;
  color: #303030;
}

@media screen and (max-width: 374px) {
  .box-equipment__des .txt-large {
    font-size: 16px;
    font-size: 1.6rem;
  }
}

.box-equipment__txt {
  width: calc(44.3% - 15px);
  margin-right: 30px;
}

@media screen and (max-width: 640px) {
  .box-equipment__txt {
    width: 100%;
    margin-right: 0;
    margin-bottom: 15px;
  }
}

.box-equipment__img {
  width: calc(55.7% - 15px);
  align-items: flex-start;
}

@media screen and (max-width: 640px) {
  .box-equipment__img {
    width: 100%;
  }
}

.box-equipment__img .img:first-child {
  width: 39.2%;
  padding-right: 15px;
}

@media screen and (max-width: 640px) {
  .box-equipment__img .img:first-child {
    width: 40.8%;
  }
}

.box-equipment__img .img:last-child {
  width: 60.8%;
}

@media screen and (max-width: 640px) {
  .box-equipment__img .img:last-child {
    width: 59.2%;
  }
}

.box-equipment__img .img img {
  margin-bottom: 11px;
}

.box-equipment .list {
  display: flex;
  justify-content: flex-start;
  align-self: flex-start;
  flex-wrap: wrap;
}

.box-equipment .list .img, .box-equipment .list .ttl-item {
  margin-bottom: 20px;
}

.box-equipment .list-col03 .item {
  width: calc( 33.33% - 13.33px);
  margin: 0 20px 20px 0;
  padding: 18px 20px 21px;
}

.box-equipment .list-col03 .item:nth-child(3n) {
  margin-right: 0;
}

@media screen and (max-width: 768px) {
  .box-equipment .list-col03 .item:nth-child(3n) {
    margin-right: 20px;
  }
}

@media screen and (max-width: 640px) {
  .box-equipment .list-col03 .item:nth-child(3n) {
    margin-right: 10px;
  }
}

@media screen and (max-width: 374px) {
  .box-equipment .list-col03 .item:nth-child(3n) {
    margin-right: 0px;
  }
}

@media screen and (max-width: 768px) {
  .box-equipment .list-col03 .item {
    width: calc(50% - 10px);
  }
  .box-equipment .list-col03 .item:nth-child(2n) {
    margin-right: 0;
  }
}

@media screen and (max-width: 640px) {
  .box-equipment .list-col03 .item {
    padding: 15px;
    margin: 0 15px 15px 0;
  }
}

@media screen and (max-width: 374px) {
  .box-equipment .list-col03 .item {
    width: 100%;
    margin: 0 0 10px 0;
  }
}

.box-equipment .list-col03 .item .img {
  margin-bottom: 14px;
}

.box-equipment .list-col03 .item .img img {
  width: 100%;
}

.box-equipment .list-col03 .item .txt-smaller {
  min-height: 72px;
}

@media screen and (min-width: 769px) {
  .page-faq_index {
    padding-top: 116px;
  }
}

@media screen and (min-width: 769px) {
  .hokuden-head {
    padding-bottom: 19px;
    margin-bottom: 55px;
  }
}

.hokuden-head .intro-head__wrap {
  align-items: center;
}

@media screen and (max-width: 990px) {
  .hokuden-head .intro-head__wrap {
    flex-wrap: wrap;
  }
}

.hokuden-head .intro-head__img {
  width: 372px;
  padding: 0;
}

@media screen and (max-width: 990px) {
  .hokuden-head .intro-head__img {
    width: 100%;
    text-align: center;
  }
}

.box-mesh {
  margin-bottom: 32px;
}

@media screen and (max-width: 768px) {
  .box-mesh {
    margin-bottom: 20px;
  }
}

.construction-flex {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-bottom: 57px;
}

@media screen and (max-width: 768px) {
  .construction-flex {
    margin-bottom: 50px;
  }
}

.construction-flex__left {
  width: 540px;
  padding-right: 30px;
}

@media screen and (max-width: 990px) {
  .construction-flex__left {
    width: 100%;
    margin-bottom: 20px;
    padding-right: 0;
  }
}

.construction-flex__list {
  margin-bottom: 7px;
}

.construction-flex__list li {
  padding-left: 34px;
  position: relative;
  margin-bottom: 9px;
}

@media screen and (max-width: 990px) {
  .construction-flex__list li {
    padding-left: 24px;
  }
}

.construction-flex__list li:last-child {
  margin-bottom: 0;
}

.construction-flex__list li .number {
  position: absolute;
  left: 2px;
  top: 0;
}

.construction-flex__note {
  padding-left: 2px;
  color: #FC0505;
  margin-bottom: 18px;
}

.construction-flex__right {
  width: calc(100% - 540px);
}

@media screen and (max-width: 990px) {
  .construction-flex__right {
    width: 100%;
    max-width: 450px;
    margin: 0 auto;
    text-align: center;
  }
}

.construction-flex__right p {
  margin-bottom: 6px;
}

.construction-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -11px;
  line-height: 1.56;
}

@media screen and (max-width: 990px) {
  .construction-list {
    margin: 0 -5px;
  }
}

.construction-list__item {
  width: 25%;
  padding: 0 11px;
  margin-bottom: 30px;
}

@media screen and (max-width: 990px) {
  .construction-list__item {
    padding: 0 5px;
  }
}

@media screen and (max-width: 768px) {
  .construction-list__item {
    width: 50%;
  }
}

@media screen and (max-width: 374px) {
  .construction-list__item {
    width: 100%;
  }
  .construction-list__item:last-child {
    margin-bottom: 0;
  }
}

.construction-list__img {
  margin-bottom: 15px;
}

.construction-list__img img {
  width: 100%;
}

.construction-list__content {
  position: relative;
  padding-left: 38px;
}

@media screen and (max-width: 640px) {
  .construction-list__content {
    padding-left: 30px;
  }
}

.construction-list__number {
  width: 30px;
  height: 30px;
  line-height: 30px;
  background: #346EB1;
  border-radius: 50%;
  text-align: center;
  color: white;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  padding: 1px 2px 2px;
}

@media screen and (max-width: 640px) {
  .construction-list__number {
    width: 24px;
    height: 24px;
    line-height: 24px;
  }
}

.info-list {
  font-weight: bold;
  line-height: 1.5;
}

.info-list__item {
  margin-bottom: 20px;
}

.info-list__item:last-child {
  margin-bottom: 0;
}

.info-list__wrap {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 11px;
  align-items: center;
}

.info-list__wrap:last-child {
  margin-bottom: 0;
}

.info-list__ttl {
  width: 223px;
  text-align: right;
  padding-right: 14px;
}

@media screen and (max-width: 640px) {
  .info-list__ttl {
    width: 100%;
    padding-right: 0;
    text-align: left;
  }
}

.info-list__ttl--full {
  width: 100%;
  text-align: left;
  margin-bottom: 9px;
}

.info-txt {
  display: flex;
  width: 100%;
  max-width: 199px;
  border: 2px solid #303030;
  text-align: center;
}

.info-txt dt {
  width: 63.4%;
  border-right: 2px solid #303030;
  background: #96B7DD;
  padding: 2px;
}

.info-txt dd {
  width: 36.6%;
  padding: 2px;
}

.box-grounding {
  margin-bottom: 51px;
}

@media screen and (max-width: 768px) {
  .box-grounding {
    margin-bottom: 50px;
  }
}

.box-grounding__des {
  font-weight: bold;
  margin-bottom: 37px;
}

.grounding-wrap {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;
}

@media only screen and (max-width: 850px) {
  .grounding-wrap > * {
    margin-bottom: 30px;
  }
  .grounding-wrap > *:last-child {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 768px) {
  .grounding-wrap {
    margin-bottom: 30px;
  }
}

.grounding-wrap__left {
  width: 260px;
}

@media only screen and (max-width: 850px) {
  .grounding-wrap__left {
    width: 100%;
  }
}

@media only screen and (min-width: 851px) {
  .grounding-wrap__left02 {
    width: 235px;
  }
}

.grounding-wrap__txt {
  margin-bottom: 22px;
}

.grounding-wrap__txt sub {
  font-size: 10px;
  font-size: 1rem;
  vertical-align: text-top;
}

.grounding-wrap__txt02 {
  font-weight: bold;
  margin-bottom: 2px;
}

.grounding-wrap__between {
  width: calc(71.05% - 260px);
}

@media only screen and (max-width: 850px) {
  .grounding-wrap__between {
    width: 100%;
  }
}

@media only screen and (min-width: 851px) {
  .grounding-wrap__between02 {
    width: calc(71.05% - 235px);
  }
}

@media only screen and (min-width: 851px) {
  .grounding-wrap__between03 {
    width: calc(60.95% - 235px);
  }
}

.grounding-wrap__img01 {
  padding: 0 10px 0 0;
}

@media only screen and (max-width: 850px) {
  .grounding-wrap__img01 {
    padding: 0;
  }
}

.grounding-wrap__img02 {
  padding: 43px 73px 0 0;
}

@media only screen and (max-width: 850px) {
  .grounding-wrap__img02 {
    padding: 0;
  }
}

.grounding-wrap__img03 {
  padding: 0 16px 0 0;
}

@media only screen and (max-width: 850px) {
  .grounding-wrap__img03 {
    padding: 0;
  }
}

.grounding-wrap__list {
  display: flex;
  text-align: center;
  justify-content: space-around;
  padding: 59px 30px 0 5px;
}

@media only screen and (max-width: 850px) {
  .grounding-wrap__list {
    padding: 0;
  }
  .grounding-wrap__list li {
    padding: 0 5px;
  }
}

.grounding-wrap__list .img {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 19px;
}

@media screen and (max-width: 1024px) and (-ms-high-contrast: active), screen and (max-width: 1024px) and (-ms-high-contrast: none) {
  .grounding-wrap__list .img {
    display: block;
  }
  .grounding-wrap__list .img img {
    width: 100%;
  }
}

.grounding-wrap__right {
  width: 28.95%;
}

@media only screen and (max-width: 850px) {
  .grounding-wrap__right {
    width: 100%;
  }
}

@media only screen and (min-width: 851px) {
  .grounding-wrap__right02 {
    width: 41.45%;
    margin-right: -2.4%;
  }
}

@media only screen and (max-width: 850px) {
  .grounding-wrap__right .img {
    text-align: center;
  }
}

.grounding-wrap02 {
  display: flex;
  flex-wrap: wrap;
}

.grounding-wrap02__left {
  width: 59.3%;
}

@media screen and (max-width: 768px) {
  .grounding-wrap02__left {
    width: 100%;
    margin-bottom: 30px;
  }
}

.grounding-wrap02__left .txt-lg, .grounding-wrap02__left .txt-businesspartner {
  font-weight: bold;
  margin-bottom: 7px;
}

.grounding-wrap02__img {
  padding-left: 3px;
}

.grounding-wrap02__right {
  width: 40.7%;
  padding: 33px 0 0 20px;
  line-height: 1.75;
}

@media screen and (max-width: 768px) {
  .grounding-wrap02__right {
    width: 100%;
    padding: 0;
  }
}

.grounding-wrap02__right ul {
  margin-left: -4px;
}

.grounding-wrap02__right ul li {
  padding-left: 13px;
  text-indent: -13px;
}

@media screen and (min-width: 991px) {
  .grounding-flex .gel-flex__left {
    width: 614px;
  }
  .grounding-flex .gel-flex__img {
    width: calc(100% - 614px);
  }
}

.grounding-construction__list {
  display: flex;
  justify-content: space-between;
  margin: 0 -10px;
}

@media screen and (max-width: 768px) {
  .grounding-construction__list {
    margin: 0 -5px -30px;
    flex-wrap: wrap;
  }
}

.grounding-construction__list li {
  padding: 0 10px;
  margin-bottom: 30px;
}

@media screen and (max-width: 768px) {
  .grounding-construction__list li {
    width: 50%;
    padding: 0 5px;
  }
}

.grounding-construction__list li .img {
  margin-bottom: 9px;
}

.pt0 {
  padding-top: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.bg-none {
  background: transparent;
}

.box-regulations {
  margin: 0 auto 43px;
}

@media screen and (max-width: 768px) {
  .box-regulations {
    margin-bottom: 0px;
  }
}

.box-regulations .box-left {
  padding-left: 0;
  padding-right: 20px;
}

@media screen and (max-width: 1024px) {
  .box-regulations .box-left {
    padding-top: 0;
  }
}

@media screen and (max-width: 768px) {
  .box-regulations .box-left {
    padding-right: 0;
    padding-top: 20px;
    padding-bottom: 0;
  }
}

.sec-nip-features {
  margin-bottom: 49px;
}

@media screen and (max-width: 768px) {
  .sec-nip-features {
    margin-bottom: 75px;
  }
}

.box-tranquil {
  padding-top: 26px;
  padding-bottom: 18px;
  background: #E3FEFF;
  margin-bottom: 66px;
}

@media screen and (max-width: 768px) {
  .box-tranquil {
    padding: 30px 16px;
    margin-bottom: 45px;
    margin-left: -16px;
    margin-right: -16px;
  }
}

.box-tranquil__ttl {
  font-size: 24px;
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 1.7;
  margin-bottom: 24px;
}

@media screen and (max-width: 768px) {
  .box-tranquil__ttl {
    font-size: 16px;
    font-size: 1.6rem;
    margin-bottom: 15px;
  }
}

.box-tranquil__ttl span {
  background: none;
  position: relative;
  display: inline-block;
  padding: 0 15px;
}

.box-tranquil__ttl span:after {
  content: '';
  bottom: 5px;
  left: 0;
  right: 0;
  position: absolute;
  background: rgba(97, 190, 247, 0.65);
  height: 16px;
  z-index: -1;
}

@media screen and (max-width: 768px) {
  .box-tranquil__ttl span:after {
    height: 12px;
  }
}

.box-tranquil__des {
  text-align: center;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.75;
}

@media screen and (max-width: 768px) {
  .box-tranquil__des {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.7;
  }
}

.box-tranquil__des a {
  color: #0060CB;
}

.box-tranquil__sub {
  padding: 20px 30px;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.75;
  margin-bottom: 15px;
}

@media screen and (max-width: 768px) {
  .box-tranquil__sub {
    padding: 16px 16px 5px;
    font-size: 14px;
    font-size: 1.4rem;
    margin-bottom: 16px;
    margin-left: 0;
  }
}

.box-nip-features {
  margin-bottom: 55px;
}

@media screen and (max-width: 768px) {
  .box-nip-features {
    margin-bottom: 50px;
  }
}

.box-nip-features .ttl-comp01 {
  margin-bottom: 35px;
}

@media screen and (max-width: 768px) {
  .box-nip-features .ttl-comp01 {
    margin-bottom: 28px;
  }
}

.box-nip-features .wp {
  max-width: 1064px;
  margin: 0 auto;
}

.box-nip-features .item {
  display: flex;
  align-items: flex-end;
  background: #EEEEEE;
  margin-bottom: 20px;
}

@media screen and (max-width: 990px) {
  .box-nip-features .item {
    align-items: center;
  }
}

@media screen and (max-width: 768px) {
  .box-nip-features .item {
    flex-wrap: wrap;
    margin-bottom: 30px;
  }
}

.box-nip-features .item:last-of-type {
  margin-bottom: 0;
}

.box-nip-features .item__img {
  width: 49.24%;
  position: relative;
}

@media screen and (max-width: 990px) {
  .box-nip-features .item__img img {
    height: 100%;
    object-fit: cover;
    font-family: "object-fit: cover;";
  }
}

@media screen and (max-width: 768px) {
  .box-nip-features .item__img {
    width: 100%;
  }
  .box-nip-features .item__img img {
    height: auto;
    width: 100%;
  }
}

.box-nip-features .item__img span {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 32px;
  font-size: 3.2rem;
  font-weight: bold;
  color: #14137E;
  width: 100%;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .box-nip-features .item__img span {
    font-size: 28px;
    font-size: 2.8rem;
  }
}

.box-nip-features .item__info {
  width: calc(100% - 49.24%);
  padding-bottom: 17px;
  padding-left: 21px;
  padding-right: 21px;
}

@media screen and (max-width: 990px) {
  .box-nip-features .item__info {
    padding-bottom: 0;
  }
}

@media screen and (max-width: 768px) {
  .box-nip-features .item__info {
    width: 100%;
    padding: 24px 14px 18px;
  }
}

.box-nip-features .item__info--des {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.75;
  margin-bottom: 30px;
}

@media screen and (max-width: 990px) {
  .box-nip-features .item__info--des {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 768px) {
  .box-nip-features .item__info--des {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.7;
    margin-bottom: 22px;
  }
}

.box-nip-features .item__info .btn-arrow {
  max-width: 154px;
  margin: 0 auto;
  width: 100%;
  height: 30px;
  background: #346EB1;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  font-size: 1.6rem;
  color: #fff;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding-left: 14px;
  position: relative;
}

.box-nip-features .item__info .btn-arrow:after {
  content: "";
  display: block;
  width: 32px;
  height: 6px;
  background: url("../img/service/lightning/index/ico-right.png") no-repeat center;
  background-size: cover;
  position: absolute;
  top: 10px;
  right: 10px;
}

.box-setup-system {
  max-width: 1064px;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .box-setup-system .ttl-comp01 {
    line-height: 1.5;
  }
  .box-setup-system .ttl-comp01 span {
    display: inline-block;
    padding: 0 30px;
  }
}

.box-setup-system-wp {
  display: flex;
  align-items: flex-start;
}

@media screen and (max-width: 768px) {
  .box-setup-system-wp {
    flex-wrap: wrap;
  }
}

.box-setup-system-wp .box-content {
  width: calc(100% - 29.04%);
  padding-right: 15px;
}

@media screen and (max-width: 990px) {
  .box-setup-system-wp .box-content {
    width: 65%;
  }
}

@media screen and (max-width: 768px) {
  .box-setup-system-wp .box-content {
    width: 100%;
    margin-bottom: 28px;
  }
}

.box-setup-system-wp .box-content .list-dots {
  margin-bottom: 20px;
}

.box-setup-system-wp .box-content .list-dots li {
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.6;
  padding-left: 30px;
  position: relative;
  margin-bottom: 20px;
}

@media screen and (max-width: 990px) {
  .box-setup-system-wp .box-content .list-dots li {
    font-size: 18px;
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 768px) {
  .box-setup-system-wp .box-content .list-dots li {
    font-size: 16px;
    font-size: 1.6rem;
    margin-bottom: 20px;
    padding-left: 20px;
  }
}

.box-setup-system-wp .box-content .list-dots li:before {
  content: '';
  display: block;
  width: 22px;
  height: 22px;
  background: #346EB1;
  border-radius: 50%;
  position: absolute;
  top: 5px;
  left: 0;
}

@media screen and (max-width: 768px) {
  .box-setup-system-wp .box-content .list-dots li:before {
    width: 15px;
    height: 15px;
  }
}

.box-setup-system-wp .box-content .txt-basic {
  line-height: 1.75;
}

.box-setup-system-wp .box-img {
  width: 29.04%;
}

@media screen and (max-width: 990px) {
  .box-setup-system-wp .box-img {
    width: 35%;
  }
}

@media screen and (max-width: 768px) {
  .box-setup-system-wp .box-img {
    width: 308px;
    margin: 0 auto;
  }
}

.box-setup-system-wp .box-img .box-ttlcomp {
  width: 100%;
  margin-bottom: 10px;
  font-size: 24px;
  font-size: 2.4rem;
}

@media screen and (max-width: 990px) {
  .box-setup-system-wp .box-img .box-ttlcomp {
    font-size: 22px;
    font-size: 2.2rem;
  }
}

@media screen and (max-width: 768px) {
  .box-setup-system-wp .box-img .box-ttlcomp {
    margin-bottom: 13px;
    font-size: 20px;
    font-size: 2rem;
    padding: 4px 10px;
  }
}

.ttl-koamaru {
  font-size: 24px;
  font-size: 2.4rem;
  font-weight: bold;
  color: #14137E;
  margin-bottom: 9px;
}

@media screen and (max-width: 768px) {
  .ttl-koamaru {
    font-size: 20px;
    font-size: 2rem;
    margin-bottom: 10px;
  }
}

.works-table {
  width: 100%;
  max-width: 740px;
  margin: 0 auto 71px;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.5;
  min-width: 500px;
}

@media screen and (max-width: 768px) {
  .works-table {
    font-size: 14px;
    font-size: 1.4rem;
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 374px) {
  .works-table {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.works-table tr th {
  text-align: center;
  font-weight: normal;
  background: #96B7DD;
  border: 1px solid white;
  border-top: none;
  border-bottom: none;
  padding: 3px 10px  1px;
}

.works-table tr td {
  border: 1px solid #707070;
  border-top: none;
  padding: 4px 14px 0px 22px;
}

@media screen and (max-width: 768px) {
  .works-table tr td {
    padding: 5px 10px;
  }
}

.works-table tr td:first-child {
  border-left: none;
  width: 12%;
  padding: 0 9px;
}

@media screen and (max-width: 768px) {
  .works-table tr td:first-child {
    padding: 0 10px;
    width: 18%;
  }
}

.works-table tr td:nth-child(2) {
  width: 45.8%;
}

@media screen and (max-width: 768px) {
  .works-table tr td:nth-child(2) {
    width: 39.8%;
  }
}

.works-table tr td:last-child {
  border-right: none;
  width: 21%;
  text-align: right;
}

.works-table tr td:nth-last-child(2) {
  width: 21.2%;
  text-align: center;
}

.works-table tr.works-table__row02 td {
  padding: 0 14px 0 22px;
}

@media screen and (max-width: 768px) {
  .works-table tr.works-table__row02 td {
    padding: 5px 10px;
  }
}

.table-scroll {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.sec-series {
  padding-bottom: 87px;
}

@media screen and (max-width: 768px) {
  .sec-series {
    padding-bottom: 50px;
  }
}

.series__link {
  font-weight: bold;
}

.series__link a {
  position: relative;
  color: #0060CB;
  padding-bottom: 9px;
}

@media screen and (max-width: 768px) {
  .series__link a {
    text-decoration: underline;
    text-underline-position: under;
  }
}

.series__link a:before {
  position: absolute;
  content: '';
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: #0060CB;
}

@media screen and (max-width: 768px) {
  .series__link a:before {
    display: none;
  }
}

.series__txt {
  line-height: 1.75;
  margin-bottom: 60px;
}

@media screen and (max-width: 768px) {
  .series__txt {
    margin-bottom: 50px;
  }
}
