.common-menu {
	background-color: #61BEF7;
	margin: 35px 0 15px;
	&.on{
		position: fixed;
		z-index: 10;
		top: 0;
		left: 0;
		width: 100%;
		margin: 0;
	}
	@include mq {
		margin: 0 0 10px;
	}
	.inner {
		display: flex;
		flex-wrap: wrap;
		@include mq {
			padding: 0;
		}
		> a {
			width: 100%;
			max-width: 20%;
			flex: 0 0 20%;
			text-align: center;
			color: #ffffff;
			border-width: 0 1px 0 0;
			border-style: solid;
			border-color: #ffffff;
			@include font-size(20);
			line-height: 2;
			padding: 5px;
			display: flex;
			align-items: center;
			justify-content: center;
			@include mq {
				@include font-size(13);
				line-height: 1.54;
			}
			&:first-child {
				border-width: 0 1px 0 1px;
				@include mq {
					border-width: 0 1px 0 0;
				}
			}
			&:last-child {
				@include mq {
					border-width: 0;
				}
			}
			&.active {
				background-color: #346EB1;
			}
		}
	}
}

.common-direction {
	margin-bottom: 56px;
	@include mq {
		margin-bottom: 34px;
	}
	.ttl {
		text-align: center;
		@include font-size(26);
		line-height: 1.77;
		color: #0060CB;
		margin-bottom: 3px;
		@include mq {
			@include font-size(20);
			line-height: 1.5;
			margin-bottom: 1px;
		}
	}
	.list {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -5px;
		> li {
			width: 100%;
			max-width: 33.3333%;
			flex: 0 0 33.3333%;
			padding: 0 5px;
			@include mq {
				max-width: 100%;
				flex: 0 0 100%;
				margin-bottom: 7px;
			}
		}
		.item {
			border: 1px solid #0060CB;
			border-radius: 10px;
			display: block;
			padding: 14px 34px 10px 15px;
			position: relative;
			height: 100%;
			color: #0060CB;
			transform: .3s;
			@include mq {
				padding: 5px 34px 5px 10px;
			}
			&:after {
				content: '';
				position: absolute;
				z-index: 1;
				width: 12px;
				height: 22px;
				@include v-align;
				right: 10px;
				transform: .3s;
				background: url("../img/service/lightning/standard/ico-next-blue.png") no-repeat center;
				@include mq {
					width: 18px;
					height: 28px;
					right: 13px;
				}
			}
			&__ttl {
				display: flex;
				@include font-size(20);
				line-height: 1.4;
				margin-bottom: 8px;
				@include mq(lg) {
					@include font-size(18);
					line-height: 1.55;
				}
				@include mq {
					margin-bottom: 2px;
					width: 100%;
				}
			}
			&__order {
				display: inline-block;
				margin-right: 7px;
				@include mq {
					margin-right: 6px;
				}
			}
			&__grow {
				flex-grow: 1;
			}
			&__txt {
				@include font-size(14);
				line-height: 1.42;
				padding: 0 0 0 19px;
				@include mq {
					@include font-size(12);
					line-height: 1.33;
					padding: 0 0 0 22px;
				}
			}
			&.active, &:hover {
				color: #ffffff;
				opacity: 1;
				background-color: #0060CB;
				&:after {
					background: url("../img/service/lightning/standard/ico-next-white.png") no-repeat center;
				}
			}
		}
	}
}

.fix-ipd {
	@include mq(lg) {
		display: none;
	}
	@include mq {
		display: block;
	}
}

.page-service_regulations_standard_index {
	.main-bg--content {
		@include mq {
			padding-top: 0;
		}
	}
}

.sec-standard {
	padding: 0 0 42px;
	@include mq {
		padding: 0 0 20px;
	}
}

.ttl-standard {
	font-weight: bold;
	@include font-size(24);
	line-height: 1;
	color: #0060CB;
	margin-bottom: 11px;
	@include mq {
		@include font-size(18);
		line-height: 1.5;
	}
}

.txt-standard {
	@include font-size(16);
	line-height: 1.75;
	@include mq {
		@include font-size(14);
		line-height: 1.71;
	}
}

.box-standard {
	margin-bottom: 36px;
	@include mq {
		margin-bottom: 30px;
	}
	.ttl {
		color: #E3FEFF;
		@include font-size(20);
		padding: 3px 12px 1px;
		background-color: #346EB1;
		margin-bottom: 13px;
		@include mq {
			@include font-size(18);
			padding: 4px 12px 3px;
			margin-bottom: 6px;
		}
	}
	.heading {
		display: flex;
		@include font-size(16);
		line-height: 1.5;
		margin-bottom: 12px;
		@include mq {
			@include font-size(14);
			flex-wrap: wrap;
		}
		&__lb {
			background-color: #0060CB;
			color: #ffffff;
			padding: 2px 11px;
			min-width: 28.9%;
			display: flex;
			align-items: center;
			@include mq {
				width: 100%;
				padding: 2px 12px;
			}
		}
		&__txt {
			flex-grow: 1;
			border: 1px solid #0060CB;
			color: #0060CB;
			padding: 2px 15px;
			@include mq {
				padding: 2px 18px;
			}
		}
	}
	.wrap {
		display: flex;
		padding-top: 4px;
		@include mq {
			flex-wrap: wrap;
			margin-bottom: 10px;
		}
		&__content {
			flex-grow: 1;
			@include font-size(14);
			line-height: 1.72;
			position: relative;
			@include mq(lg) {
				width: 50%;
				padding-right: 20px;
			}
			@include mq {
				@include font-size(12);
				line-height: 1.5;
			}
			@include mq(sm) {
				width: 100%;
			}
			p {
				margin-bottom: 24px;
				&:last-child {
					margin-bottom: 0;
				}
			}
			@include mq(xl-min) {
				margin-right: -72px;
			}
		}
		&__img {
			flex-shrink: 0;
			@include mq(lg) {
				width: 50%;
			}
			@include mq(sm) {
				width: 100%;
			}
		}
	}
}

.row {
	display: flex;
	flex-wrap: wrap;
}

.sec-design-sample {
	background-color: #FCF3E0;
	padding: 62px 0 61px;
	@include mq {
		padding: 16px 0 10px;
	}
	.col-4 {
		width: 100%;
		max-width: 40.42%;
		flex: 0 0 40.42%;
		display: flex;
		align-items: center;
		@include mq {
			max-width: 100%;
			flex: 0 0 100%;
			justify-content: center;
			padding-bottom: 16px;
		}
	}
	.col-6 {
		width: 100%;
		max-width: 59.58%;
		flex: 0 0 59.58%;
		@include mq {
			max-width: 100%;
			flex: 0 0 100%;
		}
	}
	.link-home {
		display: flex;
		align-items: center;
		@include font-size(32);
		line-height: 1.71;
		@include mq {
			@include font-size(28);

		}
		img {
			flex-shrink: 0;
			margin-right: 20px;
		}
		.pt {
			padding-top: 18px;
		}
	}
	.list {
		@extend .row;
		margin: 0 -9px;
		@include mq {
			margin: 0 auto;
			max-width: 255px;
		}
		li {
			width: 100%;
			max-width: 50%;
			flex: 0 0 50%;
			padding: 0 9px;
			@include mq {
				max-width: 100%;
				flex: 0 0 100%;
				padding: 0;
				margin-bottom: 8px;
			}
			a {
				border: 1px solid #707070;
				display: block;
				padding: 40px 50px 39px 27px;
				position: relative;
				@include font-size(20);
				line-height: 1.2;
				background-color: #fff;
				@include mq {
					@include font-size(14);
					line-height: 1.43;
					padding: 12px 22px 10px 11px;
				}
				&:after {
					content: '';
					position: absolute;
					z-index: 1;
					width: 15px;
					height: 26px;
					@include v-align;
					right: 21px;
					background: url("../img/service/lightning/standard/ico-next.png") no-repeat center;
					@include mq {
						width: 12px;
						height: 18px;
						right: 4px;
						background-size: contain;
					}
				}
			}
		}
	}
}