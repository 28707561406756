.hokuden-head {
	@include mq(md-min) {
		padding-bottom: 19px;
		margin-bottom: 55px;
	}
	.intro-head {
		&__wrap {
			align-items: center;
			@include mq(lg) {
				flex-wrap: wrap;
			}
		}
		&__img {
			width: 372px;
			padding: 0;
			@include mq(lg) {
				width: 100%;
				text-align: center;
			}
		}
	}
}


.box-mesh {
	margin-bottom: 32px;
	@include mq {
		margin-bottom: 20px;
	}
}
.box-construction {

}
.construction {
	&-flex {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		margin-bottom: 57px;
		@include mq {
			margin-bottom: 50px;
		}
		&__left {
			width: 540px;
			padding-right: 30px;
			@include mq(lg) {
				width: 100%;
				margin-bottom: 20px;
				padding-right: 0;
			}
		}
		&__list {
			margin-bottom: 7px;
			li {
				padding-left: 34px;
				position: relative;
				margin-bottom: 9px;
				@include mq(lg) {
					padding-left: 24px;
				}
				&:last-child {
					margin-bottom: 0;
				}
				.number {
					position: absolute;
					left: 2px;
					top: 0;
				}
			}
		}
		&__note {
			padding-left: 2px;
			color: #FC0505;
			margin-bottom: 18px;
		}
		&__right {
			width: calc(100% - 540px);
			@include mq(lg) {
				width: 100%;
				max-width: 450px;
				margin: 0 auto;
				text-align: center;
			}
			p {
				margin-bottom: 6px;
			}
		}
	}
	&-list {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -11px;
		line-height: 1.56;
		@include mq(lg) {
			margin: 0 -5px;
		}
		&__item {
			width: 25%;
			padding: 0 11px;
			margin-bottom: 30px;
			@include mq(lg) {
				padding: 0 5px;
			}
			@include mq {
				width: 50%;
			}
			@include mq(xs) {
				width: 100%;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
		&__img {
			margin-bottom: 15px;
			img {
				width: 100%;
			}
		}
		&__content {
			position: relative;
			padding-left: 38px;
			@include mq(sm) {
				padding-left: 30px;
			}
		}
		
		&__number {
			width: 30px;
			height: 30px;
			line-height: 30px;
			background: #346EB1;
			border-radius: 50%;
			text-align: center;
			color: white;
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			padding: 1px 2px 2px;
			@include mq(sm) {
				width: 24px;
				height: 24px;
				line-height: 24px;
			}
		}
	}
}

.info {
	&-list {
		font-weight: bold;
		line-height: 1.5;
		&__item {
			margin-bottom: 20px;
			&:last-child {
				margin-bottom: 0;
			}
		}
		&__wrap {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 11px;
			align-items: center;
			&:last-child {
				margin-bottom: 0;
			}
		}
		&__ttl {
			width: 223px;
			text-align: right;
			padding-right: 14px;
			@include mq(sm) {
				width: 100%;
				padding-right: 0;
				text-align: left;
			}
			&--full {
				width: 100%;
				text-align: left;
				margin-bottom: 9px;
			}
		}
	}
	&-txt {
		display: flex;
		width: 100%;
		max-width: 199px;
		border: 2px solid #303030;
		text-align: center;
		dt {
			width: 63.4%;
			border-right: 2px solid #303030;
			background: #96B7DD;
			padding: 2px;
		}
		dd {
			width: 36.6%;
			padding: 2px;
		}
	}
}



.box-grounding {
	margin-bottom: 51px;
	@include mq {
		margin-bottom: 50px;
	}
	&__des {
		font-weight: bold;
		margin-bottom: 37px;
	}
}

.grounding {
	&-wrap {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 50px;
		@media only screen and (max-width: 850px) {
			> * {
				margin-bottom: 30px;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
		@include mq {
			margin-bottom: 30px;
		}
		&__left {
			width: 260px;
			@media only screen and (max-width: 850px) {
				width: 100%;
			}
			&02 {
				@media only screen and (min-width: 851px) {
					width: 235px;
				}
			}
		}
		&__txt {
			margin-bottom: 22px;
			sub{
				@include font-size(10);
				vertical-align: text-top;
			}
		}
		&__txt02 {
			font-weight: bold;
			margin-bottom: 2px;
		}
		&__between {
			width: calc(71.05% - 260px);
			@media only screen and (max-width: 850px) {
				width: 100%;
			}
			&02 {
				@media only screen and (min-width: 851px) {
					width: calc(71.05% - 235px);
				}
			}
			&03 {
				@media only screen and (min-width: 851px) {
					width: calc(60.95% - 235px);
				}
			}
		}
		&__img01 {
			padding: 0 10px 0 0;
			@media only screen and (max-width: 850px) {
				padding: 0;
			}
		}
		&__img02 {
			padding: 43px 73px 0 0;
			@media only screen and (max-width: 850px) {
				padding: 0;
			}
		}
		&__img03 {
			padding: 0 16px 0 0;
			@media only screen and (max-width: 850px) {
				padding: 0;
			}
		}
		
		&__list {
			display: flex;
			text-align: center;
			justify-content: space-around;
			padding: 59px 30px 0 5px;
			@media only screen and (max-width: 850px) {
				padding: 0;
				li {
					padding: 0 5px;
				}
			}
			.img {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-bottom: 19px;
				@include mq(ipd) {
					@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
						display: block;
						img {
							width: 100%;
						}
					}
				}
			}
		}
		&__right {
			width: 28.95%;
			@media only screen and (max-width: 850px) {
				width: 100%;
			}
			&02 {
				@media only screen and (min-width: 851px) {
					width: 41.45%;
					margin-right: -2.4%;
				}
			}
			.img {
				@media only screen and (max-width: 850px) {
					text-align: center;
				}
			}
		}
	}
	
	&-wrap02 {
		display: flex;
		flex-wrap: wrap;
		&__left {
			width: 59.3%;
			@include mq {
				width: 100%;
				margin-bottom: 30px;
			}
			.txt-lg {
				font-weight: bold;
				margin-bottom: 7px;
			}
		}
		&__img {
			padding-left: 3px;
		}
		&__right {
			width: 40.7%;
			padding: 33px 0 0 20px;
			line-height: 1.75;
			@include mq {
				width: 100%;
				padding: 0;
			}
			ul {
				margin-left: -4px;
				li {
					padding-left: 13px;
					text-indent: -13px;
				}
			}
		}
	}
	
	&-flex {
		.gel-flex {
			@include mq(lg-min) {
				&__left {
					width: 614px;
				}
				&__img {
					width: calc(100% - 614px);
				}
			}
		}
	}
	&-construction {
		&__list {
			display: flex;
			justify-content: space-between;
			margin: 0 -10px;
			@include mq {
				margin: 0 -5px -30px;
				flex-wrap: wrap;
			}
			li {
				padding: 0 10px;
				margin-bottom: 30px;
				@include mq {
					width: 50%;
					padding: 0 5px;
				}
				.img {
					margin-bottom: 9px;
				}
			}
		}
	}
}