
body {
	color: #303030;
	font-family: $font-meiryo;
	font-size: 100%;
	-webkit-font-smoothing: antialiased;
	line-height: 1.4;
}

.mac {
	table {
		table-layout: inherit !important;
	}
}

//header
.header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 99;
	@include mq(ipd) {
		height: 70px;
	}
	
	&-top {
		padding: 16px 0;
		background: #fff;
		
		.inner {
			display: flex;
			align-items: center;
			justify-content: space-between;
			@include mq(ipd) {
				height: 100%;
			}
		}
		
		@include mq(ipd) {
			height: 100%;
		}
	}
	
	&-logo {
		@include mq(ipd) {
			width: 87.5%;
			max-width: 300px;
		}
	}
	
	&-control {
		display: flex;
		align-items: center;
		@include mq(ipd) {
			position: fixed;
			bottom: 0;
			left: 0;
			right: 0;
			background: #D0D0D0;
			height: 70px;
			align-items: stretch;
			@include mq(){
				transform: translateY(70px);
				transition: all 0.3s;
				&.on{
					transform: translateY(0px);
					transition: all 0.3s;
				}
			}
		}
		
		&__mail {
			@include font-size(16);
			margin-right: 39px;
			
			img {
				margin-right: 7px;
			}
			
			@include mq(ipd) {
				width: 50%;
				margin: 0;
				background: #346EB1;
				@include font-size(12);
				color: #fff;
				img {
					margin: 0 0 0 15px;
					width: 25px;
					@include mq() {
						width: 25px;
					}
				}
				> a {
					width: 100%;
					height: 100%;
					padding: 21px 17px 0 0;
					display: block;
					@include mq(sm) {
						padding: 23px 17px 0 0;
					}
					@include mq(xs) {
						padding-top: 26px;
					}
					
					> span {
						width: 100%;
						display: flex;
						align-items: center;
						justify-content: center;
					}
					img{
						transform: translateY(2px);
					}
				}
			}
			
			.txt {
				position: relative;
				top: 2px;
				@include mq(ipd) {
					@include font-size(18);
				}
				@include mq(sm){
					@include font-size(14);
				}
			}
		}
		
		&__search {
			width: 180px;
			@include mq(ipd) {
				width: 50%;
				display: flex;
				align-items: center;
				padding: 8px 0 0 8px;
				justify-content: center;
			}
			table.gsc-search-box {
				position: relative;
				@include mq(){
					width: 210px !important;
				}
				@include mq(sm){
					width: 165px !important;
				}
				@include mq(xs){
					width: 137px !important;
				}
				button.gsc-search-button.gsc-search-button-v2 svg {
					width: 32px !important;
					height: 22px !important;
				}
				input#gsc-i-id1 {
					background: none !important;
					text-indent: 4px !important;
					padding-left: 35px !important;
					max-width: 100% !important;
				}
				button.gsc-search-button.gsc-search-button-v2 {
					position: absolute;
					left: 0;
					top: 0;
				}
			}
			form {
				display: flex;
				
				button {
					width: 30px;
					height: 30px;
					border: 1px solid #303030;
					background: #303030;
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 0;
					text-align: center;
					@include mq {
						position: relative;
						img {
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
						}
					}
				}
				
				input {
					border: 1px solid #303030;
					border-radius: 0;
					border-left: none;
					width: 100%;
					max-width: 155px;
					@include font-size(12);
					padding: 2px 5px;
					-webkit-appearance: none;
					-moz-appearance: none;
					@include mq(xs) {
						width: 112px;
					}
				}
			}
		}
	}
	
	&-nav {
		background: rgba(#EEE, 0.6);
		//transition: 0.3s;
		@include mq(ipd) {
			background: #F1F0ED;
			position: fixed;
			left: 0;
			right: 0;
			overflow-y: auto;
			top: 70px;
			height: calc(100vh - 70px);
			opacity: 0;
			pointer-events: none;
			transition: 0.3s;
			.inner {
				padding: 0;
			}
			&.active {
				opacity: 1;
				pointer-events: auto;
				transition: 0.3s;
			}
		}
	}
	
	&-menu {
		display: flex;
		align-items: stretch;
		flex-wrap: wrap;
		@include mq(ipd) {
			display: block;
			padding: 0 0 50px;
		}
		
		&__item {
			width: 16.66%;
			@include font-size(12);
			text-align: center;
			@include mq(ipd) {
				width: 100%;
				@include font-size(14);
			}
			
			a {
				display: block;
				width: 100%;
				height: 100%;
				border-bottom: 3px solid transparent;
				padding: 3px 10px;
				@include mq(ipd) {
					border-bottom: 1px solid #707070;
					padding: 0 10px 0 16px;
					text-align: left;
				}
				
				.en {
					@include mq(ipd-min) {
						font-family: $font-raleway;
						@include font-size(18);
						display: block;
					}
				}
				
				&:hover {
					@include mq(ipd-min) {
						border-color: #346EB1;
					}
					opacity: 1;
				}
			}
			
			&.is-active {
				a {
					border-color: #346EB1;
					@include mq(ipd) {
						border-color: #707070;
					}
				}
			}
		}
		
		&__sub {
			display: none;
			background: #E2E0DC;
			
			a {
				@include mq(ipd) {
					padding-left: 48px;
				}
			}
		}
		
		&__sub02 {
			display: none;
			background: #61BEF7;
			
			a {
				@include mq(ipd) {
					padding-left: 80px;
					color: #fff;
					border-color: #FFF;
				}
			}
		}
		
		&__wrap {
			@include mq(ipd) {
				position: relative;
				display: inline-block;
				padding: 11px 0 9px 0;
				letter-spacing: -0.1px;
				&.wrap-sub {
					padding-right: 35px;
				}
			}
		}
		
		&__icon {
			position: absolute;
			width: 35px;
			right: 0;
			top: 0;
			bottom: 0;
			padding: 10px 0 0 6px;
			
			&:before {
				display: inline-block;
				content: '';
				border-top: 8px solid #000;
				border-left: 5px solid transparent;
				border-right: 5px solid transparent;
			}
		}
	}
	
	.mobile-icon {
		@include mq(ipd) {
			width: 28px;
			height: 29px;
			position: absolute;
			top: 50%;
			right: 16px;
			transform: translateY(-50%);
			span {
				position: absolute;
				width: 100%;
				height: 4px;
				background: #346EB1;
				transition: 0.3s;
				
				&:first-child {
					top: 0;
				}
				
				&:nth-of-type(2) {
					top: 45%;
				}
				
				&:last-child {
					bottom: 0;
				}
			}
			&--close {
				width: 37px;
				height: 37px;
				position: absolute;
				top: 49%;
				right: 11px;
				
				span {
					&:first-child {
						transform: rotate(45deg);
						top: 45%
					}
					
					&:nth-of-type(2) {
						opacity: 0;
					}
					
					&:last-child {
						transform: rotate(-45deg);
						bottom: 45%
					}
				}
			}
		}
	}
	
	&.fixed {
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
		.header-nav {
			background: rgba(238, 238, 238, 1);
			//transition: 0.3s;
		}
	}
}


//footer
.footer {
	background: #EEEEEE;
	position: relative;
	@include mq(ipd) {
		transition: all 0.3s;
		&.on{
			padding-bottom: 70px;
			transition: all 0.3s;
		}
	}
	
	&__ttl {
		@include font-size(20);
		margin-bottom: 8px;
		@include mq {
			margin-bottom: 11px;
		}
		@include mq(xs) {
			@include font-size(17);
		}
		
		span {
			@include font-size(16);
			font-family: $font-raleway;
			@include mq(xs) {
				@include font-size(13);
			}
		}
		
		&--border {
			border-bottom: 1px solid #707070;
			padding-bottom: 6px;
			margin-bottom: 28px;
			@include mq {
				padding-bottom: 6px;
				margin-bottom: 15px;
			}
		}
	}
	
	&-sitemap {
		padding: 36px 0 3px;
		@include mq {
			padding: 28px 0 38px;
		}
		
		.sitemap-list {
			display: flex;
			flex-wrap: wrap;
			align-items: flex-start;
			justify-content: space-between;
			@include font-size(14);
			@include mq(lg) {
				justify-content: flex-start;
			}
			@include mq(xs) {
				@include font-size(12);
			}
			
			&__item {
				line-height: 1.43;
				padding-right: 20px;
				margin-bottom: 30px;
				@include mq(lg) {
					width: 33.33%;
				}
				@include mq {
					margin-bottom: 25px;
					transform: translateY(3px);
				}
				
				&:last-child {
					padding-right: 0;
					@include mq(lg) {
						width: 100%;
					}
					@include mq {
						margin-bottom: 0;
						padding-top: 8px;
					}
					
					.sitemap-list {
						&__ttl {
							margin-bottom: 38px;
							@include mq {
								margin-bottom: 16px;
							}
							
							&:last-child {
								margin-bottom: 0;
							}
						}
					}
				}
			}
			
			&__ttl {
				@include font-size(18);
				line-height: 1.78;
				margin-bottom: 13px;
				display: block;
				@include mq {
					@include font-size(16);
					line-height: 2;
					margin-bottom: 0;
				}
				@include mq(xs) {
					@include font-size(14);
				}
			}
			
			&__sub {
				@include mq {
					display: none;
				}
				
				li {
					margin-bottom: 8px;
					
					&:last-child {
						margin-bottom: 0;
					}
					
					a {
						padding-left: 4px;
						position: relative;
						display: block;
						
						&:before {
							position: absolute;
							content: '';
							width: 3px;
							height: 1px;
							background: #303030;
							top: 10px;
							left: 0;
						}
					}
				}
			}
		}
	}
	
	&-contact {
		background: #14137E;
		color: white;
		padding: 46px 0 54px;
		align-items: flex-start;
		@include mq {
			padding: 19px 0 32px;
		}
		
		.inner {
			display: flex;
			flex-wrap: wrap;
		}
		
		&__left {
			width: 380px;
			padding: 13px 20px 0 0;
			@include mq {
				width: 100%;
				max-width: 100%;
				padding-right: 0;
				margin-bottom: 22px;
			}
		}
		
		&__logo {
			display: inline-block;
			margin-bottom: 25px;
			@include mq {
				margin-bottom: 14px;
			}
		}
		
		&__txt {
			@include font-size(16);
			line-height: 1.75;
			@include mq {
				@include font-size(14);
				line-height: 1.72;
			}
			@include mq(xs) {
				@include font-size(12);
			}
		}
		
		&__right {
			width: calc(100% - 380px);
			max-width: 756px;
			margin-left: auto;
			@include mq {
				width: 100%;
				max-width: 100%;
			}
		}
	}
	
	&-btn {
		width: 100%;
		height: 159px;
		display: flex;
		align-items: center;
		background: url("../img/common/bg-footer-contact.png") no-repeat;
		background-size: cover;
		@include mq {
			height: 197px;
			background: url("../img/common/bg-footer-contact-sp.png") no-repeat;
			background-size: cover;
		}
		
		&__inner {
			background: rgba(#303030, 0.81);
			width: 100%;
			max-width: 355px;
			height: 80px;
			display: flex;
			justify-content: center;
			align-items: center;
			@include mq(lg) {
				max-width: 290px;
			}
			@include mq {
				max-width: 176px;
			}
		}
		
		&__wrap {
			font-family: $font-raleway;
			@include font-size(30);
			line-height: 1;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			@include mq(lg) {
				@include font-size(22);
			}
			
			img {
				margin-right: 14px;
				@include mq {
					margin-right: 5px
				}
			}
			
			span {
				font-family: $font-meiryo;
				@include font-size(16);
				margin-left: 14px;
				@include mq {
					width: 100%;
					text-align: center;
					margin: 10px 0 0 0;
				}
			}
		}
	}
	
	.copyright {
		color: #14137E;
		text-align: center;
		padding: 6px 16px 5px;
		@include font-size(14);
		@include mq {
			@include font-size(12);
			padding: 8px 16px 6px;
		}
		@include mq(xs) {
			@include font-size(10);
		}
	}
	
	.backtop {
		width: 40px;
		height: 40px;
		background: #346EB1;
		position: fixed;
		bottom: 30px;
		right: 42px;
		z-index: 10;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
		font-family: $font-raleway;
		@include font-size(12);
		display: flex;
		align-items: center;
		justify-content: center;
		color: white;
		text-align: center;
		cursor: pointer;
		@include mq(ipd) {
			bottom: 80px;
			right: 10px;
		}
		
		span {
			padding-top: 21px;
			position: relative;
			
			&:before {
				position: absolute;
				content: '';
				width: 14px;
				height: 14px;
				border-left: 2px solid white;
				border-top: 2px solid white;
				top: 10px;
				left: 50%;
				transform: translateX(-50%) rotate(45deg);
			}
		}
	}
}


// css for block-safe
.sec-safe {
	position: relative;
	
	.ttl-safe {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		
		@include mq(lg) {
			justify-content: center;
			flex-wrap: wrap;
		}
		
		span {
			padding-left: 18px;
			@include mq(lg) {
				padding-left: 10px;
			}
		}
		
		img {
			@include mq(xl){
				width: 5vw;
			}
			@include mq(lg){
				width: 64px;
			}
			
		}
		
	}
	
	.block-safe {
		@include font-size(32);
		line-height: 1.5;
		color: #303030;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		@include mq(xl) {
			font-size: 2.5vw;
		}
		@include mq(lg) {
			@include font-size(28);
		}
		
		a {
			@include font-size(20);
			line-height: 1.2;
			color: #303030;
			background: #fff;
			border: 1px solid #707070;
			width: 100%;
			padding: 6px 10px 6px 16px;
			min-height: 81px;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			margin-bottom: 10px;
			position: relative;
			@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
				height: 81px;
			}
			@include mq(xl){
				font-size: 1.6vw;
				padding-right: 2.5vw;
			}
			
			@include mq(lg) {
				@include font-size(14);
				min-height: 44px;
				max-width: 255px;
				margin: 0px auto 9px;
				line-height: 1.43;
				padding: 9px 10px 6px 16px;
				@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
					height: 44px;
				}
			}
			
			&:after {
				content: '';
				height: 15px;
				width: 15px;
				border-top: 2px solid #303030;
				border-right: 2px solid #303030;
				position: absolute;
				top: 50%;
				transform: rotate(45deg) translateY(-50%);
				right: 30px;
				display: inline-block;
				@include mq(xl){
					right: 1.9vw;
					height: 1.2vw;
					width: 1.2vw;
				}
				@include mq(lg) {
					height: 10px;
					width: 10px;
					right: 11px;
					top: 48%;
				}
			}
			
			&:last-child {
				margin-bottom: 0;
			}
		}
		
		.box-safe {
			position: relative;
			z-index: 1;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			padding-top: 30px;
			padding-bottom: 30px;
			@include mq(lg) {
				padding-bottom: 20px;
				padding-top: 15px;
			}
		}
		
		.ttl-safe {
			width: calc(49% - 1px);
			padding-right: 5px;
			@include mq(xl){
				width: 50%;
			}
			@include mq(lg) {
				width: 100%;
				padding-right: 0;
				margin-bottom: 9px;
			}
		}
		
		.link-safe {
			width: calc(51% + 1px);
			@include mq(xl){
				width: 50%;
			}
			@include mq(lg) {
				width: 100%;
			}
		}
		
		.part-safe {
			width: 50%;
			@include mq(lg) {
				width: 100%;
				
			}
			
			&__right {
				padding-left: 39px;
				@include mq(xl){
					padding-left: 2.5vw;
				}
				@include mq(lg) {
					padding-left: 0;
					position: relative;
				}
				
				.box-safe {
					@include mq(lg) {
						padding-top: 14px;
						padding-bottom: 18px;
					}
				}
				
				.ttl-safe {
					@include mq(lg) {
						margin-bottom: 17px;
						max-width: 255px;
						margin: 0 auto 17px;
						justify-content: flex-start;
						align-items: flex-end;
					}
					span {
						@include mq(lg) {
							padding-left: 20px;
						}
					}
					img {
						@include mq(lg) {
							width: 66px;
						}
					}
				}
				
				a {
					min-height: 105px;
					padding: 6px 10px 6px 29px;
					@include mq(lg) {
						min-height: 44px;
						padding: 6px 10px 6px 11px;
						@include font-size(16);
					}
				}
				
				&:after {
					content: '';
					position: absolute;
					right: 0;
					top: 0;
					bottom: 0;
					background: #FCF3E0;
					left: 50%;
					z-index: 0;
					@include mq(lg) {
						left: 0;
						right: -16px;
						width: auto;
					}
				}
				
				&:before {
					@include mq(lg) {
						content: '';
						position: absolute;
						left: -16px;
						top: 0;
						bottom: 0;
						width: auto;
						background: #FCF3E0;
						right: 0;
						z-index: 0;
					}
					
				}
			}
			
			&__left {
				//background: #E3FEFF;
				padding-right: 39px;
				@include mq(xl) {
					padding-right: 2.5vw;
				}
				@include mq(lg) {
					padding-right: 0;
					position: relative;
				}
				
				&:after {
					content: '';
					position: absolute;
					left: 0;
					top: 0;
					bottom: 0;
					background: #E3FEFF;
					right: 50%;
					z-index: 0;
					@include mq(lg) {
						left: 0;
						right: -16px;
						width: auto;
					}
				}
				
				&:before {
					@include mq(lg) {
						content: '';
						position: absolute;
						left: -16px;
						top: 0;
						bottom: 0;
						width: auto;
						background: #E3FEFF;
						right: 0;
						z-index: 0;
					}
					
				}
			}
		}
	}
	
}

// end css for block-safe