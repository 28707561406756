.block-worksdetail{
	.part{
		&-time{
			background: #96B7DD;
			padding: 6px 10px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 15px;
			&__name{
				@include font-size(12);
				color: #346EB1;
				background: #fff;
				border: 1px solid #346EB1;
				padding: 2px 14px;
				display: inline-block;
			}
			.topic-list__date{
				margin-bottom: 0;
			}
		}
		&-introduce{
			display: flex;
			align-items: flex-start;
			flex-wrap: wrap;
			margin-bottom: 31px;
			&__img{
				width: 41%;
				img{
					width: 100%;
				}
				@include mq{
					width: 100%;
					margin-bottom: 20px;
				}
			}
			
			&__des{
				width: 59%;
				padding-left: 20px;
				margin-top: -3px;
				@include mq{
					width: 100%;
					padding-left: 0;
					margin-top: 0;
				}
			}
			
		}
	}
}