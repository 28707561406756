.list-quality{
	display: flex;
	flex-wrap: wrap;
	margin: 0 -8px 40px;
	@include mq{
		margin-bottom: 13px;
	}
	li{
		width: 33.33%;
		padding: 0 8px;
		margin-bottom: 47px;
		@include mq{
			width: 50%;
			margin-bottom: 18px;
			&:last-child{
				margin-bottom: 0;
			}
		}
		@include mq(lg){
			width: 100%;
		}
	}
	img{
		display: block;
		margin: 0 auto 10px;
		width: 100%;
	}
	&__ttl{
		text-align: center;
		@include font-size(16);
		line-height: 1.75;
		text-align: center;
		@include mq(lg){
			@include font-size(14);
			line-height: 1.6875;
		}
	}
}

.box-txt{
	padding: 18px 20px 11px 40px;
	border:solid 3px #346EB1;
	max-width: 848px;
	margin: 0 auto;
	@include mq{
		padding: 12px 16px;
	}
	p{
		@include font-size(18);
		line-height: 2;
		@include mq{
			@include font-size(16);
			line-height: 1.5;
		}
	}
}
.sec-service-regulation-quality{
	padding-bottom: 88px;
	.box-equipment-inspection{
		margin-bottom: 53px;
	}
	@include mq{
		padding-bottom: 50px;
	}
}