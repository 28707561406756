.sec-drone-top{
	padding-bottom: 100px;
	@include mq(){
		padding-bottom: 46px;
	}
	.ttl-comp02{
		margin-bottom: 38px;
		@include mq(){
			margin-bottom: 22px;
		}
	}
}
.box-drone{
	display: flex;
	margin-bottom: 62px;
	@include mq(){
		flex-wrap: wrap;
		margin-bottom: 30px;
	}
	&__left{
		width: 49.375%;
		img{
			width: 100%;
		}
		@include mq(xl-1250){
			width: 50%;
		}
		@include mq(){
			width: 100%;
			margin-bottom: 25px;
		}
	}
	&__right{
		width: calc(100% - 49.375%);
		padding-left: 10px;
		position: relative;
		top: -6px;
		@include mq(xl-1250){
			width: 50%;
			top: 0;
		}
		@include mq(){
			width: 100%;
			padding-left: 0;
			top: 0;
		}
		.txt-basic{
			@include font-size(18);
			line-height: 1.8;
			font-weight: bold;
			margin-bottom: 19px;
			padding-left: 7px;
			@include mq(){
				@include font-size(16);
				padding-left: 0;
				margin-bottom: 15px;
			}
		}
		.box-support{
			display: flex;
			align-items: center;
			justify-content: space-between;
			@include mq(lg){
				flex-wrap: wrap;
			}
			@include mq(){
				flex-wrap: nowrap;
			}
			@include mq(sm){
				flex-wrap: wrap;
			}
			.img{
				width: calc(100% - 216px);
				@include mq(lg){
					width: 100%;
					text-align: center;
				}
				@include mq(){
					width: calc(100% - 216px);
					text-align: left;
				}
				@include mq(sm){
					width: 100%;
					margin-bottom: 15px;
				}
			}
			.txt-circle{
				width: 216px;
				height: 216px;
				border-radius: 50%;
				background: #FF8D08;
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;
				@include font-size(24);
				color: #fff;
				line-height: 1.35;
				margin-right: 25px;
				
				@include mq(xl-1250){
					margin-right: 0;
				}
				@include mq(lg){
					margin: 0 auto;
				}
				
				@include mq(sm){
					margin: 0 auto;
					@include font-size(20);
					line-height: 1.5;
				}
			}
		}
	}
}
.box-steps{
	margin-bottom: 100px;
	@include mq(){
		margin-bottom: 50px;
	}
	.ttl-comp01{
		margin-bottom: 52px;
		@include mq(){
			margin-bottom: 20px;
		}
	}
	.list-steps{
		display: flex;
		margin: 0 -8px;
		@include mq(){
			flex-wrap: wrap;
			margin: 0;
		}
		.item{
			padding: 0 8px;
			width: 25%;
			@include mq(){
				width: 100%;
				padding: 0;
				margin-bottom: 15px;
			}
			&-wp{
				background: #F2F0ED;
				text-align: center;
				padding: 18px 20px 42px;
				@include mq(lg){
					padding: 18px 10px;
				}
				@include mq(){
					padding: 15px;
				}
				&__ttl{
					padding-bottom: 3px;
					margin-bottom: 20px;
					display: inline-flex;
					align-items: center;
					border-bottom: 1px dashed #346EB1;
					span{
						color: #346EB1;
						&:first-of-type{
							@include font-size(16);
							font-weight: bold;
							margin-right: 12px;
							@include mq(){
								@include font-size(14);
							}
						}
						&:last-of-type{
							@include font-size(48);
							font-weight: bold;
							line-height: 1;
							@include mq(){
								@include font-size(36);
							}
						}
					}
				}
				&__des{
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					justify-content: center;
					.txt-basic{
						line-height: 1.75;
					}
					a{
						color: #346EB1;
					}
					small{
						width: 100%;
						display: block;
						@include font-size(14);
						margin-top: 6px;
						text-align: left;
						@include mq(){
							@include font-size(12);
						}
					}
				}
			}
		}
	}
}
.box-flight-plan{
	padding-bottom: 30px;
	.list-item{
		.item{
			display: flex;
			@include mq(){
				flex-wrap: wrap;
			}
			&__info{
				width: calc(100% - 40.9%);
				padding-right: 16px;
				@include mq(){
					width: 100%;
					order: 2;
					padding-right: 0;
				}
				&--ttl{
					@include font-size(24);
					color: #346EB1;
					font-weight: bold;
					border-bottom: 1px solid #346EB1;
					margin-bottom: 20px;
					padding-bottom: 9px;
					@include mq(lg){
						@include font-size(22);
					}
					@include mq(){
						@include font-size(18);
						padding-bottom: 5px;
					}
				}
				.txt-basic{
					@include font-size(20);
					margin-bottom: 27px;
					@include mq(){
						@include font-size(16);
						margin-bottom: 15px;
					}
				}
				.list-dots{
					li{
						@include font-size(20);
						line-height: 1.6;
						padding-left: 30px;
						position: relative;
						margin-bottom: 32px;
						@include mq(lg){
							@include font-size(18);
						}
						@include mq(){
							@include font-size(16);
							margin-bottom: 20px;
							padding-left: 20px;
						}
						&:before{
							content: '';
							display: block;
							width: 22px;
							height: 22px;
							background: #346EB1;
							border-radius: 50%;
							position: absolute;
							top: 5px;
							left: 0;
							@include mq(){
								width: 15px;
								height: 15px;
							}
						}
					}
				}
			}
			&__img{
				width: 40.9%;
				img{
					width: 100%;
				}
				@include mq(){
					width: 100%;
					order: 1;
					margin-bottom: 15px;
				}
			}
		}
	}
}
.box-orange{
	background: #FFEBCD;
	padding: 35px 46px 42px;
	margin-bottom: 80px;
	@include mq(){
		margin-bottom: 50px;
		padding: 15px 20px;
	}
	.ttl-border-orange{
		text-align: center;
		margin-bottom: 28px;
		@include mq(){
			margin-bottom: 15px;
		}
		span{
			display: inline-block;
			@include font-size(20);
			font-weight: bold;
			border-bottom: 3px solid #FF8D08;
			padding: 0 6px 10px;
			@include mq(){
				@include font-size(18);
			}
		}
	}
	.list-orange{
		display: flex;
		margin: 0 -10px;
		@include mq(sm){
			flex-wrap: wrap;
			margin: 0;
		}
		li{
			width: 25%;
			padding: 0 10px;
			img{
				width: 100%;
			}
			@include mq(sm){
				width: 100%;
				padding: 0;
				margin-bottom: 15px;
				&:last-of-type{
					margin-bottom: 0;
				}
			}
		}
	}
}
.box-shooting-sample{
	margin-bottom: 50px;
	@include mq(){
		margin-bottom: 30px;
	}
	.box-video{
		display: flex;
		@include mq(){
			flex-wrap: wrap;
		}
		&__left{
			width: calc(100% - 57.81%);
			padding-right: 50px;
			@include mq(){
				width: 100%;
				padding-right: 0;
				margin-bottom: 30px;
				text-align: center;
			}
		}
		&__right{
			width: 57.81%;
			@include mq(){
				width: 100%;
			}
			.box-iframe{
				margin-bottom: 17px;
				@include mq(){
					margin-bottom: 15px;
				}
				iframe{
					width: 100%;
					height: 416px;
				}
			}
			.txt-caption{
				@include font-size(18);
				text-align: center;
				display: block;
				@include mq(){
					@include font-size(16);
				}
			}
		}
	}
}
.box-drone-lineup{
	margin-bottom: 82px;
	@include mq(){
		margin-bottom: 30px;
	}
}
.box-contact{
	margin-bottom: 85px;
	@include mq(){
		margin-bottom: 30px;
	}
}
.list-single-img{
	max-width: 1066px;
	margin: 0 auto;
	.wp{
		margin: 0 -9px;
		display: flex;
		@include mq(){
			flex-wrap: wrap;
			margin: 0;
		}
		.item{
			width: 50%;
			padding: 0 9px;
			@include mq(sm){
				width: 100%;
				padding: 0;
				margin-bottom: 15px;
				&:last-of-type{
					margin-bottom: 0;
				}
			}
			&__caption{
				text-align: center;
				@include font-size(24);
				font-weight: bold;
				display: block;
				margin-top: 17px;
				@include mq(lg){
					@include font-size(22);
				}
				@include mq(){
					@include font-size(16);
					margin-top: 5px;
				}
			}
		}
	}
}

.tab-blue-white{
	display: flex;
	align-items: center;
	background: #fff;
	border: 2px solid #346EB1;
	max-width: 1066px;
	margin: 0 auto 72px;
	@include mq(){
		margin-bottom: 30px;
	}
	span{
		width: 50%;
		@include font-size(24);
		line-height: 1;
		font-weight: bold;
		text-align: center;
		padding: 11px 0;
		@include mq(lg){
			@include font-size(20);
		}
		@include mq(){
			@include font-size(16);
			line-height: 1.5;
		}
		&.blue{
			background: #346EB1;
			color: #fff;
			width: 49.3%;
		}
		&.white{
			width: calc(100% - 49.3%);
			color: #303030;
		}
		&.w100{
			width: 100%;
		}
	}
}
.txt-guide{
	@include font-size(24);
	font-weight: bold;
	text-align: center;
	margin-bottom: 37px;
	@include mq(lg){
		@include font-size(22);
	}
	@include mq(){
		@include font-size(16);
		margin-bottom: 15px;
	}
}

.btn-gradient{
	max-width: 416px;
	width: 100%;
	min-height: 75px;
	margin: 0 auto;
	background: rgb(97,190,247);
	background: linear-gradient(180deg, rgba(97,190,247,1) 0%, rgba(15,98,150,1) 100%);
	display: flex;
	align-items: center;
	@include font-size(24);
	line-height: 1;
	font-weight: bold;
	padding-left: 20px;
	color: #fff;
	position: relative;
	@include mq(){
		@include font-size(20);
		min-height: 50px;
	}
	&:after{
		content: '';
		display: block;
		background: url("/img/service/drone/ico-right.png") no-repeat center;
		background-size: cover;
		width: 13px;
		height: 26px;
		position: absolute;
		right: 20px;
		top: 50%;
		transform: translateY(-50%);
	}
}

.tl{
	display: block;
	@include mq(lg){
		display: none;
	}
}