.sec-ofice {
	padding-bottom: 69px;
	@include mq {
		padding-bottom: 50px;
	}
}

.ofice {
	&-link {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -8px 53px;
		text-align: center;
		@include font-size(18);
		font-weight: bold;
		color: #fff;
		@include mq(xl) {
			@include font-size(16);
			margin: 0 -5px 50px;
		}
		@include mq {
			@include font-size(15);
		}
		&__item {
			width: 25%;
			padding: 0 8px;
			margin-bottom: 10px;
			@include mq(xl) {
				padding: 0 5px;
			}
			@include mq(lg) {
				width: 33.33%;
			}
			@include mq {
				width: 50%;
			}
			a {
				display: block;
				width: 100%;
				background: #61BEF7;
				padding: 8px 10px 7px;
			}
		}
	}
	&-list {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -8px;
		@include mq(lg) {
			margin: 0;
		}
		&__item {
			width: 50%;
			padding: 0 8px;
			margin-bottom: 49px;
			@include mq(lg) {
				padding: 0;
				width: 100%;
				margin-bottom: 30px;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
		&__ttl {
			@include font-size(20);
			font-weight: bold;
			margin-bottom: 11px;
			color: #0060CB;
			@include mq(xl) {
				@include font-size(18);
			}
			@include mq {
				@include font-size(15);
			}
		}
		
		&__map {
			margin-bottom: 20px;
			@include mq {
				margin-bottom: 10px;
			}
			iframe {
				display: block;
				width: 100%;
				max-height: 328px;
			}
		}
		&__info {
			@include font-size(16);
			line-height: 2;
			@include mq(xl) {
				@include font-size(14);
				line-height: 1.5;
			}
			dl {
				display: flex;
				align-items: stretch;
				margin-bottom: 2px;
				dt {
					width: 31.8%;
					background: #346EB1;
					color: #fff;
					@include font-size(20);
					text-align: center;
					@include mq(xl) {
						@include font-size(17);
						padding: 5px 5px 4px;
						width: 110px;
					}
					@include mq {
						@include font-size(15);
						width: 85px;
					}
				}
				dd {
					width: calc(68.2% - 2px);
					margin-left: auto;
					background: #EEEEEE;
					padding: 7px 5px 0px 13px;
					@include mq(xl) {
						width: calc(100% - 110px);
					}
					@include mq {
						padding: 6px 5px 0px 10px;
						width: calc(100% - 85px);
					}
				}
			}
		}
	}
}