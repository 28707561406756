.sec-items-search{
	padding-bottom: 100px;
	@include mq() {
		padding-bottom: 60px;
	}
}
.subcategory {
	@include font-size(18);
	color: #346EB1;
	font-weight: bold;
	margin-bottom: 10px;
	@include mq() {
		@include font-size(16);
	}
}

.item-search-block{
	border: 1px solid #707070;
	background: #F2F0ED;
	padding: 20px 20px 0;
	margin-bottom: 55px;
	@include mq() {
		margin-bottom: 20px;
	}
	.search-ttl{
		@include font-size(28);
		font-weight: bold;
		color: #0060CB;
		border-bottom: 10px solid #96B7DD;
		padding-bottom: 3px;
		margin-bottom: 30px;
		@include mq() {
			@include font-size(18);
			margin-bottom: 15px;
			padding-bottom: 7px;
		}
		&--02{
			@include font-size(24);
			font-weight: bold;
			margin-bottom: 25px;
			@include mq() {
				@include font-size(16);
				margin-bottom: 15px;
			}
		}
	}
	.search-area{
		margin-bottom: 50px;
		@include mq() {
			margin-bottom: 20px;
		}
		.item{
			display:-webkit-box; // old
			display:-moz-box; // old
			display:-ms-flexbox; // ie
			display:-webkit-flex; // new
			display:flex; // new
			flex-wrap: wrap;
			margin-bottom: 10px;
			&__ttl{
				width: 280px;
				height: 40px;
				line-height: 40px;
				background: #D0D0D0;
				@include font-size(20);
				text-align: center;
				@include mq() {
					width: 100%;
					height: 30px;
					line-height: 30px;
					@include font-size(14);
					margin-bottom: 15px;
				}
			}
			&__field{
				position: relative;
				display:-webkit-box; // old
				display:-moz-box; // old
				display:-ms-flexbox; // ie
				display:-webkit-flex; // new
				display:flex; // new
				flex-wrap: wrap;
				padding-left: 15px;
				@include font-size(16);
				@include mq(md-min) {
					flex: 1;
				}
				@include mq() {
					width: 100%;
					padding: 0;
					margin-bottom: 15px;
				}
			}
			input[type="text"],
			select{
				height: 40px;
				border: 1px solid #707070;
				border-radius: 0;
				padding: 0 15px;
			}
			input[type="text"]{
				width: calc(100% - 190px);
				@include mq() {
					width: 100%;
					appearance: none;
				}
			}
			select{
				max-width: 250px;
				width: 100%;
				border-radius: 10px;
				margin-right: 15px;
				background: #FFF url("../img/items/select-arrow.png") no-repeat right 20px center;
				background-size: 13px auto;
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				&::-ms-expand{
					display: none;
				}
				@include mq(lg) {
					max-width: 200px;
					margin-right: 10px;
				}
				@include mq() {
					max-width: 100%;
					margin: 0 0 10px;
					&:last-of-type{
						margin-bottom: 0;
					}
				}
			}
			.s-btn{
				cursor: pointer;
				width: 172px;
				background: #0060CB url("../img/items/search-ico.png") no-repeat right 15px center;
				background-size: 22px auto;
				color: #FFF;
				letter-spacing: 10px;
				border: none;
				border-radius: 10px;
				@include font-size(21);
				text-align: left;
				padding: 0 0 0 20px;
				margin-left: 18px;
				height: 40px;
				@include mq() {
					margin: 25px auto 0;
					appearance: none;
				}
			}
		}
	}
	.search-category{
		display:-webkit-box; // old
		display:-moz-box; // old
		display:-ms-flexbox; // ie
		display:-webkit-flex; // new
		display:flex; // new
		flex-wrap: wrap;
		align-items: flex-start;
		@include mq() {
			margin-top: 20px;
		}
		li{
			width: 24.47%;
			margin: 0 0.7% 20px 0;
			position: relative;
			&:nth-child(4n){
				margin-right: 0;
			}
			&:hover{
				.list-search{
					display: block;
				}
			}
			@include mq(lg) {
				width: 32.5%;
				margin-right: 1.25%;
				&:nth-child(4n){
					margin-right: 1.25%;
				}
				&:nth-child(3n){
					margin-right: 0;
				}
			}
			@include mq() {
				width: 100%;
				margin: 0 0 20px;
			}
			a{
				position: relative;
				display: block;
				border: 1px solid #346EB1;
				height: 100%;
				color: #346EB1;
				background: #FFF;
				text-align: center;
				@include font-size(16);
				font-weight: bold;
				padding: 19px 30px 19px 30px;
				@include mq() {
					padding: 19px 20px;
				}
				&::after{
					content: "";
					display: block;
					position: absolute;
					right: 10px;
					top: 50%;
					margin-top: -9px;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 9px 0 9px 12px;
					border-color: transparent transparent transparent #346EB1;
				}
			}
			.list-search{
				position: absolute;
				top: 60px;
				left: 0;
				width: 100%;
				z-index: 23;
				padding: 15px;
				display: none;
				background: #346EB1 !important;
				li{
					width: 100%;
					margin-bottom: 5px;
					border: 0px;
					a{
						padding: 4px 30px !important;
						border: 0px !important;
						color: #fff !important;
						background: none !important;
						text-align: left;
						@include font-size(16);
						&:after{
							display: none;
						}
					}
				}
			}
		}
	}
}


.catalog-dl-btn{
	max-width: 630px;
	margin: 0 auto;
	padding: 45px 0 0;
	@include mq() {
		padding: 30px 0 0;
	}
	a{
		display:-webkit-box; // old
		display:-moz-box; // old
		display:-ms-flexbox; // ie
		display:-webkit-flex; // new
		display:flex; // new
		position: relative;
		align-items: center;
		width: 100%;
		padding: 0 0 0 165px;
		height: 160px;
		line-height: 1.8;
		border-radius: 20px;
		color: #FFF;
		@include font-size(36);
		/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#61bef7+0,0f6296+100 */
		background: #61bef7; /* Old browsers */
		background: -moz-linear-gradient(top,  #61bef7 0%, #0f6296 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top,  #61bef7 0%,#0f6296 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom,  #61bef7 0%,#0f6296 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#61bef7', endColorstr='#0f6296',GradientType=0 ); /* IE6-9 */
		@include mq() {
			@include font-size(20);
			height: 173px;
			padding-left: 175px;
		}
		@media screen and (max-width : 320px) {
			padding-left: 160px;
			@include font-size(18);
		}
		&::before{
			position: absolute;
			left: 20px;
			top: 50%;
			margin-top: -60px;
			content: "";
			display: block;
			width: 120px;
			height: 120px;
			background: url("../img/items/catalog.png") no-repeat 0 0;
			background-size: 100% auto;
		}
	}
}

.empty-txt{
	@include font-size(18);
	margin-bottom: 80px;
}