.list-qa{
	margin-bottom: 50px;
	&__ttl{
		@include font-size(24);
		font-weight: bold;
		line-height: 1.667;
		margin-bottom: 20px;
		@include mq{
			@include font-size(18);
			margin-bottom: 10px;
		}
	}
	&__item{
		margin-bottom: 53px;
		@include mq{
			margin-bottom: 30px;
		}
		&:last-child{
			margin-bottom: 0;
		}
	}
	&__question{
		padding: 17px 15px;
		border-radius: 7px;
		background: #346eb1;
		color:#fff;
		@include font-size(24);
		line-height: 1.2;
		display: flex;
		align-items: center;
		font-weight: bold;
		margin-bottom: 10px;
		position: relative;
		padding-left: 75px;
		@include mq{
			@include font-size(18);
			padding: 11px 10px 11px 60px;
		}
		@include mq(sm-ip){
			@include font-size(16);
		}
		span{
			display: inline-flex;
			justify-content: center;
			align-items: center;
			width: 50px;
			height: 50px;
			border-radius: 50%;
			color:#0060CB;
			background: #ffffff;
			@include font-size(36);
			line-height: .6;
			margin-right: 10px;
			flex-shrink: 0;
			font-weight: bold;
			position: absolute;
			left: 15px;
			top:6px;
			@include mq{
				width: 35px;
				height: 35px;
				flex-basis: 35px;
				@include font-size(20);
				margin-right: 5px;
			}
		}
	}
	&__answer{
		padding: 30px 15px 33px;
		border-radius: 3px;
		background: #e3feff;
		border:solid 3px #61BEF7;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin-bottom: 30px;
		&:last-child{
			margin-bottom: 0;
		}
		@include mq{
			padding: 10px 10px;
		}
		span{
			display: inline-flex;
			justify-content: center;
			align-items: center;
			width: 50px;
			height: 50px;
			border-radius: 50%;
			background:#61BEF7;
			font-weight: bold;
			color:#fff;
			@include font-size(36);
			line-height: .6;
			margin-right: 27px;
			@include mq{
				@include font-size(20);
				margin-right: 10px;
				width: 35px;
				height: 35px;
			}
		}
		.txt-basic{
			width: calc(100% - 77px);
			line-height: 1.75;
			
			@include mq{
				line-height: 1.5;
				width: calc(100% - 45px);
			}
			@include mq(sm-ip){
				@include font-size(16);
			}
		}
	}
}
.box-apply{
	margin-bottom: 58px;
	@include mq{
		margin-bottom: 30px;
	}
	&__ttl{
		color: #0060cb;
		font-weight: bold;
		@include font-size(24);
		text-align: center;
		margin-bottom: 10px;
		@include mq{
			@include font-size(18);
		}
	}
	img{
		display: block;
		margin: 0 auto;
		@include mq{
			max-width: 70%;
		}
	}
}
.sec-recruit-fresh{
	padding-bottom: 100px;
	@include mq{
		padding-bottom: 50px;
	}
	.inner{
		max-width: 1096px;
	}
}
