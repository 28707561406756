.sec-privacy{
	//margin-bottom: 100px;
	//@include mq{
	//	margin-bottom: 50px;
	//}
	.txt-basic{
		line-height: 1.75;
		@include mq{
			margin-bottom: 20px;
			&:last-child{
				margin-bottom: 0;
			}
		}
	}
	.txt-lg{
		@include mq{
			margin-bottom: 20px;
		}
	}
	.block-policy{
		&__list{
			padding-left: 50px;
			padding-right: 50px;
			@include mq{
				padding-left: 5.5vw;
				padding-right: 5.5vw;
			}
		}
	}
	.block-policy{
		.txt-basic{
			margin-bottom: 26px;
			@include mq{
				margin-bottom: 20px;
			}
			&:last-child{
				margin-bottom: 0;
			}
			&--bdbt{
				margin-bottom: 8px;
				line-height: 1.5;
			}
		}
		&__list{
			.list{
				padding-left: 17px;
				@include mq{
					padding-left: 2.5vw;
				}
				&--pdl{
					padding-left: 0;
				}
			}
			
		}
		&__item{
			margin-bottom: 28px;
			@include mq{
				margin-bottom: 20px;
			}
		}
	}
}
