.box-intro-nipearth{
	display: flex;
	align-items: center;
	padding: 0 200px 27px 150px;
	border-bottom: 10px solid rgba(#346EB1, 0.4);
	margin-bottom: 54px;
	@include mq(lg){
		padding: 0 60px 30px 60px;
	}
	@include mq(){
		flex-wrap: wrap;
		padding: 0 0 20px 0;
		margin-bottom: 30px;
	}
	.ttl{
		width: calc(100% - 213px);
		@include font-size(32);
		font-weight: bold;
		line-height: 1;
		color: #346EB1;
		@include mq(lg){
			@include font-size(28);
			line-height: 1.5;
		}
		@include mq(){
			width: 100%;
			@include font-size(24);
			text-align: center;
		}
	}
	.img{
		width: 213px;
		@include mq(){
			margin: 0 auto;
		}
	}
}
.box-special{
	display: flex;
	margin-bottom: 54px;
	@include mq(){
		flex-wrap: wrap;
		margin-bottom: 30px;
	}
	.box-circle{
		width: 112px;
		height: 112px;
		border-radius: 50%;
		background: #346EB1;
		display: flex;
		align-items: center;
		justify-content: center;
		@include font-size(24);
		font-weight: bold;
		color: #fff;
		@include mq(){
			@include font-size(20);
			margin: 0 auto 20px;
		}
		&--green {
			background: #4EB46E;
		}
	}
	.txt-dots{
		width: calc(100% - 112px);
		@include font-size(18);
		line-height: 1.8;
		padding-left: 16px;
		position: relative;
		top: -6px;
		@include mq(ipd) {
			@include font-size(16);
		}
		@include mq(){
			width: 100%;
			@include font-size(14);
			padding-left: 0;
			top: 0;
		}
		.dots-txt {
			margin-top: 30px;
			line-height: 1.75;
			.txt-lg {
				font-weight: bold;
				margin-bottom: 5px;
			}
		}
	}
}
.box-main-uses{
	margin-bottom: 70px;
	@include mq(){
		margin-bottom: 30px;
	}
	.tag-blue{
		margin-bottom: 6px;
		@include mq(){
			margin-bottom: 15px;
		}
	}
}
.box-specifications{
	.tag-blue{
		margin-bottom: 42px;
		@include mq(){
			margin-bottom: 15px;
		}
	}
	.list-item{
		display: flex;
		margin-bottom: 45px;
		@include mq(lg){
			margin: 0 -10px 45px -10px;
		}
		@include mq(){
			margin: 0 auto 30px;
			flex-wrap: wrap;
		}
		.item{
			width: calc(100% - 417px);
			display: flex;
			align-items: center;
			padding-right: 130px;
			@include mq(lg){
				width: 55%;
				padding: 0 10px;
			}
			@include mq(){
				width: 100%;
				padding-right: 0;
				margin-bottom: 20px;
				&:last-of-type{
					margin-bottom: 0;
				}
			}
			&:nth-of-type(2n){
				width: 417px;
				padding-right: 0;
				@include mq(lg){
					width: 45%;
				}
				@include mq(){
					width: 100%;
					padding-right: 0;
				}
			}
			&__name{
				max-width: 200px;
				width: 100%;
				height: 78px;
				border: 3px solid #346EB1;
				border-radius: 10px;
				@include font-size(36);
				font-weight: bold;
				line-height: 1;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #346EB1;
				margin-right: 15px;
				@include mq(lg){
					@include font-size(28);
					max-width: 150px;
					height: 50px;
				}
				@include mq(){
					@include font-size(24);
					margin-right: 10px;
					max-width: 150px;
					height: 50px;
				}
			}
			&__arrow{
				width: 70px;
				margin-right: 13px;
				@include mq(lg){
					margin-right: 10px;
					width: 50px;
				}
			}
			&__des{
				width: calc(100% - 270px);
				@include font-size(18);
				font-weight: bold;
				color: #346EB1;
				line-height: 1.6;
				@include mq(lg){
					width: calc(100% - 200px);
					@include font-size(16);
				}
			}
		}
	}
}
.box-chart{
	margin-bottom: 73px;
	@include mq(){
		margin-bottom: 30px;
	}
	.tag-blue{
		margin-bottom: 18px;
		@include mq(){
			margin-bottom: 15px;
		}
	}
	&-wp{
		display: flex;
		margin-right: -110px;
		@include mq(xl-1250){
			margin-right: 0;
		}
		@include mq(){
			flex-wrap: wrap;
			margin-right: 0;
		}
		.box-table02{
			width: 632px;
			@include mq(xl){
				width: 50%;
			}
			@include mq(){
				width: 100%;
			}
		}
		.img-chart{
			width: 524px;
			position: relative;
			right: -18px;
			top: 3px;
			@include mq(xl){
				width: 50%;
				position: static;
			}
			@include mq(){
				text-align: center;
				width: 100%;
				position: static;
			}
		}
	}
}
.box-internal{
	margin-bottom: 70px;
	@include mq(){
		margin-bottom: 30px;
	}
	.tag-blue{
		margin-bottom: 18px;
		@include mq(){
			margin-bottom: 15px;
		}
	}
	.item{
		display: flex;
		align-items: center;
		@include mq(sm){
			flex-wrap: wrap;
		}
		&__img{
			width: 350px;
			@include mq(sm){
				margin: 0 auto 15px;
			}
		}
		&__content{
			width: calc(100% - 350px);
			padding-left: 41px;
			@include font-size(20);
			line-height: 2;
			@include mq(){
				padding-left: 20px;
				@include font-size(16);
			}
			@include mq(sm){
				width: 100%;
				padding-left: 0;
			}
		}
	}
}
.box-instructions{
	.tag-blue{
		margin-bottom: 18px;
		@include mq(){
			margin-bottom: 15px;
		}
	}
	.list-steps-instructions{
		display: flex;
		justify-content: space-between;
		margin-bottom: 39px;
		@include mq(lg){
			margin-bottom: 30px;
			flex-wrap: wrap;
			margin: 0 -10px;
		}
		.item{
			@include mq(lg){
				margin-bottom: 25px;
				width: 33.33% !important;
				padding: 0 10px;
			}
			@include mq(lg){
				margin-bottom: 15px;
			}
			@include mq(sm){
				width: 50% !important;
			}
			&__ttl{
				display: flex;
				align-items: center;
				margin-bottom: 11px;
				@include mq(lg){
					justify-content: center;
				}
				.number{
					width: 30px;
					height: 30px;
					background: #346EB1;
					display: flex;
					align-items: center;
					justify-content: center;
					@include font-size(20);
					font-weight: bold;
					color: #fff;
					border-radius: 50%;
					margin-right: 10px;
					@include mq(){
						width: 25px;
						height: 25px;
						@include font-size(16);
						margin-right: 5px;
					}
				}
				.txt{
					@include font-size(18);
					font-weight: bold;
					color: #346EB1;
					@include mq(){
						@include font-size(16);
					}
				}
			}
			&__img{
				text-align: center;
			}
			&:nth-of-type(1){
				width: 11.11%;
			}
			&:nth-of-type(2){
				width: 12.61%;
			}
			&:nth-of-type(3){
				width: 17.6%;
			}
			&:nth-of-type(4){
				width: 13.18%;
			}
			&:nth-of-type(5){
				width: 12.71%;
			}
			&:nth-of-type(6){
				width: 12.71%;
			}
		}
	}
	.txt-contact{
		@include font-size(18);
		font-weight: bold;
		line-height: 1.6;
		margin-bottom: 48px;
		@include mq(){
			@include font-size(16);
			line-height: 1.5;
			margin-bottom: 30px;
		}
	}
}


.txt-number{
	@include font-size(20);
	line-height: 2;
	margin-bottom: 40px;
	@include mq(){
		@include font-size(16);
		margin-bottom: 20px;
		line-height: 1.6;
	}
}
.txt-table{
	@include font-size(16);
	margin-bottom: 10px;
	display: block;
	@include mq(){
		@include font-size(14);
	}
}
.box-table{
	margin-bottom: 68px;
	@include mq(){
		margin-bottom: 30px;
	}
	table{
		width: 100%;
		max-width: 833px;
		tr{
			th{
				font-weight: normal;
				width: 455px;
				background: #BBD6F5;
				@include font-size(18);
				line-height: 1.6;
				padding-left: 13px;
				padding-top: 2px;
				padding-bottom: 2px;
				border-bottom: 4px solid #fff;
				border-right: 4px solid #fff;
				@include mq{
					width: auto;
					@include font-size(14);
					line-height: 1.5;
					padding-left: 5px;
					padding-right: 5px;
					border-width: 2px;
				}
			}
			td{
				width: 115px;
				background: #F2F0ED;
				border-bottom: 4px solid #fff;
				border-right: 4px solid #fff;
				@include font-size(18);
				line-height: 1.6;
				padding-left: 10px;
				@include mq{
					width: auto;
					@include font-size(14);
					padding-left: 5px;
					padding-right: 5px;
					text-align: center;
					border-width: 2px;
				}
			}
		}
	}
}
.box-table02{
	table{
		border: 1px solid #303030;
		width: 100%;
		tr{
			td{
				border-bottom: 1px solid #303030;
				border-right: 1px solid #303030;
				background: #fff;
				@include font-size(18);
				line-height: 1.6;
				padding: 10px 13px;
				@include mq(){
					@include font-size(14);
					padding: 5px;
				}
			}
			&:first-of-type{
				td{
					text-align: center;
					padding: 13px 0;
					@include mq(){
						padding: 8px 5px;
					}
					&:first-of-type{
						width: 48.25%;
					}
					&:nth-of-type(2){
						width: 18.67%;
					}
					&:last-of-type{
						width: calc(100% - 48.25% - 18.67%);
					}
				}
			}
			&:nth-of-type(2){
				td{
					&:first-of-type{
						width: 15.82%;
					}
					&:nth-of-type(2){
						width: 32.59%;
					}
					&:nth-of-type(3){
						width: 18.67%;
					}
					&:nth-of-type(4){
						width: 17.24%;
					}
					&:last-of-type{
						width: 15.82%;
					}
				}
			}
		}
	}
}
.txt-dots{
	@include font-size(18);
	line-height: 1.8;
	@include mq(){
		@include font-size(16);
		line-height: 1.6;
	}
}
.tag-blue{
	max-width: 200px;
	min-height: 38px;
	display: flex;
	align-items: center;
	justify-content: center;
	@include font-size(20);
	font-weight: bold;
	color: #fff;
	line-height: 1;
	background: #346EB1;
	@include mq(){
		@include font-size(18);
		min-height: 30px;
	}
}

.mb100{
	margin-bottom: 100px !important;
	@include mq(){
		margin-bottom: 50px !important;
	}
}