.sec-catalog{
	margin-bottom: 66px;
	@include mq{
		margin-bottom: 25px;
	}
	.inner{
		&>.ttl-comp02{
			margin-bottom: 116px;
			@include mq{
				margin-bottom: 50px;
			}
		}
	}
	.block-catalog{
		margin-bottom: 50px;
		@include mq{
			margin-bottom: 25px;
		}
	}
	.mb-common{
		.ttl-comp02{
			margin-bottom: 13px;
			@include mq{
				margin-bottom: 13px;
			}
		}
	}
}
.list-file{
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-wrap: wrap;
	.item-file{
		width: calc(25% - 15px);
		margin-right: 20px;
		margin-bottom: 35px;
		
		&:nth-child(4n){
			margin-right: 0;
		}
		@include mq(xl){
			width: calc(33.33% - 13.33px);
			margin-right: 20px;
			&:nth-child(3n){
				margin-right: 0;
			}
			&:nth-child(2n),&:nth-child(4n){
				margin-right: 20px;
			}
		}
		@include mq{
			width: calc(50% - 10px);
			margin-right: 20px;
			&:nth-child(2n){
				margin-right: 0;
			}
			&:nth-child(3n){
				margin-right: 20px;
			}
		}
		@include mq(sm){
			width: 100%;
			margin-right: 0;
			margin-bottom: 25px;
		}
		.img{
			text-align: center;
			padding: 26px 5px;
			border: 1px solid #707070;
			margin-bottom: 15px;
			height: 285px;
			display: flex;
			align-items: center;
			justify-content: center;
			img{
				height: auto;
				width: auto;
				max-height: 100%;
			}
		}
	}
	
	.function-download{
		position: relative;
		a{
			@include font-size(18);
			line-height: 1.78;
			color: #0060CB;
			border: 1px solid #0060CB;
			background: #F2F0ED;
			padding: 21px 45px 21px 14px;
			display: inline-block;
			width: 100%;
			position: relative;
			z-index: 2;
			@include mq(xl){
				line-height: 1.6;
				@include font-size(16);
				padding: 21px 45px 21px 8px;
			}
			&:after{
				content: '';
				width: 36px;
				height: 36px;
				background: url("../img/catalog/icon-donwload.png") no-repeat center center;
				-webkit-background-size: cover;
				background-size: cover;
				display: inline-block;
				right: 9px;
				top: 50%;
				transform: translateY(-50%);
				position: absolute;
			}
		}
		
		.txt-nodownload{
			visibility:visible;
			width: 100%;
			background-color: #346EB1;
			color: #fff;
			text-align: center;
			border-radius: 6px;
			padding: 5px 0;
			position: absolute;
			z-index: 1;
			bottom: calc( 100% + 5px);
			left: 0;
			margin-left: 0;
			opacity: 1;
			transition: opacity 0.3s;
			@include font-size(14);
			&::after {
				content: "";
				position: absolute;
				top: 100%;
				left: 50%;
				margin-left: -5px;
				border-width: 5px;
				border-style: solid;
				border-color: #346EB1 transparent transparent transparent;
			}
		}
		
	}
}
