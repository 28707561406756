/* ====================================================
Font
==================================================== */


@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Raleway:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap');

$font-mincho: YuMincho,"游明朝", "游明朝体", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", 'Sawarabi Mincho',"Yu Mincho","ＭＳ 明朝", "MS Mincho", "HiraMinProN-W3", "TakaoEx明朝", TakaoExMincho, "MotoyaLCedar", "Droid Sans Japanese", serif;
$font-meiryo: "Meiryo","メイリオ","ヒラギノ角ゴPro W3","Hiragino Kaku Gothic Pro", Osaka,"MS Pゴシック",Arial, Helvetica, Verdana,sans-serif;
$font-raleway: 'Raleway', sans-serif;
$font-Roboto: 'Roboto';

@mixin font-size($sizeValue) {
	font-size: ($sizeValue) + px;
	font-size: ($sizeValue / 10) + rem;
}


@mixin v-align {
	top: 50%;
	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}

@mixin h-align {
	left: 50%;
	-webkit-transform: translateX(-50%);
	   -moz-transform: translateX(-50%);
	    -ms-transform: translateX(-50%);
	     -o-transform: translateX(-50%);
	        transform: translateX(-50%);
}

@mixin v-h-align {
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	   -moz-transform: translate(-50%, -50%);
	    -ms-transform: translate(-50%, -50%);
	     -o-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}

@mixin smooth-transition {
	-webkit-backface-visibility: hidden;
	-webkit-transition: all .3s ease;
	   -moz-transition: all .3s ease;
	    -ms-transition: all .3s ease;
	     -o-transition: all .3s ease;
	        transition: all .3s ease;
}



/* ====================================================
Media Quary
==================================================== */
@mixin mq($breakpoint: md) {
	@media #{map-get($breakpoints, $breakpoint)} {
		@content;
	}
}

$breakpoint-sm: 640px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 990px !default;

$breakpoints: (
		'sm-ip': 'screen and (max-width: 330px)',
		'xs': 'screen and (max-width: 374px)',
		'xs-large': 'screen and (max-width: 560px)',
		'sm': 'screen and (max-width: 640px)',
		'sm-420': 'screen and (max-width: 420px)',
		'md': 'screen and (max-width: 768px)',
		'lg': 'screen and (max-width: 990px)',
		'ipd': 'screen and (max-width: 1024px)',
		'xl': 'screen and (max-width: 1200px)',
		'xl-1250': 'screen and (max-width: 1250px)',
		'xl-1350': 'screen and (max-width: 1350px)',
		'lp': 'screen and (max-width: 1440px)',
		'mac': 'screen and (max-width: 1770px)',
		'xs-min': 'screen and (min-width: 375px)',
		'sm-min': 'screen and (min-width: 641px)',
		'md-min': 'screen and (min-width: 769px)',
		'lg-min': 'screen and (min-width: 991px)',
		'ipd-min': 'screen and (min-width: 1025px)',
		'xl-min': 'screen and (min-width: 1201px)',
) !default;


//marginPC and SP
@for $i from 0 through 100 {
	@include mq(md-min){
		.mbpc-#{$i} {
			margin-bottom: $i + px !important;
		}
	}
	@include mq(){
		.mbsp-#{$i} {
			margin-bottom: $i + px !important;
		}
	}
}