.txt-des{
	font-family: $font-meiryo;
	@include font-size(20);
	line-height: 2;
	color: #303030;
	font-weight: 400;
	max-width: 1065px;
	margin: 0 auto 61px;
	@include mq{
		margin: 0 auto 45px;
		@include font-size(16);
		line-height: 1.75;
	}
}
.sec-resolve {
	padding-bottom: 100px;
	@include mq{
		padding-bottom: 60px;
	}
}
.block-resolve{
	.ttl-list{
		font-family: $font-meiryo;
		@include font-size(38);
		line-height: 1.2;
		color: #346EB1;
		position: relative;
		padding: 14px 0 0px;
		text-align: left;
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		min-height: 148px;
		margin-bottom: 9px;
		@include mq(xl-1350){
			padding-top: 0;
			min-height: 135px;
		}
		@include mq(xl){
			min-height: auto;
			padding: 0 ;
			max-height: 65px;
			@include font-size(30);
			margin-bottom: 20px;
		}
		@include mq(ipd){
			@include font-size(28);
			max-height: 50px;
		}
		@include mq(lg){
			max-height: 100%;
		}
		@include mq{
			@include font-size(24);
			margin-bottom: 25px;
		}
		&__icon{
			padding-left: 0;
			width: 200px;
			display: flex;
			align-items: flex-start;
			justify-content: center;
			@include mq(xl){
				width: 44%;
				padding-left: 0px;
				height: 60px;
			}
			@include mq(ipd){
				height: 50px;
			}
			@include mq(lg){
				height: auto;
			}
			@include mq{
				height: 70px;
				width: 40%;
			}
			img{
				@include mq(xl){
					height: 100%;
					width: auto;
				}
				
				
			}
		}
		&__txt{
			width: calc( 100% - 200px);
			padding-left: 56px;
			@include mq(xl){
				width: 56%;
				padding-left: 30px;
			}
			@include mq{
				width: 60%;
			}
			
		}
	}
	.item{
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
		background: #F2F0ED;
		.img-resolve{
			width: 50%;
			@include mq(lg){
				width: 100%;
				
			}
			img{
				@include mq(lg){
					width: 100%;
				}
			}
		}
		.txt-resolve{
			width: 50%;
			padding:35px 35px 29px;
			@include mq(xl-1350){
				padding:2.5vw 2.5vw 2.5vw ;
			}
			@include mq(xl){
				display: flex;
				align-items: center;
			}
			@include mq(lg){
				width: 100%;
				padding: 35px;
			}
			@include mq{
				padding: 24px 19px 33px;
				overflow: hidden;
			}
			.txt-basic{
				margin-bottom:20px ;
				line-height: 1.75;
				@include mq{
					margin-bottom: 22px;
					min-height: 117px;
				}
			}
		}
		
		&:nth-child(2n){
			flex-direction: row-reverse;
		}
		
		&.fade-reverse {
			.txt-resolve__block {
				opacity: 0;
				-webkit-transform: translate(-70px, 0px);
				-ms-transform: translate(-70px, 0px);
				transform: translate(-70px, 0px);
				-webkit-transition: opacity 1s, -webkit-transform 1s;
				transition: opacity 1s, -webkit-transform 1s;
				-o-transition: transform 1s, opacity 1s;
				transition: transform 1s, opacity 1s;
				transition: transform 1s, opacity 1s, -webkit-transform 1s;
			}
			&:nth-child(even) {
				.txt-resolve__block {
					-webkit-transform: translate(70px, 0px);
					-ms-transform: translate(70px, 0px);
					transform: translate(70px, 0px);
				}
			}
			&.active {
				.txt-resolve__block {
					-webkit-transform: translate(0px, 0px);
					-ms-transform: translate(0px, 0px);
					transform: translate(0px, 0px);
					opacity: 1;
				}
			}
		}
	}
	.btn-comp01{
			@include font-size(16);
		font-weight: 500;
		&--modify{
			@include font-size(14);
			font-weight: 400;
			@include mq{
				margin-bottom: 12px;
			}
			&.sp{
				margin-bottom: 0;
			}
		}
	}
}
.btn-comp01--modify{
	justify-content: center;
	&:after{
		content: none;
		
	}
}