.sec-torinip {
	margin-bottom: 101px;
	@include mq {
		margin-bottom: 50px;
	}
}

.torinip-head {
	@include mq(md-min) {
		padding-bottom: 21px;
		&__wrap {
			align-items: center;
			.intro-head {
				&__ttl {
					line-height: 1.875;
					padding-top: 6px;
				}
				&__ttl, &__img {
					width: auto;
				}
			}
		}
	}
}

.torinip-intro {
	margin-bottom: 76px;
	@include mq {
		margin-bottom: 50px;
	}
	&__flex {
		display: flex;
		align-items: center;
		@include font-size(20);
		line-height: 2;
		margin-bottom: 25px;
		@include mq(lg) {
			@include font-size(18);
		}
		@include mq {
			@include font-size(16);
			margin-bottom: 15px;
			flex-wrap: wrap;
		}
		@include mq(xs) {
			@include font-size(14);
		}
	}
	&__img {
		width: 267px;
		@include mq {
			width: 100%;
			text-align: center;
			margin-bottom: 15px;
		}
	}
	&__txt {
		width: calc(100% - 267px);
		padding: 0 10px 9px;
		@include mq {
			width: 100%;
			padding: 0;
		}
	}
}

.box-pigeon {
	margin-bottom: 70px;
	@include mq {
		margin-bottom: 50px;
	}
	&__block {
		margin-bottom:62px;
		overflow: hidden;
		@include mq {
			margin-bottom: 50px;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
	&__img {
		margin-bottom: 25px;
	}
	&__ttl {
		font-weight: bold;
		margin-bottom: 3px;
	}
	&__txt {
		line-height: 1.75;
	}
}
.pigeon {
	&-list {
		display: flex;
		text-align: center;
		justify-content: center;
		margin: 0 0 24px;
		@include mq(lg) {
			@include font-size(13);
		}
		@include mq {
			@include font-size(12);
			width: 100%;
			max-width: 600px;
			margin: 0 auto 30px;
			flex-wrap: wrap;
		}
		@include mq(sm) {
			display: block;
		}
		
		&__item {
			margin-right: 60px;
			@include mq(lg) {
				margin-right: 20px;
			}
			@include mq {
				width: 50%;
				margin: 0 0 20px;
				&:last-child {
					margin-bottom: 0;
				}
			}
			@include mq(sm) {
				width: 100%;
			}
			&:last-child {
				margin-right: 0;
			}
		}
		&__img {
			display: flex;
			align-items: flex-end;
			justify-content: center;
			margin-bottom: 10px;
			@include mq(lg) {
				@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
					display: block;
				}
			}
		}
	}
}