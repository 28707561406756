
.list-group{
	li{
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		align-content: center;
		padding: 20px 0 10px;
		min-height: 150px;
		border-bottom: 1px solid #707070;
		@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
			min-height: 50px;
			padding: 40px 0;
		}
		@include mq{
			min-height: 120px;
			padding: 20px 0;
		}
	}
	span{
		display: inline-block;
		width: 22.5%;
		font-weight: bold;
		@include font-size(18);
		line-height: 1.33;
		color:#0060CB;
		padding-right: 10px;
		&.txt-black{
			color:#303030;
		}
		@include mq(lg){
			@include font-size(16);	
			width: 26%;	
		}
		@include mq{
			@include font-size(14);	
			width: 70%;	
		}
	}
	.txt-basic{
		width: 57.5%;
		line-height: 1.875;
		@include mq(lg){
			width: 54%;		
		}
		@include mq{
			line-height: 1.5;
			width: 100%;
			margin-top: 20px;
		}
	}
	&__img{
		width: 20%;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		padding: 0 10px;
		@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
display: block;
		}
		img{
			max-width: 90%;
			display: block;
		}
		@include mq{
			width: 30%;
			justify-content: flex-start;
			padding-left: 0;
			img{
				width: auto;
			}
		}
	}
}

.sec-content{
	padding-bottom: 100px;
	@include mq{
		padding-bottom: 50px;
	}
}