.page-service_resistance_analysis_index{
	.box-equipment-inspection{
		@include mq() {
			margin-bottom: 40px;
		}
	}
	.ttl-comp01{
		@include mq() {
			margin-bottom: 10px;
		}
	}
}