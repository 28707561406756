.sec-items-search{
	&.result{
		@include mq() {
			padding-bottom: 50px;
		}
	}
}
.item-back-btn{
	max-width: 416px;
	margin: 0 auto;
	a{
		display: block;
		height: 64px;
		line-height: 60px;
		border: 2px solid #346EB1;
		@include font-size(26);
		text-align: center;			
		color: #346EB1;
		border-radius: 15px;
		@include mq() {
			@include font-size(20);
			height: 54px;
			line-height: 50px;
		}
		&:hover{
			background: #346EB1;
			opacity: 1;
			color: #FFF;
		}
	}
}
.item-search-result{
	margin: 60px 0;
	padding-bottom: 20px;
	border-bottom: 1px solid #707070;
	@include mq() {
		margin-top: 20px;
		margin-bottom: 50px;
		border: none;
		padding-bottom: 0;
	}
	.result-item{
		@include font-size(18);
		margin-bottom: 25px;
		@include mq() {
			align-items: flex-start;
			@include font-size(14);
			margin-bottom: 20px;
		}
		&__wrap{
			display:-webkit-box; // old
			display:-moz-box; // old
			display:-ms-flexbox; // ie
			display:-webkit-flex; // new
			display:flex; // new
			flex-wrap: wrap;
			align-items: center;
			&:hover{
				opacity: 1;
				.result-item__ttl{
					text-decoration: underline;
				}
			}
		}
		&__img{
			width: 80px;
			min-height: 80px;
			&__inner{
				overflow: hidden;
				position: relative;
				border: 1px solid #707070;
				&::after{
					content: "";
					display: block;
					padding-top: 100%;
				}
				img{				
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					height: auto;
					width: auto;
					max-width: 97%;
					max-height: 97%;
					margin: auto;
				}
			}
		}
		&__inner{
			padding-left: 15px;
			display:-webkit-box; // old
			display:-moz-box; // old
			display:-ms-flexbox; // ie
			display:-webkit-flex; // new
			display:flex; // new
			flex-wrap: wrap;
			align-items: center;
			@include mq(md-min) {
				flex: 1;
			}
			@include mq() {
				display: block;
				width: calc(100% - 80px);
			}
		}
		&__code{
			width: 15%;
			padding-right: 20px;
			color: #0060CB;
			word-break: break-all;
			@include mq() {
				width: 100%;
				padding: 0;
				margin-bottom: 5px;
			}
		}
		&__ttl{
			width: 41%;
			font-weight: bold;
			padding-right: 20px;
			@include mq() {
				width: 100%;
				padding: 0;
			}
		}
		&__category{
			width: 22%;
			padding-right: 20px;
			@include mq() {
				display: none;
			}
		}
		&__type{
			width: 22%;
			@include mq() {
				display: none;
			}
		}
	}
}